import React, { useState } from 'react'
import styles from "./sold.module.css";
import PDF from "../../DealerSell/components/PDF.jsx";
import { useSelector } from 'react-redux';
import {
  Button,
  Col,
  Container,
  Row,
  Table,
  OverlayTrigger,
  Tooltip,
  Modal,
  Form,
} from "react-bootstrap";


const SoldListPreview = ({show,
    handleClose,
    data,}) => {
        //Preview Modal
  const userData = useSelector((state) => state.user);
  const pdfArray = [
    `title_issuance_${data?.addListing2?.vin?.slice(-8)}${userData.id}.pdf`,
    `tax_exemption_${data?.addListing2?.vin?.slice(-8)}${userData.id}.pdf`,
    `limited_attorney_${data?.addListing2?.vin?.slice(-8)}${userData.id}.pdf`,
    `buyers_guide_${data?.addListing2?.vin?.slice(-8)}${userData.id}.pdf`,
    `rebuilt_disclosure_${data?.addListing2?.vin?.slice(-8)}${userData.id}.pdf`,
    `title_registration_${data?.addListing2?.vin?.slice(-8)}${userData.id}.pdf`,
    `billOfSale_${data?.addListing2?.vin?.slice(-8)}${userData.id}.pdf`,
    `odometer_statement_${data?.addListing2?.vin?.slice(-8)}${userData.id}.pdf`,
    `salesContract_${data?.addListing2?.vin?.slice(-8)}${userData.id}.pdf`,
  ];
  console.log("pdfArray =>", data);

  //Checkboxes for preview pdfs
  const [checkboxes, setCheckboxes] = useState({
    billOfSale: false,
    buyerGuide: false,
    odometerDisclosure: false,
    titleApp: false,
    AgreementInsurance: false,
    creditBureauDisclosure: false,
    rebuildTitleDisclosure: false,
    powerOfAttorney: false,
    retailContractInstallment: false,
    salesTaxExemptionCertificate: false,
  });

  const PDFTOSHOWArray = [
    {
      pdf: pdfArray && pdfArray[0],
      isShow: checkboxes && checkboxes.AgreementInsurance,
    },
    {
      pdf: pdfArray && pdfArray[1],
      isShow: checkboxes && checkboxes.salesTaxExemptionCertificate,
    },
    {
      pdf: pdfArray && pdfArray[2],
      isShow: checkboxes && checkboxes.powerOfAttorney,
    },
    {
      pdf: pdfArray && pdfArray[3],
      isShow: checkboxes && checkboxes.buyerGuide,
    },
    {
      pdf: pdfArray && pdfArray[4],
      isShow: checkboxes && checkboxes.rebuildTitleDisclosure,
    },
    {
      pdf: pdfArray && pdfArray[5],
      isShow: checkboxes && checkboxes.titleApp,
    },
    {
      pdf: pdfArray && pdfArray[6],
      isShow: checkboxes && checkboxes.billOfSale,
    },
    {
      pdf: pdfArray && pdfArray[7],
      isShow: checkboxes && checkboxes.odometerDisclosure,
    },
    {
      pdf: pdfArray && pdfArray[8],
      isShow: checkboxes && checkboxes.retailContractInstallment,
    },
  ];

  const AllCheckBoxesFromAllForms = [
    {
      id: "AgreementInsurance",
      label: "Agreement To Provide Insurance",
    },
    { id: "billOfSale", label: "Bill of Sale /Buyer Order" },
    { id: "buyerGuide", label: "Buyer Guide" },
    {
      id: "creditBureauDisclosure",
      label: "Credit Bureau Disclosure",
    },
    { id: "odometerDisclosure", label: "Odometer Disclosure" },
    {
      id: "rebuildTitleDisclosure",
      label: "Rebuilt Title Disclosure",
    },
    { id: "titleApp", label: `Title App I130u` },
    {
      id: "powerOfAttorney",
      label: ` Power Of Attorney`,
    },
    {
      id: "retailContractInstallment",
      label: `Retail Contract Installment`,
    },
    {
      id: "salesTaxExemptionCertificate",
      label: ` Sales Tax Exemption Certificate`,
    },
  ];
  const [showPdfs, setShowPdfs] = useState(false);
  const [selectAll, setSelectAll] = useState(false);

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    setCheckboxes((prevData) => ({
      ...prevData,
      billOfSale: !selectAll,
      buyerGuide: !selectAll,
      odometerDisclosure: !selectAll,
      titleApp: !selectAll,
      AgreementInsurance: !selectAll,
      creditBureauDisclosure: !selectAll,
      rebuildTitleDisclosure: !selectAll,
      powerOfAttorney: !selectAll,
      retailContractInstallment: !selectAll,
      salesTaxExemptionCertificate: !selectAll,
      saleContract: !selectAll,
    }));
  };
  const [showDeleteOptions, setShowDeleteOptions] = useState(false);
  const [selectedDeleteOption, setSelectedDeleteOption] = useState("");
  const [selectedDeletePurpose, setSelectedDeletePurpose] = useState("");

  // console.log("selectedDeletePurpose  ", selectedDeletePurpose);

  const handleDeleteCheck = (e) => {
    const { value } = e.target;
    setSelectedDeleteOption(value);
    if (value === "deleteOption1") {
      setSelectedDeletePurpose("delete");
    } else {
      setSelectedDeletePurpose("active");
    }
  };
  //sell/toggle_status/delete/sellid/userid
  //sell/active/sellid/userid
  console.log("data of the current product in sold inventory => ", data);
  const handleCheckboxChange = (e, manualId = null) => {
    const id = manualId || e.target.id;
    const checked = manualId ? !checkboxes[id] : e.target.checked;

    setCheckboxes((prev) => ({
      ...prev,
      [id]: checked,
    }));
  };
  return (
    <Modal
                 show={show} size="xl" onHide={handleClose}  dialogClassName="modal-90w"
                  aria-labelledby="example-custom-modal-styling-title"
                >
                  <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                      Sold car details summary
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className={styles.soldSummaryWrapper}>
                      <div className={styles.soldSummaryContainer}>
                        <p className={styles.soldSubHeader}>
                          <span>Buyer Info</span>
                        </p>
                        <p>
                          <span style={{ borderBottom: "1px solid black" }}>
                            Buyer
                          </span>
                        </p>
                        <div className={styles.soldSubContainer}>
                          <p>
                            Name : {data?.firstName || "Name not available"}{" "}
                            {data?.middleName || ""} {data?.lastName || ""}
                          </p>
                          <p>
                            Phone No. : {data?.phoneNumber || "Not Available"}
                          </p>
                          <p>Email address : {data?.email || "Not Available"}</p>
                          <p>ID Type : {data?.idType || "Not Available"}</p>
                          <p>ID State : {data?.idState || "Not Available"}</p>
                          <p>ID Number : {data?.idNumber || "Not Available"}</p>
                          <p>Address : {data?.address || "Not Available"}</p>
                          <p>Address 2 : {data?.address2 || "Not Available"}</p>
                          <p>County : {data?.county || "Not Available"}</p>
                        </div>
                        <p>
                          <span style={{ borderBottom: "1px solid black" }}>
                            Co-Buyer
                          </span>
                        </p>
                        <div className={styles.soldSubContainer}>
                          <p>
                            Name :{" "}
                            {data?.sellCoBuyer?.firstName || "Name not available"}{" "}
                            {data?.sellCoBuyer?.middleName || ""}{" "}
                            {data?.sellCoBuyer?.lastName || ""}
                          </p>
                          <p>
                            Phone No. :{" "}
                            {data?.sellCoBuyer?.phoneNumber || "Not Available"}
                          </p>
                          <p>
                            Email address :{" "}
                            {data?.sellCoBuyer?.email || "Not Available"}
                          </p>
                          <p>
                            ID Type :{" "}
                            {data?.sellCoBuyer?.idType || "Not Available"}
                          </p>
                          <p>
                            ID State :{" "}
                            {data?.sellCoBuyer?.idState || "Not Available"}
                          </p>
                          <p>
                            ID Number :{" "}
                            {data?.sellCoBuyer?.idNumber || "Not Available"}
                          </p>
                          <p>
                            Address :{" "}
                            {data?.sellCoBuyer?.address || "Not Available"}
                          </p>
                          <p>
                            Address 2 :{" "}
                            {data?.sellCoBuyer?.address2 || "Not Available"}
                          </p>
                          <p>
                            County :{" "}
                            {data?.sellCoBuyer?.county || "Not Available"}
                          </p>
                        </div>
                      </div>
                      <div className={styles.soldSummaryContainer}>
                        <p className={styles.soldSubHeader}>
                          <span>Vehicle Selected</span>
                        </p>
                        <div className={styles.soldSubContainer}>
                          {/* <p>VIN Number : {data.vin || "Not Available"} </p> */}
                          <p>
                            Make : {data?.addListing2?.make || "Not Available"}{" "}
                          </p>
                          <p>
                            Model :{" "}
                            {data?.addListing2?.makeModel || "Not Available"}
                          </p>
                          <p>
                            Year :{" "}
                            {data?.addListing2?.modelYear || "Not Available"}
                          </p>
                          <p>
                            Trim : {data?.addListing2?.trim || "Not Available"}
                          </p>
                          <p>
                            Exterior Color :{" "}
                            {data?.addListing2?.exteriorColor ||
                              "Not Available"}
                          </p>
                          <p>
                            Interior Color :{" "}
                            {data?.addListing2?.interiorColor ||
                              "Not Available"}
                          </p>
                          <p>
                            Condition :{" "}
                            {data?.addListing2?.condition || "Not Available"}
                          </p>
                          <p>
                            Engine :{" "}
                            {data?.addListing2?.engine || "Not Available"}
                          </p>
                          <p>
                            Fuel Type :{" "}
                            {data?.addListing2?.fuelType || "Not Available"}
                          </p>
                          <p>
                            Mileage :{" "}
                            {data?.addListing2?.mileage_in || "Not Available"}
                          </p>
                          <p>
                            Transmission :{" "}
                            {data?.addListing2?.transmission.trim() ||
                              "Not Available"}
                          </p>
                          <p>
                            Drive Train :{" "}
                            {data?.addListing2?.driveTrain || "Not Available"}
                          </p>
                          <p>
                            Doors :{" "}
                            {data?.addListing2?.doorCount || "Not Available"}
                          </p>
                          <p>
                            City MPG :{" "}
                            {data?.addListing2?.doorCount || "Not Available"}
                          </p>
                          <p>
                            Highway MPG :{" "}
                            {data?.addListing2?.highway_mpg || "Not Available"}
                          </p>
                        </div>
                      </div>
                      <div className={styles.soldSummaryContainer}>
                        <p className={styles.soldSubHeader}>
                          <span>Price and Fees</span>
                        </p>
                        <div className={styles.soldSubContainer}>
                          {/* <p>VIN Number : {data.vin || "Not Available"} </p> */}
                          <p>
                            Documentation Fees :
                            {data?.sellPriceFees?.documentationFee
                              ? ` $${data?.sellPriceFees?.documentationFee}`
                              : "Not Available"}
                          </p>
                          <p>
                            Drive Out Tag :
                            {data?.sellPriceFees?.drivesOutTag
                              ? ` $${data?.sellPriceFees?.drivesOutTag}`
                              : "Not Available"}
                          </p>
                          <p>
                            Title App Fee :
                            {data?.sellPriceFees?.titleApplicationFee
                              ? ` $${data?.sellPriceFees?.titleApplicationFee}`
                              : "Not Available"}
                          </p>
                          <p>
                            TERP Title Fee :
                            {data?.sellPriceFees?.terpTitlefee
                              ? ` $${data?.sellPriceFees?.terpTitlefee}`
                              : "Not Available"}
                          </p>
                          <p>
                            Buyer Tag :
                            {data?.sellPriceFees?.buyerTagFees
                              ? ` $${data?.sellPriceFees?.buyerTagFees}`
                              : "Not Available"}
                          </p>
                          <p>
                            Wind-Shield Sticker :
                            {data?.sellPriceFees?.windshieldTrackerSticker
                              ? ` $${data?.sellPriceFees?.windshieldTrackerSticker}`
                              : "Not Available"}
                          </p>
                          <p>
                            REG. FEE-DPS :
                            {data?.sellPriceFees?.regFeeDps
                              ? ` $${data?.sellPriceFees?.regFeeDps}`
                              : "Not Available"}
                          </p>
                          <p>
                            CNTY ROAD Bridge Add on Fee :
                            {data?.sellPriceFees?.cntyRoadBridgeFee
                              ? ` $${data?.sellPriceFees?.cntyRoadBridgeFee}`
                              : "Not Available"}
                          </p>
                          <p>
                            Child Safety Fee :
                            {data?.sellPriceFees?.childSafetyFund
                              ? ` $${data?.sellPriceFees?.childSafetyFund}`
                              : "Not Available"}
                          </p>
                          <p>
                            Inspection Fee :
                            {data?.sellPriceFees?.inspectionFeeOBDNL
                              ? ` $${data?.sellPriceFees?.inspectionFeeOBDNL}`
                              : "Not Available"}
                          </p>
                          <p>
                            Process Handling Fee :{" "}
                            {data?.sellPriceFees?.processingHandlingFee
                              ? ` $${data?.sellPriceFees?.processingHandlingFee}`
                              : "Not Available"}
                          </p>
                          <p>
                            Card Fees :{" "}
                            {data?.sellPriceFees?.cardFees
                              ? ` $${data?.sellPriceFees?.cardFees}`
                              : "Not Available"}
                          </p>
                          <p>
                            Service Contract :{" "}
                            {data?.sellPriceFees?.warranty
                              ? ` $${data?.sellPriceFees?.warranty}`
                              : "Not Available"}
                          </p>
                          <p>
                            Gap Insurance :
                            {data?.sellPriceFees?.gapInsurance
                              ? ` $${data?.sellPriceFees?.gapInsurance}`
                              : "Not Available"}
                          </p>
                          <p>
                            Mail Delivery Fees :{" "}
                            {data?.sellPriceFees?.mailDeliveryFees
                              ? ` $${data?.sellPriceFees?.mailDeliveryFees}`
                              : "Not Available"}
                          </p>
                          <p>
                            Transportation Fees :{" "}
                            {data?.sellPriceFees?.transportationFees
                              ? ` $${data?.sellPriceFees?.transportationFees}`
                              : "Not Available"}
                          </p>
                          <p>
                            Sale Price :{" "}
                            {data?.sellPriceFees?.listingPrice
                              ? ` $${data?.sellPriceFees?.listingPrice}`
                              : "Not Available"}
                          </p>
                          <p>
                            Total Taxes :{" "}
                            {data?.sellPriceFees?.totalFees
                              ? ` $${data?.sellPriceFees?.totalFees}`
                              : "Not Available"}
                          </p>
                          <p>
                            Total Fees And Others :{" "}
                            {data?.sellPriceFees?.totalFeesAndothers
                              ? ` $${data?.sellPriceFees?.totalFeesAndothers}`
                              : "Not Available"}
                          </p>
                          <p>
                            TOTAL DUE :{" "}
                            {data?.sellPriceFees?.cashDifference
                              ? ` $${data?.sellPriceFees?.cashDifference}`
                              : "Not Available"}
                          </p>
                          <p>
                            Payment :{" "}
                            {data?.sellPriceFees?.paymentPriceFees
                              ? ` $${data?.sellPriceFees?.paymentPriceFees}`
                              : "Not Available"}
                          </p>
                          <p>
                            Balance Due :{" "}
                            {data?.sellPriceFees?.transportationFees
                              ? ` $${data?.sellPriceFees?.transportationFees}`
                              : "Not Available"}
                          </p>
                          <p>
                            Payment Method :{" "}
                            {data?.sellPriceFees?.priceFeesPaymentMethod
                              ? ` $${data?.sellPriceFees?.priceFeesPaymentMethod}`
                              : "Not Available"}
                          </p>
                        </div>
                      </div>
                      {/* PDFs Starts here */}
                      <div className={styles.soldSummaryContainer}>
                        <p
                          className={styles.soldSubHeader}
                          style={{ textAlign: "center", paddingRight: "17rem" }}
                        >
                          <span>Forms Printed</span>
                          <br />
                          <label htmlFor="selectAll">
                            <input
                              type="checkbox"
                              onChange={handleSelectAll}
                              id="selectAll"
                              value={selectAll}
                            />{" "}
                            {selectAll ? "DeSelect All" : "Select All"}
                          </label>
                        </p>
                        {/* checkboxes here */}
                        <div
                          style={{
                            display: "flex",
                            gap: "4rem",
                            justifyContent: "center",
                          }}
                        >
                          <div>
                            {" "}
                            {AllCheckBoxesFromAllForms.map(({ id, label }) => (
                              <Form.Group
                              key={id}
                              className="mb-2 flex align-items-center justify-content-center"
                            >
                              <Form.Check
                                type="checkbox"
                                id={id}
                                checked={checkboxes[id]}
                                onChange={handleCheckboxChange}
                                label={
                                  <span
                                    onClick={(e) => {
                                      e.preventDefault();
                                      handleCheckboxChange(null, id);
                                    }}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {label}
                                  </span>
                                }
                                className="form-option border-bottom pb-2"
                                style={{
                                  display: "flex",
                                  justifyContent: "start",
                                  alignItems: "center",
                                  gap: "1rem",
                                  paddingLeft: "30px",
                                }}
                              />
                            </Form.Group>
                            ))}
                          </div>
                          <div
                            style={{
                              
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              width: "10%",
                            }}
                          >
                            <button
                              onClick={() => setShowPdfs(true)}
                              className="bg-orange border-none color-white "
                              style={{
                                border: "none",
                                color: "white",
                                fontWeight: "500",
                                padding: "0.5rem 1rem",
                                cursor: Object.values(checkboxes).every(
                                  (item) => item === false
                                )
                                  ? "not-allowed"
                                  : "pointer",
                                color: Object.values(checkboxes).every(
                                  (item) => item === false
                                )
                                  ? "grey"
                                  : "white",
                                backgroundColor: Object.values(
                                  checkboxes
                                ).every((item) => item === false)
                                  ? "#F2D2BD"
                                  : "orange",
                              }}
                              disabled={Object.values(checkboxes).every(
                                (item) => item === false
                              )}
                            >
                              Preview
                            </button>
                          </div>
                          {/* Modal for pdf here */}
                          <Modal
                            show={showPdfs}
                            onHide={() => setShowPdfs(false)}
                            size="xl"
                            dialogClassName="modal-70w"
                          >
                            <Modal.Header closeButton>
                              <Modal.Title>
                                Documents Printed while finalising the deal
                              </Modal.Title>
                            </Modal.Header>
                            {PDFTOSHOWArray.some((item) => item.isShow) && (
                              <Modal.Body className="h-100 ">
                               

                                <PDF
                                  pdfArray={PDFTOSHOWArray.filter(
                                    (item) => item.isShow
                                  )}
                                />
                              </Modal.Body>
                            )}
                          </Modal>
                        </div>
                      </div>
                     
                    </div>
                  </Modal.Body>
                </Modal>
  )
}

export default SoldListPreview