import React from "react";
import "./Styles/WarningCenter.css";
import { BsInfoCircle } from "react-icons/bs";
import { BsPersonUp } from "react-icons/bs";
import { PiShareFatFill } from "react-icons/pi";
import { MdOutlineMarkEmailRead } from "react-icons/md";
import { MdOutlineDelete } from "react-icons/md";

const WarningCenter = () => {
  const warnings = [
    { id: 1, title: "Inventory Tax Reminder" },
    { id: 2, title: "Comptroller Tax Reminder" },
    { id: 3, title: "Federal Tax Due" },
    { id: 4, title: "State Tax Due" },
  ];

  const actions = [
    { name: "Important", buttonClass: "btn-important", symbol: <BsPersonUp /> },
    {
      name: "Assign To",
      buttonClass: "btn-assign",
      symbol: <PiShareFatFill />,
    },
    {
      name: "Read",
      buttonClass: "btn-read",
      symbol: <MdOutlineMarkEmailRead />,
    },
    { name: "Delete", buttonClass: "btn-delete", symbol: <MdOutlineDelete /> },
  ];
  return (
    <div className="warning_center_wrapper">
      <div className="warning_center_container">
        <p className="warning_center_header">
          <span>
            <BsInfoCircle />
          </span>
          <span>Warning Center</span>
        </p>
        <div className="warning-message">
          <p>
            This is a warning message in this box for coding and typing only to
            test this page with any thing you need to Know about this how is
            going to be able to work
          </p>
        </div>
        {/* <div className="warning-actions">
        <button>Important</button>
        <button>Assign To</button>
        <button>Read</button>
        <button>Delete</button>
      </div>
      <table className="warning-table">
        <tbody>
          {warnings.map((warning) => (
            <tr key={warning.id}>
              <td>{warning.id}.</td>
              <td>{warning.title}</td>
              <td><button className="btn-important">!</button></td>
              <td><button className="btn-assign">↗</button></td>
              <td><button className="btn-read">✉</button></td>
              <td><button className="btn-delete">🗑</button></td>
            </tr>
          ))}
        </tbody>
      </table> */}

        <table className="warning-table">
          <thead>
            <tr>
              <th colSpan="2"></th>
              {actions.map((action, index) => (
                <th key={index} style={{color : "white", fontWeight : "600", paddingLeft : "1rem"}}>{action.name}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {warnings.map((warning) => (
              <tr key={warning.id} className="row_detail_warning">
                <td>{warning.id}.</td>
                <td>{warning.title}</td>
                {actions.map((action, index) => (
                  <td key={index}>
                    <button className={`${action.buttonClass} symbol_container`} >
                      {action.symbol}
                    </button>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default WarningCenter;
