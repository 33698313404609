import React, { useEffect, useRef, useState } from "react";
import Styles from "../Styles/InputFieldComp.module.css";
import { BsCurrencyDollar } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { setRetailData } from "../../../../../Redux/slice/taxes&FeesSlice";
import Image from "../../../../imgData";
import { MdOutlineEdit } from "react-icons/md";
import ediIcon from "./editIcon.png";

const InputFieldComp = ({
  header,
  InputValue,
  name,
  handleInputChange,
  setInputState,
  inputState,
}) => {
  // Function to format numbers with commas and preserve decimals
  const formatNumberWithCommas = (number) => {
    if (number === undefined || number === null) return "";
    const parts = number.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  };

  //total due
  const totalDue = useSelector(
    (state) => parseFloat(state.taxesAndFees.dealerRetail.totalDue) || 0
  );
  const handleChange = (e) => {
    // Remove existing commas
    let value = e.target.value.replace(/,/g, "");
    // Allow only numbers and a single decimal point
    value = value.replace(/[^0-9.]/g, "");
    // Ensure only one decimal point
    const parts = value.split(".");
    if (parts.length > 2) {
      value = parts[0] + "." + parts.slice(1).join("");
    }
    // Limit to two decimal places
    if (parts[1] && parts[1].length > 2) {
      value = parseFloat(value).toFixed(2);
    }
    // Call the parent's handleInputChange with the numeric value
    handleInputChange(name, value);
  };
  const dispatch = useDispatch();
  const handlePaymentDue = () => {
    if (totalDue) {
      dispatch(
        setRetailData({
          paymentPriceFees: totalDue ? totalDue.toFixed(2) : 0,
        })
      );
    }
  };

  console.log("updated input state => ", inputState);
  //here editing of the header label starts
  const [isEditing, setIsEditing] = useState(false);
  const [editingInput, setEditingInput] = useState(header);
  const inputRef = useRef(null);

  const handleClickOutside = (event) => {
    if (inputRef.current && !inputRef.current.contains(event.target)) {
      setIsEditing(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleheaderInputChange = (e) => {
    setEditingInput(e.target.value);
  };

  useEffect(() => {
    if (!isEditing && editingInput !== header) {
      // Assuming `editingInput` is the new value you want to set
      setInputState((prevData) =>
        prevData.map((item) =>
          item.name === name ? { ...item, header: editingInput } : item
        )
      );
      let itemToUpdate;
      console.log("Name here is ", name);
      switch (name) {
        case "documentationFee":
          itemToUpdate = "documentFees";
          break;
        case "drivesOutTag":
          itemToUpdate = "driveOutTags";
          break;
        case "titleApplicationFee":
          itemToUpdate = "titleAppFees";
          break;
        case "terpTitlefee":
          itemToUpdate = "terpTitleFees";
          break;
        case "buyerTagFees":
          itemToUpdate = "buyerTag";
          break;
        case "windshieldTrackerSticker":
          itemToUpdate = "windShieldTracker";
          break;
        case "regFeeDps":
          itemToUpdate = "regFeeDpsl";
          break;
        case "cntyRoadBridgeFee":
          itemToUpdate = "cntyRoadBridge";
          break;
        case "childSafetyFund":
          itemToUpdate = "childSafetyFee";
          break;
        case "InspectionFeeOBDNL":
          itemToUpdate = "inspectionFee";
          break;
        case "StateInspectionFees":
          itemToUpdate = "stateInspectionFee";
          break;
        case "processingHandlingFee":
          itemToUpdate = "procHandlingFee";
          break;
        //
        case "cardFees":
          itemToUpdate = "cardFeesl";
          break;
        case "warranty":
          itemToUpdate = "serviceContractl";
          break;
        case "gapInsurance":
          itemToUpdate = "gapInsurancel";
          break;
        case "mailDeliveryFees":
          itemToUpdate = "mailDeliveryFeesl";
          break;
        case "transportationFees":
          itemToUpdate = "transportationFeesl";
          break;
      }
      dispatch(setRetailData({ [itemToUpdate]: editingInput }));
    }
  }, [isEditing, header]);

  //Checking whether to view or not the pencil edit icon
  const [showEditIcon, setShowEditIcon] = useState(false);
  useEffect(() => {
    if (
      name === "documentationFee" ||
      name === "drivesOutTag" ||
      name === "titleApplicationFee" ||
      name === "terpTitlefee" ||
      name === "titleApplicationFee" ||
      name === "buyerTagFees" ||
      name === "windshieldTrackerSticker" ||
      name === "regFeeDps" ||
      name === "cntyRoadBridgeFee" ||
      name === "childSafetyFund" ||
      name === "InspectionFeeOBDNL" ||
      name === "StateInspectionFees" ||
      name === "processingHandlingFee" ||
      name === "cardFees" ||
      name === "warranty" ||
      name === "gapInsurance" ||
      name === "mailDeliveryFees" ||
      name === "transportationFees"
    ) {
      setShowEditIcon(true);
    } else {
      setShowEditIcon(false);
    }
  }, []);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      setIsEditing(false);
    }
  };
  return (
    <div className={Styles.container}>
      <p className={Styles.header}>
        {isEditing ? (
          <input
            type="text"
            className="HeaderInputField"
            ref={inputRef}
            value={editingInput}
            onChange={handleheaderInputChange}
            onKeyDown={handleKeyDown}
          />
        ) : (
          <div className={Styles.subHeader}>
            <span style={{ marginRight: "15px" }}>{header}</span>
            {showEditIcon ? (
              <img
                src={ediIcon}
                alt="edit"
                style={{ height: "15px", cursor: "pointer" }}
                onClick={() => setIsEditing(true)}
              />
            ) : null}
          </div>
        )}
      </p>
      {header === "Payment" && (
        <p className={Styles.mi_logo_update}>
          <img
             onClick={name === "paymentPriceFees" ? handlePaymentDue : undefined}
            style={{ width: "25px", height: "25px",cursor:"pointer" }}
            src="https://static.thenounproject.com/png/1694564-200.png"
            alt="Mileage Icon"
            title="Update"
          />
        </p>
      )}
      {header === "Mileage On Sale:" ? (
        <>
          <p className={Styles.mi_logo}>
            <img
              style={{ width: "15px", height: "15px" }}
              src={Image.mileage}
            />
          </p>
        </>
      ) : (
        <p>
         {header === "Payment" ? (
        <>
          <p>
          <BsCurrencyDollar style={{left:"48",top:"23"}}/>
          </p>
        </>
      ) : (
        <p>
          <BsCurrencyDollar style={{top:"23"}}/>
        </p>
      )}
        </p>
      )}
      <input
        type="text"
        value={formatNumberWithCommas(InputValue)}
        className={Styles.inputField}
        name={name}
        onChange={handleChange}
        placeholder="0"
        onClick={name === "paymentPriceFees" ? handlePaymentDue : undefined}
        style={{ flex: name === "paymentPriceFees" ? "0.36" : undefined }}
      />
    </div>
  );
};

export default InputFieldComp;
