import React from "react";
import "./footer.css";
import { Link } from "react-router-dom";
import ScrollToTopButton from "./ScrollToTopButton";
import Image from "../imgData";

const Footer = () => {
  // console.log(Image)
  return (
    <>
      <footer className="text-center text-lg-start text-light pt-4" style={{ backgroundColor: "rgb(107, 99, 99)" }}>
        <section>
          <div className="container-fluid text-center text-md-start">
            <div className="row mt-3">
              {/* Company Column */}
              <div className="col-md-4 col-lg-4 col-xl-3 mx-auto mb-4">
                <h6 className="logo_home_footer">
                  <Link to="/">
                    <span>DEALERS</span>
                    <span>AUTO</span>
                    <span>CENTER</span>
                  </Link>
                  {/* <Link>
                  <img src={Image.darkLogo} alt="Logo" width="30%" className="L_g"/>
                </Link> */}
                </h6>
                <div>
                  <Link to="" className="ico-reset">
                    <i className="fab fa-facebook-f"></i>
                  </Link>
                  <Link to="" className=" text-reset">
                    <i className="fab fa-twitter"></i>
                  </Link>
                  <Link to="" className=" text-reset">
                    <i className="fab fa-google"></i>
                  </Link>
                  <Link to="" className="text-reset">
                    <i className="fab fa-instagram"></i>
                  </Link>
                  <Link to="" className=" text-reset">
                    <i className="fab fa-linkedin"></i>
                  </Link>
                </div>
                {/* <br /> */}
                <p>
                  <b>Download Our Mobile App</b>
                </p>
                <div className="app-btn ">
                  <img src={Image.ios} alt="iOS" />
                  <img src={Image.android} alt="Android" />
                </div>
              </div>

              <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                {/* <!-- Links --> */}
                <h6 className="text-uppercase fw-bold mb-4">Company</h6>
                <p>
                  <Link to="#" className="text-reset">
                    Contact us
                  </Link>
                </p>
                <p>
                  <Link to="#" className="text-reset">
                    About us
                  </Link>
                </p>
                <p>
                  <Link to="/term-condition" className="text-reset">
                    Terms & Conditions
                  </Link>
                </p>
                <p>
                  <Link to="/privacy-policy" className="text-reset">
                    Privacy policy
                  </Link>
                </p>
                <p>
                  <Link to="#" className="text-reset">
                    Account sign in
                  </Link>
                </p>
                <p>
                  <Link to="#" className="text-reset">
                    Advertise with us
                  </Link>
                </p>
                <p>
                  <Link to="#" className="text-reset">
                    Dealer Directory
                  </Link>
                </p>
              </div>

              {/* <!-- Grid column 1--> */}
              <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                {/* <!-- Links --> */}
                <h6 className="text-uppercase fw-bold mb-4">Shop</h6>
                <p>
                  <Link to="#" className="text-reset">
                    Browse by Category
                  </Link>
                </p>
                <p>
                  <Link to="#" className="text-reset">
                    View all Inventory
                  </Link>
                </p>
                <p>
                  <Link to="#" className="text-reset">
                    Find Link Store
                  </Link>
                </p>
              </div>
              {/* <!-- Grid column 1 End--> */}

              {/* <!-- Grid column 2--> */}
              <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                {/* <!-- Links --> */}
                <h6 className="text-uppercase fw-bold mb-4">Financing</h6>
                <p>
                  <Link to="#" className="text-reset">
                    Browse by Category
                  </Link>
                </p>
                <p>
                  <Link to="#" className="text-reset">
                    View all Inventory
                  </Link>
                </p>
                <p>
                  <Link to="#" className="text-reset">
                    Find Link Store
                  </Link>
                </p>
              </div>
              {/* <!-- Grid column 2  End--> */}

              {/* <!-- Grid column 3 --> */}
              <div className="col-md-2 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                <h6 className="text-uppercase fw-bold mb-4">Sell / Trade</h6>

                <p>
                  <Link to="#" className="text-reset">
                    Get an Online offer
                  </Link>
                </p>
                <p>
                  <Link to="#" className="text-reset">
                    How it works
                  </Link>
                </p>
              </div>
              {/* <!-- Grid column 3  End--> */}

              {/* Other Columns (Company, Shop, Financing, Sell/Trade)
            ... (Remaining code for other columns) */}
            </div>
          </div>
        </section>

        {/* Copyright Section */}
        <section style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}>
          <div className="container-fluid p-4">
            <div className="row">
              <div className="col-lg-6">
                Dealer Auto Center 2023 | All right reserved
              </div>
              <div className="col-lg-6 ">
                <img
                  src={Image.payment}
                  width="40%"
                  alt="Accepted Payments"
                  style={{ float: "right" }}
                />
              </div>
            </div>
          </div>
          <ScrollToTopButton />
        </section>
      </footer>

    </>
  );
};

export default Footer;
