import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./DealerSideBarItem.css";
import { IoIosArrowUp } from "react-icons/io";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../../Redux/slice/userSlice";
import { resetRetailData } from "../../../Redux/slice/taxes&FeesSlice";

const DealerSideBarItem = ({
  item,
  selectedItemId,
  onItemClick,
  isExpanded,
  isOpenState,
  // ADDED: Accept currentPath prop
  currentPath,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(isOpenState[item.id] || false);

  // MODIFIED: Update isSelected to check if currentPath starts with item.route
  const isSelected =
    selectedItemId === item.id && currentPath.startsWith(item.route) && !currentPath.startsWith(item.route.trim() === "");

  // ADDED: Effect to update local open state when isOpenState changes
  useEffect(() => {
    setIsOpen(isOpenState[item.id] || false);
  }, [isOpenState, item.id]);

  // MODIFIED: Simplified handleClick function
  const handleClick = () => {
    if (item.children) {
      setIsOpen(!isOpen);
    } else {
      navigate(item.route);
    }
    if (onItemClick) {
      onItemClick(item.id, !isOpen);
    }
  };

  const handleLogout = () => {
    dispatch(logoutUser());
    dispatch(resetRetailData());
  };

  const renderItemName = (name) => {
    const words = name.split(" ");
    if (words.length === 1) {
      return <span className="item-name">{name}</span>;
    }
    return (
      <div className="item-name-wrapper">
        {words.map((word, index) => (
          <span
            key={index}
            className="item-name-part"
            style={{ marginRight: "0.5rem" }}
          >
            {word}
          </span>
        ))}
      </div>
    );
  };

  return (
    <div className={`side_bar_item_wrapper ${isSelected ? "clicked" : ""}`}>
      <div
        onClick={item.id === 15 ? handleLogout : handleClick}
        className={`side_bar_item_container ${isExpanded ? "" : ""}`}
      >
        <img
          src={item.image}
          alt={item.name}
          style={{
            height: "45px",
            width: "45px",
            paddingLeft: "5px",
            marginRight: "1rem",
          }}
        />
        {renderItemName(item.name)}
        {isExpanded && item.children ? (
          isOpen ? (
            <IoIosArrowUp />
          ) : (
            <MdOutlineKeyboardArrowDown />
          )
        ) : null}
      </div>
      {item.children && isExpanded && isOpen && (
        <div
          style={{
            paddingLeft: "20px",
            borderTop: "1px solid orange",
            marginTop: "0.3rem",
            width: "100%",
          }}
          className="nested_item_wrapper"
        >
          {item.children.map((child) => (
            <DealerSideBarItem
              key={child.id}
              item={child}
              selectedItemId={selectedItemId}
              onItemClick={onItemClick}
              isExpanded={isExpanded}
              isOpenState={isOpenState}
              // ADDED: Pass currentPath to child items
              currentPath={currentPath}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default DealerSideBarItem;
