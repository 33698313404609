import React from "react";
import Image from "../../../imgData";

const VehicleReport1 = () => {
  return (
    <section
      style={{
        backgroundImage: `url(${Image.car_bg3})`,
        backgroundPosition: "center",
      }}
      className="p-lg-5 pb-5"
    >
      <div className="container-fluid">
        <div className="col-lg-12">
          <div className=" row">
            <div className="col-lg-3"></div>
            <div className="col-lg-6">
              <div className="p-4 text-light text-center">
                <h2>We buy cars</h2>
                <p>Get Started now by entering the VIN below</p>
              </div>
              <div className="card p-3 shadow">
                {/* <!-- <h2 className="text-center p-3">Add Listing</h2> --> */}
                <nav>
                  <div
                    className="nav nav-tabs mb-3 nav-justified"
                    id="nav-tab"
                    role="tablist"
                  >
                    <button
                      className="nav-link active"
                      id="nav-home-tab1"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-home1"
                      type="button"
                      role="tab"
                      aria-controls="nav-home"
                      aria-selected="true"
                    >
                      VIN
                    </button>
                    <button
                      className="nav-link"
                      id="nav-profile-tab2"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-profile1"
                      type="button"
                      role="tab"
                      aria-controls="nav-profile"
                      aria-selected="false"
                    >
                      License Plate
                    </button>
                  </div>
                </nav>
                <div className="tab-content p-3 border " id="nav-tabContent ">
                  <div
                    className="tab-pane fade active show"
                    id="nav-home1"
                    role="tabpanel"
                    aria-labelledby="nav-home-tab1"
                  >
                    <div className="input-group">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Enter VIN Number"
                      />{" "}
                      <span className="input-group-btn">
                        <button className="btntttt btn-theme" type="submit">
                          {" "}
                          Search{" "}
                        </button>
                      </span>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade d-flex"
                    id="nav-profile1"
                    role="tabpanel"
                    aria-labelledby="nav-profile-tab2"
                  >
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter state"
                      />{" "}
                      <span className="input-group-btn">
                        <button className="btntttt btn-theme" type="submit">
                          {" "}
                          Search{" "}
                        </button>
                      </span>
                    </div>
                    <div className="input-group mt-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter License Number"
                      />{" "}
                      <span className="input-group-btn">
                        <button className="btntttt btn-theme" type="submit">
                          {" "}
                          Search{" "}
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default VehicleReport1;
