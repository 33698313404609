import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { fetchGeoMapData } from "../dashboard/geoAPI";
import Image from "../imgData";
import "./NearByCars.css";
import { useSelector } from "react-redux";

const NearByCars = () => {
    const { id } = useSelector((state) => state.user);
    const [formData, setFormData] = useState({
        latitude: 0,
        longitude: 0,
        ip: "",
    });

    const [carsListing, setCarListing] = useState([]);
    console.log("Near by cars Listing =>", carsListing);

    // useEffect(() => {
    //   // Fetch Latitude and longitude
    //   const handleGMapAPI = async () => {
    //     try {
    //       const res = await fetchGeoMapData();
    //       console.log("response of 2 latitude", res.coordinates.lat);
    //       console.log("response of 2 longitude", res.coordinates.lng);
    //       setFormData((prevData) => ({
    //         ...prevData,
    //         latitude: res.coordinates.lat || 0,
    //         longitude: res.coordinates.lng || 0,
    //       }));
    //     } catch (error) {
    //       console.error("Error fetching data:", error);
    //     }
    //   };
    //   handleGMapAPI();
    // }, []);

    const axiosInstance = axios.create({
        // Add crossorigin attribute to enable CORS
        headers: {
            'Access-Control-Allow-Origin': '*',
        },
    });

    useEffect(() => {
        // Fetch Latitude and longitude
        const handleGMapAPI = async () => {
            try {
                const res = await fetchGeoMapData();
                console.log("Response of latitude:", res.coordinates.lat);
                console.log("Response of longitude:", res.coordinates.lng);
                setFormData((prevData) => ({
                    ...prevData,
                    latitude: res.coordinates.lat || 0,
                    longitude: res.coordinates.lng || 0,
                }));

                // After fetching coordinates, fetch nearby cars
                await fetchData(res.coordinates.lat, res.coordinates.lng);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        handleGMapAPI();
    }, []);

    const fetchData = async (lat, lng) => {
        try {
            const ipResponse = await axios.get("https://api.ipify.org/?format=json");
            setFormData((prevData) => ({
                ...prevData,
                ip: ipResponse.data.ip || "",
            }));

            if (ipResponse.data.ip) {
                const fd = new FormData();
                fd.append("latitude", lat);
                fd.append("longitude", lng);
                fd.append("ip", ipResponse.data.ip);

                const carsResponse = await axios.post(
                    `${process.env.REACT_APP_API_URL}/listing/getnearby_listing`,
                    fd
                );
                setCarListing(carsResponse.data);
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    // useEffect(() => {
    //   // Fetch IP
    //   const getUserIPaddress = async () => {
    //     try {
    //       const res = await axiosInstance.get(
    //         "https://api.ipify.org/?format=json"
    //       );
    //       console.log("response of ip", res.data);
    //       setFormData((prevData) => ({
    //         ...prevData,
    //         ip: res.data.ip || "",
    //       }));
    //     } catch (error) {
    //       console.log("error ", error);
    //     }
    //   };
    //   getUserIPaddress();
    // }, []);

    // useEffect(() => {
    //   // Making a post request
    //   const fetchNearByCars = async () => {
    //     try {
    //       const fd = new FormData();
    //       fd.append("latitude", formData.latitude);
    //       fd.append("longitude", formData.longitude);
    //       fd.append("ip", formData.ip);
    //       if (formData.ip) {
    //         const res = await axios.post(
    //           `${process.env.REACT_APP_API_URL}/listing/getnearby_listing`,
    //           fd
    //         );
    //         setCarListing(res.data); // Assuming res.data contains the array of car listings
    //       }
    //     } catch (error) {
    //       console.log("Server side error ", error);
    //     }
    //   };
    //   fetchNearByCars();

    // }, [formData]);

    const navigate = useNavigate();
    const handleClick = (product) => {
        navigate(`/cardetail/${product.id}_${product.year}_${product.make}`, {
            state: { product },
        });
    };

    const getImageSrc = (multimedia, id) => {
        console.log("multimedia", multimedia);

        if (multimedia.all_images) {
            const allImages = multimedia.all_images.split(",");
            if (allImages.length > 0 && allImages[0]) {
                return `https://5starsmotors.com/images/${id}/${allImages[0]}`;
            }
        }
        if (multimedia.exterior_images) {
            const exteriorImages = multimedia.exterior_images.split(",");
            if (exteriorImages.length > 0 && exteriorImages[0]) {
                return `https://5starsmotors.com/images/${id}/${exteriorImages[0]}`;
            }
        }
        if (multimedia.interior_images) {
            const interiorImages = multimedia.interior_images.split(",");
            if (interiorImages.length > 0 && interiorImages[0]) {
                return `https://5starsmotors.com/images/${id}/${interiorImages[0]}`;
            }
        }
        if (multimedia.misc_images) {
            return `https://5starsmotors.com/images/${id}/${multimedia.misc_images.split(",")[0]}`;
        }
        return Image.image_not_available;
    };

    return (
        <section style={{ cursor: "pointer" }}>
            <div className="container-fluid p-4">
                <div className="row">
                    <h3 className="text-center p-4">Featured Dealers Near Me</h3>
                    {/* <button onClick={fetchData}>Fetch Nearby Cars</button> */}
                    {carsListing.length > 0 && (
                        <>
                            <div className="col-12">
                                <div className="col-lg-12">
                                    <div className="row">
                                        {carsListing.slice(0, 9).map((car, index) => (
                                            <div
                                                key={index}
                                                className="col-lg-4 nearByCarcardWrapper"
                                                onClick={() => handleClick(car)}
                                            >
                                                <div className="card mt-3 card-product">
                                                    <div className="img-over-color">
                                                        <img
                                                            src={getImageSrc(car.multimedia, car.userId)}
                                                            // src={
                                                            //   car.multimedia && car.multimedia.all_images
                                                            //     ? `https://5starsmotors.com/images/${car.userId
                                                            //     }/${car?.multimedia?.all_images.split(",")[0]
                                                            //     }`
                                                            //     : Image.image_not_available
                                                            // } // Replace with actual image field
                                                            className="card-img-top card-product-img"
                                                            alt="..."
                                                            style={{
                                                                backgroundSize: "cover", // or "contain" depending on your desired behavior
                                                                backgroundPosition: "center",
                                                                width: "100%",
                                                                height: "100%",
                                                            }}
                                                        />
                                                    </div>
                                                    <div
                                                        className="card-body card-home-body2"
                                                        style={{ paddingBottom: "5px" }}
                                                    >
                                                        <div className="card-space">
                                                            <h5
                                                                className="card-title"
                                                                style={{ fontSize: "15px" }}
                                                            >
                                                                {car.year_Make_Model || "Default Title"}
                                                            </h5>
                                                        </div>
                                                        <div className="card-space d-flex">
                                                            <div className="chip col-lg-6">
                                                                <Link to="#" className="color-blk">
                                                                    <i className="fa-solid fa-gauge-high"></i>{" "}
                                                                    {new Intl.NumberFormat().format(
                                                                        car.mileage_in
                                                                    ) || "0"}{" "}
                                                                    Miles
                                                                </Link>
                                                            </div>
                                                            {/* <div className="chip">
                              <Link to="#" className="color-blk">
                                
                                <i className="fa-solid fa-gauge-high"></i> {car.mileage_in || "0"} Miles
                              </Link>
                            </div> */}
                                                            <div className="chip col-lg-6">
                                                                <Link to="#" className="color-blk">
                                                                    <i class="fa-solid fa-phone"></i>{" "}
                                                                    {car.fuel || "Fuel Type"}
                                                                </Link>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="card-space"
                                                            style={{
                                                                padding: "0px",
                                                                paddingBottom: "0.4rem",
                                                            }}
                                                        >
                                                            <div className="d-flex total font-weight-bold justify-content-between">
                                                                <span className="doller-price">
                                                                    $
                                                                    {new Intl.NumberFormat().format(
                                                                        car.listing_price
                                                                    ) || "0"}
                                                                </span>
                                                                <span className="doller-price">
                                                                    <img
                                                                        src={Image.messanger}
                                                                        alt="messanger"
                                                                        height={25}
                                                                    />{" "}
                                                                    Check Availability
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </section>
    );
};

export default NearByCars;
