import React, { useState } from "react";

import "../../../productsListing/CryptoCard.css";
import { Link, useNavigate } from "react-router-dom";
import Image from "../../../imgData";
import { MdPhoneInTalk } from "react-icons/md";
import { FaRegMessage } from "react-icons/fa6";
import { IoShareSocial } from "react-icons/io5";
import { FaHeart } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { VscTag } from "react-icons/vsc";
import {
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookShareButton,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { useSelector } from "react-redux";
import axios from "axios";

const CryptoCard1 = ({ product }) => {
  const navigate = useNavigate();
  const [showSocialMedia, setShowSocialMedia] = useState(false);
  const [isFav, setIsFav] = useState(false);
  // console.log(product);
  const location = useLocation();
  const { id } = useSelector((state) => state.user);
  // console.log("User ID ", id);
  const handleShareLink = () => {
    console.log("Sharing link with the id");
    setShowSocialMedia(!showSocialMedia);
  };
  const generateShareUrl = (itemId) => {
    return `${window.location.origin}${location.pathname}/${itemId}`;
  };

  const handleLikeTheProduct = async () => { };
  // for single product details---------------
  const handleClick = () => {
    // navigate(
    //   `/cardetail/${product.id}_${product.modelYear}_${product.modelMake}`,
    //   {
    //     state: { product },
    //   }
    // );
    navigate("/dealer/carDetails1")
  };
  // for mask vin----------
  const maskVin = () => {
    const vin = product.vin;
    return vin?.slice(0, 9) + "*".repeat(vin.length - 9);
  };

  return (
    <div className="card wrapper_card_product p-2">
      {/* Top Items */}
      <div className="row  p-3 pb-0 rounded card_container">
        <div className="col-lg-4 col-md-5 card_left">
          <div className="product-image-box">
            <img
              // src={
              //   product.multimedia.all_images
              //     ? `https://5starsmotors.com/images/${product.userId}/${
              //         product.multimedia.all_images?.split(",")[0]
              //       }`
              //     : Image.image_not_available
              // }
              src={product.image}
              alt="product_car_image"
              className="product_car_image"
            />
            {/* <i className = "fa-regular fa-heart"></i> */}
            <div className="product_share_like">
              <span onClick={handleShareLink}>
                <IoShareSocial />
              </span>
              {/* {id !== 0 ? (
                <span onClick={handleLikeTheProduct}>
                  <FaHeart fill={`${isFav ? "red" : "white"}`} />
                </span>
              ) : null} */}
            </div>
            {/* Share buttons */}
            {showSocialMedia ? (
              <div className="social_media_container">
                <FacebookShareButton url={generateShareUrl(id)}>
                  <FacebookIcon size={32} round />
                </FacebookShareButton>
                <TwitterShareButton url={generateShareUrl(id)}>
                  <TwitterIcon size={32} round />
                </TwitterShareButton>
                <WhatsappShareButton url={generateShareUrl(id)}>
                  <WhatsappIcon size={32} round />
                </WhatsappShareButton>
                <TelegramShareButton url={generateShareUrl(id)}>
                  <FacebookMessengerIcon size={32} round />
                </TelegramShareButton>
              </div>
            ) : null}
          </div>
          <div className="interior_exterior_color_container">
            <span
              style={{
                backgroundColor: product.exterior_color,
                color: product.exterior_color ? "white" : "",
              }}
            >
              Exterior
            </span>
            <span
              style={{
                backgroundColor: product.exteriorColor,
              }}
              title={product.exteriorColor ? product.exteriorColor : ""}
            ></span>
            <span
              style={{
                backgroundColor: product.interior_color,
                color: product.interior_color ? "white" : "",
                marginLeft: "0.3rem",
              }}
            >
              Interior
            </span>
            <span
              style={{
                backgroundColor: product.interiorColor,
              }}
              title={product.interiorColor ? product.interiorColor : ""}
            ></span>
          </div>
        </div>
        <div className=" card_center">
          {/*----------------------content deatils Start---------------------*/}
          {/* <div className="row"> */}
          <div className=" center_primary">
            <h4
              style={{ fontWeight: 800, cursor: "pointer" }}
              onClick={handleClick}
            >
              {product.carName}
            </h4>
            <div className="vin_extras_container">
              <h6 style={{ fontSize: "18px" }}>
                <span
                  className="theme-color "
                  style={{
                    color: "#D1823A",
                    fontWeight: "bold",
                    margin: "10px 3px",
                    textWrap: 'nowrap',
                  }}
                >
                  Mileage :
                </span>
                <span style={{ cursor: "pointer" }} onClick={handleClick}>
                  {/* {product.mileage_in} */}
                  {new Intl.NumberFormat().format(product.MileageIn)}
                </span>
              </h6>
              <h6 style={{ fontSize: "18px", display: "flex", gap: "0.5rem" }}>
                <span> {product?.WheelDrive || 0}WD </span>{" "}
                <span> {product?.engineSize}L </span>{" "}
                <span> {product?.numOfEngines}CYL </span>{" "}
                <span> {product.enginType || "Transmission NA"} </span>
              </h6>
            </div>

            <h6 style={{ fontSize: "18px", margin: "01.3rem 0" }}>
              <span
                className="theme-color"
                style={{ color: "#D1823A", fontWeight: "bold" }}
              >
                Location :{" "}
              </span>
              {product.location || "Seller Location NA"}{" "}
              <b>
                <sub>132 miles</sub>
              </b>
            </h6>
            <div className="d-flex seller_section_product_listing">
              <h6 style={{ fontSize: "18px" }}>
                <span
                  className="theme-color"
                  style={{ color: "#D1823A", fontWeight: "bold" }}
                >
                  Seller :{" "}
                </span>
                <span>{product.sellerName || "Seller Name NA"}</span>
              </h6>
              <label
                className="form-check-label ms-2"
                htmlFor="flexCheckDefault"
              >
                <i className="fas fa-star " style={{ color: "#D1823A" }}></i>
                <i className="fas fa-star " style={{ color: "#D1823A" }}></i>
                <i className="fas fa-star " style={{ color: "#D1823A" }}></i>
                <i className="fas fa-star " style={{ color: "#D1823A" }}></i>
                <i className="fas fa-star "></i>
              </label>
            </div>
          </div>
          <div className="center_secondary">
            <h5 style={{ fontSize: "30px", marginBottom: "1rem" }}>
              {" "}
              <b>Price : </b>
              <span className="theme-color" style={{ color: "#D1823A" }}>
                {" "}
                {/* ${product.listing_price}{" "} */}
                {new Intl.NumberFormat().format(product.price)}
              </span>{" "}
              <span style={{ fontSize: "medium" }}>/Mo Est.</span>{" "}
            </h5>
            <div className="call_seller_button_container">
              <div className="product-g-btn">
                <span>
                  <MdPhoneInTalk />
                </span>{" "}
                +1<span style={{ fontSize: "20px" }}>**********</span>
              </div>
              <div className="product-S-btn">
                <span style={{ transform: "rotateY(180deg)" }}>
                  <VscTag />
                </span>{" "}
                Send Offers
              </div>
            </div>
            <div className="contact_message_section">
              <p
                style={{
                  fontSize: "18px",
                  // border: "2px solid blue",
                  textAlign: "center",
                  fontWeight: "600",
                  marginBottom: "2px",
                }}
              >
                Check Availibity
              </p>
              <div className="input_contact_message_section">
                {/* <img
                  src={Image.messanger}
                  alt="messenger icon"
                  height={30}
                  width={30}
                /> */}
                <input
                  type="text"
                  placeholder="Hi Kumar, Is this still available ?"
                />
                <button>Send</button>
              </div>
            </div>
          </div>
          {/* </div> */}
        </div>
      </div>
      {/* Bottom items */}
    </div>
  );
};

export default CryptoCard1;
