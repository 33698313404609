import React, { useEffect, useState } from "react";
import Image from "../../imgData";
import "../Styles/Dealer_OTP_Feature.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";

const Dealer_OTP_Feature = () => {
  const navigate = useNavigate();
  const [InputOtp, setInputOtp] = useState("");

  const [disableResendOtp, setdisableResendOtp] = useState(false);

  console.log(InputOtp);

  const location = useLocation();
  console.log("location.state in otp ", location.state);
  const handleContinueResult = async (value) => {
    try {
      console.log("Handling API continue Result");
      const formData = new FormData();
      console.log("formData", formData);
      formData.append("email", location.state);
      formData.append("user_type", "Dealer");
      formData.append("otp", value);
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/verifyotp`,
        formData
      );

      if (res.status !== 200) {
        console.log("User OTP Failure");
      }
      toast.success("OTP verified");
      navigate("/dealer/signupSetPass", { state: location.state });
    } catch (error) {
      console.log("error in  catch part while using otp");
      toast.error(error.response.data);
    }
  };

  const handleInputChange = async (e) => {
    const { value } = e.target;
    if (/^\d{0,6}$/.test(value)) {
      setInputOtp(value);

      if (value.length === 6) {
        await handleContinueResult(value);
      }
    }
  };

  const [countryCode, setCountryCode] = useState("");
  console.log(countryCode);
  useEffect(() => {
    const getUserCountryCode = async () => {
      try {
        const res = await axios.get("https://ipapi.co/json");
        console.log("User all info", res.data);
        setCountryCode(res.data.country_calling_code);
        // setIP(res.data.ip)
      } catch (error) {
        console.log("Error while getting ip ", error);
      }
    };
    getUserCountryCode();
  }, [countryCode]);

  // const [time, setTime] = useState(300); // 300 seconds for 5 minutes
  // const [isDisabled, setIsDisabled] = useState(false);
  const [time, setTime] = useState(() => {
    const savedTime = localStorage.getItem("otpTimer");
    return savedTime ? parseInt(savedTime, 10) : 300;
  });

  const [isDisabled, setIsDisabled] = useState(() => {
    return localStorage.getItem("otpButtonDisabled") === "true";
  });

  // useEffect(() => {
  //   let timer;

  //   if (time > 0 && isDisabled) {
  //     timer = setInterval(() => {
  //       setTime((prevTime) => prevTime - 1);
  //     }, 1000);
  //   } else if (time <= 0) {
  //     setIsDisabled(false);
  //     clearInterval(timer);
  //   }

  //   return () => clearInterval(timer); // Cleanup on component unmount
  // }, [time, isDisabled]);

  useEffect(() => {
    let timer;

    if (time > 0 && isDisabled) {
      timer = setInterval(() => {
        setTime((prevTime) => {
          const newTime = prevTime - 1;
          localStorage.setItem("otpTimer", newTime.toString());
          return newTime;
        });
      }, 1000);
    } else if (time <= 0) {
      setIsDisabled(false);
      localStorage.removeItem("otpTimer");
      localStorage.setItem("otpButtonDisabled", "false");
      clearInterval(timer);
    }

    return () => clearInterval(timer); // Cleanup on component unmount
  }, [time, isDisabled]);
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(secs).padStart(
      2,
      "0"
    )}`;
  };
  const handleResendOtp = async () => {
    if (!isDisabled) {
      setIsDisabled(true);
      setTime(300); // Reset to 5 minutes
      localStorage.setItem("otpTimer", "300");
      localStorage.setItem("otpButtonDisabled", "true");
    }
    console.log("Please resend OTP");
    const formData = new FormData();
    formData.append("email", location.state);
    formData.append("user_type", "Dealer");
    formData.append("country_code", countryCode);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/send_link`,
        formData
      );

      if (res.status === 200) {
        console.log(res.data);
        toast.success("OTP sent again.");
      }
    } catch (error) {
      if (error.response.status === 400) {
        toast.error(error.response.data);
      } else if (error.response.status === 500) {
        toast.error(error.response.data);
      }
    }
  };

  useEffect(() => {
    localStorage.setItem("otpButtonDisabled", isDisabled.toString());
  }, [isDisabled]);

  useEffect(() => {
    // Reset timer and enable button when route changes
    return () => {
      setTime(300);
      setIsDisabled(false);
      localStorage.removeItem("otpTimer");
      localStorage.setItem("otpButtonDisabled", "false");
    };
  }, [location.pathname]);
  return (
    <div className="login_feature_wrapper" style={{ height: "700px" }}>
      <div className="login_feature_container">
        <p
          className="welcome_message"
          style={{ fontWeight: "700", fontSize: "xx-large" }}
        >
          Dealer Log-in
        </p>
        {/* <p className="sign_in_message">
         Please enter the OTP sent to your email
        </p> */}
        {/* <label htmlFor="email_username" className="email_username_label">
         Enter OTP *
        </label> */}
        <div className="dealer_email_container">
          <input
            type="text"
            onChange={handleInputChange}
            value={InputOtp}
            placeholder="Enter 6 Digit OTP"
            style={{ fontWeight: "700", fontSize: "xx-large" }}
          />
        </div>

        <p className="forget_password">
          <span
            style={{
              color: "white",
              borderBottom: "0",
              fontSize: "large",

              paddingBottom: "0",
            }}
          >
            {isDisabled ? formatTime(time) : ""}
          </span>
          <button
            className={`dealer_forget_pass_btn ${
              isDisabled ? "greyColor" : "whiteColor"
            }`}
            onClick={handleResendOtp}
            style={{
              cursor: "pointer",
              cursor: isDisabled ? "not-allowed" : "pointer",
              marginLeft: "0.5em",
              color: isDisabled ? "#B2BEB5" : "white",
              backgroundColor: "#515558",
              fontSize: "smaller",

              paddingBottom: "0px",
              width: "20%",
            }}
            disabled={isDisabled}
          >
            Resend OTP
          </button>
        </p>
      </div>
      <Toaster
        position="top-right"
        reverseOrder={false}
        toastOptions={{
          // Define default options
          className: "",
          duration: 3000,
          style: {
            background: "#363636",
            color: "#fff",
            fontSize: "larger",
          },
        }}
      />
    </div>
  );
};

export default Dealer_OTP_Feature;
