import { createSlice } from "@reduxjs/toolkit";

const commonFields = {
  SellPriceInputs : [],
  listingId: "",
  showSellNav: "",
  salesTaxes: "6.25",
  mInventoryTax: "0.001488",
  titleApplicationFee: "13.00",
  terpTitlefee: "20",
  drivesOutTag: "5.00",
  windshieldTrackerSticker: "50.75",
  regFeeDps: "1",
  cntyRoadBridgeFee: "10",
  childSafetyFund: "1.50",
  InspectionFeeOBDNL: "25",
  processingHandlingFee: "4.75",
  documentationFee: "150.00",
  technologyFees: "0.00",
  buyerTagFees: "5",
  other1: "0.00",
  other2: "0.00",
  cardFees: "",
  warranty: "",
  gapInsurance: "",
  mailDeliveryFees: "",
  transportationFees: 273,
  totalFees: 0,
  totalFeesAndothers: "",
  txtState: "",
  listingPrice: "",
  txtTitleType: "",
  ownedTrade: "",
  cashDifference: "",
  paymentPriceFees: "",
  date: "",
  saleTaxValue: "",
  mInventoryValue: "",
  balanceDue: "",
  // -----------------------------------
  lienHolderName: "",
  leanHolderId: "",
  lienHolderID: "",
  lienHolderAddress: "",
  lienHolderAddress2: "",
  serviceContractId: "",
  serviceContractName: "",
  serviceContractLicense: "",
  serviceContractAddress: "",
  serviceProviderActivationStatus: "",
  serviceProviderType: "",
  providerPhoneNumber: "",
  providerCounty: "",
  providerListingExpiry: "",
  priceFeesPaymentMethod: "",
  serviceProviderMonths:"",
  serviceContractMiles:"",
  //Gap Insurance Provider Data
  gapInsuranceId: "",
  gapName: "",
  gapLicense: "",
  gapAddress: "",
  gapActivationStatus: "",
  gapType: "",
  gapPhoneNumber: "",
  gapCounty: "",
  gapListingExpiry: "",
  gapMonths:"",
  gapMiles:"",
  // Sell Form ---------------------------
  sellForm: "",
  // trade in key--------
  dealerId: 0,
  vin: "",
  year_make_model: "",
  // make year modal only check purpose for backend------
  make: "",
  year: "",
  model: "",
  bodyClass: "",
  wheelbase: "",
  driveType: "",
  doorCount: "",
  // -----------------------
  trim: "",
  engine: "",
  transmission: "",
  vehicleType: "",
  seat_count: "",
  city_mpg: "",
  highway_mpg: "",
  inspection: "",
  exteriorColor: "",
  interiorColor: "",
  interiorType: "",
  mileage_in: "",
  purchase_price: "",
  //tradeInValue: formData.tradeInValue,
  listing_price: "",
  mileageIn: "",
  // trade in key end--------
  // for images of vehicle card--------
  imageUrl: "",

  // SellPriceInputs: [
  //   { id: 1, label: "Documentation Fees", InputValue: 150.0 },
  //   { id: 3, label: "Drive Out Tag", InputValue: 5.0 },
  //   { id: 4, label: "TERP Title Fee", InputValue: 20 },
  //   { id: 5, label: "Buyer Tag", InputValue: 5 },
  //   { id: 7, label: "Wind-Shield Sticker", InputValue: 50.75 },
  //   { id: 8, label: "REG. FEE-DPS", InputValue: 1 },
  //   { id: 9, label: "CNTY ROAD Bridge Addon Fee", InputValue: 10 },
  //   { id: 10, label: "Child Safety Fee", InputValue: 1.5 },
  //   { id: 11, label: "Inspection Fee", InputValue: 25 },
  //   { id: 12, label: "Proc. & Handling Fee", InputValue: 4.75 },
  //   { id: 13, label: "Card Fee", InputValue: 0 },
  //   { id: 14, label: "Service Contract", InputValue: 0 },
  //   { id: 16, label: "Mail Delivery Fees", InputValue: 0 },
  //   { id: 17, label: "Transportation Fees", InputValue: 0 },
  // ],
  sumPriceFeesLeft: 0,
  //Updated label starts here
  documentFees: "Line 1",
  driveOutTags: "Line 2",
  titleAppFees: "Line 3",
  terpTitleFees: "Line 4",
  buyerTag: "Line 5",
  windShieldTracker: "Line 6",
  regFeeDpsl: "Line 7", //Special case
  cntyRoadBridge: "Line 8",
  childSafetyFee: "Line 9",
  inspectionFee: "Line 10",
  stateInspectionFee: "Line 11",
  procHandlingFee: "Line 12",

  cardFeesl: "Line 13",
  serviceContractl: "Line 14",
  gapInsurancel: "Line 15",
  mailDeliveryFeesl: "Line 16 ",
  transportationFeesl: "Line 17 ",
};

const initialState = {
  dealerRetail: { ...commonFields },
  dealerFinance_BHPH: { ...commonFields },
  outOfState: { ...commonFields },
};
console.log("Date in slice => ", initialState.dealerRetail.date);
const taxesFeesSlice = createSlice({
  name: "taxesAndFees",
  initialState,
  //   Setting the data
  reducers: {
    setRetailData: (state, action) => {
      state.dealerRetail = { ...state.dealerRetail, ...action.payload };
    },

    //All the labels in Price and fees
    addSellPriceLabels: (state, action) => {
      if (!state.dealerRetail.SellPriceInputs) {
        state.dealerRetail.SellPriceInputs = [];
      }
      state.dealerRetail?.SellPriceInputs?.push({
        id: action.payload.id,
        label: action.payload.label,
        InputValue: "",
      });
      const sum = state.dealerRetail?.SellPriceInputs?.reduce((acc, item) => {
        const itemValue = parseFloat(item.InputValue) || 0;
        return acc + itemValue;
      }, 0);
      state.dealerRetail.totalFeesAndothers = sum;
    },
    //Update the input field in the Price and fees
    updateSellPriceInputs: (state, action) => {
      const { index, value } = action.payload;
      state.dealerRetail.SellPriceInputs[index].InputValue = value;
      console.log("Update the sum ", state);
      // Calculate the sum of all InputValue fields
      const sum = state?.dealerRetail?.SellPriceInputs?.reduce((acc, item) => {
        const itemValue = parseFloat(item.InputValue) || 0;
        return acc + itemValue;
      }, 0);

      console.log("Sum is ", sum);
      // Update sumPriceFeesLeft with the calculated sum
      state.dealerRetail.totalFeesAndothers = sum;
    },
    //Update the label  in the Price and fees
    updateSellPriceLabels: (state, action) => {
      const { index, value } = action.payload;
      state.dealerRetail.SellPriceInputs[index].label = value;
      const sum = state.dealerRetail.SellPriceInputs.reduce((acc, item) => {
        const itemValue = parseFloat(item.InputValue) || 0;
        return acc + itemValue;
      }, 0);

      console.log("Sum is ", sum);
      // Update sumPriceFeesLeft with the calculated sum
      state.dealerRetail.totalFeesAndothers = sum;
    },

    //Delete the label  in the Price and fees
    deleteSellPriceLabels: (state, action) => {
      const { index } = action.payload;
      state.dealerRetail.SellPriceInputs.splice(index, 1); // Remove item at index
      const sum = state.dealerRetail.SellPriceInputs.reduce((acc, item) => {
        const itemValue = parseFloat(item.InputValue) || 0;
        return acc + itemValue;
      }, 0);

      console.log("Sum is ", sum);
      // Update sumPriceFeesLeft with the calculated sum
      state.dealerRetail.totalFeesAndothers = sum;
    },

    //fetching the labels and setting it back here in price and fees
    setDynamicAPILabels : (state,action) => {
      state.dealerRetail.SellPriceInputs = action.payload;
    },
    //   Resetting the data
    resetRetailData: (state) => {
      state.dealerRetail = { ...commonFields };
    },
    resetFinance_BHPH_Data: (state) => {
      state.dealerFinance_BHPH = { ...commonFields };
    },
    resetoutOfStateData: (state) => {
      state.outOfState = { ...commonFields };
    },
    resetTexasDynamicPriceFees: (state) => {
      state.dealerRetail = {
        ...state.dealerRetail,
        SellPriceInputs: [
          { id: 1, label: "Documentation Fees", InputValue: "" },
          { id: 2, label: "Inventory Tax", InputValue: "" },
          { id: 3, label: "Drive Out Tag", InputValue: "" },
          { id: 4, label: "TERP Title Fee", InputValue: "" },
          { id: 5, label: "Buyer Tag", InputValue: "" },
          { id: 7, label: "Wind-Shield Sticker", InputValue: "" },
          { id: 8, label: "REG. FEE-DPS", InputValue: "" },
          { id: 9, label: "CNTY ROAD Bridge Addon Fee", InputValue: "" },
          { id: 10, label: "Child Safety Fee", InputValue: "" },
          { id: 11, label: "Inspection Fee", InputValue: "" },
          { id: 12, label: "Proc. & Handling Fee", InputValue: "" },
          { id: 13, label: "Card Fee", InputValue: "" },
          { id: 14, label: "Service Contract", InputValue: "" },
          { id: 16, label: "Mail Delivery Fees", InputValue: "" },
          { id: 17, label: "Transportation Fees", InputValue: "" },
        ],
      };
    },
  },
});

export const {
  setRetailData,
  setFinance_BHPH_Data,
  setoutOfStateData,
  resetRetailData,
  resetFinance_BHPH_Data,
  resetoutOfStateData,
  addSellPriceLabels,
  updateSellPriceInputs,
  updateSellPriceLabels,
  deleteSellPriceLabels,
  resetTexasDynamicPriceFees,
  setDynamicAPILabels
} = taxesFeesSlice.actions;

export default taxesFeesSlice.reducer;
