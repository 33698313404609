// import React from 'react'

// const DealerForms = () => {
//   return (
//     <div>Dealer Forms</div>
//   )
// }

// export default DealerForms

import React, { useEffect, useState, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Dropdown,
  Modal,
} from "react-bootstrap";
import { AiOutlineMail, AiFillPrinter } from "react-icons/ai";
import Image from "../../imgData";
import "../DealerSell/forms.css";
import { FaHandshake } from "react-icons/fa6";
import { FaRegEye } from "react-icons/fa";
import { FaFileContract } from "react-icons/fa";
import usaStates from "../DealerInventory/DealerAddInventory/usaStates.json";
import { useSelector } from "react-redux";
import PDFViewer from "../DealerSell/components/PDFViewer";
import PDF from '../../DealerSection/DealerSell/components/PDF'

function DealerForms() {
  // State to manage Select All
  const [selectAll, setSelectAll] = useState(false);
  const [selectedState, setSelectedState] = useState("TX - Texas");
  const reduxForms = useSelector(
    (state) => state.taxesAndFees.dealerRetail.sellForm
  );

  // State to manage checkboxes
  const [checkboxes, setCheckboxes] = useState({
    billOfSale: false,
    buyerGuide: false,
    odometerDisclosure: false,
    titleApp: false,
    AgreementInsurance: false,
    creditBureauDisclosure: false,
    rebuildTitleDisclosure: false,
    powerOfAttorney: false,
    retailContractInstallment: false,
    salesTaxExemptionCertificate: false,
  });

  const [search, setSearch] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [filteredStates, setFilteredStates] = useState([]);
  const searchInputRef = useRef(null);

  useEffect(() => {
    if (search.length > 0) {
      // Filter states based on the search input
      setFilteredStates(
        usaStates.filter((state) =>
          state.name.toLowerCase().includes(search.toLowerCase())
        )
      );
    } else {
      setFilteredStates(usaStates);
    }
  }, [search, usaStates, isOpen]);

  useEffect(() => {
    if (isOpen && searchInputRef.current) {
      searchInputRef.current.focus();
    }
    setSearch("");
  }, [isOpen]);

  // console.log(checkboxes);
  // Handle individual checkbox change
  const handleCheckboxChange = (e) => {
    const { id, checked } = e.target;
    setCheckboxes((prev) => ({
      ...prev,
      [id]: checked,
    }));

    // Update Select All state based on individual checkboxes
    setSelectAll(
      Object.values({ ...checkboxes, [id]: checked }).every((val) => val)
    );
  };
  // Handle state selection
  const handleSelectState = (state) => {
    setSelectedState(state);
  };

  // Handle Select All change
  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setCheckboxes({
      billOfSale: newSelectAll,
      buyerGuide: newSelectAll,
      odometerDisclosure: newSelectAll,
      titleApp: newSelectAll,
      AgreementInsurance: newSelectAll,
      creditBureauDisclosure: newSelectAll,
      rebuildTitleDisclosure: newSelectAll,
      powerOfAttorney: newSelectAll,
      retailContractInstallment: newSelectAll,
      salesTaxExemptionCertificate: newSelectAll,
    });
  };

  const [isTriggered, setIsTrigerred] = useState({
    previewed: false,
    eContracted: false,
  });

  const handlePrint = () => {
    // Display a confirmation dialog
    const userConfirmed = window.confirm(
      "Are you sure you want to sold this item?"
    );

    if (!userConfirmed) {
      // If the user clicks "Cancel", exit the function
      return;
    }

    setIsTrigerred({
      previewed: true,
      eContracted: true,
    });
  };

  const handleSelect = (eventKey) => {
    setSearch(""); // Clear search input when an item is selected
    setIsOpen(false); // Close the dropdown
  };

  // Handle the search input change
  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const [showPdfs, setShowPdfs] = useState(false);
  const handlePreviewSelect = () => {
    setShowPdfs(true);
  };

  const pdfArray = [
    {
      pdf: require("../../pdfData/14-312 Motor Vehicle Sales Tax Exemption Certificate for Vehicles Taken Out of State.pdf"),
      isShow: checkboxes.salesTaxExemptionCertificate,
    },

    {
      pdf: require("../../pdfData/Buyers Guide.pdf"),
      isShow: checkboxes.buyerGuide,
    },
    {
      pdf: require("../../pdfData/County of Title Issuance (Form VTR-136).pdf"),
      isShow: checkboxes.titleApp,
    },
  ];

  return (
    <Container
      className="forms-container rounded relative"
      style={{ width: "100%" }}
    >
      <div className="absolute">
        <Modal show={showPdfs} onHide={() => setShowPdfs(false)} size="xl">
          <Modal.Header closeButton>
            <Modal.Title>
              {pdfArray.some((item) => item.isShow) ? (
                <div className="previewModalBtnContainer">
                  <span>Finalise</span>
                  <span>Print</span>
                  <span>e-Contract</span>

                  <span onClick={() => setShowPdfs(false)}>Go back</span>
                </div>
              ) : (
                <div> Please select the forms to view them</div>
              )}
            </Modal.Title>
          </Modal.Header>
          {pdfArray.some((item) => item.isShow) && (
            <Modal.Body className="h-50 ">
              {/* {reduxForms && reduxForms?.map((item, i) => ( */}

              {pdfArray
                ?.filter((item) => item.isShow)
                .map((item, i) => (
                  <div key={i} style={{ height: "80vh" }}>
                    <PDF item={item} />
                  </div>
                ))}
            </Modal.Body>
          )}
        </Modal>
      </div>

      <Row className=" px-1 text-center header_container d-flex justify-content-between">
        <Col sm={2} className="p-1">
          <h3>Forms</h3>
        </Col>

        <Col sm={3} className="text-right p-1 d-flex justify-content-around">
          <div className="state">
            <Dropdown
              show={isOpen}
              onSelect={handleSelectState}
              onToggle={(open) => setIsOpen(open)}
              className="m-0 "
            >
              <Dropdown.Toggle
                size="sm"
                className="px-3 py-2 bg-orange border-0 fw-bold text-light"
                onClick={() => setIsOpen(!isOpen)}
              >
                {selectedState}
              </Dropdown.Toggle>
              <Dropdown.Menu className="state-drp">
                {/* Hidden input to manage search */}
                <input
                  type="text"
                  value={search}
                  ref={searchInputRef}
                  autofocus
                  onChange={handleSearchChange}
                  onFocus={() => setIsOpen(true)}
                  style={{
                    position: "absolute",
                    top: "-9999px",
                    left: "-9999px",
                  }} // Hide the input field
                />
                {filteredStates.length > 0 ? (
                  filteredStates.map((state) => (
                    <Dropdown.Item
                      key={state.name}
                      eventKey={state.name}
                      onClick={() => handleSelect(state.name)}
                    >
                      {state.name}
                    </Dropdown.Item>
                  ))
                ) : (
                  <Dropdown.Item disabled>No states found</Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="forms">
            <Button className="px-3 py-2 border-0 btn-orange">Forms</Button>
          </div>
        </Col>
      </Row>

      <Row>
        <Col
          sm={7}
          className="d-flex flex-column justify-content-end align-items-end"
        >
          <div className="mb-3 w-75" style={{ marginRight: "-1%" }}>
            {/* <span
          onClick={handleSelectAll}
          style={{
            cursor: "pointer",
            fontWeight: "bold",
            textDecoration: "underline",
          }}
        >
          {selectAll ? "Unselect All" : "Select All"}
        </span> */}
            <label
              style={{
                cursor: "pointer",
                fontWeight: "bold",
                textDecoration: "underline",
              }}
            >
              <input
                type="checkbox"
                checked={selectAll}
                onChange={handleSelectAll}
              />
              {selectAll ? "Unselect All" : "Select All"}
            </label>
          </div>

          <Form className="test w-75 d-flex flex-column align-items-end">
            {[
              {
                id: "AgreementInsurance",
                label: "Agreement To Provide Insurance",
              },
              { id: "billOfSale", label: "Bill of Sale /Buyer Order" },
              { id: "buyerGuide", label: "Buyer Guide" },
              {
                id: "creditBureauDisclosure",
                label: "Credit Bureau Disclosure",
              },
              { id: "odometerDisclosure", label: "Odometer Disclosure" },
              {
                id: "rebuildTitleDisclosure",
                label: "Rebuilt Title Disclosure",
              },
              { id: "titleApp", label: `${selectedState} -Title App I130u` },
              {
                id: "powerOfAttorney",
                label: `${selectedState} - Power Of Attorney`,
              },
              {
                id: "retailContractInstallment",
                label: `${selectedState} - Retail Contract Installment`,
              },
              {
                id: "salesTaxExemptionCertificate",
                label: `${selectedState} - Sales Tax Exemption Certificate`,
              },
            ].map(({ id, label }) => (
              <Form.Group
                key={id}
                className="mb-2 flex align-items-center justify-content-center"
              >
                <Form.Check
                  type="checkbox"
                  id={id}
                  checked={checkboxes[id]}
                  onChange={handleCheckboxChange}
                  label={label}
                  className="form-option border-bottom pb-2 "
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: "1rem",
                    paddingLeft: "30px",
                  }}
                />
              </Form.Group>
            ))}
          </Form>
        </Col>

        <Col
          sm={3}
          className="d-flex flex-column justify-content-between align-items-between gap-4"
        >
          <div className="d-flex justify-content-around flex-column mt-5">
            <Button
              variant="light"
              className="btn-orange mb-2 border-0 button-width d-flex align-items-center justify-content-center gap-2"
              onClick={handlePreviewSelect}
            >
              <span style={{ fontSize: "large" }}>
                {/* <FaRegEye /> */}
                <img src={Image.docxPreview} height="20px" alt="Docx preview" />
              </span>
              Preview
            </Button>
          
          </div>
         
        </Col>
      </Row>
    </Container>
  );
}

export default DealerForms;
