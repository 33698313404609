import React, { useCallback, useEffect, useState } from "react";

import "./CryptoCard.css";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Image from "../imgData";
import { MdPhoneInTalk } from "react-icons/md";
import { FaRegMessage } from "react-icons/fa6";
import { IoShareSocial } from "react-icons/io5";
import { FaHeart } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { VscTag } from "react-icons/vsc";
import {
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookShareButton,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { useSelector } from "react-redux";
import axios from "axios";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";

import {
  saveUser,
  setShowUserCompleteProfileDialogueBox,
} from "../../Redux/slice/userSlice";
import WebSocketService from "../DealerSection/DealerMessages/WebSocketService";
import { debounce } from "lodash";

const CryptoCard = ({ handleShowDialogueBox, product, ProductId }) => {
  const navigate = useNavigate();
  const [showSocialMedia, setShowSocialMedia] = useState(false);
  const [isFav, setIsFav] = useState(false);
  // console.log(product);
  const location = useLocation();
  const { id } = useSelector((state) => state.user);
  // console.log("User ID ", id);
  const handleShareLink = () => {
    console.log("Sharing link with the id");
    setShowSocialMedia(!showSocialMedia);
  };
  const generateShareUrl = (itemId) => {
    return `${window.location.origin}${location.pathname}/${itemId}`;
  };
  const userData = useSelector((state) => state.user);

  // Helper function to get the border color
  const getBorderColor = (condition) => {
    switch (condition) {
      case "New 8/32>":
        return Image.greenTyre;
      case "Good 6/32-7/32>":
        return Image.yellowTyre;
      case "Fair <5/32":
        return Image.blueTyre;
      case "Fully-Used NA":
        return Image.redTyre;
      default:
        return Image.tireCondition; // Default color if condition is unknown
    }
  };

  const handleLikeTheProduct = async () => {
    if (id === 0) {
      handleShowDialogueBox(true);
    } else {
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_API_URL}/listing/add_favourite/${id}/${ProductId}`
        );
        console.log(res);
        setIsFav(true);
      } catch (error) {
        console.log("Server side error while liking the product");
      }
    }
  };
  // for single product details---------------
  const handleClick = () => {
    navigate(
      `/cardetail/${product.id}_${product.modelYear}_${product.modelMake}`,
      {
        state: { product },
      }
    );
  };
  // for mask vin----------
  const maskVin = () => {
    const vin = product.vin;
    return vin?.slice(0, 9) + "*".repeat(vin.length - 9);
  };

  const maskMileageIn = () => {
    const mileage_in = product.mileage_in.toString();
    return "*".repeat(mileage_in.length);
  };

  // for popup seller-description----
  const words = product?.seller_description?.split(" ");
  const {
    all_images,
    exterior_images,
    misc_images,
    interior_images,
    three_sixty_images,
  } = product && product.multimedia;
  const noImage = Image.image_not_available;
  const getFirstValidImage = (images) => {
    if (images) {
      const imageList = images.split(",").map((img) => img.trim());
      for (let image of imageList) {
        if (image) {
          return `https://5starsmotors.com/images/${product.userId
            }/${image.trim()}`;
        }
      }
    }
    return null;
  };
  const imageUrl =
    (all_images && getFirstValidImage(all_images)) ||
    (exterior_images && getFirstValidImage(exterior_images)) ||
    (misc_images && getFirstValidImage(misc_images)) ||
    (interior_images && getFirstValidImage(interior_images)) ||
    (three_sixty_images && getFirstValidImage(three_sixty_images)) ||
    noImage;
  const [showLoginModal, setShowLoginModal] = useState(false);
  const handleSendMsg = () => {
    if (id === 0) {
      setShowLoginModal(true);
    } else {
      alert("What is your message ? ");
    }
  };

  const dispatch = useDispatch();

  const handleCustomerLogin = (userType) => {
    setShowLoginModal(false);
    if (userType === "Customer") {
      dispatch(saveUser({ showLoginDialogue: true }));

      navigate("/");
    } else {
      navigate("/dealer/login");
    }
  };

  //Message input
  const [isTyping, setIsTyping] = useState(false);
  const [messages, setMessages] = useState([]);
  console.log("messages => ", messages);
  const [messageInput, setMessageInput] = useState("");
  const handleMessageInput = (e) => {
    setMessageInput(e.target.value);
  };
  useEffect(() => {
    if (id !== 0 && messageInput.trim() !== "") {
      WebSocketService.connect(onMessageReceived, id);
    } else {
      WebSocketService.disconnect();
    }
    // Cleanup on component unmount
  }, [messageInput]);

  const onMessageReceived = (message) => {
    const messageBody = JSON.parse(message.body);
    console.log("Received message:", messageBody);
    if (messageBody.type === "CHAT") {
      setMessages((prevMessages) => [...prevMessages, messageBody]);
    } else if (messageBody.type === "TYPING") {
      setIsTyping(messageBody.content !== "");
    }
  };

  const sendMessage = () => {
    if (!id || !product.userId || !messageInput) {
      setShowLoginModal(true);
      return;
    }

    const message = {
      sender: id,
      recipient: product.userId,
      content: messageInput,
      type: "CHAT",
    };

    // Add the sender's message to the state immediately

    // Send the message to the backend via WebSocket
    WebSocketService.sendMessage(message);

    // Clear the input field
    setMessageInput("");
    navigate("/dealer/messages", { state: product });
  };
  // for esttmated cost------------------------------
  const estimatedPrice = product.estimated_cost;
  // -----------------------------------------------------
  return (
    <div className="card wrapper_card_product p-2">
      {/* Top Items */}
      <div className="row  p-3 pb-0 rounded card_container">
        <div className="col-lg-4 col-md-5 card_left">
          <div className="product-image-box">
            <img
              // src={
              //   product.multimedia.all_images
              //     ? `https://5starsmotors.com/images/${product.userId}/${
              //         product.multimedia.all_images?.split(",")[0]
              //       }`
              //     : Image.image_not_available
              // }
              src={imageUrl}
              alt="product_car_image"
              className="product_car_image"
            />
            {/* <i className = "fa-regular fa-heart"></i> */}
            <div className="product_share_like">
              <span onClick={handleShareLink}>
                <IoShareSocial />
              </span>
              {id !== 0 ? (
                <span onClick={handleLikeTheProduct}>
                  <FaHeart fill={`${isFav ? "red" : "white"}`} />
                </span>
              ) : null}
            </div>
            {/* Share buttons */}
            {showSocialMedia ? (
              <div className="social_media_container">
                <FacebookShareButton url={generateShareUrl(id)}>
                  <FacebookIcon size={32} round />
                </FacebookShareButton>
                <TwitterShareButton url={generateShareUrl(id)}>
                  <TwitterIcon size={32} round />
                </TwitterShareButton>
                <WhatsappShareButton url={generateShareUrl(id)}>
                  <WhatsappIcon size={32} round />
                </WhatsappShareButton>
                <TelegramShareButton url={generateShareUrl(id)}>
                  <FacebookMessengerIcon size={32} round />
                </TelegramShareButton>
              </div>
            ) : null}
          </div>
          <div className="interior_exterior_color_container">
            <span
              style={{
                backgroundColor: product.exterior_color,
                color: product.exterior_color ? "white" : "",
              }}
            >
              Exterior
            </span>
            <span
              style={{
                backgroundColor: product.exteriorColor,
              }}
              title={product.exteriorColor ? product.exteriorColor : ""}
            ></span>
            <span
              style={{
                backgroundColor: product.interior_color,
                color: product.interior_color ? "white" : "",
                marginLeft: "0.3rem",
              }}
            >
              Interior
            </span>
            <span
              style={{
                backgroundColor: product.interiorColor,
              }}
              title={product.interiorColor ? product.interiorColor : ""}
            ></span>
          </div>
        </div>
        <div className="ps-0 card_center">
          {/*----------------------content deatils Start---------------------*/}
          {/* <div className="row"> */}
          <div className=" center_primary">
            <h4
              style={{ fontWeight: 800, cursor: "pointer" }}
              onClick={handleClick}
            >
              {product.year_Make_Model} {product.trim}
            </h4>
            <div className="vin_extras_container">
              <h6 style={{ fontSize: "18px" }}>
                <span
                  className="theme-color "
                  style={{
                    color: "#D1823A",
                    fontWeight: "bold",
                    margin: "10px 3px",
                  }}
                >
                  Mileage :
                </span>
                <span style={{ cursor: "pointer" }} onClick={handleClick}>
                  {/* {product.mileage_in} */}
                  {new Intl.NumberFormat().format(product.mileage_in)}
                </span>
              </h6>
              <h6 style={{ fontSize: "18px", display: "flex", gap: "0.2rem" }}>
                <span> {product?.driveType || 0} </span>{" "}
                <span> {product?.engine.split(" ")[1]} </span>{" "}
                <span> {product?.engine.split(" ")[0]} </span>{" "}
                <span> {product?.engine.split(" ")[3]} </span>{" "}
                <span> {product.transmission || "Transmission NA"} </span>
                <span>{product.id}</span>
              </h6>
            </div>

            <h6 style={{ fontSize: "18px", margin: "01.3rem 0" }}>
              <span
                className="theme-color"
                style={{ color: "#D1823A", fontWeight: "bold" }}
              >
                Location :{" "}
              </span>
              {product.location || "Seller Location NA"}{" "}
              <b>
                <sub>132 miles</sub>
              </b>
            </h6>
            <div className="d-flex seller_section_product_listing">
              <h6 style={{ fontSize: "18px" }}>
                <span
                  className="theme-color"
                  style={{ color: "#D1823A", fontWeight: "bold" }}
                >
                  Seller :{" "}
                </span>
                <span>{product.user_name || "Seller Name NA"}</span>
              </h6>
              <label
                className="form-check-label ms-2"
                htmlFor="flexCheckDefault"
              >
                <i className="fas fa-star " style={{ color: "#D1823A" }}></i>
                <i className="fas fa-star " style={{ color: "#D1823A" }}></i>
                <i className="fas fa-star " style={{ color: "#D1823A" }}></i>
                <i className="fas fa-star " style={{ color: "#D1823A" }}></i>
                <i className="fas fa-star "></i>
              </label>
            </div>

            {/* <div className=" d-flex" style={{ marginTop: "1rem" }}>
                <h6
                  style={{
                    fontSize: "15px",
                    marginRight: "1rem",
                    marginTop: "0.2rem",
                  }}
                >
                  <b>Seller Report</b>{" "}
                </h6>
                <p>
                  {firstFourWords || "Seller Description NA"} {" "}
                  {product.seller_description ? (
                    <Link
                      to="#"
                      className="tooltip-bottom"
                      data-tooltips={product.seller_description}
                    // data-tooltips="Welcome to Dac, Sector 62,Noida D-Block"
                    >
                      More
                    </Link>
                  ) : null}
                </p>
              </div> */}
          </div>
          <div className="center_secondary">
            <h5 style={{ fontSize: "20px", marginBottom: "1rem" }}>
              {" "}
              <b>Price : </b>
              <span className="theme-color" style={{ color: "#D1823A" }}>
                {" "}
                ${new Intl.NumberFormat().format(product.listing_price)}{" "}
                /${new Intl.NumberFormat().format(estimatedPrice ? Math.ceil(estimatedPrice) : 0)}
              </span>{" "}
              <span style={{ fontSize: "medium" }}>/Mo</span>{" "}
            </h5>
            <div className="call_seller_button_container">
              <div className="product-g-btn">
                <span>
                  <MdPhoneInTalk />
                </span>{" "}
                +1<span style={{ fontSize: "16px" }}>(123) 423 7635</span>
              </div>
              <div className="product-S-btn">
                <span style={{ transform: "rotateY(180deg)" }}>
                  <VscTag />
                </span>{" "}
                Send Offers
              </div>
            </div>
            <div className="contact_message_section">
              <p
                style={{
                  fontSize: "18px",
                  // border: "2px solid blue",
                  textAlign: "center",
                  fontWeight: "600",
                  marginBottom: "2px",
                }}
              >
                Check Availibity
              </p>
              <div className="input_contact_message_section">
                {/* <img
                  src={Image.messanger}
                  alt="messenger icon"
                  height={30}
                  width={30}
                /> */}
                <input
                  type="text"
                  placeholder="Hi Kumar, Is this still available ?"
                  value={messageInput}
                  onChange={handleMessageInput}
                // style={{ marginLeft: "31%" }}
                />
                <button
                  disabled={messageInput.trim() === "" ? true : false}
                  style={{
                    cursor:
                      messageInput.trim() === "" ? "not-allowed" : "pointer",
                    color: messageInput.trim() === "" ? "lightgrey" : "white",
                  }}
                  onClick={sendMessage}
                >
                  Send
                </button>
              </div>
            </div>
          </div>
          {/* </div> */}
        </div>
        <Modal
          size="xl"
          show={showLoginModal}
          onHide={() => setShowLoginModal(false)}
        >
          <Modal.Header>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4 style={{ textAlign: "center", marginTop: "1rem" }}>
              Log in to unlock this feature! You need to be logged in to send
              message to this dealer.
            </h4>
            <h5 style={{ margin: "2rem 0", textAlign: "center" }}>
              {" "}
              Click below to log in and continue.
            </h5>
            <p
              style={{ display: "flex", justifyContent: "center", gap: "3rem" }}
            >
              <button
                style={{
                  border: "none",
                  backgroundColor: "#d1823a",
                  color: "white",
                  fontSize: "larger",
                  fontWeight: "600",
                  padding: "0.4rem 0.7rem",
                  borderRadius: "10px",
                }}
                onClick={() => handleCustomerLogin("Customer")}
              >
                Customer Login
              </button>
              <button
                style={{
                  border: "none",
                  backgroundColor: "#d1823a",
                  color: "white",
                  fontSize: "larger",
                  fontWeight: "600",
                  padding: "0.4rem 0.7rem",
                  borderRadius: "10px",
                }}
                onClick={() => handleCustomerLogin("Dealer")}
              >
                Dealer Login
              </button>
            </p>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      </div>
      {/* Bottom items */}
    </div>
  );
};

export default CryptoCard;
