import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { PDFDocument } from "pdf-lib";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const PDFViewer = ({
  pdfArray,

  isCallFunc,
  setisCallFunc,
}) => {
  const [mergedPdfUrl, setMergedPdfUrl] = useState(null);

  console.log("Merged PDF URL", mergedPdfUrl);

  const userID = useSelector((state) => state.user);
  const selectedCar = useSelector((state) => state.sell.selectedVehicle);

  //method to merge pdfs
  const mergePDFs = async (pdfArray) => {
    try {
      const mergedPdf = await PDFDocument.create();
      for (const item of pdfArray) {
        const existingPdfBytes = await fetch(
          `https://5starsmotors.com/documents/${userID.id}/${item.pdf}`
        ).then((res) => res.arrayBuffer());
        const pdf = await PDFDocument.load(existingPdfBytes);
        const copiedPages = await mergedPdf.copyPages(
          pdf,
          pdf.getPageIndices()
        );
        copiedPages.forEach((page) => mergedPdf.addPage(page));
      }

      const mergedPdfBytes = await mergedPdf.save();

      return URL.createObjectURL(
        new Blob([mergedPdfBytes], { type: "application/pdf" })
      );
    } catch (error) {
      toast.error(error.message);
    }
  };
  useEffect(() => {
    const mergeAndSetPdf = async () => {
      const url = await mergePDFs(pdfArray);
      setMergedPdfUrl(url);
    };

    mergeAndSetPdf();
  }, []);
  const downloadMergedPDF = () => {
    if (mergedPdfUrl) {
      const link = document.createElement("a");
      link.href = mergedPdfUrl;
      // link.download = "merged_document.pdf";
      link.download = `${selectedCar?.year_Make_Model} ${
        selectedCar?.trim
      } ${selectedCar?.vin.slice(-8)}${userID.id}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      toast.error("PDF is not ready for download yet.");
    }
    setTimeout(() => {
      navigate("/dealer/inventory/sold", { state: true });
     }, 1500); // Delay of 2000 milliseconds (2 seconds)
    console.log("Download Successfully.........")
  };
  // useEffect(() => {
  //   if (isCallFunc) {
  //     downloadMergedPDF();
  //     setisCallFunc(false);
  //   }
  // }, [isCallFunc]);
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (isCallFunc) {
  //     downloadMergedPDF();
  //   }
  // }, [isCallFunc]);
  useEffect(() => {
    if (isCallFunc  && mergedPdfUrl) {
      downloadMergedPDF(() => {
        navigate("/dealer/inventory/sold", { state: true });
      });
      setisCallFunc(false);
      // Clean up the timer if the component unmounts
      // return () => {
      //      navigate("/dealer/inventory/sold", { state: true });
      // };
    }
  }, [isCallFunc, navigate,mergedPdfUrl]);

  if (!mergedPdfUrl) {
    return <div>Loading...</div>;
  }

  return (
    <div style={{ height: "85vh", marginBottom: "1.5rem" }}>
      {/* <button onClick={downloadMergedPDF}>Download All PDFS</button> */}
      <iframe
        src={mergedPdfUrl + "#zoom=150"}
        width="100%"
        height="100%"
        type="application/pdf"
        title="Merged PDF File"
      />
      <Toaster
        position="top-right"
        reverseOrder={false}
        toastOptions={{
          // Define default options
          className: "",
          duration: 1000,
          style: {
            background: "#363636",
            color: "#fff",
            fontSize: "larger",
          },
        }}
      />
    </div>
  );
};

export default PDFViewer;
