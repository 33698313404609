import React, { useEffect, useState } from "react";
import { DealerSideBarArray } from "./DealerSideBarData";
import DealerSideBarItem from "./DealerSideBarItem";
import { useLocation } from "react-router-dom";

const DealerSideBar = ({ isPermanentlyExpanded, onHoverChange }) => {
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [openState, setOpenState] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredItems, setFilteredItems] = useState(DealerSideBarArray);

  // ADDED: Use useLocation hook to get current path
  const location = useLocation();
  const isExpanded = isPermanentlyExpanded || isHovered;

  // ADDED: Function to find the selected item based on current path
  const findSelectedItemId = (items, path) => {
    for (let item of items) {
      if (item.route === path) {
        return item.id;
      }
      if (item.children) {
        const childId = findSelectedItemId(item.children, path);
        if (childId) return childId;
      }
    }
    return null;
  };

  // ADDED: Effect to update selected item and open state based on current path
  useEffect(() => {
    const currentItemId = findSelectedItemId(
      DealerSideBarArray,
      location.pathname
    );
    setSelectedItemId(currentItemId);

    // ADDED: Function to open parent items when a child is selected
    const openParents = (items, id, parents = {}) => {
      for (let item of items) {
        if (item.id === id) {
          return parents;
        }
        if (item.children) {
          const childResult = openParents(item.children, id, {
            ...parents,
            [item.id]: true,
          });
          if (childResult) return childResult;
        }
      }
      return null;
    };

    if (currentItemId) {
      const parentsToOpen = openParents(DealerSideBarArray, currentItemId);
      if (parentsToOpen) {
        setOpenState((prevState) => ({ ...prevState, ...parentsToOpen }));
      }
    }
  }, [location]);

  // MODIFIED: Updated handleItemClick to manage open state
  const handleItemClick = (itemId, isOpen) => {
    setSelectedItemId(itemId);
    setOpenState((prevState) => ({
      ...prevState,
      [itemId]: isOpen,
    }));
  };

  useEffect(() => {
    if (!isExpanded) {
      setOpenState({});
    }
  }, [isExpanded]);

  const filterItems = (items, parentIds = new Set()) => {
    return items
      .map((item) => {
        if (item.children) {
          const filteredChildren = filterItems(item.children, parentIds);
          if (filteredChildren.length) {
            parentIds.add(item.id);
          }
          return {
            ...item,
            children: filteredChildren.length ? filteredChildren : undefined,
          };
        }
        if (item.name.toLowerCase().includes(searchQuery.toLowerCase())) {
          parentIds.add(item.id);
          return item;
        }
        return null;
      })
      .filter(Boolean);
  };

  useEffect(() => {
    const filtered = filterItems(DealerSideBarArray);
    setFilteredItems(filtered);

    const newOpenState = {};
    filtered.forEach((item) => {
      if (item.children) {
        newOpenState[item.id] = false;
      }
    });
    setOpenState(newOpenState);
  }, [searchQuery]);

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <div
      className={`dealer-sidebar ${isExpanded ? "expanded" : "collapsed"}`}
      onMouseEnter={() => {
        setIsHovered(true);
        onHoverChange(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
        onHoverChange(false);
      }}
    >
      {isExpanded && (
        <div className="dealer_sidebar_input_container">
          <input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearch}
          />
        </div>
      )}
      {filteredItems.map((item) => (
        <DealerSideBarItem
          key={item.id}
          item={item}
          selectedItemId={selectedItemId}
          onItemClick={handleItemClick}
          isExpanded={isExpanded}
          isOpenState={openState}
          // ADDED: Pass current path to DealerSideBarItem
          currentPath={location.pathname}
        />
      ))}
    </div>
  );
};

export default DealerSideBar;
