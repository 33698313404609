import React from 'react';
 import image1 from '../../../imgData/icon1.png'
 import image2 from '../../../imgData/icon2.png'
 import image3 from '../../../imgData/icon3.png'

const ExtraFeatures1 = () => {
  return (
    <section className="p-lg-5 p-md-5 p-xs-5 bg-lightOrange box1">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-4 col-md-12 my-2">
              <div className="d-sm-flex text-md-start text-center justify-content-center align-items-center p-1 bg-white border rounded my-2 mh-100 h-100">
                <img className="w-25 mx-2" src={image1} alt="con" />
                <div>
                  <h4>Digital Contracting</h4>
                  <p>
                    Ensuring simplicity, swiftly sealing deals, and offering
                    eSign, eContract, Online Payment, & Delivery.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 my-2">
              <div
                className="d-sm-flex text-md-start text-center justify-content-center align-items-center p-1 bg-orange text-white rounded my-2 mh-100 h-100"
                style={{ backgroundColor: "#c57a3f" }}
              >
                <img className="w-25 mx-2" src={image2} alt="con" />
                <div>
                  <h4>Safe and Secure</h4>
                  <p>
                    Dive into Cutting-Edge Technology, Where Excellence is
                    Redefined and Delivered with Every Interaction
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 my-2">
              <div className="d-sm-flex text-md-start text-center justify-content-center align-items-center p-1 bg-white border rounded my-2 mh-100 h-100">
                <img className="w-25 mx-2" src={image3} alt="con" />
                <div>
                  <h4>Verified Dealers</h4>
                  <p>
                    Connecting you to the USA's most trusted car professionals
                    and genuine private sellers. Safe, straightforward, and
                    reliable every time.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
  )
}

export default ExtraFeatures1
