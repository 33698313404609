import "../home/home.css";
// import Marquee from 'react-fast-marquee';
import { Link, useLocation, useNavigate } from "react-router-dom";
import SellYourCar from "./SellYourCar";
import MonthlyLoanCalculator from "./MonthlyLoanCalculator";
import MainMarquee from "./MainMarquee";
import VehicleSlider from "./VehicleSlider";
import Navbar from "../navbars/Navbar";
import { React, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import CompleteUserDetailModal from "../UserAuthDialogue/Pages/CompleteUserDetailModal";
import HomeFilters from "./HomeFilters";
import NearByCars from "./NearByCars";
import ExtraFeatures1 from "./WebsitePreviews/components/ExtraFeatures1";
import VehicleReport1 from "./WebsitePreviews/components/VehicleReport";
import DealerLoginMarquee from "./WebsitePreviews/DealerLoginMarquee";
import { useDispatch } from "react-redux";
import { getRedirectResult } from "firebase/auth";
import { auth } from "../UserAuthDialogue/components/firebaseConfig";
import { getIPAddress } from "../UserAuthDialogue/components/SignInwithApple";
import { saveUser } from "../../Redux/slice/userSlice";
import axios from "axios";
import HandleAppleResullt from "./HandleAppleResullt";
import DealerSideBar from "../DealerSection/DealerSideBar/DealerSideBar";
import HomeFiltersLogin from "./HomeFilterLogin";
import { useGoogleOneTapLogin } from "@react-oauth/google";

const Home = () => {
  const navigate = useNavigate();
  const calculatorRef = useRef(null);
  const userData = useSelector((state) => state.user);
  const { firstName, lastName, id, email, roles } = userData;
  console.log("Id of user in my profile from redux", userData.id);
  const [showDialogueBox, setShowDialogueBox] = useState(false);
  useEffect(() => {
    if (id && email) {
      if (firstName && lastName && roles === "User") {
        setShowDialogueBox(false);
        console.log(
          "All user details are present like first name , lastname and profile picture"
        );
      } else if (roles === "Dealer") {
        setShowDialogueBox(false);
      } else {
        // console.log(
        //   "All user details are present like first name , lastname and profile picture"
        // );
        console.log(
          "Not present =>  first name , lastname and profile picture"
        );
        setShowDialogueBox(true);
      }
    }
  }, [firstName, lastName, id, email, roles]);
  useEffect(() => {
    if (showDialogueBox) {
      document.body.classList.add("disable-scroll");
      // Disable other user interactions
      const disableUserEvents = (e) => e.stopImmediatePropagation();
      // window.addEventListener("click", disableUserEvents, true);
      window.addEventListener("keydown", disableUserEvents, true);

      return () => {
        window.removeEventListener("click", disableUserEvents, true);
        window.removeEventListener("keydown", disableUserEvents, true);
      };
    } else {
      document.body.classList.remove("disable-scroll");
    }
  }, [showDialogueBox]);

  //
  const [isPermanentlyExpanded, setIsPermanentlyExpanded] = useState(() => {
    // Initialize state from localStorage, default to false if not set
    return localStorage.getItem("sidebarExpanded") === "true";
  });

  useEffect(() => {
    // Update localStorage whenever isPermanentlyExpanded changes
    localStorage.setItem("sidebarExpanded", isPermanentlyExpanded);
  }, [isPermanentlyExpanded]);

  const toggleSidebar = () => {
    setIsPermanentlyExpanded(!isPermanentlyExpanded);
  };
  const [isHovered, setIsHovered] = useState(false);
  const handleSidebarHover = (hovered) => {
    setIsHovered(hovered);
  };

  const isExpanded = isPermanentlyExpanded || isHovered;
  const isSideBarOpen = useSelector((state) => state.user.isSideBarOpen);




  return (
    <div className="home_grand_parent_wrapper">
      {showDialogueBox ? (
        <div className="complete_user_details_modal">
          <CompleteUserDetailModal setShowDialogueBox={setShowDialogueBox} />
        </div>
      ) : null}

      <Navbar position="absolute" shouldShow={true} />
      <div style={{ display: "flex" }}>
        {isSideBarOpen && id !== 0 && email && roles === "Dealer" && (
          <div
            className={`dealer_sidebar_home_Parent ${isSideBarOpen ? "ShowSidebar" : "HideSidebar"
              }`}
            style={{
              position: "relative",
              top: "0",
              flex: isSideBarOpen ? "0.15" : "0.75",
            }}
          >
            <DealerSideBar
              isPermanentlyExpanded={isPermanentlyExpanded}
              onHoverChange={handleSidebarHover}
            />
          </div>
        )}

        <div
          className="d-flex justify-content-center flex-column"
          style={{ overflowX: "scroll", flex: "1" }}
        >
          {roles && roles === "Dealer" ? (
            <DealerLoginMarquee />
          ) : (
            <MainMarquee />
          )}

          {roles && roles === "Dealer" ? (
            <HomeFiltersLogin
              scrollToCalculator={() =>
                calculatorRef.current.scrollIntoView({ behavior: "smooth" })
              }
            />
          ) : (
            <HomeFilters
              scrollToCalculator={() =>
                calculatorRef.current.scrollIntoView({ behavior: "smooth" })
              }
            />
          )}

          <ExtraFeatures1 />
          <VehicleSlider />
          {/* <HandleAppleResullt /> */}
          <NearByCars />

          <MonthlyLoanCalculator ref={calculatorRef} />
          <SellYourCar />

          <VehicleReport1 />

          {roles !== "Dealer" ? (
            <>
              <section className="our-solutions">
                <div className="container-fluid">
                  <div className="text-center">
                    <h2>Our Products</h2>
                    <p>
                      Explore our Services to make your car buying selling
                      experience seamless.
                    </p>
                  </div>
                  {/* <div className="col-lg-12 pt-3"> */}
                  <div className="row justify-content-center">
                    {/* <div className="col-md-2"></div> */}
                    <div className="col-md-10 text-center">
                      <div className="row justify-content-between gap-1">
                        <div className="col-md-3 mb-3">
                          <div className="box-cont">
                            <img
                              src="image/solutions-icon1.png"
                              alt="1"
                              width="100%"
                            />
                            <h5 className="h_5 fw-bold">DMS</h5>
                          </div>
                        </div>
                        <div className="col-md-3 mb-3">
                          <div className="box-cont img_e">
                            <img
                              src="image/solutions-icon2.png"
                              alt="2"
                              width="100%"
                            />
                            <h5 className="h_5 fw-bold">Fax Service</h5>
                          </div>
                        </div>
                        <div className="col-md-3 mb-3">
                          <div className="box-cont img_e">
                            <img
                              src="image/solutions-icon9.png"
                              alt="3"
                              width="100%"
                            />
                            <h5 className="h_5 fw-bold">Logistics Service</h5>
                          </div>
                        </div>
                        <div className="col-md-3 mb-3">
                          <div className="box-cont img_e">
                            <img
                              src="image/solutions-icon3.png"
                              alt="4"
                              width="100%"
                            />
                            <h5 className="h_5 fw-bold">
                              Facebook Market Service
                            </h5>
                          </div>
                        </div>
                        <div className="col-md-3 mb-3">
                          <div className="box-cont">
                            <img
                              src="image/solutions-icon4.png"
                              alt="5"
                              width="100%"
                            />
                            <h5 className="h_5 fw-bold">Qualified Leads</h5>
                          </div>
                        </div>
                        <div className="col-md-3 mb-3">
                          <div className="box-cont img_e">
                            <img
                              src="image/solutions-icon5.png"
                              alt="6"
                              width="100%"
                            />
                            <h5 className="h_5 fw-bold">Smart CRM</h5>
                          </div>
                        </div>
                        <div className="col-md-3 mb-3">
                          <div className="box-cont img_e">
                            <img
                              src="image/solutions-icon6.png"
                              alt="7"
                              width="100%"
                            />
                            <h5 className="h_5 fw-bold">Social Media Ads</h5>
                          </div>
                        </div>
                        <div className="col-md-3 mb-3">
                          <div className="box-cont img_D">
                            <img
                              src="image/solutions-icon8.png"
                              alt="8"
                              width="100%"
                            />
                            <h5 className="h_5 fw-bold">Website For Dealers</h5>
                          </div>
                        </div>
                        <div className="col-md-3 mb-3">
                          <div className="box-cont img_e">
                            <img
                              src="image/solutions-icon7.png"
                              alt="9"
                              width="100%"
                            />
                            <h5 className="h_5 fw-bold">HR System</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-md-2"></div> */}
                  </div>
                  {/* </div> */}
                </div>
              </section>
              <section className="p-lg-5 p-md-3 p-2 bg-lightOrange box1">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-12">
                      <h3 className="text-center">CRM Featured</h3>
                      <p className="text-center">
                        Dealers Auto Center is a trusted partner of leading
                        banks across the USA
                      </p>
                    </div>
                    <div className="col-lg-4 col-md-12">
                      <div className=" h-100 d-sm-flex text-md-start text-center justify-content-center align-items-center p-1 bg-white border rounded my-2">
                        <img
                          className="w-25 mx-2"
                          src="image/icon-box1.webp"
                          alt="box1"
                        />
                        <div>
                          <h4>Track with Precision</h4>
                          <p>
                            Harness the power of real-time data, ensuring every
                            customer interaction is logged, every opportunity
                            captured, and every sales move is strategic.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                      <div className=" h-100 d-sm-flex text-md-start text-center justify-content-center align-items-center p-1 bg-white border rounded my-2">
                        <img
                          className="w-25 mx-2"
                          src="image/icon-box2.webp"
                          alt="box2"
                        />
                        <div>
                          <h4>Profile with Insight</h4>
                          <p>
                            Dive deep into customer behaviors, preferences, and
                            histories, crafting detailed profiles that empower
                            sales teams to personalize and connect more
                            effectively.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                      <div className="h-100 d-sm-flex text-md-start text-center justify-content-center align-items-center p-1 bg-white border rounded my-2">
                        <img
                          className="w-25 mx-2"
                          src="image/icon-box3.webp"
                          alt="box3"
                        />
                        <div>
                          <h4>Market with Mastery</h4>
                          <p>
                            Seamlessly integrate potent marketing tools,
                            automating campaigns that resonate, engage, and
                            convert, all while boosting sales and fortifying
                            brand relationships.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="text-center mt-4">
                      <button className="btn-grad">More</button>
                    </div>
                  </div>
                </div>
              </section>{" "}
            </>
          ) : null}

          {/* ----------------------------------------------------------------CRM Featured End--------------------------------------------------------- */}

          {/* ----------------------------------------------------------------Trusted Financing Partners Start--------------------------------------------------------- */}
          <section className="p-4">
            <div className="container-fluid text-center">
              <div className="row">
                <div className="col-md-12 p-4 px-0">
                  <h2>Trusted Financing Partners</h2>
                  <p>
                    Dealers Auto Center is a trusted partner of leading banks
                    across the USA
                  </p>
                  <div className="row pt-4">
                    {/* <div className="customer-logos slider p-0"> */}

                    <div className="auto_slider_containers">
                      <ul className="auto_sliders">
                        <li>
                          <img src="client-logo/logo1.webp" alt="" />
                        </li>
                        <li>
                          <img src="client-logo/logo2.webp" alt="" />
                        </li>
                        <li>
                          <img src="client-logo/logo3.webp" alt="" />
                        </li>
                        <li>
                          <img src="client-logo/logo4.webp" alt="" />
                        </li>
                        <li>
                          <img src="client-logo/logo5.webp" alt="" />
                        </li>
                        <li>
                          <img src="client-logo/logo1.webp" alt="" />
                        </li>
                      </ul>
                      <ul className="auto_sliders">
                        <li>
                          <img src="client-logo/logo2.webp" alt="" />
                        </li>
                        <li>
                          <img src="client-logo/logo3.webp" alt="" />
                        </li>
                        <li>
                          <img src="client-logo/logo4.webp" alt="" />
                        </li>
                        <li>
                          <img src="client-logo/logo5.webp" alt="" />
                        </li>
                        <li>
                          <img src="client-logo/logo1.webp" alt="" />
                        </li>
                        <li>
                          <img src="client-logo/logo2.webp" alt="" />
                        </li>
                      </ul>
                      <ul className="auto_sliders">
                        <li>
                          <img src="client-logo/logo3.webp" alt="" />
                        </li>
                        <li>
                          <img src="client-logo/logo4.webp" alt="" />
                        </li>
                        <li>
                          <img src="client-logo/logo5.webp" alt="" />
                        </li>
                        <li>
                          <img src="client-logo/logo1.webp" alt="" />
                        </li>
                        <li>
                          <img src="client-logo/logo2.webp" alt="" />
                        </li>
                        <li>
                          <img src="client-logo/logo3.webp" alt="" />
                        </li>
                      </ul>
                    </div>
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* ----------------------------------------------------------------Trusted Financing Partners End--------------------------------------------------------- */}

          {/* -------------------------------------------------USA's top-rated car buying app Start--------------------------------------------------------------*/}
          <section>
            <div className="row" style={{ height: "700px" }}>
              <div className="col-lg-3" style={{ margin: "auto" }}>
                <h2>USA's top-rated car buying app</h2>
                <p>
                  Search through thousands of incredible new and used vehicles.
                  <br />
                  Dealers will respond in real-time to your offer.
                </p>
                <div className="app-btn">
                  <img src="image/ios-black.png" alt="iOS" />
                  <img src="image/android-black.png" alt="Android" />
                </div>
                <p className="pt-3">Download the App Now!</p>
              </div>
              {roles && roles === "Dealer" ? null : (
                <div className="col-lg-7">
                  <img src="image/app.png" alt="App" width="100%" />
                </div>
              )}
            </div>
          </section>

          <section>
            <div className="bg-color"></div>
          </section>
          {/* ----------------------------- */}

          <section
            className="pb-5"
            style={{
              backgroundImage: "url('image/bg-img.webp')",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-2"></div>
                <div className="col-lg-8">
                  <div
                    className="p-4"
                    style={{
                      backgroundImage: "url('image/waiting.jpg')",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                    }}
                  >
                    <div className="waiting">
                      <div className="pt-4 pb-1">
                        <h1>
                          <b>
                            Your next car is waiting <br />
                            for you here...
                          </b>
                        </h1>
                      </div>
                      <div className="pt-4 pb-4">
                        <button className="btn-grad">More</button>
                      </div>
                    </div>
                  </div>
                  <div style={{ backgroundColor: "#f5f7fb" }}>
                    <div className="row p-4">
                      <div className="col-lg-12 text-center">
                        <p
                          style={{
                            backgroundColor: "#fff",
                            padding: "15px",
                            fontSize: "33px",
                            borderRadius: "50px",
                            margin: "auto",
                            color: "#c57a40",
                            width: "78px",
                            height: "78px",
                          }}
                        >
                          <i
                            className="fa-solid fa-envelope"
                            style={{ fontSize: "35px" }}
                          ></i>
                        </p>
                        <p>Contact Us</p>
                        <p>
                          <b>abc@email.co</b>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2"></div>
              </div>
            </div>
          </section>
          {/* -------------------------------------------------USA's top-rated car buying app End--------------------------------------------------------------*/}
        </div>
      </div>
    </div>
  );
};

export default Home;
