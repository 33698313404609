import React, { useEffect, useState } from "react";
import Styles from "./Styles/DealerBusinessInformation.module.css";
import BusinessLocationCard from "./BusinessLocationCard";
import { useOutletContext } from "react-router-dom";
import SavedDealerLocations from "./SavedDealerLocations";
import axios from "axios";
import { useSelector } from "react-redux";
import { index } from "mathjs";

const DealerBusinessInformation = () => {
  const isExpanded = useOutletContext();
  const [showForm,setShowForm] = useState(false)
 
  const handleAdd = (boolVal) => {
    setShowForm(boolVal);
  };

  const handleShowForms = () => {
    setShowForm(prevState => !prevState);
  };
  const { id } = useSelector((state) => state.user);

  const [savedLocations, setSavedLocations] = useState([]);
  console.log(savedLocations);
  const [editLocation, setEditLocation] = useState(null);
  const [isEditing,setIsEditing] = useState(false)
  const fetchLocation = async () => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/dealer/get/${id}`
      );
      console.log(res);
      if (res.status === 200) {
        setSavedLocations(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchLocation();
  }, []);

  const handleEdit = (location) => {
    console.log("Location of the card to be edited", location )
    setEditLocation(location)
  }
  // ---------------------

 
  
  return (
    <div
      className={`${Styles.dealer_business_information_wrapper} ${
        isExpanded ? Styles.content_expanded : Styles.content_collapsed
      }  `}
    >
      <div className={Styles.dealer_business_information_container}>
        <div className={Styles.savedLocationWrapper}>
          {savedLocations &&
            savedLocations.map((loc, index) => (
              <div key={loc.id} className={Styles.savedLocationContainer}>
                <SavedDealerLocations editLocation={editLocation} index={index} location ={loc} fetchLocation={fetchLocation} onEdit={() => handleEdit(loc)} handleAdd={() => handleAdd(index)} setIsEditing={setIsEditing} isEditing={isEditing} setShowForm={setShowForm}/>
              </div>
            ))}  
        </div>
        <p className={Styles.addLocation_container}>
          <button className={Styles.addLocation_button} onClick={handleShowForms}>
            Add more location
          </button>
        </p>
          {showForm && <BusinessLocationCard fetchLocation={fetchLocation} index={savedLocations.length} editLocation={editLocation} setEditLocation={setEditLocation} handleAdd={handleAdd}/>}  
      </div>
    </div>
  );
};

export default DealerBusinessInformation;
