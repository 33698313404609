import React, { useState, useEffect, useRef } from 'react';

const PopupData = ({ radioOptions, onStateSelect }) => {
  const [selectedState, setSelectedState] = useState('');
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [lastKey, setLastKey] = useState('');
  const [lastKeyTime, setLastKeyTime] = useState(0);
  const listRef = useRef(null);

  useEffect(() => {
    if (listRef.current) {
      listRef.current.focus();
    }
  }, []);

  const handleKeyDown = (e) => {
    const currentTime = new Date().getTime();
    const key = e.key.toLowerCase();

    if (e.key.length === 1 && e.key.match(/[a-z0-9]/i)) {
      if (key !== lastKey || currentTime - lastKeyTime > 1000) {
        setCurrentIndex(-1);
      }

      setLastKey(key);
      setLastKeyTime(currentTime);

      const matchingOptions = radioOptions.filter(option =>
        option.label.toLowerCase().startsWith(key)
      );

      if (matchingOptions.length > 0) {
        const newIndex = (currentIndex + 1) % matchingOptions.length;
        setCurrentIndex(newIndex);
        const optionToSelect = matchingOptions[newIndex].label;
        setSelectedState(optionToSelect);
        const optionElement = listRef.current.querySelector(`[data-value="${optionToSelect}"]`);
        if (optionElement) {
          optionElement.scrollIntoView({ block: 'nearest' });
        }
      }
    } else if (e.key === 'Enter') {
      selectState();
    }
  };

  const handleClick = (label) => {
    setSelectedState(label);
    onStateSelect(label);
  };

  const selectState = () => {
    if (selectedState) {
      onStateSelect(selectedState);
    }
  };

  return (
    <div 
      style={{
        width: '470px',
        height: '88%',
        overflow: 'hidden'
      }}
    >
      <div
        ref={listRef}
        tabIndex="0"
        onKeyDown={handleKeyDown}
        style={{
          width: '100%',
          height: '100%',
          // overflowY: 'scroll',
          outline: 'none'
        }}
      >
        {radioOptions.map((option) => (
          <div 
            key={option.value}
            data-value={option.label}
            onClick={() => handleClick(option.label)}
            style={{
              padding: '5px',
              cursor: 'pointer',
              backgroundColor: option.label === selectedState ? '#e0e0e0' : 'transparent'
            }}
          >
            <label 
              style={{ display: 'flex', alignItems: 'center', width: '100%' }}
              onClick={() => handleClick(option.label)} // Ensure click triggers selection
            >
              <input
                type="radio"
                readOnly
                checked={option.label === selectedState} // Reflect selected state
                style={{ marginRight: "1rem" }}
              />
              <span>{option.label}</span>
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PopupData;
