import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaArrowRightLong } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip as ToolTip1 } from "react-tooltip";
import {
  setSelectedVehicle,
  incrementTabIndex,
  setActiveTab,
  resetBuyerSellData,
  resetCoBuyerSellData,
  setVehicleData,
} from "../../../../Redux/slice/sellSlice";
import "./vehicle-list.css";
import VehicleCard from "./VehicleCard";
import {
  resetRetailData,
  setRetailData,
} from "../../../../Redux/slice/taxes&FeesSlice";
import axios from "axios";
import { BiReset } from "react-icons/bi";
const apiUrl = process.env.REACT_APP_API_URL;

const VehicleList = ({
  vehicleData,
  isLoading,
  handleTabSelect,
  handleSelectedVehicle,
  handleModalOpenAdd
}) => {
  const previousListingId = useSelector(
    (state) => state.taxesAndFees.dealerRetail.listingId
  );

  const counter = useSelector((state) => state.active.counter);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${apiUrl}/listing/get_user_listing/${id}`
        );
        const data = await response.json();

        if (response.ok) {
          dispatch(setVehicleData(data));
          setInitialFilters(vehicleData);
        }
      } catch (error) {
        throw new Error("Failed to fetch data");
      }
    };

    fetchData();
  }, [counter]);

  const [selectedCard, setSelectedCard] = useState(null);
  const dispatch = useDispatch();
  const { id } = useSelector((state) => state.user);
  const handleSelected = (index, vehicle) => {
    setSelectedCard(index === selectedCard ? null : index);
    dispatch(setSelectedVehicle(vehicle));
    handleSelectedVehicle(vehicle);
    // dispatch(incrementTabIndex());
    // dispatch(setActiveTab("Sale Type"));
    dispatch(setRetailData({ listingId: vehicle.id }));
    // Compare with the previous listingId
    dispatch(resetCoBuyerSellData());
    dispatch(resetBuyerSellData());
    dispatch(
      setRetailData({
        salesTaxes: "6.25",
        mInventoryTax: "0.001488",
        titleApplicationFee: 13.0,
        terpTitlefee: 20,
        drivesOutTag: 5.0,
        windshieldTrackerSticker: 50.75,
        regFeeDps: 1,
        cntyRoadBridgeFee: 10,
        childSafetyFund: 1.5,
        InspectionFeeOBDNL: 25,
        StateInspectionFees: 8.25,
        processingHandlingFee: 4.75,
        documentationFee: 150.0,
        technologyFees: 0.0,
        buyerTagFees: 5,
        other1: 0.0,
        other2: 0.0,
        cardFees: 0,
        warranty: 0,
        gapInsurance: 0,
        mailDeliveryFees: 0,
        transportationFees: 0,
        totalFees: 0,
        totalFeesAndothers: 294.5,
        txtState: "",
        listingPrice: "",
        userListingPrice: 0,
        txtTitleType: null,
        ownedTrade: "",
        cashDifference: "",
        paymentPriceFees: "",
        date: "",
        saleTaxValue: "",
        mInventoryValue: "",
        balanceDue: "",
        totalDue: 0,
        // -----------------------------------
        lienHolderName: "",
        leanHolderId: "",
        lienHolderID: "",
        lienHolderAddress: "",
        lienHolderAddress2: "",
        serviceContractId: "",
        serviceContractName: "",
        serviceContractLicense: "",
        serviceContractAddress: "",
        serviceProviderActivationStatus: "",
        serviceProviderType: "",
        providerPhoneNumber: "",
        providerCounty: "",
        providerListingExpiry: "",
        priceFeesPaymentMethod: "",
        //Gap Insurance Provider Data
        serviceProviderMonths: "",
        serviceContractMiles: "",
        gapMonths: "",
        gapMiles: "",
        // -------------
        gapInsuranceId: "",
        gapName: "",
        gapLicense: "",
        gapAddress: "",
        gapActivationStatus: "",
        gapType: "",
        gapPhoneNumber: "",
        gapCounty: "",
        gapListingExpiry: "",
        // Sell Form ---------------------------
        sellForm: "",
        // tax maker forms-----------------------
        taxMakerForms: "",
        // trade in key--------
        dealerId: 0,
        vin: "",
        year_make_model: "",
        // make year modal only check purpose for backend------
        make: "",
        year: "",
        model: "",
        bodyClass: "",
        wheelbase: "",
        driveType: "",
        doorCount: "",
        // -----------------------
        trim: "",
        engine: "",
        transmission: "",
        vehicleType: "",
        seat_count: "",
        city_mpg: "",
        highway_mpg: "",
        inspection: "",
        exteriorColor: "",
        interiorColor: "",
        interiorType: "",
        mileage_in: "",
        purchase_price: "",
        //tradeInValue: formData.tradeInValue,
        listing_price: "",
        mileageIn: "",
        // trade in key end--------
        // for images of vehicle card--------
        imageUrl: "",
      })
    );
    handleModalOpenAdd();
  };
  //  for filter --------on basic of make modal--------------------------------
  const [initialFilters, setInitialFilters] = useState([]);
  useEffect(() => {
    // setInitialFilters(vehicleData);
  }, [vehicleData]);
  //Fetching the filters
  //   First fetch the filters
  const [filters, setFilters] = useState({
    make: [],
    models: [],
    year: [],
    bodyClass: [],
    engineType: [],
    fuelType: [],
    vehicleType: [],
    owner: [],
    state: [],
    interiorColor: [],
    exteriorColor: [],
    interiorType: [],
    doorCounts: [],
    driveTrain: [],
    odometer: [],
    price: [],
    exterior_damage: [],
    photos: [],
    seller: [id && id],
    zip_code: [],
  });
  // console.log("Filter selected ", filters);

  // Selected filter state
  const [selectedFilter, setSelectedFilter] = useState({
    make: "Make",
    models: "Model",
    year: "Year",
    bodyClass: "Body Type",
  });
  // Handle Year Filter Dropdown Click
  const handleFilterDDClick = (PropToUpdate, ValueForUpdate) => {
    // If ValueForUpdate is null, reset the selected filter
    if (!ValueForUpdate) {
      setSelectedFilter((prevData) => ({
        ...prevData,
        [PropToUpdate]:
          PropToUpdate === "make"
            ? "Make"
            : PropToUpdate === "models"
              ? "Model"
              : PropToUpdate === "year"
                ? "Year"
                : "Body Type",
      }));
      setFilters((prevData) => ({
        ...prevData,
        [PropToUpdate]: [],
      }));
      return;
    }

    // Update selected filter
    setSelectedFilter((prevData) => ({
      ...prevData,
      [PropToUpdate]: ValueForUpdate,
    }));

    // Set filters: if year, send as array of strings
    const valueToUpdate =
      PropToUpdate === "year"
        ? [String(ValueForUpdate), String(ValueForUpdate)]
        : [ValueForUpdate];

    setFilters((prevData) => ({
      ...prevData,
      [PropToUpdate]: valueToUpdate,
    }));
  };

  const handleResetFilter = () => {
    setFilters({
      make: [],
      models: [],
      year: [],
      bodyClass: [],
      engineType: [],
      fuelType: [],
      vehicleType: [],
      owner: [],
      state: [],
      interiorColor: [],
      exteriorColor: [],
      interiorType: [],
      doorCounts: [],
      driveTrain: [],
      odometer: [],
      price: [],
      exterior_damage: [],
      photos: [],
      seller: [id && id],
      zip_code: [],
    });

    // Reset selected filters to initial state
    setSelectedFilter({
      make: "Make",
      models: "Model",
      year: "Year",
      bodyClass: "Body Class",
    });
    // Use this function wherever you need to reset the filters
  };
  //Here after changing the filter fetch the products again
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/listing/filter_listing`,
          filters
        );
        console.log("response filters from the API", response.data);
        dispatch(setVehicleData(response.data));
        // setSortedData(Array.isArray(response.data) ? response.data : []);
      } catch (error) {
        console.log("Server side while fetching data during getting filters");
      }
    };
    fetchProducts();
  }, [filters]);

  // ---------------------------------------------------------------------------------------
  // ----------for search bar filter -----------------------------------------------------------
  const [inputValue, setInputValue] = useState("");
  const [selectedSort, setSelectedSort] = useState(null);

  const handleSelect = async (selectedStatus) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/listing/get_user_listing/${id}?searchTerm=${selectedStatus}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      // Sort the data immediately after fetching
      // const sortedData = [...data].sort((a, b) => b.id - a.id); // Sort in descending order by id
      dispatch(setVehicleData(data)); // Dispatch the sorted data
      setSelectedSort(selectedStatus);
      console.log(data);
      // Handle the response data as needed
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSelect(inputValue); // Call the function with the input value
      setInputValue(""); // Clear the input field if desired
    }
  };
  const handleButtonClick = () => {
    handleSelect(inputValue); // Call the function with the input value
    setInputValue(""); // Clear the input field if desired
  };
  // -------------------------------------------------------------------------------------------
  return (
    <Container>
      {vehicleData && vehicleData.length > 0 ? (
        <Row>
          <Col sm={11} className="mx-auto px-0 py-1">
            <div className="input-group mb-4" id="act-search">
              <Button
                className="btn border-0 dropdown-toggle search-all"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                All
              </Button>
              <ul className="dropdown-menu">
                <li onClick={() => handleSelect("Price10k")}>
                  <Link className="dropdown-item" to="#">
                    Price &lt; 10k
                  </Link>
                </li>
                <li onClick={() => handleSelect("Mileage50k")}>
                  <Link className="dropdown-item" to="#">
                    Miles &lt; 50k
                  </Link>
                </li>
                <li onClick={() => handleSelect("Mileage100k")}>
                  <Link className="dropdown-item" to="#">
                    Miles &lt; 100k
                  </Link>
                </li>
              </ul>
              <input
                type="text"
                className="form-control"
                placeholder="Search Inventory"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)} // Update state on input change
                onKeyPress={handleKeyPress} // Call handleKeyPress on key press
              />
              <Button
                className="btn border-0 search-btn"
                type="button"
                id="button-addon2"
                style={{ backgroundColor: "#e8a262" }}
                onClick={handleButtonClick}
              >
                <i className="fas fa-search"></i>
              </Button>
              <Button
                className="btn border-0 dropdown-toggle btn-eq-wd"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                id="dropdownYear" // Added ID for accessibility
                style={{ backgroundColor: "#e8a262", margin: "0 0.3rem" }}
              >
                {selectedFilter.year}
              </Button>
              <ul
                className="dropdown-menu"
                aria-labelledby="dropdownYear"
                style={{ maxHeight: "300px", overflowY: "scroll" }}
              >
                <li onClick={() => handleFilterDDClick("year", null)}>
                  <Link className={`dropdown-item ${selectedFilter.year === ''
                    ? "selected-item"
                    : ""
                    }`} to="#">
                    Select None
                  </Link>
                </li>
                {initialFilters &&
                  [...new Set(initialFilters.map((car) => car.year))]
                    .sort((a, b) => a - b)
                    .map((year, index) => (
                      <li
                        key={index}
                        onClick={() => handleFilterDDClick("year", year)}
                      >
                        <Link className={`dropdown-item ${selectedFilter.year === year
                          ? "selected-item"
                          : ""
                          }`} to="#">
                          {year}
                        </Link>
                      </li>
                    ))}
              </ul>

              <Button
                className="btn border-0 dropdown-toggle btn-eq-wd"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                id="dropdownMake" // Added ID for accessibility
                style={{ backgroundColor: "#e8a262" }}
              >
                {selectedFilter.make}
              </Button>
              <ul
                className="dropdown-menu"
                aria-labelledby="dropdownMake"
                style={{ maxHeight: "300px", overflowY: "scroll" }}
              >
                <li onClick={() => handleFilterDDClick("make", null)}>
                  <Link className={`dropdown-item ${selectedFilter.make === ''
                    ? "selected-item"
                    : ""
                    }`} to="#">
                    Select None
                  </Link>
                </li>
                {initialFilters &&
                  [...new Set(initialFilters.map((car) => car.make))]
                    .sort()
                    .map((make, index) => (
                      <li
                        key={index}
                        onClick={() => handleFilterDDClick("make", make)}
                      >
                        <Link className={`dropdown-item ${selectedFilter.make === make
                          ? "selected-item"
                          : ""
                          }`} to="#">
                          {make}
                        </Link>
                      </li>
                    ))}
              </ul>

              <Button
                className="btn border-0 dropdown-toggle btn-eq-wd"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                id="dropdownModel" // Added ID for accessibility
                style={{
                  backgroundColor: "#e8a262",
                  margin: "0 0.3rem",
                }}
              >
                {selectedFilter.models}
              </Button>
              <ul
                className="dropdown-menu"
                aria-labelledby="dropdownModel"
                style={{ maxHeight: "300px", overflowY: "scroll" }}
              >
                <li onClick={() => handleFilterDDClick("models", null)}>
                  <Link className={`dropdown-item ${selectedFilter.models === ''
                    ? "selected-item"
                    : ""
                    }`} to="#">
                    Select None
                  </Link>
                </li>
                {initialFilters &&
                  [...new Set(initialFilters.map((car) => car.model))]
                    .sort()
                    .map((model, index) => (
                      <li
                        key={index}
                        onClick={() => handleFilterDDClick("models", model)}
                      >
                        <Link className={`dropdown-item ${selectedFilter.models === model
                          ? "selected-item"
                          : ""
                          }`} to="#">
                          {model}
                        </Link>
                      </li>
                    ))}
              </ul>

              <Button
                className="btn border-0 dropdown-toggle btn-eq-wd"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                id="dropdownModel" // Added ID for accessibility
                style={{
                  backgroundColor: "#e8a262",
                  margin: "0 0.3rem",
                }}
              >
                {selectedFilter.bodyClass}
              </Button>
              <ul
                className="dropdown-menu"
                aria-labelledby="dropdownModel"
                style={{ maxHeight: "300px", overflowY: "scroll" }}
              >
                <li onClick={() => handleFilterDDClick("bodyClass", null)}>
                  <Link className={`dropdown-item ${selectedFilter.bodyClass === ''
                    ? "selected-item"
                    : ""
                    }`} to="#">
                    Select None
                  </Link>
                </li>
                {initialFilters &&
                  [...new Set(initialFilters.map((car) => car.bodyClass))]
                    .sort()
                    .map((bodyClass, index) => (
                      <li
                        key={index}
                        onClick={() =>
                          handleFilterDDClick("bodyClass", bodyClass)
                        }
                      >
                        <Link className={`dropdown-item ${selectedFilter.bodyClass === bodyClass
                          ? "selected-item"
                          : ""
                          }`} to="#">
                          {bodyClass}
                        </Link>
                      </li>
                    ))}
              </ul>

              <Button
                className="btn border-0 dropdown-toggle btn-eq-wd"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                id="dropdownModel" // Added ID for accessibility
                style={{
                  backgroundColor: "#e8a262",
                  margin: "0 0.3rem",
                }}
              >
                Sort BY
              </Button>
              <ul className="dropdown-menu">
                <li onClick={() => handleSelect("Desc")}>
                  <Link className={`dropdown-item ${selectedSort === "Desc" ? 'selected-item' : ''}`} to="#">
                    Newest
                  </Link>
                </li>
                <li onClick={() => handleSelect("Asc")}>
                  <Link className={`dropdown-item ${selectedSort === "Asc" ? 'selected-item' : ''}`} to="#">
                    Oldest
                  </Link>
                </li>
              </ul>
              <Button
                className="btn border-0 btn-eq-wd"
                type="button"
                id="button-addon1"
                style={{ backgroundColor: "#e8a262" }}
                onClick={handleResetFilter}
                data-tooltip-id="reset1"
                data-tooltip-content="Reset"
                data-event="click"
              >
                <BiReset className="icons-r-flt" />
              </Button>
              <ToolTip1 id="reset1" place="top" />
            </div>
          </Col>
        </Row>
      ) : null}

      {isLoading ? (
        <h2>Loading...</h2>
      ) : vehicleData && vehicleData.length > 0 ? (
        <Row>
          <Col sm={11} className="mx-auto px-0">
            <Container className="d-flex justify-content-center px-0">
              <Row className="card-parent w-100">
                {vehicleData.map((vehicle, index) => (
                  <div style={{ position: "relative", paddingLeft: "4rem" }}>
                    <VehicleCard
                      key={index}
                      index={vehicle.id}
                      selectedCard={selectedCard}
                      handleSelected={() => handleSelected(index, vehicle)}
                      data={vehicle}
                    />
                    <span
                      style={{
                        border: "1px solid grey",
                        position: "absolute",
                        top: "35%",
                        left: "2rem",
                        transform: "translateX(-100%)",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "white",
                        paddingLeft: "15px",
                        paddingTop: "4px",
                        borderRadius: "50%",
                        height: "40px",
                        width: "40px",
                        fontSize: "large",
                        fontWeight: "500",
                        boxShadow: "2px 2px 2px grey",
                        // padding : "0.5rem"
                      }}
                    >
                      {index + 1}
                    </span>
                  </div>
                ))}
              </Row>
            </Container>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col sm={12} className="outer-box px-0">
            <div className="no-list">
              <h2>No active Inventory</h2>
              <h2>
                <Link to="/dealer/inventory/add">
                  Click here to add inventory
                </Link>
              </h2>
            </div>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default VehicleList;
