import React, { useEffect, useRef, useState } from "react";
import {
  Link,
  useNavigate,
  useParams,
  useLocation,
  useOutletContext,
} from "react-router-dom";
import {
  Col,
  Container,
  Nav,
  Row,
  Button,
  Tab,
  Form,
  Dropdown,
} from "react-bootstrap";
import Autocomplete from "react-google-autocomplete";
import axios from "axios";
import Image from "../../imgData";
import "./vehicle-list.css";
import "./DealerSell.css";
import "./VehicleSaleDetails.css";
import "./forms.css";
import VehicleList from "./VehicleList";
import Payment from "./Payment";
import Forms from "./Forms";
import TradeIn from "./TradeIn";
import { fetchGeoMapData } from "./geoAPI";
import PricesFees from "./PricesFees";
import { useDispatch, useSelector } from "react-redux";
import { setRetailData } from "../../../Redux/slice/taxes&FeesSlice";
import {
  setVehicleData,
  setSelectedVehicle,
  setLoading,
  setError,
  setActiveTab,
  setSellData,
  setActiveTabIndex,
  incrementTabIndex,
  decrementTabIndex,
  saveSellData,
} from "../../../Redux/slice/sellSlice";
import BuyerInfo from "./BuyerInfo";
import SaleType from "./SaleType";

const apiUrl = process.env.REACT_APP_API_URL;

const DealerSell = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { data: item } = location.state || {};

  const activeTab = useSelector((state) => state.sell.activeTab);
  const vehicleData = useSelector((state) => state.sell.vehicleData);
  const isLoading = useSelector((state) => state.sell.isLoading);
  const selectedVehicle = useSelector((state) => state.sell.selectedVehicle);
  const activeTabIndex = useSelector((state) => state.sell.activeTabIndex);

  const { id } = useSelector((state) => state.user);
  const fetchData = async () => {
    try {
      dispatch(setLoading(true));
      const response = await fetch(`${apiUrl}/listing/get_user_listing/${id}`);
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await response.json();

      // Sort the data immediately after fetching
      // const sortedData = [...data].sort((a, b) => b.id - a.id); // Sort in descending order by id
      dispatch(setVehicleData(data)); // Dispatch the sorted data

      // dispatch(setVehicleData(data));
    } catch (error) {
      dispatch(setError(error.message));
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    if (location.state) {
      // dispatch(setActiveTab("Sale Type"));
      // dispatch(setActiveTabIndex(1));
    }
    console.log("Received data:", location.state);
    console.log("item", item);
  }, [location.state]);

  // useEffect(() => {
  //   console.log("Received data:", location.state);
  //   dispatch(setSelectedVehicle(item));
  // }, [location.state]);

  const handleSelectedVehicle = (vehicle) => {
    dispatch(setSelectedVehicle(vehicle));
    handleTabSelect("Sale Type");
  };

  const handleTabSelect = (tab) => {
    dispatch(setActiveTab(tab));
  };

  const tabs = [
    "Vehicle List",
    "Sale Type",
    "Buyer",
    "Prices and Fees",
    "Forms",
  ];

  useEffect(() => {
    if (activeTab === "Vehicle List") {
      console.log("Calling all the Vehicle present in Vehicle List");
      fetchData();
    }
  }, [activeTab]);

  const handleNext = () => {
    dispatch(incrementTabIndex());
  };

  const handlePrevious = () => {
    dispatch(decrementTabIndex());
  };

  const isExpanded = useOutletContext();
  console.log("isExpanded => ", isExpanded);
  // const fetchPriceLabels = async () => {
  //   try {
  //     const result = await axios.get(
  //       `${process.env.REACT_APP_API_URL}/sell/get_labels/${id}`
  //     );
  //     if (result.status === 200) {
  //       const data = result.data;
  //       console.log("All the labels => ", data);
  //       dispatch(
  //         setRetailData({
  //           documentFees: data.documentFees || "Documentation Fees",
  //           driveOutTags: data.driveOutTags || "Drive Out Tag",
  //           titleAppFees: data.titleAppFees || "Title App Fee",
  //           terpTitleFees: data.terpTitleFees || "TERP App Fee",
  //           buyerTag: data.buyerTag || "Buyer Tag",
  //           windShieldTracker: data.windShieldTracker || "Wind-Shield Sticker",
  //           regFeeDpsl: data.regFeeDpsl || "REG. FEE-DPS", //Special case
  //           cntyRoadBridge: data.cntyRoadBridge || "CNTY ROAD Bridge Addon Fee",
  //           childSafetyFee: data.childSafetyFee || "Child Safety Fee",
  //           inspectionFee: data.inspectionFee || "Inspection Fee",
  //           stateInspectionFee:
  //             data.stateInspectionFee || "State Inspection Fee",
  //           procHandlingFee: data.procHandlingFee || "Proc. & Handling Fee",
  //           cardFeesl: data.cardFeesl || "Card Fee",
  //           serviceContractl: data.serviceContractl || "Service Contract",
  //           gapInsurancel: data.gapInsurancel || "Gap Insurance",
  //           mailDeliveryFeesl: data.mailDeliveryFeesl || "Mail Delivery Fees",
  //           transportationFeesl:
  //             data.transportationFeesl || "Transportation Fees",
  //         })
  //       );
  //     }
  //   } catch (error) {
  //     console.log("error ", error);
  //   }
  // };
  // useEffect(() => {
  //   fetchPriceLabels();
  // }, [dispatch]);
  console.log("selectedVehicle**********123344", selectedVehicle);

  return (
    <div
      //  className={isExpanded ? "wrapper_expanded" : "wrapper_collapsed"}
      style={{ marginTop: "-0.45rem" }}
    >
      <Container fluid className="main-container px-4">
        <Row style={{ display: "flex", justifyContent: "center" }}>
          <Tab.Container activeKey={activeTab}>
            <span id="btn-buyer-payment" className=" ">
              <Nav fill variant="tabs" className="justify-content-center">
                {tabs.map((tab, index) => (
                  <Nav.Item key={index}>
                    <Nav.Link
                      eventKey={tab}
                      className={activeTab === tab ? "active px-0" : "px-0"}
                      onClick={() => handleTabSelect(tab)}
                    >
                      {tab}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </span>

            <Row className="" style={{ padding: "0" }}>
              <div className="input-contant-container">
                <Tab.Content
                  style={{ padding: "0px", height: "auto", minWidth: "100%" }}
                >
                  <Tab.Pane eventKey="Vehicle List" className="border-0">
                    <VehicleList
                      vehicleData={vehicleData}
                      isLoading={isLoading}
                      handleTabSelect={handleTabSelect}
                      handleSelectedVehicle={handleSelectedVehicle}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="Sale Type">
                    <SaleType handleNext={handleNext} />
                  </Tab.Pane>

                  <Tab.Pane eventKey="Buyer">
                    <BuyerInfo selectedVehicle={selectedVehicle} data={item} />
                  </Tab.Pane>

                  <Tab.Pane eventKey="Prices and Fees">
                    <div style={{ width: "100% important" }}>
                      <PricesFees selectedVehicle={selectedVehicle} />
                    </div>
                  </Tab.Pane>

                  <Tab.Pane eventKey="Forms">
                    <Forms />
                  </Tab.Pane>

                  <Tab.Pane eventKey="Payment">
                    <Payment />
                  </Tab.Pane>
                </Tab.Content>
              </div>
            </Row>
          </Tab.Container>
        </Row>

        {/* <Row className="mt-3">
          <Col className="d-flex justify-content-end">
            <button
              type="button"
              className="btn btn-secondary p-0 border-0 bg-transparent text-dark"
              onClick={handlePrevious}
              style={{ width: "10%", height: "30%" }}
              disabled={activeTabIndex === 0}
            >
              <img src={Image.back_btn} width="90%" />
            </button>
            <button
              type="button"
              className="btn btn-secondary p-0 border-0 bg-transparent text-dark"
              onClick={handleNext}
              style={{ width: "10%", height: "30%" }}
              disabled={activeTabIndex === tabs.length - 1}
            >
              <img src={Image.next_btn} width="90%" />
            </button>
          </Col>
        </Row> */}
      </Container>
    </div>
  );
};

export default DealerSell;
