import React, { useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import { IoMenu } from "react-icons/io5";

import {
  FaRegArrowAltCircleLeft,
  FaRegArrowAltCircleRight,
} from "react-icons/fa";

import { useDispatch, useSelector } from "react-redux";
import Image from "../imgData";
import "./Sidebar.css";

import { sideBarArray } from "./SideBarData";
import {
  logoutUser,
  resetAllSidebarCounts,
  updateSidebarClickCount,
} from "../../Redux/slice/userSlice";
import axios from "axios";
import { resetSellData } from "../../Redux/slice/sellSlice";
// import { Tooltip } from "react-tooltip";

const SideBar = ({ onButtonClick,  isOpen, toggleSidebar, isPermanentlyExpanded,setIsPermanentlyExpanded,setIsOpen }) => {
  const [profilePicture, setProfilePicture] = useState(null);
  // const [isOpen, setIsOpen] = useState(false);
  // const [isPermanentlyExpanded, setIsPermanentlyExpanded] = useState(false); // Added state for permanent expansion

  const [sidebarRearrangedState, setSidebarRearrangedState] =
    useState(sideBarArray);
  console.log("Rearranged sidebar", sidebarRearrangedState);
  const [id_and_count, set_id_and_count] = useState([]);
  console.log(id_and_count);
  const [selectedItem, setSelectedItem] = useState(null);

  const { sideBarOrder } = useSelector((state) => state.user);
  const { id } = useSelector((state) => state.user);
  const userData = useSelector((state) => state.user);
  console.log("sideBarOrder in sidebar component ", sideBarOrder);

  const dispatch = useDispatch();
  // For side open close --------------------------
  useEffect(() => {
    function handleResize() {
      setIsOpen(window.innerWidth > 600);
    }
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // const toggleSidebar = () => {
  //   console.log("isOpen", isOpen);
  //   setIsOpen(!isOpen);
  //   setIsPermanentlyExpanded(!isPermanentlyExpanded)
  // };



  const x = useSelector((state) => state.data.x);
  const profilePictureFromRedux = useSelector((state) => state.profileimg.y);

  useEffect(() => {
    const savedProfilePicture = localStorage.getItem("profilePicture");
    if (savedProfilePicture) {
      setProfilePicture(savedProfilePicture);
    } else {
      setProfilePicture(profilePictureFromRedux);
      localStorage.setItem("profilePicture", profilePictureFromRedux);
    }
  }, [profilePictureFromRedux]);

  const handleStoreItemsClick = async (itemId) => {
    console.log(itemId);

    const updatedItems = sidebarRearrangedState.map((item) =>
      item.id === itemId ? { ...item, count: item.count + 1 } : item
    );
    updatedItems.sort((a, b) => b.count - a.count);
    console.log("updatedItems", updatedItems);
    const updatedCountArray = updatedItems.map(({ id, count }) => ({
      id,
      count,
    }));
    console.log("Updated count Array", updatedCountArray);
    set_id_and_count(updatedCountArray);
    // setSidebarRearrangedState(updatedItems);
    dispatch(updateSidebarClickCount({ id: itemId }));

    // Change background colour of selected
    setSelectedItem(itemId);
  };
  useEffect(() => {
    const localStorageArray = JSON.parse(
      localStorage.getItem("user_sidebar_activity")
    );
    if (localStorageArray) {
      localStorageArray.sort((a, b) => b.count - a.count);
      console.log("localStorageArray =>", localStorageArray);
      setSidebarRearrangedState(localStorageArray);
    }
  }, []);

  const apiData =
    sideBarOrder &&
    sideBarOrder?.map((item) => ({
      tabId: item.id,
      tabCount: item.count,
      userId: id,
    }));

  const handleLogout = async () => {
    console.log("User is logged out");
    dispatch(logoutUser());
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/tab/save`,
        apiData
      );
      if (res.status === 200) {
        dispatch(resetAllSidebarCounts());
        localStorage.removeItem("user_sidebar_activity");
        dispatch(resetSellData());
      }
      console.log(
        "response from the API while sending user activity to the backend related to sidebar",
        res
      );
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    localStorage.setItem("user_sidebar_permanent_state", JSON.stringify(isPermanentlyExpanded))
  }, [isPermanentlyExpanded]);

  return (
    <div className="side_bar_wrapper">
      {/* -------------------------------id="sidebar-wrapper"---------- Sidemenu Start-----------------------------*/}
      {/* <div className={"toggle_button_container-text"}>
       {navbarText}
      </div> */}
      <div
        className={`d-flex ${isOpen ? "toggled" : "no_toggled"} `}
        // className={`d-flex ${isPermanentlyExpanded ? "toggled" : isOpen ? "toggled" : "no_toggled"} `}

        id="wrapper"
      >
        <nav
          className={`sidebar parentBorder  `}
          id="sidebar-wrapper"
         
        >
          <ul className="nav col-lg-md-12" style={{marginTop:"0px"}}>
            {/* <li className="nav-item nav-profile dropdown d-block m-auto">
              <div className={`toggle_button_container ${isOpen ? "open-width" : "closed-width"}`}>
                <button onClick={toggleSidebar} className={`menu-button-user ${isOpen ? "center_pos" : "btn-sidebar"}`}>
                  <IoMenu />
                </button>
              </div>
            </li> */}
            <div
              className={`col-md-12 nav-item 
               
              } 
               bggcor`}
            >
              {/* <hr style={{color:'red'}}/> style={{backgroundColor:'#f7e6d2',paddingTop:'15px'}} */}
              <hr style={{ color: "red", margin: "0px" }} />
              <div
                onClick={() => handleStoreItemsClick("dashboard")}
                style={{
                  backgroundColor:
                    selectedItem === "dashboard" ? "#ebd6c3" : "white",
                }}
              >
                <li
                  className="nav-item hh "
                  onClick={() => onButtonClick("Dashboard")}
                >
                  <Link
                    className="nav-link fw-bold "
                    to="/myprofile/dashboard"
                    style={{ paddingTop: "0px" }}
                  >
                    <span
                      className={`me-1 sps image_container `}
                    // style={{ marginTop: "9px" }}
                    >
                      <img
                        src={Image.sidebarHome}
                        style={{
                          height: "45px",
                          width: "45px",
                          paddingLeft: "1px",
                          marginRight: "2rem",
                          paddingBottom: "11px"
                        }}
                        title={!isOpen ? "Dashboard" : "img-logo-dash"}
                      />
                    </span>
                    {isOpen ? (
                      <span
                        className="menu-title sps ffsdbr"
                        style={{ marginTop: "14px", marginLeft: "-19px" }}
                      >
                        Dashboard
                      </span>
                    ) : null}
                  </Link>
                </li>
                <hr style={{ color: "red", margin: "0px" }} />
              </div>
              {sidebarRearrangedState.map((item) => (
                <div
                  key={item.id}
                  onClick={
                    item.id === 16
                      ? handleLogout
                      : () => handleStoreItemsClick(item.id)
                  }
                  style={{
                    backgroundColor:
                      selectedItem === item.id ? "#ebd6c3" : "white",
                  }}
                  onMouseEnter={() => !isPermanentlyExpanded && setIsOpen(true)}
                  onMouseLeave={() => !isPermanentlyExpanded && setIsOpen(false)}
                >
                  <li
                    className="nav-item hh "
                    onClick={() => onButtonClick(item.name)}
                  >
                    <Link
                      className="nav-link fw-bold  d-flex align-items-center"
                      to={item.route}
                      style={{ paddingTop: "0px" }}
                    >
                      <span
                        className={` sps image_container `}
                      // style={{ marginTop: "9px" }}
                      >
                        <img
                          src={item.image}
                          style={{
                            height: "45px",
                            width: "45px",
                            paddingLeft: "5px",
                            marginRight: "1rem",
                            objectFit: "contain",
                          }}
                          title={!isOpen ? item.name : ""}
                        />
                      </span>
                      {isOpen ? (
                        <span
                          className="menu-title sps ffsdbr"
                          style={{ marginTop: "14px" }}
                        >
                          {item.name}
                        </span>
                      ) : null}
                    </Link>
                  </li>
                  <hr style={{ color: "red", margin: "0px" }} />
                </div>
              ))}
            </div>
          </ul>
        </nav>

        {/*---------------------------------Sidemenu End----------------------------------------*/}

        <div>{/* <CustomerDetails y= {x}/> */}</div>

        <Outlet />
      </div>
    </div>
  );
};

export default SideBar;
