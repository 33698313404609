import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  Button,
  Col,
  Container,
  Row,
  Table,
  Modal,
  Form,
  FormGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { BiReset, BiSolidPhoneCall } from "react-icons/bi";
import { MdEmail } from "react-icons/md";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import styles from "./tax.module.css";
import { BsStars } from "react-icons/bs";
import { setRetailData } from "../../../../Redux/slice/taxes&FeesSlice";
import axios from "axios";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";

const apiUrl = process.env.REACT_APP_API_URL;

const DealerInventoryTaxMaker = () => {
  const { id } = useSelector((state) => state.user);

  const [tableData, setTableData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const [showSettings, setShowSettings] = useState(false);
  const [showGenerate, setShowGenerate] = useState(false);
  const [showPdf, setShowPdf] = useState(false);
  const [tabs, setTabs] = useState(1);
  const [openDropdown, setOpenDropdown] = useState(null);
  const dropdownRef = useRef();
  const Navigate = useNavigate();
  const location = useLocation();
  // Extract data and originalData from location state
  const originalData = location.state?.originalData || {};
  const updatedData = location.state?.updatedData || {};
  const [counter, setCounter] = useState(0);
  const [initialFilters, setInitialFilters] = useState([]);
  useEffect(() => {
    const data = location.state || {};
    console.log("filterData234:", data);
    if (Object.keys(data).length > 0) {
      setTableData(data);
      // setSortedData(data);
      // setIsLoading(false);
    } else {
      const fetchData = async () => {
        try {
          const response = await fetch(
            `${apiUrl}/listing/get_sold_listing/${id}`
          );
          if (!response.ok) {
            throw new Error("Failed to fetch data");
          }
          const data = await response.json();
          setTableData(data);
          setInitialFilters(data.map(item => item.addListing2));
          // setSortedData(data);
        } catch (error) {
          // setError(error);
        } finally {
          // setIsLoading(false);
        }
      };

      fetchData();
    }
  }, [location.state, apiUrl, id, counter]);
  // for fetch data----------------------------
  // useEffect(() => {
  //   fetchData();
  // }, [counter]);

  const handleIncrement = () => {
    setCounter(prevCounter => prevCounter + 1);
    setSelectedFilter({
      make: "Make",
      models: "Model",
      year: "Year",
      bodyClass: "Body Type",
    });
  };
  // --------------------------------------
  // List of months
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const isFieldUpdated = (data, fieldName) => {
    if (data.vin === updatedData.vin) {
      return originalData[fieldName] !== updatedData[fieldName];
    }
  };
  // --------------------------------------for generate forms section ----------------------------------------
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    dealerId: id,
    taxMakerIds: [],
  });
  const [taxMakerForms, setTaxMakerForms] = useState([]);
  console.log("sellForm", taxMakerForms);
  useEffect(() => {
    dispatch(
      setRetailData({
        taxMakerForms: taxMakerForms,
      })
    );
  }, [taxMakerForms]);
  console.log("formData97", formData);
  const GenerateTaxMakerForms = async (e) => {
    e.preventDefault();
    const fnd = new FormData();
    fnd.append("tax", JSON.stringify(formData));

    try {
      const response = await axios.post(`${apiUrl}/tax/forms`, fnd, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.status === 200) {
        console.log("Data saved successfully:", response.data);
        setTaxMakerForms(response.data);

        // Show the modal
        setShowPdf(true);
      }
    } catch (error) {
      console.error("Error saving form data:", error);
    }
  };
  // ----------------------------------------------------------------------------------------------------------------------------
  // for form to save data to craete sell form---------------------------

  // ------------------------------------------------------------------------------------------------------------------
  const handlePublish = (id) => {
    // Handle publish event
    console.log(`Publishing item with id: ${id}`);
  };

  const handleLinkClick = (vin, data) => {
    // Navigate to AddList component programmatically with state
    Navigate("/dealer/inventory/add", { state: { vin, data, active: true } });
    // Navigate('/myprofile/addlist', { state: vin, });
  };

  const handleRowClick = (index, MakerIds) => {
    // Ensure MakerIds is an array
    const ids = Array.isArray(MakerIds) ? MakerIds : [MakerIds];

    if (selectedRows.includes(index)) {
      // Deselect the row
      setSelectedRows((prevSelectedRows) =>
        prevSelectedRows.filter((selectedIndex) => selectedIndex !== index)
      );
      // Remove MakerIds from taxMakerIds
      setFormData((prevFormData) => ({
        ...prevFormData,
        taxMakerIds: prevFormData.taxMakerIds.filter((id) => !ids.includes(id)),
      }));
    } else {
      // Select the row
      setSelectedRows((prevSelectedRows) => [...prevSelectedRows, index]);
      // Add MakerIds to taxMakerIds
      setFormData((prevFormData) => ({
        ...prevFormData,
        taxMakerIds: [...new Set([...prevFormData.taxMakerIds, ...ids])],
      }));
    }

    console.log("line114 taxMakerIds", ids);
  };

  const toggleDropdown = (dropdownName) => {
    setOpenDropdown((prevDropdown) => {
      // If the clicked dropdown is already open, close it
      if (prevDropdown === dropdownName) {
        return null;
      } else {
        // Open the clicked dropdown and close any other open dropdown
        return dropdownName;
      }
    });
  };

  const handleSort = (order) => {
    const sorted = [...tableData].sort((a, b) => {
      const dateA = new Date(a.created_at);
      const dateB = new Date(b.created_at);
      return order === "oldest" ? dateA - dateB : dateB - dateA;
    });
    // setSortedData(sorted);
  };

  const itemsPerPage = 20; // Change this value according to your preference
  const totalItems = tableData.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  // Calculate current page items
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = tableData.slice(indexOfFirstItem, indexOfLastItem);
  console.log("tableData", tableData);
  // Add empty rows if necessary
  const emptyRowsCount = Math.max(itemsPerPage - currentItems.length, 0);
  const emptyRows = Array.from({ length: emptyRowsCount }).map((_, index) => (
    <tr key={`empty-${index}`}>
      {[...Array(11)].map((_, i) => (
        <td key={i}>&nbsp;</td>
      ))}
    </tr>
  ));

  const renderTableRow = (item, index) => {
    if (!item.addListing2.year_Make_Model) {
      return null;
    }
    const year_make_model = item.addListing2.year_Make_Model;
    const [year, make, model, series, bodyClass] = year_make_model.split(" ");

    return (
      <tr
        key={index}
        className={selectedRows.includes(index) ? "selected-rows" : ""}
        onClick={() => item && handleRowClick(index, item.id)}
      >
        {item ? (
          <>
            <td>{index + 1}</td>
            <td>{item?.sellPriceFees?.priceFeeDate}</td>
            <td>{item?.saleType}</td>
            <td>{item?.accountNumber}</td>
            <td>{item?.firstName}</td>
            <td>{item?.addListing2?.year}</td>
            <td>{item?.addListing2?.make}</td>
            <td>{item?.addListing2?.model}</td>

            <td
              className="vin-column fw-bold"
            // onClick={() => handleLinkClick(item.addListing2.vin, item)}
            >
              {item?.addListing2?.vin}
            </td>

            {/* <OverlayTrigger
              placement="bottom-start"
              overlay={
                <Tooltip id={`tooltip-${index}`} className="custom-tooltip">
                  Purchased Date: <br />
                  {item.purchasedDate}
                </Tooltip>
              }
            >
              <td style={{ textShadow: isFieldUpdated(item, "inspection") ? '#ff8c21 1px 0 10px' : 'black' }}>5</td>
            </OverlayTrigger> */}

            <td
              style={{
                textShadow: isFieldUpdated(item, "listing_price")
                  ? "#ff8c21 1px 0 10px"
                  : "black",
              }}
            >
              $ {new Intl.NumberFormat().format(item?.sellPriceFees?.listingPrice)}
            </td>
            <td>{new Intl.NumberFormat().format(item?.sellPriceFees?.mInventoryValue)}</td>
          </>
        ) : (
          Array.from({ length: 11 }).map((_, i) => <td key={i}>&nbsp;</td>)
        )}
      </tr>
    );
  };

  // Calculate total mileage
  const totalMileage = tableData.reduce(
    (total, item) => total + item.mileage_in,
    0
  );

  // Calculate total mileage
  const listingPrice = tableData.reduce(
    (total, price) => total + price.listing_price,
    0
  );

  // handlers for modals
  const generateDatesForMonth = (year, month) => {
    const dates = [];
    const daysInMonth = new Date(year, month + 1, 0).getDate(); // Get number of days in the month

    for (let day = 1; day <= daysInMonth; day++) {
      dates.push(day);
    }

    return dates;
  };

  const days = Array.from({ length: 31 }, (_, index) => index + 1);
  // for filter make model year-------------------------------------------------
  // for filter sold data---------------------------------------------------------------*********************-----------------------------


  const [selectedFilter, setSelectedFilter] = useState({
    make: "Make",
    models: "Model",
    year: "Year",
    bodyClass: "Body Type",
  });

  // ----------for search bar filter -----------------------------------------------------------
  const [inputValue, setInputValue] = useState("");
  const [selectedSort, setSelectedSort] = useState(null);

  const handleSelect = async (selectedStatus, type = "") => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/listing/get_sold_listing/${id}?searchTerm=${selectedStatus}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();

      if (type) {
        setSelectedFilter((prev) => ({
          ...prev,
          [type]: selectedStatus, // Use computed property name here
        }));
      }
      setTableData(data);
      setSelectedSort(selectedStatus);
      console.log("selectedFilter", selectedFilter, type, selectedStatus);
      console.log(data);
      // Handle the response data as needed
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSelect(inputValue); // Call the function with the input value
      setInputValue(""); // Clear the input field if desired
    }
  };
  const handleButtonClick = () => {
    handleSelect(inputValue); // Call the function with the input value
    setInputValue(""); // Clear the input field if desired
  };

  const handleSortByColumn = async (sort, column) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/listing/get_sold_listing/${id}?searchTerm=${sort}&extraParam=${column}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setTableData(data);
      console.log(data);
      // Handle the response data as needed
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  // -------------------------------------------------------------------------------------------
  console.log("initialFilters32455", initialFilters);
  // ----------------------------------------------------------------------------
  return (
    <>
      <Container fluid className="px-4" style={{ minWidth: "82vw" }}>
        <Row className="px-3">
          <Col sm={2} className="px-0" style={{ paddingTop: "2%" }}>
            <span type="button" style={{ color: "blue" }}>
              <BsStars style={{ outline: "none" }} /> AI Inventory Tax Maker
            </span>
          </Col>

          <Col className="d-flex justify-content-end align-items-center px-0">
            <Button
              className={` m-2 py-1 px-3 rounded-3 fw-bold ${styles.bgBtn}`}
              onClick={() => setShowGenerate(true)}
            >
              Generate
            </Button>

            <Button
              className={` m-2 py-1 px-3 rounded-3 fw-bold ${styles.bgBtn}`}
              onClick={() => setShowSettings(true)}
            >
              Settings
            </Button>

            {/* First dropdown */}
            {/* <div className="">
              <button
                className="btn dropdown-toggle me-2 drp-btn page-count"
                type="button"
                onClick={() => toggleDropdown("page-count")}
              >
                10
              </button>
              {openDropdown === "page-count" && (
                <ul
                  className="page-count-list p-0"
                  ref={dropdownRef}
                  style={{ display: openDropdown ? "block" : "none" }}
                >
                  <li>
                    <Button className="dropdown-item">1</Button>
                  </li>
                  <li>
                    <Button className="dropdown-item">2</Button>
                  </li>
                </ul>
              )}
            </div> */}
          </Col>
        </Row>

        <Row>
          <Col sm={12} className="px-0" style={{ position: "relative" }}>
            <Container fluid className="px-3">
              <Row>
                {/* input field start */}
                <Col
                  sm={11}
                  className="input-group mt-3 mb-4"
                  id="act-search"
                >
                  <Button
                    className="btn border-0 dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    style={{ backgroundColor: "#e8a262" }}
                  >
                    All
                  </Button>
                  <ul className="dropdown-menu">
                    <li onClick={() => handleSelect("Price10k")}>
                      <Link className="dropdown-item" to="#">
                        Price &lt; 10k
                      </Link>
                    </li>
                    <li onClick={() => handleSelect("Mileage50k")}>
                      <Link className="dropdown-item" to="#">
                        Miles &lt; 50k
                      </Link>
                    </li>
                    <li onClick={() => handleSelect("Mileage100k")}>
                      <Link className="dropdown-item" to="#">
                        Miles &lt; 100k
                      </Link>
                    </li>
                  </ul>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search Inventory"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)} // Update state on input change
                    onKeyPress={handleKeyPress} // Call handleKeyPress on key press
                  />
                  <Button
                    className="btn border-0"
                    type="button"
                    id="button-addon1"
                    style={{ backgroundColor: "#e8a262" }}
                    onClick={handleButtonClick}
                  >
                    <i className="fas fa-search"></i>
                  </Button>
                  <Button
                    className="btn border-0 dropdown-toggle btn-eq-wd"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    id="dropdownYear" // Added ID for accessibility
                    style={{
                      backgroundColor: "#e8a262",
                      margin: "0 0.3rem",
                    }}
                  >
                    {selectedFilter.year}
                  </Button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownYear"
                    style={{ maxHeight: "300px", overflowY: "scroll" }}
                  >
                    <li onClick={handleIncrement}>
                      <Link className={`dropdown-item ${selectedFilter.year === ''
                        ? "selected-item"
                        : ""
                        }`} to="#">
                        Select None
                      </Link>
                    </li>
                    {initialFilters &&
                      [
                        ...new Set(initialFilters.map((car) => car.year)),
                      ].sort((a, b) => a - b).map((year, index) => (
                        <li
                          key={index}
                          onClick={() => handleSelect(year, "year")}
                        >
                          <Link className={`dropdown-item ${selectedFilter.year === year
                            ? "selected-item"
                            : ""
                            }`} to="#">
                            {year}
                          </Link>
                        </li>
                      ))}
                  </ul>

                  <Button
                    className="btn border-0 dropdown-toggle btn-eq-wd"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    id="dropdownMake" // Added ID for accessibility
                    style={{ backgroundColor: "#e8a262" }}
                  >
                    {selectedFilter.make}
                  </Button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMake"
                    style={{ maxHeight: "300px", overflowY: "scroll" }}
                  >
                    <li onClick={handleIncrement}>
                      <Link className={`dropdown-item ${selectedFilter.make === ''
                        ? "selected-item"
                        : ""
                        }`} to="#">
                        Select None
                      </Link>
                    </li>
                    {initialFilters &&
                      [
                        ...new Set(initialFilters.map((car) => car.make)),
                      ].sort().map((make, index) => (
                        <li
                          key={index}
                          onClick={() => handleSelect(make, 'make')}
                        >
                          <Link className={`dropdown-item ${selectedFilter.make === make
                            ? "selected-item"
                            : ""
                            }`} to="#">
                            {make}
                          </Link>
                        </li>
                      ))}
                  </ul>

                  <Button
                    className="btn border-0 dropdown-toggle btn-eq-wd"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    id="dropdownModel" // Added ID for accessibility
                    style={{
                      backgroundColor: "#e8a262",
                      margin: "0 0.3rem",
                    }}
                  >
                    {selectedFilter.models}
                  </Button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownModel"
                    style={{ maxHeight: "300px", overflowY: "scroll" }}
                  >
                    <li onClick={handleIncrement}>
                      <Link className={`dropdown-item ${selectedFilter.models === ''
                        ? "selected-item"
                        : ""
                        }`} to="#">
                        Select None
                      </Link>
                    </li>
                    {initialFilters &&
                      [
                        ...new Set(initialFilters.map((car) => car.model)),
                      ].sort().map((model, index) => (
                        <li
                          key={index}
                          onClick={() =>
                            handleSelect(model, "models")
                          }
                        >
                          <Link className={`dropdown-item ${selectedFilter.models === model
                            ? "selected-item"
                            : ""
                            }`} to="#">
                            {model}
                          </Link>
                        </li>
                      ))}
                  </ul>
                  <Button
                    className="btn border-0 dropdown-toggle btn-eq-wd"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    id="dropdownModel" // Added ID for accessibility
                    style={{
                      backgroundColor: "#e8a262",
                      margin: "0 0.3rem",
                    }}
                  >
                    {selectedFilter.bodyClass}
                  </Button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownModel"
                    style={{ maxHeight: "300px", overflowY: "scroll" }}
                  >
                    <li
                      onClick={handleIncrement}
                    >
                      <Link className={`dropdown-item ${selectedFilter.bodyClass === ''
                        ? "selected-item"
                        : ""
                        }`} to="#">
                        Select None
                      </Link>
                    </li>
                    {initialFilters &&
                      [
                        ...new Set(
                          initialFilters.map((car) => car.bodyClass)
                        ),
                      ].sort().map((bodyClass, index) => (
                        <li
                          key={index}
                          onClick={() =>
                            handleSelect(bodyClass, "bodyClass")
                          }
                        >
                          <Link className={`dropdown-item ${selectedFilter.bodyClass === bodyClass
                            ? "selected-item"
                            : ""
                            }`} to="#">
                            {bodyClass}
                          </Link>
                        </li>
                      ))}
                  </ul>
                  <Button
                    className="btn border-0 dropdown-toggle btn-eq-wd"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    id="dropdownModel" // Added ID for accessibility
                    style={{
                      backgroundColor: "#e8a262",
                      margin: "0 0.3rem",
                    }}
                  >
                    Sort BY
                  </Button>
                  <ul className="dropdown-menu">
                    <li onClick={() => handleSelect("Desc")}>
                      <Link className={`dropdown-item ${selectedSort === "Desc" ? 'selected-item' : ''}`} to="#">
                        Newest
                      </Link>
                    </li>
                    <li onClick={() => handleSelect("Asc")}>
                      <Link className={`dropdown-item ${selectedSort === "Asc" ? 'selected-item' : ''}`} to="#">
                        Oldest
                      </Link>
                    </li>
                  </ul>
                  <Button
                    className="btn border-0 btn-eq-wd"
                    type="button"
                    id="button-addon1"
                    style={{ backgroundColor: "#e8a262" }}
                    onClick={handleIncrement}
                  >
                    <BiReset className="icons-r-flt" />
                  </Button>
                </Col>
                {/* input field end */}
              </Row>
            </Container>
          </Col>
        </Row>

        <Row
          className="mx-0 rounded  bg-body border-2"
          style={{
            position: "relative",
            top: "",
            zIndex: "5 ",
            // minWidth: "1000px", overflowX: "scroll"
          }}
        >
          <div className="table-wrapper rounded px-0 table-scrollbar">
            <Table striped bordered hover className="shadow mb-0" id="table">
              <thead>
                <tr>
                  <th>No#</th>
                  <th className="ac-col">Sale Date</th>
                  <th>Sale Type</th>
                  <th>Acc. no.</th>
                  <th>Buyer Name</th>
                  <th>Year
                    <span className="filter-icons">
                      <OverlayTrigger
                        key="bottom-start"
                        placement="bottom-start"
                        overlay={<Tooltip id={`tooltip-bottom-start`} className="table-tooltip" >Descending</Tooltip>}
                      >
                        <span onClick={() => handleSortByColumn('Desc', "Vehicle")}><TiArrowSortedUp /></span>
                      </OverlayTrigger>

                      <OverlayTrigger
                        key="bottom-start"
                        placement="bottom-start"
                        overlay={<Tooltip id={`tooltip-bottom-start`} className="table-tooltip" >Ascending</Tooltip>}
                      >
                        <span onClick={() => handleSortByColumn('Asc', "Vehicle")}><TiArrowSortedDown
                        /></span>
                      </OverlayTrigger>
                    </span>
                  </th>
                  <th>Make</th>
                  <th>Model</th>
                  <th className="vin-column">VIN</th>
                  <th>Sold Price </th>
                  <th>Unit Property Tax</th>
                </tr>
              </thead>
              <tbody>
                {tableData.map(renderTableRow)}
                {emptyRows}
              </tbody>
              <tfoot>
                <tr style={{ backgroundColor: "orange" }}>
                  <td colSpan={8}>All Inventory: {tableData.length}</td>
                  <td colSpan={3}>Total Sale: $587,465,483</td>
                </tr>
              </tfoot>
            </Table>
          </div>
        </Row>
      </Container>

      <Modal
        show={showSettings}
        onHide={() => setShowSettings(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Settings
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* 
          <Container>
            <Row>
              <Col sm={12}>
                <Row className="mb-3">
                  <Col
                    sm={8}
                    className="d-flex justify-content-end align-items-center"
                  >
                    <h5
                      className={`w-50 ${styles.tabs} ${tabs === 1 ? styles.active : ""
                        }`}
                      onClick={() => setTabs(1)}
                    >
                      Monthely Inventory Tax Report 50-246
                    </h5>
                  </Col>
                  <Col
                    sm={4}
                    className="d-flex justify-content-center align-items-center pe-0"
                  >
                    <Button className="w-50 pe-0 py-2 btn-orange border-0">
                      Generate
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col sm={12}>
                <Row className="mb-5">
                  <Col
                    sm={8}
                    className="d-flex justify-content-end align-items-center"
                  >
                    <h5
                      className={`w-50 ${styles.tabs} ${tabs === 2 ? styles.active : ""
                        }`}
                      onClick={() => setTabs(2)}
                    >
                      Motor Vehicle Inventory Declaration 50-242
                    </h5>
                  </Col>
                  <Col
                    sm={4}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <Button className="me-2 w-25 py-2 btn-orange border-0">Download</Button>
                    <Button className="w-25 py-2 btn-orange border-0">Email</Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container> */}

          <Container>
            <Form>
              <Row>
                <Col sm={7}>
                  <Container>
                    <Row>
                      <Col sm={12}>
                        <label className={` ${styles.label} `}>
                          Select Reporting Range
                        </label>
                      </Col>
                      <Col sm={12}>
                        <label className={` ${styles.label} `}>
                          Account Number
                        </label>
                      </Col>
                      <Col sm={12}>
                        <label className={` ${styles.label} `}>
                          Persone Name
                        </label>
                      </Col>
                      <Col sm={12}>
                        <label className={` ${styles.label} `}> Title</label>
                      </Col>
                      <Col sm={12}>
                        <label className={` ${styles.label} `}>Date</label>
                      </Col>
                      <Col sm={12}>
                        <label className={` ${styles.label} `}>Phone</label>
                      </Col>
                      <Col sm={12}>
                        <label className={` ${styles.label} `}>Signature</label>
                      </Col>
                      <Col sm={12}>
                        <label className={` ${styles.label} `}>
                          Bussiness Start Date
                        </label>
                      </Col>
                      <Col sm={12}>
                        <label className={` ${styles.label} `}>
                          Generate Distinguishing Number of Location
                        </label>
                      </Col>
                    </Row>
                  </Container>
                </Col>
                <Col sm={5}>
                  <Container>
                    <Row>
                      <Col
                        sm={12}
                        className={`d-flex justify-content-between `}
                      >
                        <Button
                          className={`btn dropdown-toggle py-1 ${styles.btnDrp}`}
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          Reporting Month
                        </Button>
                        <ul className="dropdown-menu">
                          {months.map((month) => (
                            <li className="text-center">{month}</li>
                          ))}
                        </ul>

                        <Button
                          className={`btn dropdown-toggle py-1 ${styles.btnDrp} `}
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          Reporting Year
                        </Button>
                        <ul className="dropdown-menu">
                          <li className="text-center">2020</li>
                          <li className="text-center">2021</li>
                          <li className="text-center">2022</li>
                          <li className="text-center">2023</li>
                          <li className="text-center">2024</li>
                        </ul>
                      </Col>
                      <Col sm={12}>
                        <input
                          type="text"
                          name=""
                          id=""
                          className={` ${styles.inText} `}
                        />
                      </Col>
                      <Col sm={12}>
                        <input
                          type="text"
                          name=""
                          id=""
                          className={` ${styles.inText} `}
                        />
                      </Col>
                      <Col sm={12}>
                        <input
                          type="text"
                          name=""
                          id=""
                          className={` ${styles.inText} `}
                        />
                      </Col>
                      <Col sm={12} className={` d-flex `}>
                        <select
                          className={`btn dropdown-toggle px-1 me-3 py-1 ${styles.btnDrp} `}
                        >
                          <option disabled selected>
                            Select Date
                          </option>
                          {days.map((day, index) => (
                            <option key={index}>{day}</option>
                          ))}
                        </select>

                        <select
                          className={`btn dropdown-toggle px-1 me-3 py-1 ${styles.btnDrp} `}
                        >
                          <option disabled selected>
                            Select Month
                          </option>
                          {months.map((month, index) => (
                            <option key={index}>{month}</option>
                          ))}
                        </select>

                        <select
                          className={`btn dropdown-toggle px-1 py-1 ${styles.btnDrp} `}
                        >
                          <option disabled selected>
                            Select Year
                          </option>
                          {months.map((month, index) => (
                            <option key={index}>{month}</option>
                          ))}
                        </select>
                      </Col>
                      <Col sm={12}>
                        <input
                          type="text"
                          name=""
                          id=""
                          className={` ${styles.inText} `}
                        />
                      </Col>
                      <Col sm={12}>
                        <input
                          type="text"
                          name=""
                          id=""
                          className={` ${styles.inText} `}
                        />
                      </Col>
                      <Col sm={12}>
                        <input
                          type="date"
                          name=""
                          id=""
                          className={` w-100 ${styles.inText}`}
                        />
                      </Col>
                      {/* <Col sm={12} className={` d-flex `}>
                        <select
                          className={`btn dropdown-toggle px-1 me-3 py-1 ${styles.btnDrp} `}
                        >
                          <option disabled selected>
                            Select Date
                          </option>
                          {days.map((day, index) => (
                            <option key={index}>{day}</option>
                          ))}
                        </select>

                        <select
                          className={`btn dropdown-toggle px-1 me-3 py-1 ${styles.btnDrp} `}
                        >
                          <option disabled selected>
                            Select Month
                          </option>
                          {months.map((month) => (
                            <option>{month}</option>
                          ))}
                        </select>

                        <select
                          className={`btn dropdown-toggle px-1 py-1 ${styles.btnDrp} `}
                        >
                          <option disabled selected>
                            Select Year
                          </option>
                          {months.map((month) => (
                            <option>{month}</option>
                          ))}
                        </select>
                      </Col> */}
                      <Col sm={12}>
                        <input
                          type="text"
                          name=""
                          id=""
                          className={` ${styles.inText} `}
                        />
                      </Col>
                    </Row>
                  </Container>
                </Col>
              </Row>

              <Row className={`p-2 ${styles.bgOrange}`}>
                <Col>
                  <Container>
                    <Row>
                      <Col sm={12}>
                        <label className={` ${styles.label} `}>
                          Motor Vehicle Inventory
                        </label>
                      </Col>
                      <Col sm={12}>
                        <label className={` ${styles.label} `}>
                          Fleet Transections
                        </label>
                      </Col>
                      <Col sm={12}>
                        <label className={` ${styles.label} `}>
                          Dealer Sales
                        </label>
                      </Col>
                      <Col sm={12}>
                        <label className={` ${styles.label} `}>
                          Subsequent Sales
                        </label>
                      </Col>
                      <Col sm={12}>
                        <label className={` ${styles.label} `}>
                          Dealer's Motor Vehicle Inventory Sales for Prior year
                        </label>
                      </Col>
                    </Row>
                  </Container>
                </Col>
                <Col>
                  <Container>
                    <Row>
                      <Col sm={12}>
                        <Row>
                          <Col sm={5}>
                            <Form.Group className={styles.formGroup}>
                              <Form.Label style={{ fontWeight: "400" }}>
                                $
                              </Form.Label>
                              <Form.Control
                                type="text"
                                style={{ fontWeight: "700" }}
                              />
                            </Form.Group>
                          </Col>
                          <Col>
                            <input
                              type="text"
                              name=""
                              id=""
                              className={` ${styles.inText}`}
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col sm={12}>
                        <Row>
                          <Col sm={5}>
                            <input
                              type="text"
                              name=""
                              id=""
                              className={` ${styles.inText}`}
                            />
                          </Col>
                          <Col>
                            <input
                              type="text"
                              name=""
                              id=""
                              className={` ${styles.inText}`}
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col sm={12}>
                        <Row>
                          <Col sm={5}>
                            <input
                              type="text"
                              name=""
                              id=""
                              className={` ${styles.inText}`}
                            />
                          </Col>
                          <Col>
                            <input
                              type="text"
                              name=""
                              id=""
                              className={` ${styles.inText}`}
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col sm={12}>
                        <Row>
                          <Col sm={5}>
                            <input
                              type="text"
                              name=""
                              id=""
                              className={` ${styles.inText}`}
                            />
                          </Col>
                          <Col>
                            <input
                              type="text"
                              name=""
                              id=""
                              className={` ${styles.inText}`}
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col sm={12}>
                        <Row>
                          <Col sm={5}>
                            <input
                              type="text"
                              name=""
                              id=""
                              className={` ${styles.inText}`}
                            />
                          </Col>
                          <Col>
                            <input
                              type="text"
                              name=""
                              id=""
                              className={` ${styles.inText}`}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Container>
                </Col>
              </Row>

              <Row className={`py-4 d-flex justify-content-end`}>
                <Col sm={2}>
                  <lable className={` ${styles.label} px-0`}>Send To:</lable>
                </Col>
                <Col sm={10}>
                  <input
                    type="text"
                    name=""
                    id=""
                    className={`px-0 ${styles.inText}`}
                  />
                </Col>
                <Col sm={2}>
                  <Form.Group className={styles.formGroup}>
                    <Form.Label style={{ fontWeight: "400" }}>
                      <BiSolidPhoneCall />
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Phone"
                      style={{ fontWeight: "700" }}
                    />
                  </Form.Group>
                </Col>
                <Col sm={3}>
                  <Form.Group className={styles.formGroup}>
                    <Form.Label style={{ fontWeight: "400" }}>
                      <MdEmail />
                    </Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Email ID"
                      style={{ fontWeight: "700" }}
                    />
                  </Form.Group>
                </Col>
                <Col sm={1} className="px-0">
                  <Button className="py-1 btn-orange border-0">Email</Button>
                </Col>
              </Row>

              <Row className={` d-flex justify-content-end`}>
                <Col sm={2}>
                  <lable className={` ${styles.label} px-0`}>Copy To:</lable>
                </Col>
                <Col sm={10}>
                  <input
                    type="text"
                    name=""
                    id=""
                    className={`px-0 ${styles.inText}`}
                  />
                </Col>
                <Col sm={2}>
                  <Form.Group className={styles.formGroup}>
                    <Form.Label style={{ fontWeight: "400" }}>
                      <BiSolidPhoneCall />
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Phone"
                      style={{ fontWeight: "700" }}
                    />
                  </Form.Group>
                </Col>
                <Col sm={3}>
                  <Form.Group className={styles.formGroup}>
                    <Form.Label style={{ fontWeight: "400" }}>
                      <MdEmail />
                    </Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Email ID"
                      style={{ fontWeight: "700" }}
                    />
                  </Form.Group>
                </Col>
                <Col sm={1} className="px-0">
                  <Button className="py-1 btn-orange border-0">Email</Button>
                </Col>
              </Row>
            </Form>
          </Container>
        </Modal.Body>
      </Modal>

      <Modal
        show={showGenerate}
        onHide={() => setShowGenerate(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Generate
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col sm={12}>
                <Row className="mb-3">
                  <Col
                    sm={9}
                    className="d-flex justify-content-end align-items-center"
                  >
                    <input
                      type="radio"
                      id="monthly-inventory"
                      name="reportType"
                      value="1"
                      checked={tabs === 1}
                      onChange={() => setTabs(1)}
                      className="me-3"
                    />
                    <label
                      htmlFor="monthly-inventory"
                      className={`w-50 ${styles.tabs} ${tabs === 1 ? styles.active : ""
                        }`}
                    >
                      Monthely Inventory Tax Report 50-246
                    </label>
                  </Col>
                  <Col
                    sm={3}
                    className="d-flex justify-content-center align-items-center pe-0"
                  >
                    <Button
                      className="w-50 pe-0 py-2 btn-orange border-0"
                      onClick={GenerateTaxMakerForms}
                    >
                      Generate
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col sm={12}>
                <Row className="mb-5">
                  <Col
                    sm={9}
                    className="d-flex justify-content-end align-items-center"
                  >
                    <input
                      type="radio"
                      id="vehicle-declaration"
                      name="reportType"
                      value="2"
                      checked={tabs === 2}
                      onChange={() => setTabs(2)}
                      className="me-3"
                    />
                    <label
                      htmlFor="vehicle-declaration"
                      className={`w-50 ${styles.tabs} ${tabs === 2 ? styles.active : ""
                        }`}
                    >
                      Motor Vehicle Inventory Declaration 50-242
                    </label>
                  </Col>
                  <Col
                    sm={3}
                    className="d-flex justify-content-center align-items-center"
                  ></Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>

      <Modal show={showPdf} onHide={() => setShowPdf(false)} size="xl">
        <Modal.Header closeButton style={{ gap: "10%" }}>
          <Modal.Title id="example-custom-modal-styling-title">
            Tax Maker Forms
          </Modal.Title>
          <Modal.Title style={{ display: "flex", width: "45%", gap: "5%" }}>
            <Button
              className="w-100 pe-0 py-2 btn-orange border-0"
            >
              E-Sign
            </Button>
            <Button
              className="w-100 pe-0 py-2 btn-orange border-0"
            >
              E-Mail
            </Button>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: "80vh" }}>
          <Container>
            {taxMakerForms &&
              taxMakerForms.map((pdf, i) => {
                return (
                  <div key={i} style={{ height: "80vh" }}>
                    <iframe
                      src={`https://5starsmotors.com/documents/${id}/${pdf}#page=1&zoom=125`}
                      type="application/pdf"
                      frameborder="0"
                      style={{ objectFit: "fill", width: "100%", height: "75vh" }}
                    ></iframe>
                  </div>
                );
              })}
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DealerInventoryTaxMaker;
