import React, { useEffect, useState } from "react";
import "../Styles/EmailLogin.css";
import axios from "axios";
import { UserCity } from "./UserCity";
import toast, { Toaster } from "react-hot-toast";

const EmailLogin = ({ setResUser, setIsEmailLogin }) => {
  const [inputEmail, setInputEmail] = useState("");
  console.log(inputEmail);
  const [isUserExists, setIsUserExists] = useState(true);
  console.log("Does user exist ?", isUserExists);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [city, setCity] = useState("");
  console.log("User current city", city);

  useEffect(() => {
    async function fetchCity() {
      const userCity = await UserCity();
      console.log(userCity);
      if (userCity) {
        setCity(userCity);
      }
    }
    fetchCity();
  }, []);

  const handleContinueResult = async () => {
    console.log("Handling API continue Result");
    setLoading(true);
    try {
      const unformattedPhoneNumber = inputEmail.replace(/\D/g, "");
      const formData = new FormData();
      if (/^\d{10}$/.test(unformattedPhoneNumber)) {
        console.log(unformattedPhoneNumber);
        console.log("Number detected");

        formData.append("email", unformattedPhoneNumber);
      } else {
        console.log("Number not detected");
        formData.append("email", inputEmail);
      }
      // formData.append("user_type", "User");
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/signin`,
        formData
      );

      if (res.status === 200) {
        console.log(res.data);
        setIsEmailLogin({
          email: res.data,
        });
        setLoading(false);
      }
      console.log(res);
    } catch (error) {
      console.log(error, "Error while logging the user");
      setLoading(false);
      if (error.response.status === 400) {
        toast.error(error.response.data);
      } else if (error.response.status === 500) {
        toast.error(error.response.data);
      }
    }
  };
  const [countryCode, setCountryCode] = useState("");
  console.log(countryCode);
  useEffect(() => {
    const getUserCountryCode = async () => {
      const res = await axios.get("https://ipapi.co/json");
      console.log("User all info", res.data);

      setCountryCode(res.data.country_calling_code);
      // setIP(res.data.ip)
    };
    getUserCountryCode();
  }, [countryCode]);

  const handleCreateResult = async () => {
    if (inputEmail)
      try {
        setLoading(true);
        const unformattedPhoneNumber = inputEmail.replace(/\D/g, "");
        const formData = new FormData();
        if (/^\d{10}$/.test(unformattedPhoneNumber)) {
          console.log(unformattedPhoneNumber);
          console.log("Number detected");

          formData.append("email", unformattedPhoneNumber);
        } else {
          console.log("Number not detected");
          formData.append("email", inputEmail);
        }
        formData.append("country_code", countryCode);
        formData.append("user_type", "User");
        const res = await axios.post(
          `${process.env.REACT_APP_API_URL}/user/send_link`,
          formData
        );

        if (res.status === 200) {
          console.log(res.data);
          setResUser({
            email: res.data,
          });
          setLoading(false);
        }
        console.log("resUser is for signup");
        console.log(res);
      } catch (error) {
        console.log(error, "Error while creating the user");
        setLoading(false);
        console.log("Error.response.status", error);
        if (error.response.status === 400) {
          toast.error(error.response.data);
          setIsUserExists(true);
          setInputEmail("");
        } else if (error.response.status === 500) {
          toast.error(error.response.data);
        }
      }
  };

  useEffect(() => {
    if (inputEmail.trim() === "") {
      setIsUserExists(true);
    }
  }, [inputEmail]);
  useEffect(() => {
    const handlekeypress = (e) => {
      if (e.key === "Enter") {
        if (isUserExists) {
          handleContinueResult();
        } else {
          handleCreateResult();
        }
      }
    };
    window.addEventListener("keypress", handlekeypress);
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("keypress", handlekeypress);
    };
  }, [isUserExists, inputEmail]);

  const handleInputChange = async (e) => {
    const { value } = e.target;

    // Check if the input is a phone number
    if (/^\d{10}$/.test(value)) {
      // Format the phone number to USA format (assuming 10 digits)
      const formattedPhoneNumber = `(${value.slice(0, 3)}) ${value.slice(
        3,
        6
      )}-${value.slice(6)}`;
      setInputEmail(formattedPhoneNumber);

      // Remove formatting for API submission
      const unformattedPhoneNumber = value.replace(/\D/g, "");
      if (unformattedPhoneNumber.length === 10) {
        await callAPI(unformattedPhoneNumber);
      }
    } else {
      setInputEmail(value);

      // Check if a dot is typed after "@"
      const indexOfAt = value.indexOf("@");
      const indexOfDot = value.indexOf(".", indexOfAt);
      if (indexOfDot !== -1) {
        // Call your API here
        await callAPI(value);
      }
    }
  };

  const callAPI = async (email) => {
    try {
      console.log("Calling the API");
      const formData = new FormData();
      formData.append("email", email);
      formData.append("user_type", "User");
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/check_account`,
        formData
      );
      console.log(res.data);
      if (res.status == 200) {
        // navigate("/loginpassword", { state: res.data });
        console.log("Status 200");
        setIsUserExists(res.data);
      } else {
        console.log("Login Faild...");
      }
    } catch (error) {
      console.log("error in catch");
    }
  };

  return (
    <div className="GrandEmailParent">
      <div className="EmailContainer">
        <h2 className="heading">Log in by Phone or Email</h2>
        <div className="input_email_Container">
          {/* <p className="detailing_1">
            <span>Email/Phone</span>
            <span>required</span>
          </p> */}

          <input
            type="email"
            onChange={handleInputChange}
            value={inputEmail}
            placeholder="Enter Phone or Email"
          />
        </div>
        <p style={{ display: "flex", justifyContent: "end", color: "red" }}>
          <span>{!inputEmail && error}</span>
        </p>

        {isUserExists ? (
          <button
            onClick={handleContinueResult}
            className={loading ? "blink_now" : ""}
            disabled={inputEmail.trim() === "" ? true : false}
          >
            <span>Continue</span>
            {loading ? (
              <img
                src="https://dacbuckets3.s3.amazonaws.com/sport-car.png"
                alt="animated car icon"
              />
            ) : null}
          </button>
        ) : (
          <button
            onClick={handleCreateResult}
            className={loading ? "blink_now" : ""}
          >
            <span>Create</span>
            {loading ? (
              <img
                src="https://dacbuckets3.s3.amazonaws.com/sport-car.png"
                alt="animated car icon"
              />
            ) : null}
          </button>
        )}

        <div className="sign_in_line">
          <p>Or Signin with</p>
        </div>
      </div>
      <Toaster
        position="top-right"
        reverseOrder={false}
        toastOptions={{
          // Define default options
          className: "",
          duration: 1000,
          style: {
            background: "#363636",
            color: "#fff",
            fontSize: "larger",
          },
        }}
      />
    </div>
  );
};

export default EmailLogin;
