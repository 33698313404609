import Image from "../../imgData";
import { CiSettings } from "react-icons/ci";
export const DealerSideBarArray = [
  {
    id: 1,
    name: "Dashboard",
    route: "/dealer/dashboard",
    image: Image.sidebarHome,
  },
  {
    id: 2,
    name: "Mail",
    route: "/dealer/mail",
    image: Image.dealer_email,
  },

  {
    id: 111,
    name: "Add Inventory",
    route: "/dealer/inventory/add",
    image: Image.addList,
  },
  {
    id: 112,
    name: "Active Inventory",
    route: "/dealer/inventory/active",
    image: Image.activeListing,
  },
  {
    id: 12,
    name: "Sell Inventory",
    route: "/dealer/sell",
    image: Image.Sell,
  },

  {
    id: 113,
    name: "Sold Inventory",
    route: "/dealer/inventory/sold",
    image: Image.boughtSold,
  },
  {
    id: 6,
    name: "Active Balance",
    route: "/dealer/inventory/balance",
    image: Image.boughtSold,
  },
  {
    id: 122,
    name: "Media Manager",
    route: "/dealer/dealer_add_manager",
    image: Image.sidebarAddManager,
  },
  {
    id: 4,
    name: "F & I",
    route: "/dealer/lien-holders",
    image: Image.dealer_master,
    // children: [
    //   {
    //     id: 6,
    //     name: "Lien Holders",
    //     route: "/dealer/lien-holders",
    //     image: Image.dealer_lien_holders,
    //   },

    //   {
    //     id: 7,
    //     name: "Service Contract",
    //     route: "/dealer/warranty-company",
    //     image: Image.dealer_warranty_company,
    //   },
    //   {
    //     id: 8,
    //     name: "Floor Company",
    //     route: "/dealer/floor-company",
    //     image: Image.dealer_floor_company,
    //   },
    //   {
    //     id: 9,
    //     name: "Gap Insurance",
    //     route: "/dealer/gap-insurance",
    //     image: Image.dealer_gap_insurance,
    //   },

    //   {
    //     id: 10,
    //     name: "Insurance Company",
    //     route: "/dealer/insurance-company",
    //     image: Image.dealer_insurance_company,
    //   },
    // ],
  },
  {
    id: 3,
    name: "Settings",
    route: "/dealer/dealer-master",
    image: `https://cdn.icon-icons.com/icons2/2761/PNG/512/settings_icon_176440.png`,
    children: [
      {
        id: 32,
        name: "Billing",
        route: "/dealer/dealer-master/taxes-fees/retail",
        image: Image.billingAdd,
      },
      {
        id: 4,
        name: "Forms",
        route: "/dealer/forms",
        image: Image.dealer_forms,
      },
      {
        id: 5,
        name: "Users",
        route: "/dealer/users",
        image: Image.dealer_user,
        // children: [
        //   {
        //     id: 51,
        //     name: "All User",
        //     route: "/dealer/users/all",
        //     image: Image.dealer_all_user,
        //   },
        //   {
        //     id: 52,
        //     name: "Setup User",
        //     route: "/dealer/users/setup",
        //     image: Image.dealer_setup_user,
        //   },
        //   {
        //     id: 53,
        //     name: "Deleted User",
        //     route: "/dealer/users/deleted",
        //     image: Image.dealer_deleted_user,
        //   },
        // ],
      },
      {
        id: 31,
        name: "Dealer-Information",
        route: "/dealer/dealer-master/bussiness-information",
        image: Image.dealer_information,
      },
    ],
  },

  {
    id: 114,
    name: "Draft",
    route: "/dealer/inventory/draft",
    image: Image.sidebarDraft,
  },
  // {
  //   id: 115,
  //   name: "Import & Export",
  //   route: "/dealer/inventory/import-export",
  //   image: Image.dealer_import_export,
  // },
  {
    id: 116,
    name: "Inventory Tax Maker",
    route: "/dealer/inventory/tax-maker",
    image: Image.dealer_inventory_tax_maker,
  },

  {
    id: 13,
    name: "Website",
    route: "/dealer/website",
    image: Image.sidebarHome,
  },
  // {
  //   id: 131,
  //   name: "HR Manager",
  //   route: "/dealer/website/hr-manager",
  //   image: Image.sidebarHome,
  // },
  {
    id: 132,
    name: "Lead",
    // route: "/dealer/messages",
    image: Image.leads,
    children: [
      {
        id: 1321,
        name: "Messages",
        route: "/dealer/messages",
        image: Image.billingAdd,
      },
      {
        id: 1322,
        name: "Phone Calls",
        route: "/dealer/messages",
        image: Image.dealer_forms,
      },
      {
        id: 1323,
        name: "Loan App",
        route: "/dealer/messages",
        image: Image.dealer_forms,
      },
      {
        id: 1324,
        name: "Email Leads",
        route: "/dealer/messages",
        image: Image.dealer_forms,
      },
    ],
  },
  {
    id: 133,
    name: "Help & Support",
    route: "/dealer/help_desk",
    image: Image.support_icon,
  },
  {
    id: 14,
    name: "Recycle Bin",
    route: "/dealer/recycle-bin",
    image: Image.sidebarRecycleBin,
  },
  {
    id: 15,
    name: "Logout",
    route: "/",
    image: Image.logout,
  },
];
