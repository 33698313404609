import React, { useEffect, useRef, useState } from "react";
import styles from "./price-fees.module.css";
import SelectedSellCar from "./components/SelectedSellCar";
import Box_A from "./components/Box_A";
import Box_B from "./components/Box_B";
import Box_C from "./components/Box_C";
import Box_D from "./components/Box_D";
import Box_E from "./components/Box_E";
import Box_F from "./components/Box_F";
import { useDispatch, useSelector } from "react-redux";
import SellFromData from "./components/SellFromData";
import EmptySellCar from "./components/EmptySellCar";
import Box_G from "./components/Box_G";
import Box_H from "./components/Box_H";
import {
  addSellPriceLabels,
  setRetailData,
} from "../../../../Redux/slice/taxes&FeesSlice";
import SelectedSellCar2 from "../../DealerSell/components/SelectedSellCar2";
import usaStates from "../DealerAddInventory/usaStates.json";
import Box_B_Non_Edit from "../../DealerSell/components/Box_B_Non_Edit";
import { Dropdown, Modal } from "react-bootstrap";

const PricesFees = ({ selectedVehicle, status }) => {
  const totalFeesAndothers = useSelector((state) =>
    parseFloat(state.taxesAndFees.dealerRetail.totalFeesAndothers)
  );
  const [apiData, setApiData] = useState(null);
  const dispatch = useDispatch();
  const retailFromRedux = useSelector(
    (state) => state.taxesAndFees.dealerRetail
  );
  const [totalTaxesOthers, setTotalTaxesOthers] = useState(0);
  const [totalTaxesOthersB, setTotalTaxesOthersB] = useState(0);
  const [totalFeesOthers, setTotalFeesOthers] = useState(0);
  const [sum, setSum] = useState(0);
  const isCarSelected = useSelector((state) => state.sell.selectedVehicle);
  const handleApiDataUpdate = (data) => {
    setApiData(data);
    console.log("API Data from SelectedSellCar:", totalTaxesOthers); // You can now use this data as needed
  };
  const handleTotalFeesUpdate = (newTotal) => {
    setTotalTaxesOthers(newTotal);
  };
  const handleTotalFeesUpdateB = (newTotal) => {
    setTotalTaxesOthersB(newTotal);
  };

  const handleSumChange = (newSum) => {
    setSum(newSum);
    // Handle the sum as needed
    console.log("Sum received from Box_F:", newSum);
  };
  const totalFeesAndOthersPrice = totalTaxesOthers + totalTaxesOthersB;
  // useEffect(() => {
  //   dispatch(
  //     setRetailData({
  //       totalFeesAndothers: totalFeesAndOthersPrice,
  //     })
  //   );
  // }, [totalFeesAndOthersPrice, totalTaxesOthersB]);
  console.log("totalTaxesOthersB", totalTaxesOthersB);
  const [showLabelPopout, setShowLabelPopout] = useState(false);

  const allInputs = useSelector(
    (state) => state.taxesAndFees.dealerRetail.SellPriceInputs
  );
  console.log("all inputs dc", allInputs);
  //state selecting for dynamic label
  const [search, setSearch] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [filteredStates, setFilteredStates] = useState([]);
  const searchInputRef = useRef(null);
  const [selectedState, setSelectedState] = useState("TX - Texas");

  useEffect(() => {
    if (search.length > 0) {
      // Filter states based on the search input
      setFilteredStates(
        usaStates.filter((state) =>
          state.name.toLowerCase().includes(search.toLowerCase())
        )
      );
    } else {
      setFilteredStates(usaStates);
    }
  }, [search, usaStates, isOpen]);

  // Handle the search input change
  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };
  // Handle state selection
  const handleSelectState = (state) => {
    setSelectedState(state);
  };
  const handleSelect = (eventKey) => {
    setSearch(""); // Clear search input when an item is selected
    setIsOpen(false); // Close the dropdown
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        {isCarSelected || status ? (
          status ? (
            <SelectedSellCar2
              onApiDataUpdate={handleApiDataUpdate}
              apiData={apiData}
              selectedVehicle={selectedVehicle}
            />
          ) : (
            <SelectedSellCar
              onApiDataUpdate={handleApiDataUpdate}
              apiData={apiData}
              selectedVehicle={selectedVehicle}
            />
          )
        ) : (
          <EmptySellCar />
        )}
        {/* <SelectedSellCar/> */}
        <div className={styles.contentContainer}>
          <div className={styles.content_left}>
            <Box_A retailFromRedux={retailFromRedux} apiData={apiData} />

            {/* <Box_B
              retailFromRedux={retailFromRedux}
              apiData={apiData}
              onTotalFeesUpdate={handleTotalFeesUpdate}
            /> */}
            <div
              style={{
                border: "none",
                // borderTop: "2px solid #d1823a",
                borderRight: "2px solid #d1823a",
                // borderBottom: "2px solid #d1823a",
                // padding: "1rem 0",
              }}
            >
              {" "}
              <Box_B_Non_Edit
                allInputs={allInputs}
                editStatus={false}
                retailFromRedux={retailFromRedux}
              />
            </div>
            <p
              style={{
                border: "none",
                // borderTop: " 2px solid #d1823a",
                borderRight: "2px solid #d1823a",
                padding: "0.2rem 0",
                marginBottom: "0",
                marginLeft: "0.7rem",
                display: "flex",
                justifyContent: "end",
                paddingRight: "3rem",
              }}
            >
              <button className="btn-orange"
                onClick={() => {
                  setShowLabelPopout(true);
                }}
                style={{
                  fontWeight: "600",
                  borderRadius: "5px",
                  padding: "0.5rem 0.7rem",
                }}
              >
                Edit{" "}
                <span
                  style={{
                    fontSize: "larger",
                    fontWeight: "700",
                    borderRadius: "5px",
                  }}
                >
                  &#8595;
                </span>
              </button>
            </p>
            {/* <Box_C
              retailFromRedux={retailFromRedux}
              apiData={apiData}
              onTotalFeesUpdateB={handleTotalFeesUpdateB}
            /> */}
          </div>
          <div className={styles.content_right}>
            <Box_G
              retailFromRedux={retailFromRedux}
              onSumChange={handleSumChange}
            />
            <Box_F
              retailFromRedux={retailFromRedux}
              onSumChange={handleSumChange}
            />
            <Box_D retailFromRedux={retailFromRedux} sum={sum} />
            <Box_H
              retailFromRedux={retailFromRedux}
              onSumChange={handleSumChange}
            />
            <Box_E retailFromRedux={retailFromRedux} />
            <SellFromData status={status}/>
          </div>
        </div>
      </div>
      <Modal
        show={showLabelPopout}
        onHide={() => setShowLabelPopout(false)}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title
            style={{
              border: "1px solid blue",
              width: "100%",
              border: "flex",
            }}
          >
            <span style={{ marginRight: "7rem" }}>Label edit center</span>
            <div style={{ display: "inline-flex", alignItems: "center" }}>
              {" "}
              <button
                style={{
                  border: "none",
                  backgroundColor: "#e8a262",
                  color: "white",
                  borderRadius: "5px",
                  padding: "0.35rem 1rem",
                  marginRight: "2rem",
                  fontSize: "medium",
                  marginTop: "0.1rem",
                }}
                className=" text-light"
                onClick={() =>
                  dispatch(
                    addSellPriceLabels({
                      id: allInputs && allInputs.length + 1,
                      label: `Input ${allInputs && allInputs?.length + 1}`,
                    })
                  )
                }
                disabled={allInputs && allInputs.length === 20}
              >
                Add
              </button>
              <Dropdown
                show={isOpen}
                onSelect={handleSelectState}
                onToggle={(open) => setIsOpen(open)}
                className="m-0 "
              >
                <Dropdown.Toggle
                  size="sm"
                  className="px-3 py-2 bg-orange border-0 fw-bold text-light"
                  onClick={() => setIsOpen(!isOpen)}
                >
                  {selectedState}
                </Dropdown.Toggle>
                <Dropdown.Menu className="state-drp">
                  {/* Hidden input to manage search */}
                  <input
                    type="text"
                    value={search}
                    ref={searchInputRef}
                    autofocus
                    onChange={handleSearchChange}
                    onFocus={() => setIsOpen(true)}
                    style={{
                      position: "absolute",
                      top: "-9999px",
                      left: "-9999px",
                    }} // Hide the input field
                  />
                  {filteredStates.length > 0 ? (
                    filteredStates.map((state) => (
                      <Dropdown.Item
                        key={state.name}
                        eventKey={state.name}
                        onClick={() => handleSelect(state.name)}
                      >
                        {state.name}
                      </Dropdown.Item>
                    ))
                  ) : (
                    <Dropdown.Item disabled>No states found</Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              border: "2px solid #d1823a",
              padding: "1rem 0",
            }}
          >
            <Box_B_Non_Edit allInputs={allInputs} editStatus={true} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <p
            style={{
              display: "flex",
              justifyContent: "end",
            }}
          >
            {" "}
            <button
              style={{
                border: "none",
                backgroundColor: "#E8A262",
                color: "white",
                fontWeight: "500",
                borderRadius: "5px",
                padding: "0.5rem 0.7rem",
                marginLeft: "0.7rem",
              }}
              onClick={() => setShowLabelPopout(false)}
            >
              Save
            </button>
          </p>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PricesFees;
