import React, { useEffect, useState } from "react";
import "../Styles/PassLogin.css";
import axios from "axios";
import Image from "../../imgData";
import Fingerprint2 from 'fingerprintjs2';
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { saveUser, setShowUserCompleteProfileDialogueBox } from "../../../Redux/slice/userSlice";
import { UserCity } from "./UserCity";

const SetPass = ({resUser,setShowLoginDialogueBox}) => {
    console.log("resUser in SetPass",resUser)
  const [showPass, setShowPass] = useState(false);
  const navigate = useNavigate()
  const dispatch = useDispatch();

  const [InputPass, setInputPass] = useState("");
  const [InputPassError, setInputPassError] = useState(null);

  const [InputConfirmPass, setInputConfirmPass] = useState("");
  const [showConfirmPass, setShowConfirmPass] = useState(false);
  const [InputConfirmPassError, setInputConfirmPassError] = useState("");

  const [passwordMatch, setPasswordMatch] = useState(true);
  const [fingerprint, setFingerprint] = useState('');
  const [ip, setIP] = useState("");
  

  // console.log(fingerprint)

  useEffect(() => {
    const getDeviceFingerprint = () => {
      return new Promise((resolve, reject) => {
        Fingerprint2.get((components) => {
          const values = components.map(component => component.value);
          const fingerprintId = Fingerprint2.x64hash128(values.join(''), 31);
          resolve(fingerprintId);
        });
      });
    };

    const fetchFingerprint = async () => {
      try {
        const fingerprintId = await getDeviceFingerprint();
        setFingerprint(fingerprintId);
      } catch (error) {
        console.error('Error getting fingerprint:', error);
      }
    };

    // Wait for fingerprint to be generated before rendering the component
    const timeout = setTimeout(() => {
      fetchFingerprint();
    }, 1000); // Adjust timeout value as needed

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,}$/;

  const handleChangeView = () => {
    setShowPass(!showPass);
  };
  const handleChangeView2 = () => {
    setShowConfirmPass(!showConfirmPass);
  };

  const handleInputChange = (e) => {
    setInputPass(e.target.value);

    if (!passwordRegex.test(e.target.value)) {
      setInputPassError(
        "Password must be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, and one special character."
      );
    } else {
      setInputPassError(null);
    }

    if (InputConfirmPass && e.target.value !== InputConfirmPass) {
      setPasswordMatch(false);
    } else {
      setPasswordMatch(true);
    }
  };

  const handleInputChange2 = (e) => {
    setInputConfirmPass(e.target.value);
    if (!passwordRegex.test(e.target.value)) {
      setInputConfirmPassError(
        "Password must be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, and one special character."
      );
    } else {
      setInputConfirmPassError(null);
    }

    if (InputPass && e.target.value !== InputPass) {
      setPasswordMatch(false);
    } else {
      setPasswordMatch(true);
    }
  };

  useEffect(() => {
    if (
      passwordMatch &&
      !InputPassError &&
      !InputConfirmPassError &&
      InputPass &&
      InputConfirmPass
    ) {
      PassMatchAPI();
    }
  }, [
    passwordMatch,
    InputPass,
    InputConfirmPass,
    InputConfirmPassError,
    InputPassError,
  ]);

  const getUserIPaddress = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");
    // console.log("User IP Address",res);
    setIP(res.data.ip)
  };
  useEffect(() => {
    getUserIPaddress();
  }, [ip]);

  const [countryCode,setCountryCode] = useState("")
  console.log(countryCode)

  useEffect(() => {
    const getUserCountryCode = async () => {
      const res = await axios.get("https://ipapi.co/json");
      console.log("User all info",res.data);
      setCountryCode(res.data.country_calling_code);
      // setLatitude(res.data.latitude)
      // setLongitude(res.data.longitude)
    };
    getUserCountryCode()
   
    
  },[countryCode])  
    
  const [city, setCity] = useState('');
  // console.log("User current city",city)

useEffect(() => {
  async function fetchCity() {
    const userCity = await UserCity();
    // console.log(userCity)
    if (userCity) {
      setCity(userCity);
    }
  }
  fetchCity();
}, []);

  const PassMatchAPI = async () => {
    console.log("Calling the API...");
    const formData = new FormData();
    try {
        formData.append("password", InputPass)
        formData.append("email", resUser.email)
        formData.append("confirmpassword", InputConfirmPass)
        formData.append("fingerprint", fingerprint)
        formData.append("ip", ip);
        formData.append("country_code", countryCode);
        formData.append("user_type", "User");

        const res = await axios.post(`${process.env.REACT_APP_API_URL}/user/signup`, formData);
        console.log("Response after saving new user",res)
        if(res.status === 200){
          dispatch(
            saveUser({
              email: res.data.email,
              id: res.data.id,
              phoneNumber: res.data.phoneNumber,
              // firstName: res.data.firstName,
              // lastName: res.data.lastName,
              office_number: res.data.office_number,
              lattitude: res.data.lattitude,
              longitude: res.data.longitude,
              zip_code: res.data.zip_code,
              address: res.data.address,
              // profile_picture: res.data.profile_picture,
              website: res.data.website,
              roles: res.data.roles,
              
              })

          );
          // navigate("/myprofile/dashboard");
          setShowLoginDialogueBox(false)
         
        }
       
        // console.log(res)
    } catch (error) {
        console.log("Error in catch part",error)
    }
  };
  return (
    <div className="GrandPassParent">
      <div className="PassContainer">
        <h2 className="heading">Set Your Own Password</h2>
        <div className="input_pass_Container">
          {/* <p className="detailing_1">
            <span>New Password</span>
            <span>required</span>
          </p> */}

          <div className="input_and_icon">
            <input
              type={!showPass ? "password" : "text"}
              onChange={handleInputChange}
              value={InputPass}
              placeholder="Enter Password"
              className="user_input_login"
            />
            <img
              src={!showPass ? Image.eye_icon : Image.hide_icon}
              alt="hide/unhide_icon"
              height={20}
              onClick={handleChangeView}
            />
          </div>
          <p className="error_pass_set">{InputPassError}</p>
          <p className="detailing_1">
            <span>Confirm Password</span>
            <span>required</span>
          </p>
          <div className="input_and_icon">
            <input
              type={!showConfirmPass ? "password" : "text"}
              onChange={handleInputChange2}
              value={InputConfirmPass}
              placeholder="Confirm Password"
              className="user_input_login"
            />
            <img
              src={!showConfirmPass ? Image.eye_icon : Image.hide_icon}
              alt="hide/unhide_icon"
              height={20}
              onClick={handleChangeView2}
            />
          </div>
          <p className="error_pass_set">{InputConfirmPassError}</p>
          {!passwordMatch && (
            <p style={{ color: "red" }}>Passwords do not match.</p>
          )}
        </div>

        <div className="sign_in_line">
          <p>Or Signin with</p>
        </div>
      </div>
    </div>
  );
};

export default SetPass;
