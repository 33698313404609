import React, { useState, useEffect, useRef, useCallback } from "react";
import styled from "styled-components";
import { filteredImage } from "../FilteredImage";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const SliderContainer = styled.div`
  width: 100%;
  // height: 50vh;
  height: ${(props) => props.containerHeight};
  position: relative;
  overflow: hidden;
  padding: 5rem;
`;

const Logo = styled.div`
  width: 80px;
  height: 80px;
  position: absolute;
  transition: transform 0.3s ease;
  cursor: pointer;
  left: ${(props) => props.x}px;
  top: ${(props) => props.y}px;

  &:hover {
    transform: scale(1.5);
    z-index: 10;
  }
`;

const LogoContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  img {
    width: 100%;
    height: 90%;
    pointer-events: none;
  }

  span {
    position: absolute;
    bottom: -20%;
    left: 50%;
    transform: translateX(-50%);
    color: black;
    padding: 2px 5px;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 600;
  }
`;

const DealerLoginMarquee = () => {
  const navigate = useNavigate();
  const [logos, setLogos] = useState([]);
  const [carCounts, setCarCounts] = useState({});
  const [isDragging, setIsDragging] = useState(false);
  const sliderRef = useRef(null);
  const dragItem = useRef(null);
  const dragStart = useRef(null);
  const [mouseDownTime, setMouseDownTime] = useState(0);
  const [mouseUpTime, setMouseUpTime] = useState(0);
  const userData = useSelector((state) => state.user);
  const { id } = useSelector((state) => state.user);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const formData = new FormData();
        formData.append("user_id", userData.id);
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/listing/get_makes_count`,
          {
            method: "POST",
            body: formData,
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();
        const counts = data.reduce((acc, item) => {
          const makeKey = item.make.toLowerCase().replace(/-/g, "");
          if (filteredImage.hasOwnProperty(makeKey)) {
            acc[makeKey] = item.count;
          }
          return acc;
        }, {});
        setCarCounts(counts);
      } catch (error) {
        console.log("Fail", error);
      }
    };

    fetchData();
  }, []);

  const [containerHeight, setContainerHeight] = useState("50vh");
  useEffect(() => {
    const containerWidth = sliderRef.current.clientWidth;
    const containerHeight = sliderRef.current.clientHeight;
    const logoWidth = 80; // Adjusted to match the styled component
    const logoHeight = 80; // Adjusted to match the styled component
    const logos = Object.keys(filteredImage).filter(
      (logo) => carCounts[logo] > 0
    );
    const totalLogos = logos.length;

    const newLogos = [];
    const positions = new Set();

    while (newLogos.length < totalLogos) {
      const x = Math.random() * (containerWidth - logoWidth);
      const y = Math.random() * (containerHeight - logoHeight);
      const positionKey = `${Math.round(x)},${Math.round(y)}`;

      // Check for collisions
      const hasCollision = Array.from(positions).some((pos) => {
        const [px, py] = pos.split(",").map(Number);
        console.log("px , py ", px, py);
        return (
          Math.abs(px - Math.round(x)) < logoWidth - 5 &&
          Math.abs(py - Math.round(y)) < logoHeight - 5
        );
      });

      if (!hasCollision) {
        newLogos.push({
          id: newLogos.length,
          url: filteredImage[logos[newLogos.length]],
          x,
          y,
          count: carCounts[logos[newLogos.length]],
          make: logos[newLogos.length],
        });
        positions.add(positionKey);
      }
    }

    setLogos(newLogos);
    // Calculate the new height based on the logos
    const lowestY = Math.min(...newLogos.map((logo) => logo.y));
    const newHeight = Math.max(containerHeight - lowestY + logoHeight, 200); // Ensure a minimum height
    setContainerHeight(`${newHeight + 100}px`);
  }, [carCounts]);

  const onMouseDown = (e, id) => {
    if (e.button !== 0) return;
    setMouseDownTime(Date.now());
    dragItem.current = id;
    dragStart.current = { x: e.clientX, y: e.clientY };
    setIsDragging(true);
    window.addEventListener("mousemove", onMouseMove);
    window.addEventListener("mouseup", onMouseUp);
  };

  const onMouseMove = (e) => {
    if (dragItem.current !== null) {
      const dx = e.clientX - dragStart.current.x;
      const dy = e.clientY - dragStart.current.y;

      setLogos((prevLogos) =>
        prevLogos.map((logo) => {
          if (logo.id === dragItem.current) {
            let newX = logo.x + dx;
            let newY = logo.y + dy;

            newX = Math.max(
              0,
              Math.min(newX, sliderRef.current.clientWidth - 80)
            );
            newY = Math.max(
              0,
              Math.min(newY, sliderRef.current.clientHeight - 80)
            );

            return { ...logo, x: newX, y: newY };
          }
          return logo;
        })
      );

      dragStart.current = { x: e.clientX, y: e.clientY };
    }
  };

  const onMouseUp = () => {
    setMouseUpTime(Date.now());
    dragItem.current = null;
    setIsDragging(false);
    window.removeEventListener("mousemove", onMouseMove);
    window.removeEventListener("mouseup", onMouseUp);
  };

  const handleClick = useCallback(
    (make) => {
      let adjustedMake;

      switch (make) {
        case "MERCEDESBENZ":
          adjustedMake = "MERCEDES-BENZ";
          break;
        case "LANDROVER":
          adjustedMake = "LAND ROVER";
          break;
        default:
          adjustedMake = make;
      }

      const filters = {
        make: [adjustedMake],
        models: [],
        year: [],
        engineType: [],
        fuelType: [],
        vehicleType: [],
        owner: [],
        state: [],
        interiorColor: [],
        exteriorColor: [],
        interiorType: [],
        doorCounts: [],
        driveTrain: [],
        odometer: [],
        price: [],
        exterior_damage: [],
        photos: [],
        seller: [id ? id : 0],
      };

      navigate("/product", { state: filters });
    },
    [navigate]
  );

  const handleLogoClick = useCallback(
    (logo, e) => {
      const clickDuration = mouseUpTime - mouseDownTime;
      if (clickDuration < 200 && !isDragging) {
        handleClick(logo.make.toUpperCase());
      }
    },
    [handleClick, isDragging, mouseDownTime, mouseUpTime]
  );

  return (
    <div
      style={{ display: "flex", justifyContent: "center", padding: "0 9rem" }}
    >
      <SliderContainer ref={sliderRef} containerHeight={containerHeight}>
        {logos.map((logo) => (
          <Logo
            key={logo.id}
            x={logo.x}
            y={logo.y}
            onMouseDown={(e) => onMouseDown(e, logo.id)}
          >
            <LogoContent
              onClick={(e) => {
                handleLogoClick(logo, e);
              }}
            >
              <img src={logo.url} alt={`Car logo ${logo.make}`} />
              <span style={{ marginTop: "0.4rem" }}>{logo.count}</span>
            </LogoContent>
          </Logo>
        ))}
      </SliderContainer>
    </div>
  );
};

export default DealerLoginMarquee;
