import React, { useEffect, useRef, useState } from "react";
import CardView from "./CradView";
import "./active.css";
import Image from "../../imgData";
import { FaEye } from "react-icons/fa";
import { Link, useNavigate, useLocation } from "react-router-dom";

import {
  Button,
  Col,
  Container,
  Row,
  Table,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import Pagination from "../../Pagination/Pagination";
import * as XLSX from "xlsx";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { saveView } from "../../../Redux/slice/activeSlice";
// import CustomPrompt from './CustomPrompt';

const apiUrl = process.env.REACT_APP_API_URL;

const ActiveInventory = () => {
  const dispatch = useDispatch();
  const view = useSelector(state => state.active.view);
  const { id } = useSelector((state) => state.user);
  // For all dropdown button in this page
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [openDropdown, setOpenDropdown] = useState(null);
  const dropdownRef = useRef();

  const [tableData, setTableData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  // const [showPrompt, setShowPrompt] = useState(false);
  // const [showRowCheck, setShowRowCheck] = useState(false)

  const [email, setEmail] = useState("");

  // For view toggle
  // const [view, setView] = useState("c");

  // const toggleView = (newView) => {
  //   setView(newView);
  // };

  const handleViewToggle = (newView) => {
    dispatch(saveView(newView));
  };

  const [sortedData, setSortedData] = useState(tableData);

  useEffect(() => {
    const fetchData = async () => {

      try {
        const response = await fetch(
          `${apiUrl}/listing/get_user_listing/${id}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        setTableData(data);
        setSortedData(data);
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const dropdownClickedRef = useRef(false);

  // useEffect(() => {
  //     const handleOutsideClick = (event) => {
  //         if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
  //             setOpenDropdown(null);
  //         }
  //     };

  //     document.addEventListener("mousedown", handleOutsideClick);

  //     return () => {
  //         document.removeEventListener("mousedown", handleOutsideClick);
  //     };
  // }, []);

  const toggleDropdown = (dropdownName) => {
    setOpenDropdown((prevDropdown) => {
      // If the clicked dropdown is already open, close it
      if (prevDropdown === dropdownName) {
        return null;
      } else {
        // Open the clicked dropdown and close any other open dropdown
        return dropdownName;
      }
    });
  };

  // const toggleDropdown = (dropdownName) => {
  //     setOpenDropdown((prevDropdown) => {
  //         // If the clicked dropdown is already open, close it
  //         if (prevDropdown === dropdownName) {
  //             // Set the ref value to true when clicking on the dropdown
  //             dropdownClickedRef.current = true;
  //             return null;
  //         } else {
  //             // Otherwise, open the clicked dropdown
  //             return dropdownName;
  //         }
  //     });
  // };

  const handleOptionClick = (option) => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter((item) => item !== option));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  const handleEmailClick = () => {
    // Handle email button click
  };

  const handleDownloadClick = () => {
    // Handle download button click
  };

  // For Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // For calculation of DaysIn collumn
  const [daysDifference, setDaysDifference] = useState(0);

  // Handle change for the date input
  // const handleChangeins = (e) => {
  //   const selectedDate = e.target.value;
  // //   setInspectionDate(selectedDate);
  // //   setFormData((prevFormData) => ({
  // //     ...prevFormData,
  // //     inspection: selectedDate, // Assuming inspection date should be displayed
  // //   }));
  //   // Calculate the days difference and update state
  //   setDaysDifference(calculateDaysDifference(selectedDate));
  // };

  // Calculate the number of days difference
  const calculateDaysDifference = (inspectionDate) => {
    if (!inspectionDate) return 0;
    const currentDate = new Date();
    const inspectionDateObj = new Date(inspectionDate);
    const timeDifference = inspectionDateObj - currentDate;
    return Math.ceil(Math.abs(timeDifference) / (1000 * 60 * 60 * 24));
  };

  // For pdf geration
  const handlePrintClick = async () => {
    // const selected = selectedRows.map(index => tableData[index].vin);

    try {
      // Generate the XLSX file data
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.aoa_to_sheet(tableData);
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      const wbout = XLSX.write(wb, { type: "binary", bookType: "xlsx" });
      const blob = new Blob([s2ab(wbout)], {
        type: "application/octet-stream",
      });

      // Create a URL for the Blob
      const url = URL.createObjectURL(blob);

      // Open the XLSX file in a new window/tab
      const printWindow = window.open(url);

      // Wait for a short delay to ensure the XLSX file is loaded
      await new Promise((resolve) => setTimeout(resolve, 1000));

      // Print the content in the new window/tab
      if (printWindow) {
        printWindow.print();
      } else {
        throw new Error("Failed to open print window.");
      }
    } catch (error) {
      // Handle errors
      console.error("Error:", error);
      alert("Failed to print. Please try again.");
    }

    // try {
    //     const response = await fetch('/backend-endpoint', {
    //         method: 'POST',
    //         headers: {
    //             'Content-Type': 'application/json'
    //         },
    //         body: JSON.stringify({
    //             stock_no: stock_no,
    //             year_make_model: year_make_model,
    //             exteriorColor: exteriorColor,
    //             vehicle_type: vehicle_type,
    //             vin: vin,
    //             mileage_in: mileage_in,
    //             daysIn: daysIn,
    //             listing_price: listing_price,
    //             inspectionDate: inspectionDate,
    //             allLeads: allLeads
    //         })
    //     });

    //     if (response.ok) {
    //         // If response is okay, get the blob and open it in a new window
    //         const blob = await response.blob();
    //         const url = window.URL.createObjectURL(blob);
    //         window.open(url);
    //     } else {
    //         // If response is not okay, log error
    //         console.error('Failed to generate XLSX file');
    //     }
    // } catch (error) {
    //     // If fetch fails, log error
    //     console.error('Error:', error);
    // }
  };

  // For selection of cards
  const [selectedCards, setselectedCards] = useState([]);

  const handleSelected = (index) => {
    setselectedCards((prevselectedCards) => {
      if (prevselectedCards.includes(index)) {
        // If the card is already selected, remove it
        return prevselectedCards.filter((i) => i !== index);
      } else {
        // If the card is not selected, add it
        return [...prevselectedCards, index];
      }
    });
  };


  // single selection of card
  // const [selectedCard, setSelectedCard] = useState(null);
  // const handleSelected = (index) => {
  //   if (selectedCard === index) {
  //     setSelectedCard(null);
  //   } else {
  //     setSelectedCard(index);
  //   }
  // };


  // For selection of rows

  const handleRowClick = (index) => {
    // If the clicked row is already selected, deselect it
    if (selectedRows.includes(index)) {
      setSelectedRows((prevSelectedRows) =>
        prevSelectedRows.filter((selectedIndex) => selectedIndex !== index)
      );
    } else {
      // If the clicked row is not selected, select it
      setSelectedRows((prevSelectedRows) => [...prevSelectedRows, index]);
    }
  };

  // Function to handle export button click
  const tableRef = useRef(null);

  const handleExportClick = () => {
    // Show popup/modal/dialog to input email address
    // console.log("tableData", tableData)

    // setShowRowCheck(true);

    const email = prompt("Please enter your email address:");
    // setShowPrompt(true);

    if (email) {
      // Get the data of selected rows
      const selectedVin = selectedRows.map((index) => tableData[index].vin);

      // If user selected rows and entered an email address
      if (selectedVin.length > 0) {
        fetch(`${apiUrl}/active_inventory/sendExcelEmail`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: email, selectedVin: selectedVin }),
        })
          .then((response) => {
            if (response.ok) {
              // If request is successful, display success message
              alert("Email has been sent successfully!");
            } else {
              // If request fails, display error message
              alert("Failed to send email. Please try again.");
            }
          })
          .catch((error) => {
            // If fetch fails, display error message
            console.error("Error:", error);
            alert("An error occurred. Please try again later.");
          });
      } else {
        // If no rows are selected, prompt the user to select rows
        alert("Please select rows to export.");
      }

      console.log("selectedData", selectedVin);
    }
  };

  // const handleExportClick = () => {
  //     // Filter the data and include only specific fields for each object in tableData
  //     const filteredData = tableData.map(item => ({
  //         stock_no: item.stock_no,
  //         year: item.year,
  //         make: item.make,
  //         model: item.model,
  //         exteriorColor: item.exteriorColor,
  //         vehicle_type: item.vehicle_type,
  //         vin: item.vin,
  //         mileage_in: item.mileage_in,
  //         daysIn: item.daysIn,
  //         listing_price: item.listing_price,
  //         inspectionDate: item.inspectionDate,
  //         allLeads: item.allLeads
  //     }));

  //     // Convert filteredData to XLSX compatible format
  //     const headers = Object.keys(filteredData[0]);
  //     const dataForExport = [
  //         headers, // First row is headers
  //         ...filteredData.map(row => headers.map(header => String(row[header])))
  //     ];

  //     // Create a new workbook
  //     const wb = XLSX.utils.book_new();

  //     // Add the data to the worksheet
  //     const ws = XLSX.utils.aoa_to_sheet(dataForExport);
  //     XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

  //     // Generate a binary string from the workbook
  //     const wbout = XLSX.write(wb, { type: "binary", bookType: "xlsx" });

  //     // Convert the binary string to a Blob
  //     const blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });

  //     // Create a download link
  //     const url = URL.createObjectURL(blob);
  //     const a = document.createElement("a");
  //     a.href = url;
  //     a.download = "table_data.xlsx";

  //     // Trigger the download
  //     document.body.appendChild(a);
  //     a.click();
  //     document.body.removeChild(a);
  //     URL.revokeObjectURL(url);

  //     // Show notification
  //     setShowAlert(true);
  // };

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  };

  useEffect(() => {
    handleSort("newest");
  }, []);

  const handleSort = (order) => {
    const sorted = [...tableData].sort((a, b) => {
      const dateA = new Date(a.created_at);
      const dateB = new Date(b.created_at);
      return order === "oldest" ? dateA - dateB : dateB - dateA;
    });
    setSortedData(sorted);
  };

  const handleWebsite = (event) => {
    event.stopPropagation();
    alert("Added");
  };

  const handleEdit = (event) => {
    event.stopPropagation();
    alert("Edited");
  };

  const handleDelete = async (event, cardId, index) => {
    event.stopPropagation();

    // Display a confirmation dialog
    const userConfirmed = window.confirm(
      "Are you sure you want to delete this inventory item?"
    );

    if (!userConfirmed) {
      // If the user clicks "Cancel", exit the function
      return;
    }

    try {
      const listing_id = "listing";
      const response = await fetch(
        `${apiUrl}/listing/delete/${listing_id}/${id}/${cardId}`,
        {
          method: "DELETE",
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete the card");
      }

      // Update the state to reflect the deletion
      const newTableData = tableData.filter((_, i) => i !== index);
      setTableData(newTableData);
      setSortedData(newTableData);
    } catch (error) {
      console.error("Error deleting card:", error);
    }
  };

  const handleUpdate = (id, updatedData) => {
    console.log("updatedData>>>>>>>>>>>>>>>>>>>>>>>", updatedData);
    setSortedData((prevData) =>
      prevData.map((item) => (item.id === id ? { ...item, addvertising: updatedData } : item))
    );
    // setTableData((prevData) =>
    //   prevData.map((item) => (item.id === id ? { ...item, addvertising: updatedData } : item))
    // );
  };


  console.log("tableData", tableData);
  // console.log("sorsortedData", sortedData);

  return (
    <>
      <Container fluid className="px-4">

        <Row className="px-3">
          <Col sm={2} className="px-0 ">
            {/* Icon in the first column */}
            <div className="toggle-view">
              <img
                src={view == "c" ? Image.cardViewS : Image.cardView}
                alt="card-view"
                width="50%"
                height="100%"
                className="card-view"
                onClick={() => handleViewToggle("c")}
              />

              <img
                src={view == "l" ? Image.listViewS : Image.listView}
                alt="list-view"
                width="50%"
                height="100%"
                className="list-view"
                onClick={() => handleViewToggle("l")}
              />
            </div>
          </Col>

          <Col sm={8} className="px-0 d-flex justify-content-center">
            <div className="inventory-info">
              <span className="inventory">All Inventory:</span>
              <span className="inventory-count orange-color">{tableData.length}</span>
            </div>

            <div className="inventory-info">
              <span className="inventory">Published:</span>
              <span className="inventory-count orange-color">27</span>
            </div>

            <div className="inventory-info">
              <span className="inventory">Not Published:</span>
              <span className="inventory-count orange-color">34</span>
            </div>

            <div className="inventory-info">
              <span className="inventory">Total Leads:</span>
              <span className="inventory-count orange-color">17</span>
            </div>
          </Col>

          <Col className="d-flex justify-content-end align-items-center px-0">
            {view === "l" ? (
              <>
                {/* <Button
                  className="m-2 bg-btn rounded-3 fw-bold export"
                  onClick={handleExportClick}
                >
                  Export
                </Button>
                <Button
                  className="m-2 bg-btn rounded-3 fw-bold print"
                  onClick={handlePrintClick}
                >
                  Print
                </Button> */}
              </>
            ) : null}

            {/* First dropdown */}
            {/* <div className="">
              <button
                className="btn dropdown-toggle me-2 drp-btn page-count"
                type="button"
                onClick={() => toggleDropdown("page-count")}
              >
                10
              </button>
              {openDropdown === "page-count" && (
                <ul
                  className="page-count-list p-0"
                  ref={dropdownRef}
                  style={{ display: openDropdown ? "block" : "none" }}
                >
                  <li>
                    <Button className="dropdown-item">1</Button>
                  </li>
                  <li>
                    <Button className="dropdown-item">2</Button>
                  </li>
                </ul>
              )}
            </div> */}

            {/* Second dropdown */}
            {/* {view === "c" ? ( */}
            <div className="">
              <button
                className="btn dropdown-toggle drp-btn sort-by"
                type="button"
                onClick={() => toggleDropdown("sort-by")}
              >
                Sort by
              </button>
              {openDropdown === "sort-by" && (
                <ul className="sort-by-list p-0"
                  ref={dropdownRef}
                  aria-labelledby="dropdownMenuButton2"
                  style={{ display: openDropdown ? "block" : "none" }}
                >
                  <li
                    className="list-style-none dropdown-item"
                    onClick={() => handleSort("oldest")}
                  >
                    Oldest
                  </li>
                  <li
                    className=" list-style-none dropdown-item"
                    href="#"
                    onClick={() => handleSort("newest")}
                  >
                    Newest
                  </li>
                </ul>
              )}
            </div>
            {/* ) : null} */}
          </Col>
        </Row>

        {view == "l" ? (
          <>
            <Row>
              <Col sm={12} className="px-0" style={{ position: "relative" }}>
                <Container fluid className="px-3">
                  <Row>
                    {/* input field start */}
                    <Col sm={11} className="input-group mt-3 mb-4" id="act-search">
                      <Button
                        className="btn border-0 dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style={{ backgroundColor: "#e8a262" }}
                      >
                        All
                      </Button>
                      <ul className="dropdown-menu">
                        <li>
                          <Link className="dropdown-item" to="#">
                            Action
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="#">
                            Another action
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="#">
                            Something else here
                          </Link>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <Link className="dropdown-item" to="#">
                            Separated link
                          </Link>
                        </li>
                      </ul>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search Inventory"
                      />
                      <Button
                        className="btn border-0"
                        type="button"
                        id="button-addon1"
                        style={{ backgroundColor: "#e8a262" }}
                      >
                        <i className="fas fa-search"></i>
                      </Button>
                    </Col>
                    {/* input field end */}
                  </Row>
                </Container>
              </Col>
            </Row>

            <Row
              className="mx-0 rounded  bg-body border-2"
              style={{
                position: "relative",
                top: "",
                zIndex: "5 ",
                // minWidth: "1000px", overflowX: "scroll"
              }}
            >
              {/* <BasicDocument /> */}
              <ListView
                tableData={sortedData}
                currentPage={currentPage}
                tableRef={tableRef}
                selectedRows={selectedRows}
                handleRowClick={handleRowClick}
              />

              <Col sm={12}>
                <Pagination
                  onPageChange={handlePageChange}
                  currentPage={currentPage}
                />
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Row>
              <Col sm={1} className="h-100">
                {/* <div className="select-btn"></div> */}
              </Col>
              <Col sm={11} className="px-0" style={{ position: "relative" }}>
                <Container fluid className="px-3">
                  <Row>
                    {/* input field start */}
                    <Col sm={12} className="input-group mt-3 mb-4" id="act-search">
                      <Button
                        className="btn border-0 dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style={{ backgroundColor: "#e8a262" }}
                      >
                        All
                      </Button>
                      <ul className="dropdown-menu">
                        <li>
                          <Link className="dropdown-item" to="#">
                            Action
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="#">
                            Another action
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="#">
                            Something else here
                          </Link>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <Link className="dropdown-item" to="#">
                            Separated link
                          </Link>
                        </li>
                      </ul>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search Inventory"
                      />
                      <Button
                        className="btn border-0"
                        type="button"
                        id="button-addon1"
                        style={{ backgroundColor: "#e8a262" }}
                      >
                        <i className="fas fa-search"></i>
                      </Button>
                    </Col>
                    {/* input field end */}
                  </Row>
                </Container>
              </Col>
            </Row>

            <Row
              className="mx-0 rounded bg-body border-2 bg-tr">
              {sortedData.map((data, index) => {
                return (
                  <CardView
                    data={{
                      ...data,
                      daysDifference: calculateDaysDifference(data.inspection),
                    }}
                    index={index}
                    key={index}
                    selectedCards={selectedCards}
                    handleSelected={handleSelected}
                    handleWebsite={handleWebsite}
                    handleEdit={handleEdit}
                    handleDelete={handleDelete}
                    onUpdate={handleUpdate}
                  />
                );
              })}
            </Row>
          </>
        )}
      </Container>
    </>
  );
};


// const CardView = ({
//   data,
//   index,
//   selectedCards,
//   handleSelected,
//   handleWebsite,
//   handleEdit,
//   handleDelete,

// }) => {
//   const navigate = useNavigate();

//   const location = useLocation();
//   // Extract data and originalData from location state
//   const originalData = location.state?.originalData || {};
//   const updatedData = location.state?.updatedData || {};

//   console.log("location", location);
//   console.log("updatedData", updatedData);
//   console.log("originalData", originalData);

//   // Function to check if a field has been updated
//   const isFieldUpdated = (fieldName) => {
//     if (data.vin === updatedData.vin) {
//       return originalData[fieldName] !== updatedData[fieldName];
//     }
//   };

//   const handleLinkClick = (vin) => {
//     // navigate("/myprofile/addlist", { state: { vin, active: true } });
//     navigate("/myprofile/addlist", { state: { vin, data, active: true } });
//   };

//   const Navigate = useNavigate();

//   const handleGoToSell = () => {
//     // Make sure that 'data' is correctly defined and holds the necessary information
//     Navigate("/myprofile/sell", { state: { data } });
//   };

//   // const handleSelected = (newCardIndex) => {
//   //     // setselectedCards(newCardIndex ? newCardIndex : null);

//   //     if (selectedCards.includes(newCardIndex)) {
//   //         //   If the card is already selected, deselect it
//   //         setselectedCards(prevSelected => prevSelected.filter(cardIndex => cardIndex !== newCardIndex));
//   //     } else {
//   //         //   If the card is not selected, select it
//   //         setselectedCards(prevSelected => [...prevSelected, newCardIndex]);
//   //     }
//   // };

//   // For Social Icons

//   const [socialIcons, setSocialIcons] = useState([]);

//   useEffect(() => {
//     if (data && data.addvertising) {
//       const newSocialIcons = [];
//       const advertisingData = data.addvertising;

//       // Iterate over the keys of the advertisingData object
//       for (const key in advertisingData) {
//         // Check if the key ends with '_status' and its value is true
//         if (key.endsWith("_status") && advertisingData[key]) {
//           // Extract the social profile name from the key
//           const profile = key.split("_status")[0];
//           // Push the profile name to the newSocialIcons array
//           newSocialIcons.push(profile);
//         }
//       }
//       // Set the socialIcons state with the newSocialIcons array
//       setSocialIcons(newSocialIcons);
//     }
//   }, [data]);

//   // console.log(data);
//   // console.log("socialIcons", socialIcons);
//   // console.log("selectedCards", selectedCards);

//   const colorMapping = {
//     Beige: "#c6bdb8",
//     Black: "#151517",
//     Blue: "#2c447b",
//     Bronze: "#655e5f",
//     Brown: "#544d48",
//     "Dark Gray": "#a6b0b6",
//     "Dark Red": "#802630",
//     Gray: "#949494",
//     "Light Gray": "#c6ccd4",
//     Magenta: "#916075",
//     Maroon: "#8e373e",
//     "Off White": "#f4ebdd",
//     Orange: "#f79057",
//     Pink: "#d60f6f",
//     Purple: "#6316bc",
//     Red: "#de0a06",
//     Silver: "#d4d4d4",
//     Tan: "#c3793f",
//     White: "#ffffff",
//     Yellow: "#f5d512",
//     Burgundy: '#800020',
//     Charcoal: '#36454F',
//     Gold: '#FFD700',
//     Green: '#008000',
//   };

//   // Assuming you receive color names from the backend
//   // const exteriorColorName = "Blue";
//   // const interiorColorName = "Beige";

//   // Set the background color dynamically based on the received color names
//   const exteriorBackgroundColor = colorMapping[data.exteriorColor];
//   const interiorBackgroundColor = colorMapping[data.interiorColor];

//   // const itemsPerPage = 5; // Change this value according to your preference
//   // const totalItems = tableData.length;
//   // // const totalItems = 7;
//   // const totalPages = Math.ceil(totalItems / itemsPerPage);

//   const { all_images, exterior_images } = data.multimedia;
//   const noImage = Image.noImage;

//   const getFirstValidImage = (images) => {
//     if (images) {
//       const imageList = images.split(",").map((img) => img.trim());
//       for (let image of imageList) {
//         if (image) {
//           return `https://dacbuckets3.s3.amazonaws.com/${image}`;
//         }
//       }
//     }
//     return null;
//   };

//   const imageUrl =
//     (all_images && getFirstValidImage(all_images)) ||
//     getFirstValidImage(exterior_images) ||
//     noImage;

//   return (
//     <>
//       <Container className="px-0 bg-tr1">
//         <Row
//           className="mx-0 rounded bg-body border-2 bg-tr1"
//           style={{ position: "relative", zIndex: "5 " }}
//           onClick={() => {
//             handleSelected(index + 1);
//           }}
//         >
//           {/* <div className="" style={{position:"relative"}}> */}
//           <Col sm={1} className="selection-box px-0" key={index}>
//             <div className="card-count p-auto">{index + 1}</div>
//             <div className="selection-btn">
//               <img
//                 src={selectedCards && selectedCards.includes(index + 1) ? Image.selected : Image.select}
//                 alt={selectedCards && selectedCards.includes(index + 1) ? "selected" : "select"}
//                 className="select-img"
//               />
//               {/* {selectedCards.includes(index) ? "S" : "N"} */}
//             </div>
//           </Col>
//           <Col sm={11} className="px-2 cards bg-tr1">
//             <Container fluid>
//               <Row
//                 className={`rounded border border-2 dorder-danger p-2 mb-4 ${selectedCards && selectedCards.includes(index + 1) ? "bg-card" : ""
//                   }`}>
//                 <Col lg={3} md={5} className="shadow px-2">
//                   <div className="product-actives">
//                     <img src={imageUrl} className="" alt="Inventory Car Image" />
//                     <div className="d-flex flex-row justify-content-between mt-2 align-items-center">
//                       <span className="add-date" style={{ color: "#D1823A" }}>
//                         Added: {data.created_at}
//                       </span>

//                       <span
//                         className="Exteriors rounded-pill"
//                         style={{
//                           backgroundColor: exteriorBackgroundColor
//                         }}
//                       >
//                         Exterior
//                       </span>
//                       <span
//                         className="Interiors rounded-pill"
//                         style={{ backgroundColor: interiorBackgroundColor }}
//                       >
//                         Interior
//                       </span>
//                     </div>
//                   </div>

//                   {/* <Container className='px-0'>
//                             <Row >
//                                 <Col sm={12} className='px-0'>
//                                     <img src="https://images.saymedia-content.com/.image/ar_16:9%2Cc_fill%2Ccs_srgb%2Cfl_progressive%2Cq_auto:eco%2Cw_1200/MjAxNzE0OTk4NTA5MDUzNzE2/how-to-buy-cars-cheaply-and-sell-them-at-Link-profit.jpg" className="w-100" alt="..." />
//                                 </Col>
//                                 <Col sm={12} className='p-1'>
//                                     <Container className='px-0'>
//                                         <Row>
//                                             <Col sm={12} md={6} className='sold-date '>
//                                                 <div style={{ color: "#e8a262", textWrap: "nowrap" }}>Sold on</div> 10/03/2022
//                                             </Col>
//                                             <Col sm={12} md={3}  className=' Exteriords'>
//                                                 <div className='rounded-circle' style={{ backgroundColor: "red" }}></div>
//                                             </Col>
//                                             <Col sm={12} md={3}  className=' Interiords'>
//                                                 <div className='rounded-circle' style={{ backgroundColor: "blue" }}></div>
//                                             </Col>
//                                         </Row>
//                                     </Container>
//                                 </Col>
//                             </Row>
//                         </Container> */}
//                 </Col>

//                 <Col lg={7} md={7}>
//                   <Container fluid className="px-0">
//                     <Row>
//                       <Col sm={12} className="px-0">
//                         <Container>
//                           <Row className="d-flex justify-content-between">
//                             {/* <Col sm={10} className='card-head'>{data.year} {data.make} {data.model} {data.vehicle_type}</Col> */}
//                             <Col
//                               sm={10}
//                               className="card-head"
//                               onClick={() => handleLinkClick(data.vin)}
//                               style={{
//                                 textShadow: isFieldUpdated("trim") ? '#ff8c21 1px 0 10px' : 'black',
//                                 // color: isFieldUpdated("trim") ? "orange" : "black",
//                               }}
//                             >
//                               {data.year_Make_Model} {data.trim}
//                               {/* {data.year} {data.make} {data.madel} {data.trims} */}
//                               {/* {data.year_make_model && data.year_make_model.trim().split(/\s+/).slice(0, 3).join(' ')} */}
//                             </Col>
//                           </Row>
//                         </Container>
//                       </Col>
//                       <Col sm={12} className="px-0">
//                         <Container>
//                           <Row className="sub-head data-font d-flex justify-content-between">
//                             <Col
//                               md={4}
//                               className="text-nowrap mx-2 px-1 vin fw-bold"
//                               onClick={() => handleLinkClick(data.vin)}
//                             >
//                               <span className="orange-color ">VIN:</span>
//                               {data.vin}{" "}
//                             </Col>
//                             <Col className="px-0 ">
//                               <Container>
//                                 <Row className=" ">
//                                   {data.mileage_in ?
//                                     <Col sm={3} className=" text-nowrap fw-bold gray-color border-2 px-2"
//                                       style={{
//                                         textShadow: isFieldUpdated("mileage_in") ? '#ff8c21 1px 0 10px' : 'black',
//                                         color: isFieldUpdated("mileage_in")
//                                           ? "orange"
//                                           : "black",
//                                       }}>
//                                       {data.mileage_in}
//                                     </Col>
//                                     : null
//                                   }

//                                   {/* {data.drive_train
//                                     ? <Col className=" border-2 px-0">
//                                       {data.drive_train}
//                                     </Col>
//                                     : null
//                                   } */}

//                                   {data.engine
//                                     ? <Col className=" border-2 px-0"
//                                       style={{
//                                         textShadow: isFieldUpdated("engine") ? '#ff8c21 1px 0 10px' : 'black',
//                                         // color: isFieldUpdated("trim") ? "orange" : "black",
//                                       }}>
//                                       {data.engine}
//                                     </Col>
//                                     : null
//                                   }

//                                   {/* <Col className=" border-2 px-0">
//                                     {data.engine_size}
//                                   </Col>
//                                   <Col className=" border-2 px-0">
//                                     {data.engine_cylinders}
//                                   </Col>
//                                   <Col className=" border-2 px-0">
//                                     {data.fuel_type}
//                                   </Col> */}
//                                   {/* <Col>{data.transmission}</Col> */}

//                                 </Row>
//                               </Container>
//                             </Col>
//                           </Row>
//                         </Container>
//                       </Col>
//                       <Col sm={12} className="px-0">
//                         <Container fluid>
//                           <Row>
//                             <Col>
//                               <Container fluid>
//                                 <Row className="text-center data-font">
//                                   <Col className="border-end border-2 px-0 "
//                                     style={{
//                                       textShadow: isFieldUpdated("purchasedDate") ? '#ff8c21 1px 0 10px' : 'black',
//                                       // color: isFieldUpdated("trim") ? "orange" : "black",
//                                     }}>
//                                     P.Date{" "}
//                                     <p className="orange-color">
//                                       {data.purchasedDate}
//                                     </p>
//                                   </Col>
//                                   <Col className="border-end border-2 px-0"
//                                     style={{
//                                       textShadow: isFieldUpdated("inspection") ? '#ff8c21 1px 0 10px' : 'black',
//                                       // color: isFieldUpdated("trim") ? "orange" : "black",
//                                     }}>
//                                     Days in{" "}
//                                     <p className="orange-color">
//                                       {data.daysDifference}
//                                     </p>
//                                   </Col>
//                                   <Col className="border-end border-2 px-0"
//                                     style={{
//                                       textShadow: isFieldUpdated("listing_price") ? '#ff8c21 1px 0 10px' : 'black',
//                                       // color: isFieldUpdated("trim") ? "orange" : "black",
//                                     }}>
//                                     Listing price
//                                     <p className="orange-color">
//                                       ${data.listing_price}
//                                     </p>
//                                   </Col>
//                                   <Col className="border-end border-2 px-0"
//                                     style={{
//                                       textShadow: isFieldUpdated("market_value_price") ? '#ff8c21 1px 0 10px' : 'black',
//                                       // color: isFieldUpdated("trim") ? "orange" : "black",
//                                     }}>
//                                     Market value{" "}
//                                     <p className="orange-color">
//                                       ${data.market_value_price}
//                                     </p>
//                                   </Col>
//                                   <Col className="border-end border-2 px-0">
//                                     All leaads{" "}
//                                     <p className="orange-color">23</p>
//                                   </Col>
//                                   <Col className="border-end border-2 px-0"
//                                     style={{
//                                       textShadow: isFieldUpdated("inspection") ? '#ff8c21 1px 0 10px' : 'black',
//                                       // color: isFieldUpdated("trim") ? "orange" : "black",
//                                     }}>
//                                     Inspection{" "}
//                                     <p className="orange-color">
//                                       {data.inspection}
//                                     </p>
//                                   </Col>
//                                 </Row>
//                               </Container>
//                             </Col>
//                           </Row>
//                         </Container>
//                       </Col>
//                     </Row>
//                   </Container>
//                 </Col>

//                 <Col lg={2} md={12}>
//                   <Container fluid className="px-0 h-100">
//                     <div className="row d-flex flex-nowrap h-100">
//                       <div className="col-9 px-1">
//                         <h6 className="data-font text-nowrap stock-no"
//                           onClick={() => handleLinkClick(data.vin)}>
//                           <span className="orange-color">
//                             Stock. no#
//                           </span>
//                           {data.stock_no}
//                         </h6>
//                         <div className="d-flex flex-column justify-content-around">
//                           <Button
//                             className="product-g-btns fw-bold py-2 px-1"
//                             onClick={handleWebsite}
//                           >
//                             Add to website
//                           </Button>
//                         </div>
//                         <div className="d-flex justify-content-evenly flex-wrap">
//                           {socialIcons.map((iconName, index) => (
//                             <img
//                               key={index}
//                               className="m-2 social-icon"
//                               src={Image[iconName]} // Corrected
//                               alt={iconName}
//                               width="25px"
//                             />
//                           ))}
//                         </div>
//                       </div>

//                       <div className="col-3 h-100 px-0 d-flex flex-column justify-content-between">
//                         <div
//                           className="d-flex align-items-center justify-content-around"
//                           style={{ height: "70%" }}
//                         >
//                           <div className="vr pr-2"></div>
//                           <div className="d-flex flex-column align-items-center ">
//                             <div
//                               className=" ml-3 rounded-circle px-2 py-1 bg-light edit"
//                               onClick={() => handleLinkClick(data.vin)}
//                             >
//                               <MdModeEdit />
//                             </div>
//                             <div className=" ml-3 rounded-circle px-2 py-1 bg-light edit"
//                               onClick={(event) =>
//                                 handleDelete(event, data.id, index)
//                               }>
//                               <MdDelete />
//                             </div>

//                           </div>
//                         </div>
//                         <Button class='bg-orange p-1 border-0 fw-bold text-center rounded text-light' onClick={handleGoToSell}>sell</Button>
//                         {/* <Link to={{ pathname: `/myprofile/sell`, state: { data } }} class='bg-orange text-center rounded text-light'>Sell</Link> */}
//                       </div>
//                     </div>
//                   </Container>
//                 </Col>
//               </Row>
//             </Container>
//           </Col>

//           {/* </div> */}
//         </Row>
//       </Container>
//     </>
//   );
// };

// Define the calculateDaysDifference function outside of ListView

const calculateDaysDifference = (inspectionDate) => {
  if (!inspectionDate) return 0;
  const currentDate = new Date();
  const inspectionDateObj = new Date(inspectionDate);
  const timeDifference = inspectionDateObj - currentDate;
  return Math.ceil(Math.abs(timeDifference) / (1000 * 60 * 60 * 24));
};

const ListView = ({
  tableData,
  currentPage,
  tableRef,
  selectedRows,
  handleRowClick,
}) => {
  const Navigate = useNavigate();

  const location = useLocation();
  // Extract data and originalData from location state
  const originalData = location.state?.originalData || {};
  const updatedData = location.state?.updatedData || {};

  const isFieldUpdated = (data, fieldName) => {
    if (data.vin === updatedData.vin) {
      return originalData[fieldName] !== updatedData[fieldName];
    }
  };

  const handlePublish = (id) => {
    // Handle publish event
    console.log(`Publishing item with id: ${id}`);
  };

  const handleLinkClick = (vin, data) => {
    // Navigate to AddList component programmatically with state
    Navigate("/myprofile/addlist", { state: { vin, data, active: true } });
    // Navigate('/myprofile/addlist', { state: vin, });
  };

  const itemsPerPage = 10; // Change this value according to your preference
  const totalItems = tableData.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  // Calculate current page items
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = tableData.slice(indexOfFirstItem, indexOfLastItem);

  // Add empty rows if necessary
  const emptyRowsCount = Math.max(itemsPerPage - currentItems.length, 0);
  const emptyRows = Array.from({ length: emptyRowsCount }).map((_, index) => (
    <tr key={`empty-${index}`}>
      {[...Array(14)].map((_, i) => (
        <td key={i}>&nbsp;</td>
      ))}
    </tr>
  ));

  const renderTableRow = (item, index) => {
    if (!item.year_make_model) {
      return null;
    }
    const year_make_model = item.year_make_model;
    const [year, make, model, series, bodyClass] = year_make_model.split(" ");

    // Calculate days difference for the inspection date
    const daysDifference = calculateDaysDifference(item.inspection);

    return (
      <tr
        key={index}
        className={selectedRows.includes(index) ? "selected-rows" : ""}
        onClick={() => item && handleRowClick(index)}
      >
        {item ? (
          <>
            <td>{index + 1}</td>
            <td
              className="ac-col fw-bold"
              onClick={() => handleLinkClick(item.vin, item)}
            >
              {item.stock_no}
            </td>
            <td>{item.year}</td>
            <td>{item.make}</td>
            <td>{item.model}</td>
            <td style={{ textShadow: isFieldUpdated(item, "exteriorColor") ? '#ff8c21 1px 0 10px' : 'black' }}>
              {item.exteriorColor}
            </td>
            <td>{item.bodyClass}</td>
            {/* <td
              className="vin-column fw-bold"
              onClick={() => handleLinkClick(item.vin, item)}
            >
              {item.vin}
            </td> */}
            <td style={{ textShadow: isFieldUpdated(item, "mileage_in") ? '#ff8c21 1px 0 10px' : 'black' }}>{item.mileage_in}</td>

            <OverlayTrigger
              placement="bottom-start"
              overlay={
                <Tooltip id={`tooltip-${index}`} className="custom-tooltip">
                  Purchased Date: <br />
                  {item.purchasedDate}
                </Tooltip>
              }
            >
              <td style={{ textShadow: isFieldUpdated(item, "inspection") ? '#ff8c21 1px 0 10px' : 'black' }}>{daysDifference}</td>
            </OverlayTrigger>

            {/* <td>{daysDifference}</td>  */}
            <td style={{ textShadow: isFieldUpdated(item, "listing_price") ? '#ff8c21 1px 0 10px' : 'black' }}>$ {item.listing_price}</td>
            <td className="border-0 mb-0"
              style={{ textShadow: isFieldUpdated(item, "inspection") ? '#ff8c21 1px 0 10px' : 'black' }}>
              {item.inspection}{" "}
              <span className="border-0 px-1"> {daysDifference}</span>
            </td>
            <td>{item.allLeads}</td>
            <td>
              <Button
                onClick={() => handlePublish(item.id)}
                className="publish-btn border-0"
              >
                Publish
              </Button>
            </td>
          </>
        ) : (
          Array.from({ length: 14 }).map((_, i) => <td key={i}>&nbsp;</td>)
        )}
      </tr>
    );
  };

  // Calculate total mileage
  const totalMileage = tableData.reduce((total, item) => total + item.mileage_in, 0);

  // Calculate total mileage
  const listingPrice = tableData.reduce((total, price) => total + price.listing_price, 0);

  // Calculate total days in
  const totalDaysIn = tableData.reduce((total, item) => total + calculateDaysDifference(item.inspection), 0);

  // Calculate average days in
  const averageDaysIn = totalDaysIn / (tableData.length || 1);  // Prevent division by zero


  return (
    <div className="table-wrapper rounded px-0 table-scrollbar">
      <Table
        striped
        bordered
        hover
        ref={tableRef}
        className="shadow mb-0"
        id="table"
      >
        <thead>
          <tr>
            <th>No#</th>
            <th className="ac-col">Stock. No#</th>
            <th>Year</th>
            <th>Make</th>
            <th>Model</th>
            <th>Color</th>
            <th>Body Class</th>
            {/* <th className="vin-column">VIN</th> */}
            <th>Miles</th>
            <th>Days In</th>
            <th>Listing Price</th>
            <th>
              Inspection <span className="border-0 m-2"> D </span>{" "}
            </th>
            <th>All Leads</th>
            <th>Website</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map(renderTableRow)}
          {emptyRows}
        </tbody>
        <tfoot>
          <tr style={{ backgroundColor: "orange" }}>
            <td colSpan={8}>All Inventory: {tableData.length}</td>
            <td>{totalMileage / tableData.length || 1}</td>
            <td>{averageDaysIn}</td>
            <td>$ {listingPrice / tableData.length || 1}</td>
            <td></td>
            <td>6575</td>
            <td></td>
          </tr>
        </tfoot>
      </Table>
    </div>
  );
};

export default ActiveInventory;




{/* <Row className="py-3">
          <Col className="px-0">
            <Container className="">
              <Row className="my-3 fw-bolder">
                <Col md={2} className="my-2 upper-card">
                  <div className="res">
                    <p className="text-center pt-2 fw-bold">All Inventory</p>
                    <div className="hr"></div>
                    <p className="text-center fw-bold pt-3 font-italic">30</p>
                  </div>
                </Col>
                <Col md={2} className="my-2 upper-card">
                  <div className="res">
                    <p className="text-center pt-2 fw-bold">
                      All Published Inventory
                    </p>
                    <div className="hr"></div>
                    <p className="text-center fw-bold pt-3 font-italic">30</p>
                  </div>
                </Col>
                <Col md={2} className="my-2 upper-card">
                  <div className="res">
                    <p className="text-center pt-2 fw-bold">
                      Total not Published
                    </p>
                    <div className="hr"></div>
                    <p className="text-center fw-bold pt-3 font-italic">30</p>
                  </div>
                </Col>
                <Col md={2} className="my-2 upper-card">
                  <div className="res">
                    <p className="text-center pt-2 fw-bold">Active on google</p>
                    <div className="hr"></div>
                    <p className="text-center fw-bold pt-3 font-italic">30</p>
                  </div>
                </Col>
                <Col md={2} className="my-2 upper-card">
                  <div className="res">
                    <p className="text-center pt-2 fw-bold">
                      Active on facebook market place
                    </p>
                    <div className="hr"></div>
                    <p className="text-center fw-bold pt-3 font-italic">30</p>
                  </div>
                </Col>
                <Col md={2} className="my-2 upper-card">
                  <div className="res">
                    <p className="text-center pt-2 fw-bold">leads</p>
                    <div className="hr"></div>
                    <p className="text-center fw-bold pt-3 font-italic">30</p>
                  </div>
                </Col>
              </Row>
            </Container>
          </Col>
          {/* {view === 'c'
                        ? <Col md={2} className='px-0'>
                            <Container className="">
                                <Row className=' mt-3 my-1 fw-bolder d-flex justify-content-between'>
                                    <Col sm={9} className=''>Selected: </Col>
                                    <Col sm={3} className='px-2 b-half orange-color text-center'>4
                                       
                                    </Col>
                                </Row>
                                <Row className='s-font px-0 custom-scrollbar' style={{ maxHeight: '145px', overflowY: 'auto' }}>
                                    <Col sm={12} className='b-half mt-2 d-flex justify-content-between'>2019 Nissan Sedan
                                        <span className='px-2 orange-color'>54D45F </span>
                                    </Col>                                   
                                    <Col sm={12} className='b-half mt-2 d-flex justify-content-between'>2019 Nissan Sedan
                                        <span className='px-2 orange-color'>54D45F </span>
                                    </Col>
                                    <Col sm={12} className='b-half mt-2 d-flex justify-content-between'>2019 Nissan Sedan
                                        <span className='px-2 orange-color'>54D45F </span>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                        : null} 
        </Row> 

        {/*<Row
          className="shadow bg-body py-2 rounded-3 mx-1"
          style={{ border: "1px solid #d1823a", borderRadius: "5px" }}>
         <Col className="btn-grp">
            {selectedRows.length > 1 && view === "l" ? (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="button-tooltip" className="custom-tooltip">
                    You can update only one row
                  </Tooltip>
                }
              >
                <span className="d-inline-block w-100">
                  <Button
                    type="button"
                    disabled
                    className={`btn w-100 border-0 my-2 text-light fw-bold ${selectedRows.length > 1 ? "bg-secondary" : "bg-orange"
                      }`}
                  >
                    Add Inventory
                  </Button>
                </span>
              </OverlayTrigger>
            ) : (
              <Button
                type="button"
                className="btn w-100 border-0 my-2 text-light fw-bold bg-orange"
              >
                Add Inventory
              </Button>
            )}
          </Col>
          <Col className="btn-grp">
            {selectedRows.length > 1 && view === "l" ? (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="button-tooltip" className="custom-tooltip">
                    You can update only one row
                  </Tooltip>
                }
              >
                <span className="d-inline-block w-100">
                  <Button
                    type="button"
                    disabled
                    className={`btn w-100 border-0 my-2 text-light fw-bold ${selectedRows.length > 1 ? "bg-secondary" : "bg-orange"
                      }`}
                  >
                    Sell Inventory
                  </Button>
                </span>
              </OverlayTrigger>
            ) : (
              <Button
                type="button"
                className="btn w-100 border-0 my-2 text-light fw-bold bg-orange"
              >
                Sell Inventory
              </Button>
            )}
          </Col>
          <Col className="btn-grp">
            {selectedRows.length > 1 && view === "l" ? (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="button-tooltip" className="custom-tooltip">
                    You can update only one row
                  </Tooltip>
                }
              >
                <span className="d-inline-block w-100">
                  <Button
                    type="button"
                    disabled
                    className={`btn w-100 border-0 my-2 text-light fw-bold ${selectedRows.length > 1 ? "bg-secondary" : "bg-orange"
                      }`}
                  >
                    Add Cost $
                  </Button>
                </span>
              </OverlayTrigger>
            ) : (
              <Button
                type="button"
                className="btn w-100 border-0 my-2 text-light fw-bold bg-orange"
              >
                Add Cost $
              </Button>
            )}
          </Col>
          <Col className="btn-grp">
            {selectedRows.length > 1 || selectedCards.length > 1 ? (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="button-tooltip" className="custom-tooltip">
                    You can update only one row
                  </Tooltip>
                }
              >
                <span className="d-inline-block w-100">
                  <Button
                    type="button"
                    disabled
                    className={`btn w-100 border-0 my-2 text-light fw-bold ${selectedRows.length > 1 || selectedCards.length > 1 ? "bg-secondary" : "bg-orange"
                      }`}
                  >
                    VHR
                  </Button>
                </span>
              </OverlayTrigger>
            ) : (
              <Button
                type="button"
                className="btn w-100 border-0 my-2 text-light fw-bold bg-orange"
              >
                VHR
              </Button>
            )}
          </Col>

          <Col className="btn-grp">
            {selectedRows.length > 1 || selectedCards.length > 1 ? (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="button-tooltip" className="custom-tooltip">
                    You can update only one row
                  </Tooltip>
                }>
                <span className="d-inline-block w-100">
                  <Button
                    type="button"
                    disabled
                    className={`btn w-100 border-0 my-2 text-light fw-bold ${selectedRows.length > 1 || selectedCards.length > 1 ? "bg-secondary" : "bg-orange"} `}
                  >
                    Ads. Manager
                  </Button>
                </span>
              </OverlayTrigger>
            ) : (
              <Button
                type="button"
                className="btn w-100 border-0 my-2 text-light fw-bold"
                style={{ backgroundColor: "#e8a262" }}
              >
                Ads. Manager
              </Button>
            )}
          </Col>

          <Col className="btn-grp">
            {selectedRows.length > 1 || selectedCards.length > 1 ? (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="button-tooltip" className="custom-tooltip">
                    You can update only one row
                  </Tooltip>
                }>
                <div className="docs-dropdown">
                  <Button
                    type="button"
                    disabled
                    className={`btn w-100 border-0 my-2 text-light fw-bold ${selectedRows.length > 1 || selectedCards.length > 1 ? "bg-secondary" : "bg-orange"} `}
                    onClick={() => toggleDropdown("docs-dropdown")}
                  >
                    Docs
                  </Button>
                   {openDropdown === "docs-dropdown" && (
                    <div
                      className="docs-list"
                      ref={dropdownRef}
                      style={{ display: openDropdown ? "block" : "none" }}
                    >
                   
                      <label>
                        <input
                          type="checkbox"
                          checked={selectedOptions.includes("Option 1")}
                          onChange={() => handleOptionClick("Option 1")}
                        />
                        All Documents
                      </label>
                      <label>
                        <input
                          type="checkbox"
                          checked={selectedOptions.includes("Option 2")}
                          onChange={() => handleOptionClick("Option 2")}
                        />
                        Contract Documents
                      </label>
                    
                      <div className="border-top border-2 d-flex justify-content-around">
                        <Button
                          type="button"
                          className="rounded-pill"
                          onClick={handleEmailClick}
                        >
                          Email
                        </Button>
                        <Button
                          type="button"
                          className="rounded-pill"
                          onClick={handleDownloadClick}
                        >
                          Download
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              </OverlayTrigger>
            ) : (
              <div className="docs-dropdown">
                <Button
                  type="button"
                  className="btn w-100 border-0 my-2 text-light fw-bold"
                  style={{ backgroundColor: "#e8a262" }}
                  onClick={() => toggleDropdown("docs-dropdown")}
                >
                  Docs
                </Button>
                {openDropdown === "docs-dropdown" && (
                  <div
                    className="docs-list"
                    ref={dropdownRef}
                    style={{ display: openDropdown ? "block" : "none" }}
                  >
                   
                    <label>
                      <input
                        type="checkbox"
                        checked={selectedOptions.includes("Option 1")}
                        onChange={() => handleOptionClick("Option 1")}
                      />
                      All Documents
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        checked={selectedOptions.includes("Option 2")}
                        onChange={() => handleOptionClick("Option 2")}
                      />
                      Contract Documents
                    </label>
                     
                    <div className="border-top border-2 d-flex justify-content-around">
                      <Button
                        type="button"
                        className="rounded-pill"
                        onClick={handleEmailClick}
                      >
                        Email
                      </Button>
                      <Button
                        type="button"
                        className="rounded-pill"
                        onClick={handleDownloadClick}
                      >
                        Download
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            )}
          </Col>

          <Col className="btn-grp">
            {selectedRows.length > 1 || selectedCards.length > 1 ? (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="button-tooltip" className="custom-tooltip">
                    You can update only one row
                  </Tooltip>
                }>
                <div className="cost-dropdown">
                  <Button
                    type="button"
                    disabled
                    className={`btn w-100 border-0 my-2 text-light fw-bold ${selectedRows.length > 1 || selectedCards.length > 1 ? "bg-secondary" : "bg-orange"} `}
                    onClick={() => toggleDropdown("cost-dropdown")}>
                    cost Info
                  </Button>
                 {openDropdown === "cost-dropdown" && (
                    <div
                      className="cost-list "
                      ref={dropdownRef}
                      style={{ display: openDropdown ? "block" : "none" }}
                    >
                      <div
                        className="dropdown-option "
                        onClick={() => handleOptionClick("Option 2")}
                      >
                        <span className="option-title">Option 2</span>
                        <FaEye className="eye-icon" />
                        <span className="cost">$20</span>
                      </div>
                      <div
                        className="dropdown-option "
                        onClick={() => handleOptionClick("Option 1")}
                      >
                        <span className="option-title">Option 1</span>
                        <FaEye className="eye-icon" />
                        <span className="cost">$10</span>
                      </div>
                      <div
                        className="dropdown-option "
                        onClick={() => handleOptionClick("Option 2")}
                      >
                        <span className="option-title">Option 2</span>
                        <FaEye className="eye-icon" />
                        <span className="cost">$20</span>
                      </div>
                      <div
                        className="dropdown-option "
                        onClick={() => handleOptionClick("Option 1")}
                      >
                        <span className="option-title">Option 1</span>
                        <FaEye className="eye-icon" />
                        <span className="cost">$10</span>
                      </div>
                      <div
                        className="dropdown-option"
                        onClick={() => handleOptionClick("Option 2")}>
                        <span className="option-title">Option 2</span>                   
                        <span className="cost">Received</span>
                      </div>
                    </div>
                  )}
                </div>
              </OverlayTrigger>
            ) : (
              <div className="cost-dropdown">
                <Button
                  type="button"
                  className="btn w-100 border-0 my-2 text-light fw-bold"
                  onClick={() => toggleDropdown("cost-dropdown")}
                  style={{ backgroundColor: "#e8a262" }}>
                  cost Info
                </Button>
                {openDropdown === "cost-dropdown" && (
                  <div
                    className="cost-list "
                    ref={dropdownRef}
                    style={{ display: openDropdown ? "block" : "none" }}
                  >
                    {/* Dropdown options 

                    <div
                      className="dropdown-option "
                      onClick={() => handleOptionClick("Option 2")}
                    >
                      <span className="option-title">Option 2</span>
                      <FaEye className="eye-icon" />
                      <span className="cost">$20</span>
                    </div>
                    <div
                      className="dropdown-option "
                      onClick={() => handleOptionClick("Option 1")}
                    >
                      <span className="option-title">Option 1</span>
                      <FaEye className="eye-icon" />
                      <span className="cost">$10</span>
                    </div>
                    <div
                      className="dropdown-option "
                      onClick={() => handleOptionClick("Option 2")}
                    >
                      <span className="option-title">Option 2</span>
                      <FaEye className="eye-icon" />
                      <span className="cost">$20</span>
                    </div>
                    <div
                      className="dropdown-option "
                      onClick={() => handleOptionClick("Option 1")}
                    >
                      <span className="option-title">Option 1</span>
                      <FaEye className="eye-icon" />
                      <span className="cost">$10</span>
                    </div>
                    <div
                      className="dropdown-option"
                      onClick={() => handleOptionClick("Option 2")}
                    >
                      <span className="option-title">Option 2</span>
                      {/* <FaEye className="eye-icon" />
                      <span className="cost">Received</span>
                    </div>
                  </div>
                )}
              </div>
            )}
          </Col>

          <Col className="btn-grp">
            <Button
              type="button"
              className="btn w-100 border-0 my-2 text-light fw-bold"
              style={{ backgroundColor: "#e8a262" }}
              onClick={handleExportClick}
            >
              Export
            </Button>
          </Col>

          <Col className="btn-grp">
            <Button
              type="button"
              className="btn w-100 border-0 my-2 text-light fw-bold"
              style={{ backgroundColor: "#e8a262" }}
              onClick={handlePrintClick}
            >
              Print
            </Button>
          </Col>

        </Row>*/}