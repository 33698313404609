import React, { useEffect } from "react";
import { FaSquareShareNodes } from "react-icons/fa6";
import { MdOutlineDeleteForever } from "react-icons/md";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { BsDot } from "react-icons/bs";
import "./Favcard.css";
import Image from "../../imgData";
import { useSelector } from 'react-redux';
import { FaRegMessage } from "react-icons/fa6";

const Favcard = ({ index, likedItem }) => {
// console.log(index)
const { id } = useSelector((state) => state.user);
const  image = likedItem.multimedia.all_images.split(",");
const  engine = likedItem.engine.split(" ");
const  engines = likedItem.engine.split("  ");

console.log("likedItem", likedItem);
  return (
    <div className="fav-cart-main-cointer fav_card_container">
      <div className="fav-cart car_image_wrapper">
        <div className="card">
          <div
            style={{ position: "relative", textAlign: "center" }}
            className="car_image_container"
          > 
            <img style={{ 
              height : "221px",
              width : "259px",
            }} src={ image[0] ? `https://5starsmotors.com/images/${id}/` +image[0] :  "/static/media/No-Image.baba1a7394e8054d69cf.png"} className="card-img-top" alt="..." />
            <div
              className="top-right"
              style={{
                position: "absolute",
                top: "8px",
                right: "16px",
                display: "flex",
                flexDirection: "column",
                zIndex : "9999",
                // border: "2px solid red"
              }}
            >
              <FaSquareShareNodes style={{ fontSize: "20px", fill: "white" }} />
              <MdOutlineDeleteForever
                style={{ fontSize: "20px", fill: "white" }}
              />
            </div>
          </div>
          <div
            className="card-body"
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <div>
                <a
                  to="#"
                  data-tooltip="Exterior"
                  style={{
                    padding: "10px",
                    backgroundColor: "darkblue",
                    borderRadius: "50%",
                    height: "auto",
                    display: "block",
                    marginRight: "39px",
                  }}
                ></a>
              </div>
              <div>
             
                <a
                  to="#"
                  data-tooltip="Interior"
                  style={{
                    padding: "10px",
                    backgroundColor: "red",
                    borderRadius: "50%",
                    height: "auto",
                    display: "block",
                  }}
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="fav-heading">
        <h4 style={{
              fontWeight: "revert",
            }}>{likedItem.year_Make_Model}</h4>
        <p>
          <span style={{ color: "#d1823a" }}>VIN: </span> {likedItem.vin}
        </p>
        <p className="car_engine_stats">
          <span title="Mileage In"
            style={{
              fontWeight: "bolder",
              display: "flex",
              alignItems: "center", 
            }}
            className="extra_engine_stats"
          >
           {likedItem.mileage_in ? likedItem.mileage_in + "MI": "NA"} 
            <BsDot />
          </span>
          <span title="Drive Type" className="extra_engine_stats">
          {likedItem.driveType ? likedItem.driveType : "NA"} <BsDot />
          </span>
          <span title="Engine Size" className="extra_engine_stats">
          {engine[1].trim()} <BsDot />
          </span>
          <span title="Engine Cylinder"  className="extra_engine_stats">
          {engine[0].trim()} <BsDot />
          </span>
          <span title="Fuel Type" className="extra_engine_stats">
          {engines[1] && engines[1].trim()} <BsDot />
          </span>
          <span title="Transmission">{likedItem.transmission ? likedItem.transmission : "NA"} </span>
        </p>
        <p>
          <span style={{ color: "#d1823a" }}>Location:</span> {likedItem.location ? likedItem.location : "NA"}
        </p>
      </div>
      <div className="fav-price">
        <h2>
          Price :
          <span style={{ color: "#d1823a", marginRight: "0.7rem" }}>
            ${likedItem.listing_price}
          </span>
          Est.:<span style={{ color: "#d1823a" }}> $257/mo</span>
        </h2>
        <p className="messenger_container">
          
          <img
            src={Image.messanger}
            alt="messenger_icon"
            height={40}
            width={40}
          />
          Contact the seller now
        </p>
        <p className="messege_input_send_container">
          <input
            type="text"
            placeholder="Hi kumar, Is this still available?..."
            id="input-send"
          />
          <button id="send-button">Send</button>
        </p>
      </div>
      <div className="fav-button-container">
        <span id="fav-button1">
          <button>+1(234)456-5434</button>
        </span>
        <span id="fav-button2">
          <button>
            
            <span style={{ marginRight: "0.7rem" }}>
              <FaRegMessage />
            </span>
            Text
          </button>
        </span>
      </div>
    </div>
  );
};

export default Favcard;
