import React, { useEffect, useState } from "react";
import "../Styles/OtpLogin.css";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";

const OtpLogin = ({ resUser, setOtpverified }) => {
  console.log("resUser in OtpLogin", resUser);
  const [InputOtp, setInputOtp] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [disableResendOtp, setdisableResendOtp] = useState(false);
  console.log(errorMessage);
  console.log(InputOtp);

  const handleContinueResult = async (value) => {
    try {
      console.log("Handling API continue Result");
      const formData = new FormData();
      console.log("formData", formData);
      formData.append("email", resUser.email);
      formData.append("otp", value);
      formData.append("user_type", "User");

      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/verifyotp`,
        formData
      );

      if (res.status !== 200) {
        console.log("User OTP Failure");
      }

      setOtpverified(true);
    } catch (error) {
      console.log("error in  catch part while using otp");
      toast.error(error.response.data);
    }
  };

  const handleInputChange = async (e) => {
    const { value } = e.target;
    if (/^\d{0,6}$/.test(value)) {
      setInputOtp(value);

      if (value.length === 6) {
        await handleContinueResult(value);
      }
    }
  };
  const [time, setTime] = useState(300); // 300 seconds for 5 minutes
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    let timer;

    if (time > 0 && isDisabled) {
      timer = setInterval(() => {
        setTime((prevTime) => prevTime - 1);
      }, 1000);
    } else if (time <= 0) {
      setIsDisabled(false);
      clearInterval(timer);
    }

    return () => clearInterval(timer); // Cleanup on component unmount
  }, [time, isDisabled]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(secs).padStart(
      2,
      "0"
    )}`;
  };
  const handleResendOtp = async () => {
    console.log("Please resend OTP");
    if (!isDisabled) {
      setIsDisabled(true);
      setTime(300); // Reset to 5 minutes
    }
    const formData = new FormData();
    formData.append("email", resUser.email);
    formData.append("user_type", "User");
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/send_link`,
        formData
      );

      if (res.status === 200) {
        console.log(res.data);
        console.log("I got new OTP");
        setTimeout(() => {
          setdisableResendOtp(true);
        }, 5000);
      }
    } catch (error) {
      console.log("Error in catch part", error);
      if (error.response.status === 400) {
        toast.error(error.response.data);
      } else if (error.response.status === 500) {
        toast.error(error.response.data);
      }
    }
  };

  return (
    <div className="GrandOTPContainer">
      <div className="OtpContainer">
        <h2 className="heading">Log in Or Create an account</h2>
        <div className="input_otp_container">
          {/* <p className="detailing_1">
            <span>Enter the OTP</span>
            <span>required</span>
          </p>  */}

          <div className="input_and_icon">
            <input
              type="text"
              onChange={handleInputChange}
              value={InputOtp}
              placeholder="6 Digit OTP"
              className="signup_otp_input"
            />
          </div>
          {/* <p style={{textAlign : "center", fontSize : "larger", fontWeight : "600", color : "green"}}><span>Account verification link sent to your email</span> </p> */}

          <p className="forget_password">
            <span
              style={{
                color: "green",
                borderBottom: "0",
                fontSize: "large",
              }}
            >
              {isDisabled ? formatTime(time) : ""}
            </span>
            <button
              className="resend_button"
              onClick={handleResendOtp}
              style={{
                cursor: "pointer",
                cursor: isDisabled ? "not-allowed" : "",
                marginLeft: "0.5em",
              }}
              disabled={isDisabled}
            >
              Resend OTP
            </button>
          </p>
        </div>

        <div className="sign_in_line">
          <p>Or Signin with</p>
        </div>
      </div>
      <Toaster
        position="top-right"
        reverseOrder={false}
        toastOptions={{
          // Define default options
          className: "",
          duration: 1000,
          style: {
            background: "#363636",
            color: "#fff",
            fontSize: "larger",
          },
        }}
      />
    </div>
  );
};

export default OtpLogin;
