import React, { useEffect, useState } from "react";
import Styles from "../DealerSetupUser/setup.module.css";
import DealerRetailInputs from "../../DealerMaster/DealerTaxesAndFees/DealerRetail/DealerRetailInputs";
import { Button, Col, Container, Row } from "react-bootstrap";
import { MdDelete } from "react-icons/md";
import { FaFileUpload } from "react-icons/fa";
import Image from "../../.././imgData";
import axios from "axios";
import { useSelector } from "react-redux";
const BasicInfo = ({ formData, setFormData, onImageUpload,profilePictureUrl,UserIdEdit }) => {
  const [image, setImage] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const { id } = useSelector((state) => state.user);
  useEffect(() => {
    if (profilePictureUrl) {
      setImage(`https://5starsmotors.com/images/${UserIdEdit
              }/${encodeURIComponent(profilePictureUrl)}`);
    }
  }, [profilePictureUrl]);

  const handleImageChange = (event) => {
    event.stopPropagation();

    const files = event.target.files;
    console.log("Files:", files); // Log the files array

    if (files && files.length > 0) {
      const file = files[0];
      onImageUpload(file);

      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      console.error("No file selected or file is invalid.");
    }
  };

  // const handleImageChange = (event) => {
  //   event.stopPropagation();

  //   const files = event.target.files;
  //   console.log("Files:", files); // Log the files array

  //   if (files && files.length > 0) {
  //     const file = files[0];
  //     onImageUpload(file);

  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       setImage(reader.result);
  //     };
  //     reader.readAsDataURL(file);
  //   } else {
  //     console.error("No file selected or file is invalid.");
  //   }
  // };



  const handleRemoveImage = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setImage(null); // Clear the image state
    onImageUpload(null); // Notify parent that the image was removed
  };

  const handleInput = (name, InputValue) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: InputValue,
    }));
  };
  useEffect(() => {
    if (formData.password && formData.confirmpassword) {
      if (formData.password !== formData.confirmpassword) {
        setError('Passwords do not match.');
      } else {
        setError('');
      }
    } else {
      setError('');
    }
  }, [formData.password, formData.confirmpassword]);

  useEffect(() => {
    console.log("Form Data:", formData);
    console.log("Image:", image);
  }, [formData, image]);
// -----------------------------------------------
const [passwordMessage,setPassMessage] = useState("");
console.log("formData***********",formData);
const resetPassword = async () => {
  setLoading(true);
  const fd = new FormData();

  fd.append("dealer_id", id);
  fd.append("user_id", UserIdEdit);
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/user/reset-password`,
      fd
    );
    if(res.status=== 200){
      setPassMessage("We Send You a Confirmation Mail..");
    }
  } catch (error) {
    console.log("Error 500 while updating the user information");
  }finally {
    setLoading(false); // Set loading to false when the request finishes
  }
};
// ------------------------------------------------------------------------------------------------------
  return (
    <Row
      className={` flex-nowrap ${Styles.infoWrapper}`}
      style={{ boxSizing: "border-box" }}
    >
      <Col sm={9} className={` p-2 ${Styles.infoInputContainer}`}>
        <Container fluid>
          {/* Line 1 */}
          <Row className={`mt-2 ${Styles.line1}`}>
            <DealerRetailInputs
              head="First Name"
              name="firstName"
              // type="%"
              InputValue={formData.firstName}
              handleChange={handleInput}
            />
            <DealerRetailInputs
              head="Last Name"
              name="lastName"
              // type="%"
              InputValue={formData.lastName}
              handleChange={handleInput}
            />
          </Row>
          {/* Line 2 */}
          <Row className={Styles.line2}>
            <DealerRetailInputs
              head="Email ID"
              name="email"
              // type="%"
              InputValue={formData.email}
              handleChange={handleInput}
            />
            <DealerRetailInputs
              head="Job Title"
              name="jobTitle"
              // type="%"
              InputValue={formData.jobTitle}
              handleChange={handleInput}
            />
          </Row>
          {/* Line 3 */}
          <Row className={Styles.line3}>
            <DealerRetailInputs
              head="Mobile Number"
              name="phoneNumber"
              // type="%"
              InputValue={formData.phoneNumber}
              handleChange={handleInput}
            />
            <DealerRetailInputs
              head="Land Line"
              name="landlineNumber"
              // type="%"
              InputValue={formData.landlineNumber}
              handleChange={handleInput}
            />
          </Row>
          {/* <Row className={Styles.line3}>
            <DealerRetailInputs
              head="Password"
              name="password"
              InputValue={formData.password}
              handleChange={handleInput}
            />
            <DealerRetailInputs
              head="Confirm Password"
              name="confirmpassword"
              InputValue={formData.confirmpassword}
              handleChange={handleInput}
            />
            {error && <div className={Styles.error}>{error}</div>}
          </Row> */}
         <Row><p style={{color:"green"}}>{passwordMessage && passwordMessage}</p></Row>
         <div style={{float:"right"}}> {UserIdEdit && <Button type="button" className="btn btn-secondary" onClick={resetPassword} disabled={loading}>{loading ? <div className={Styles.spinner} style={{float:"right"}}></div> : 'Reset Password'}</Button>}</div>
        </Container>
       
      </Col>

      <Col sm={3} className={` p-2 ${Styles.infoImageContainer}`}>
        <div className={` `}>
          <span>Avatar</span>
        </div>

        <div
          htmlFor="profile" // Correctly points to the input
          className={` image-upload-fields ${Styles.proImg}`}
          style={{
            position: "relative",
            top: "-5px",
            left: ".5rem",
            color: "white",
            fontSize: "x-large",
            backgroundColor: "rgba(0, 0, 0, 0.1)",
            display: "grid",
            zIndex: "2",
            cursor: "pointer",
          }}
        >
          {image && (
            <>
              <span className={Styles.icon} onClick={handleRemoveImage} > <MdDelete /></span>
              <img
                src={image}
                alt="avatar"
                style={{
                  borderRadius: "10px",
                  height: "100%",
                  width: "100%",
                  display: "grid",
                  zIndex: "2",
                  cursor: "pointer",
                }}
              />
            </>
          )}
          <span className={Styles.uploadIcon} onClick={() => document.getElementById("profile").click()}> <FaFileUpload /></span>
        </div>

        <input className="d-none" name={`user-profile`} id="profile" type="file" onChange={handleImageChange}
        />
      </Col>

    </Row>
  );
};

export default BasicInfo;
