import React, { useEffect, useState } from "react";
import "../Styles/PassLogin.css";
import axios from "axios";
import Image from "../../imgData";
import Fingerprint2 from "fingerprintjs2";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { sideBarArray } from "../../dashboard/SideBarData";
import rollerLoader from "../Styles/loadingRoller.gif";
import {
  saveUser,
  setShowUserCompleteProfileDialogueBox,
  updateInitialDataFromAPI,
} from "../../../Redux/slice/userSlice";
import toast, { Toaster } from "react-hot-toast";

const PassLogin = ({
  isLoginEmail,
  setIsPassWordForgot,
  setDeviceVerify,
  setExtraInformation,
  setShowLoginDialogueBox,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [InputPass, setInputPass] = useState("");
  const [showPass, setShowPass] = useState(false);
  const [fingerprint, setFingerprint] = useState("");
  const [error, setError] = useState("");
  //
  const [ip, setIP] = useState("");
  const [city, setCity] = useState("");
  console.log(fingerprint);
  const [loading, setLoading] = useState(false);
  console.log("loading =>", loading);
  console.log(fingerprint);
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  console.log(
    "longitude in  PassLogin=> ",
    longitude,
    "latitude in PassLogin=>",
    latitude
  );

  useEffect(() => {
    const getUserCountryCode = async () => {
      const res = await axios.get("https://ipapi.co/json");
      console.log("User all info", res.data);
      setLatitude(res.data.latitude);
      setLongitude(res.data.longitude);
      // setIP(res.data.ip)
    };
    getUserCountryCode();
  }, []);

  // UseEffect for Fingerprint
  useEffect(() => {
    const getDeviceFingerprint = () => {
      return new Promise((resolve, reject) => {
        Fingerprint2.get((components) => {
          const values = components.map((component) => component.value);
          const fingerprintId = Fingerprint2.x64hash128(values.join(""), 31);
          resolve(fingerprintId);
        });
      });
    };

    //useEffect for location of city name

    const fetchFingerprint = async () => {
      try {
        const fingerprintId = await getDeviceFingerprint();
        setExtraInformation((prevData) => ({
          ...prevData,
          fingerprint: fingerprintId,
        }));
        setFingerprint(fingerprintId);
      } catch (error) {
        console.error("Error getting fingerprint:", error);
      }
    };

    // Wait for fingerprint to be generated before rendering the component
    const timeout = setTimeout(() => {
      fetchFingerprint();
    }, 1000); // Adjust timeout value as needed

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  // useEffect for IP Address of user while
  const getUserIPaddress = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");
    console.log("User IP Address", res);
    setIP(res.data.ip);
  };
  useEffect(() => {
    getUserIPaddress();
  }, [ip]);

  //useEffect to get the city data

  //   const [cityStats, setCityStats] = useState('');
  //   console.log("User current city",city)

  // useEffect(() => {
  //   async function fetchCity() {
  //     const userCity = await UserCity();
  //     console.log(userCity)
  //     if (userCity) {
  //       setCityStats(userCity);
  //     }
  //   }
  //   fetchCity();
  // }, []);

  // function for verifying the password
  const handleContinueResult = async () => {
    console.log("Handling API continue Result");
    setLoading(true);
    const formData = new FormData();
    try {
      formData.append("email", isLoginEmail.email);
      formData.append("password", InputPass);
      formData.append("fingerprint", fingerprint);
      formData.append("ip", ip);
      formData.append("user_type", "User");

      console.log("formData", formData);
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/verify_password`,
        formData
      );
      console.log("response while loggin in", res);
      if (res.status === 200) {
        if (res.data.device_verify) {
          // navigate("/myprofile/dashboard");
          dispatch(
            saveUser({
              email: res.data.email,
              id: res.data.id,
              phoneNumber: res.data.phoneNumber,
              firstName: res.data.firstName,
              lastName: res.data.lastName,
              office_number: res.data.office_number,
              lattitude: res.data.lattitude,
              longitude: res.data.longitude,
              zip_code: res.data.zip_code,
              address: res.data.address,
              profile_picture: res.data.profile_picture,
              website: res.data.website,
              roles: res.data.roles,
            })
          );
          // navigate("/myprofile/dashboard");

          fetchSideBarData(res.data.id);
          setLoading(false);
          console.log("Device verify is true");
          setDeviceVerify(true);
          setShowLoginDialogueBox(false);
          console.log("Device verify in setpass", res.data.device_verify);
        } else {
          setDeviceVerify(false);
          toast("Please verify your device", {
            duration: 2000,
          });
          console.log("Device verify in passlogin", res.data.device_verify);
          console.log("Device verify in passlogin is false");
          setLoading(false);
        }
      } else{
        toast.error("Bad Request");
      }
    } catch (error) {
      if (error.response.status === 400) {
        console.log("Bad request");
        toast.error(error.response.data);
        setLoading(false);
      } else if (error.response.status === 500) {
        console.log("Server side error");
        toast.error(error.response.data);
        setLoading(false);
      }
    }
  };
  const handleChangeView = () => {
    setShowPass(!showPass);
  };

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === "Enter") {
        handleContinueResult();
      }
    };
    document.addEventListener("keypress", handleKeyPress);
    return () => {
      document.removeEventListener("keypress", handleKeyPress);
    };
  }, [InputPass]);

  const handleInputChange = async (e) => {
    setInputPass(e.target.value);
    setExtraInformation((prevData) => ({
      ...prevData,
      password: e.target.value,
    }));
  };
  const [forgetPasswordLoader, setForgetPasswordLoader] = useState(false);
  const handleForgetPassword = async () => {
    setForgetPasswordLoader(true);
    if (isLoginEmail.email !== "") {
      try {
        const formData = new FormData();
        formData.append("email", isLoginEmail.email);
        formData.append("user_type", "User");
        const res = await axios.post(
          `${process.env.REACT_APP_API_URL}/user/forgetotp`,
          formData
        );
        if (res.status === 200) {
          setIsPassWordForgot(true);
          console.log("I forgot password ");
          console.log("res.data", res.data);
          setForgetPasswordLoader(false);
        }
      } catch (error) {
        console.log("Error 500 while handling forgot password", error);
        toast.error(error.response.data);
        setForgetPasswordLoader(false);
      }
      await setIsPassWordForgot(true);
    }
  };

  // Update side bar user activity after login
  const fetchSideBarData = async (user_id) => {
    const fd = new FormData();
    fd.append("user_id", user_id);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/tab/get`,
        fd
      );
      console.log(res);
      if (res.status === 200) {
        const transformedData = sideBarArray.map((item) => {
          const apiItem = res.data.find((apiItem) => apiItem.tabId === item.id);
          return {
            ...item,
            count: apiItem ? apiItem.tabCount : item.count,
          };
        });

        // Dispatch action to update the initial state with transformed data
        dispatch(updateInitialDataFromAPI(transformedData));
        localStorage.setItem(
          "user_sidebar_activity",
          JSON.stringify(transformedData)
        );
      }
    } catch (error) {
      console.log(
        "Server side error during getting user activity in my profile",
        error
      );
    }
  };
  return (
    <div className="GrandPassParent">
      <div className="PassContainer">
        <h2 className="heading">Log in Or Create an account</h2>
        <div className="input_pass_Container">
          {/* <p className="detailing_1">
            <span>Enter Password</span>
            <span>required</span>
          </p> */}

          <div className="input_and_icon">
            <input
              type={!showPass ? "password" : "text"}
              onChange={handleInputChange}
              value={InputPass}
              placeholder="Enter Password"
              className="user_input_login"
            />
            <img
              src={!showPass ? Image.eye_icon : Image.hide_icon}
              alt="hide/unhide_icon"
              height={20}
              onClick={handleChangeView}
            />
          </div>

          <div className="forget_password">
            {forgetPasswordLoader && (
              <img src={rollerLoader} alt="loader" height={20} />
            )}{" "}
            <button
              onClick={handleForgetPassword}
              disabled={forgetPasswordLoader ? true : false}
              style={{
                cursor: forgetPasswordLoader ? "not-allowed" : "pointer",
                marginLeft: "0.3rem",
                color: forgetPasswordLoader ? "grey" : "black",
              }}
              className="forget_pass_btn"
            >
              Forget Password
            </button>
          </div>
        </div>
        {error ? (
          <p className="error_message">
            {error
              .split(" ")
              .map((item) => item.charAt(0).toUpperCase() + item.slice(1))
              .join(" ")}
          </p>
        ) : null}
        <button
          onClick={handleContinueResult}
          className={`continue_button ${loading ? "blink_now" : ""}`}
          disabled={InputPass.trim() === "" ||  forgetPasswordLoader ? true : false}
        >
          <span>Continue</span>
          {loading ? (
            <img
              src="https://dacbuckets3.s3.amazonaws.com/sport-car.png"
              alt="animated car icon"
              className="car_image_move"
            />
          ) : null}
        </button>
        <div className="sign_in_line">
          <p>Or Signin with</p>
        </div>
      </div>
      <Toaster
        position="top-right"
        reverseOrder={false}
        toastOptions={{
          // Define default options
          className: "",
          duration: 1000,
          style: {
            background: "#363636",
            color: "#fff",
            fontSize: "larger",
          },
        }}
      />
    </div>
  );
};

export default PassLogin;
