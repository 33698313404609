import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Row, Table } from 'react-bootstrap'
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { MdDelete } from "react-icons/md";
import { BiSolidEditAlt } from "react-icons/bi";
import { Link, useOutletContext } from "react-router-dom";
import styles from '../users.module.css'
import Image from "../../../imgData";
import { TbRestore } from "react-icons/tb";
import { useSelector } from 'react-redux';

const DealerDeletedUser = () => {
  const isExpanded = useOutletContext();
  const { id } = useSelector((state) => state.user);
  const [userCount, setUserCount] = useState("");
  const [deleteCount, setDeleteCount] = useState(0);
  const [message, setMessage] = useState('');
  const [userStatus, setUserStatus] = useState(true);
  const [deletedUsers, setDeletedUsers] = useState([]);
  //   fetrch user details -----------------------------------------------------
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/user/get_deleted_users/${id}`);

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const result = await response.json();
        setDeletedUsers(result);
        // setData(result);
      } catch (err) {
        console.log(err.message);
      } finally {
        console.log(false);
      }
    };

    fetchData();
  }, [deleteCount]); // Re-run when the id changes
  // -------------------------------------------------------------------------------
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/user/get_dealer_users/${id}`);

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const result = await response.json();
        setUserCount(result);
        console.log("result", result);
      } catch (err) {
        console.log(err.message);
      } finally {
        console.log(false);
      }
    };

    fetchData();
  }, [deleteCount]); // Re-run when the id changes
  // ------------------------------------------------------------------
  // for delete the user-------------------------------------------------------------------------
  const handleDelete = async (userIdData) => {
    console.log("userIdData", userIdData);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/user/delete_user/${id}/${userIdData}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response;
      setDeleteCount(prevCount => prevCount + 1);
      setMessage(`Resource updated: ${JSON.stringify(data)}`);
    } catch (error) {
      setMessage(`Error: ${error.message}`);
    }
  };
  // --------------------------------------------------------------------
  const handleRestore = async (userIdData) => {
    console.log("userIdData", userIdData);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/user/revert/${id}/${userIdData}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response;
      setDeleteCount(prevCount => prevCount + 1);
      setMessage(`Resource updated: ${JSON.stringify(data)}`);
    } catch (error) {
      setMessage(`Error: ${error.message}`);
    }
  };
  // ------------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------

  const [selectedSort, setSelectedSort] = useState(null);
  const handleSelect = async (selectedStatus) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/user/get_deleted_users/${id}?sort=${selectedStatus}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setDeletedUsers(data);
      setSelectedSort(selectedStatus);
      console.log(data);
      // Handle the response data as needed
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  // -------------------------------------------------------------------------------------
  return (
    <Container fluid className={`p-5 ${styles.wrapper} ${isExpanded ? styles.content_expanded : styles.content_collapsed
      }  `}>
      <Row className="mb-4" style={{ display: "flex", justifyContent: "end", }}>
        <Col sm={3} className={`pe-0`}>
          <Link to='/dealer/users/setup' className={`${styles.linkOrange} border-0`}>Create User</Link>
        </Col>
        <Col sm={3} className={``}>
          <Link to='/dealer/users' className={`${styles.linkOrange} border-0 ms-3`}>All Users</Link>
        </Col >
      </Row>

      <Row className={`d-flex justify-content-evenly my-4`}>
        <Col sm={3} className={`p-3 border rounded text-center shadow d-flex justify-content-between`}>
          <div className={``}>
            <h1>{userCount?.all_users}</h1>
            <span className='text-warning fw-bolder'> All Users </span>
          </div>
          <div className={`${styles.profile} border-0`}>
            <img src={Image.user_icon} className={`p-2 rounded`} alt='All Users' width="65px" height='65px' />
          </div>
        </Col>
        <Col sm={3} className={`p-3 border rounded text-center shadow d-flex justify-content-between`}>
          <div className={``}>
            <h1>{userCount?.active_users}</h1>
            <span className='text-success fw-bolder'>Actives Users</span>
          </div>
          <div className={`${styles.profile} `}>
            <img src={Image.user_icon} className={`p-2 rounded`} alt='Actives Users' width="65px" height='65px' />
          </div>
        </Col>
        <Col sm={3} className={`p-3 border rounded text-center shadow d-flex justify-content-between`}>
          <div className={``}>
            <h1>{userCount?.deleted_users}</h1>
            <span className='text-danger fw-bolder' >Deleted users</span>
          </div>
          <div className={`${styles.profile} `}>
            <img src={Image.user_icon} className={`p-2 rounded`} alt='Deleted Users' width="65px" height='65px' />
          </div>
        </Col>
      </Row>

      <Row className={`border-0 d-flex justify-content-between pb-5 mb-5`}>
        <Col sm={3}><span>Show: </span>
          <DropdownButton id="dropdown-basic-button" title="10" className={`${styles.dropOrange} border-0`} style={{ display: 'inline-block' }}>
            <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
            <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
            <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
          </DropdownButton>
        </Col>
        <Col sm={4} className={`border-0 d-flex justify-content-end align-items-center`}>
          <span>Sort By: </span>
          <DropdownButton id="dropdown-basic-button" title="Status" className={`${styles.dropOrange} border-0 mx-1`} style={{ display: 'inline-block' }}>
            {/* <Dropdown.Item onClick={()=> handleSelect("Active")}>Active</Dropdown.Item>
            <Dropdown.Item onClick={()=> handleSelect("Deactive")}>Inactive</Dropdown.Item> */}
            <Dropdown.Item
              className={`dropdown-item ${selectedSort === "Desc" ? 'selected-item' : ''}`}
              onClick={() => handleSelect("Desc")}>Newest</Dropdown.Item>

            <Dropdown.Item
              className={`dropdown-item ${selectedSort === "Asc" ? 'selected-item' : ''}`}
              onClick={() => handleSelect("Asc")}>Oldest</Dropdown.Item>
          </DropdownButton>
        </Col>
      </Row>

      <Row className='mt-5'>
        <Col sm={12}>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Sr. No.</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Job Title</th>
                <th>Grant Access</th>
                {/* <th>Login History</th> */}
                <th>Created Date </th>
                <th>Restore</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {deletedUsers && deletedUsers.map((user, index) => (
                <tr>
                  <td>{index + 1}</td>
                  <td>{user.firstName}</td>
                  <td>{user.lastName}</td>
                  <td>{user.jobTitle}</td>
                  <td>{user.grantAccess}</td>
                  {/* <td><Button className={`${styles.btnOrange} p-1 px-3 border-0 `}>View</Button></td> */}
                  <td>{user.createdAt}</td>
                  <td className='text-center'>
                    {/* <TbRestore className={`${styles.restore}`} onClick={()=>handleRestore(user.id)}/> */}
                    <Button className="publish-btn border-0 py-2" onClick={() => handleRestore(user.id)} style={{ color: "white", width: "70px", }}>
                      Edit
                    </Button>
                  </td>
                  <td className='text-center'>
                    {/* <MdDelete className={`${styles.deleteIcon}`} onClick={()=>handleDelete(user.id)}/> */}
                    <Button className="publish-btn border-0 py-2" onClick={() => handleDelete(user.id)} style={{ color: "white", width: "70px", }}>
                      Delete
                    </Button>
                  </td>
                </tr>
              ))
              }


              {/* <tr>
                <td>6</td>
                <td>Jacob</td>
                <td>Thornton</td>
                <td>@fat</td>
                <td>Admin</td>
               
                <td>Mark</td>
                <td><TbRestore className={`${styles.restore}`} /></td>
                <td><MdDelete className={`${styles.deleteIcon}`} /></td>
              </tr>
              <tr>
                <td>7</td>
                <td>Jacob</td>
                <td>Thornton</td>
                <td>@fat</td>
                <td>Dealer</td>
               
                <td>Mark</td>
                <td><TbRestore className={`${styles.restore}`} /></td>
                <td><MdDelete className={`${styles.deleteIcon}`} /></td>
              </tr> */}
            </tbody>
          </Table>
        </Col>
        {/* <Col sm={12} className={`d-flex justify-content-between my-5`} >
          <div className="row-count">pagination</div>
          <div className="pagination">pagination</div>
        </Col> */}
      </Row>

    </Container>
  )
}

export default DealerDeletedUser;