import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import DealerSideBar from "../DealerSideBar/DealerSideBar";
import DealerNavbar from "../DealerNavbar/DealerNavbar";
import "./DealerLayout.css";
import { IoMenu } from "react-icons/io5";
import { useSelector } from "react-redux";
import CompleteUserDetailModal from "../../UserAuthDialogue/Pages/CompleteUserDetailModal";
import { setDynamicAPILabels } from "../../../Redux/slice/taxes&FeesSlice";
import axios from "axios";
import { useDispatch } from "react-redux";
import Navbar from "../../navbars/Navbar";
import { saveUser } from "../../../Redux/slice/userSlice";

const DealerLayout = () => {
  const dispatch = useDispatch();
  //for modal of the first name , last name
  const userData = useSelector((state) => state.user);
  const { firstName, lastName, id, email, roles } = userData;
  const fetchDynamicLabels = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/sell/get_labels/${id}`
      );
      console.log("Line 31", res.data);
      const mappedResponse =
        res &&
        res.data &&
        res.data.map((item, index) => ({
          id: item.id,
          label: item.itemName,
          InputValue: Number(item.itemValue),
        }));
      console.log("mappedResponse => ", mappedResponse);
      dispatch(setDynamicAPILabels(mappedResponse));
    } catch (error) {
      console.log("Error while fetching labels => ", error);
    }
  };
  useEffect(() => {
    fetchDynamicLabels();
  }, []);
  // const [  isPermanentlyExpanded, setIsPermanentlyExpanded] = useState(() => {
  //   // Initialize state from localStorage, default to false if not set
  //   return localStorage.getItem("sidebarExpanded") === "true";
  // });
  const isPermanentlyExpanded = true;

  useEffect(() => {
    // Update localStorage whenever isPermanentlyExpanded changes
    localStorage.setItem("sidebarExpanded", isPermanentlyExpanded);
  }, [isPermanentlyExpanded]);

  // const toggleSidebar = () => {
  //   setIsPermanentlyExpanded(!isPermanentlyExpanded);
  // };
  const [isHovered, setIsHovered] = useState(false);
  const handleSidebarHover = (hovered) => {
    setIsHovered(hovered);
  };

  const isExpanded = isPermanentlyExpanded || isHovered;

  // console.log("Id of user in my profile from redux", userData.id);
  const [showDialogueBox, setShowDialogueBox] = useState(false);
  useEffect(() => {
    if (id && email) {
      if (firstName && lastName) {
        setShowDialogueBox(false);
        console.log(
          "All user details are present like first name , lastname and profile picture"
        );
      } else {
        console.log(
          "Not present =>  first name , lastname and profile picture"
        );
        setShowDialogueBox(true);
      }
    }
  }, [firstName, lastName, id, email, roles]);
  useEffect(() => {
    if (showDialogueBox) {
      document.body.classList.add("disable-scroll");
      // Disable other user interactions
      const disableUserEvents = (e) => e.stopImmediatePropagation();
      // window.addEventListener("click", disableUserEvents, true);
      window.addEventListener("keydown", disableUserEvents, true);

      return () => {
        window.removeEventListener("click", disableUserEvents, true);
        window.removeEventListener("keydown", disableUserEvents, true);
      };
    } else {
      document.body.classList.remove("disable-scroll");
    }
  }, [showDialogueBox]);

  const isSideBarOpen = useSelector((state) => state.user.isSideBarOpen);
  useEffect(() => {
    dispatch(saveUser({ isSideBarOpen: true }));
  }, []);
  return (
    <div className="dealer_layout_wrapper">
      {showDialogueBox ? (
        <div className="complete_user_details_modal">
          <CompleteUserDetailModal setShowDialogueBox={setShowDialogueBox} />
        </div>
      ) : null}
      <div className="dealer_layout_wrapper">
        <Navbar position="relative" shouldShow={false} />

        <div className="dealer_homepage_container">
          {isSideBarOpen && (
            <div
              className={`dealer_sidebar_home_Parent ${
                isSideBarOpen ? "ShowSidebar" : "HideSidebar"
              }`}
              style={{ position: "relative", top: "0" }}
            >
              <DealerSideBar
                isPermanentlyExpanded={isPermanentlyExpanded}
                onHoverChange={handleSidebarHover}
              />
            </div>
          )}

          <div className="dealer_layout_content" style={{ width: "100%" }}>
            <Outlet context={isExpanded} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DealerLayout;
