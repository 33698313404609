import DealerInformation from "../DealerSection/DealerMaster/DealerInformation/DealerInformation";

const Image = {
  title: require("./tittle-G.png"),
  odo: require("./odo-blue.PNG"),
  owr: require("./owr.PNG"),
  sprkBlur: require("./sprk-blur.png"),
  carBlur: require("./car-blue.png"),
  key: require("./key.PNG"),
  tire: require("./tir-g.png"),
  smoke: require("./smok.PNG"),
  icon1: require("./icon1.jpeg"),
  icon2: require("./icon2.jpeg"),
  logo: require("./Logo.png"),
  logoDac: require("./LogoDac.png"),
  // footor
  ios: require("./ios.png"),
  android: require("./android.png"),
  payment: require("./payments.png"),
  // CustomerDetails
  verifyImg: require("./verify-img.png"),
  verify: require("./verify.png"),
  darkLogo: require("./dark-logo.png"),
  dashboard: require("./audi.webp"),
  //Dashboard Navbar image
  addcost: require("./AddCost.png"),
  notification: require("./notification.png"),
  messanger: require("./messanger.png"),
  offers: require("./offers.png"),
  logout: require("./logout.png"),
  home: require("./home.png"),
  addList: require("./addList.png"),
  Sell: require("./Sell.png"),
  //Dashboard Sidebar logo
  sidebarHome: require("./sidebarHome.png"),
  sidebarSell: require("./sidebarSell.png"),
  sidebarAddListing: require("./sidebarAddListing.png"),
  // sidebarMyFavrites: require("./sidebarMyFavorite.png"),
  sidebarAddManager: require("./sidebarAddManager.png"),
  sidebarCompareVehicles: require("./sidebarCompareVehicles.png"),
  sidebarDraft: require("./sidebarDraft.png"),
  sidebarLogout: require("./sidebarLogout.png"),
  sidebarMessages: require("./sidebarMessages.png"),
  sidebarMyProfile: require("./sidebarMyProfile.png"),
  sidebarOffers: require("./sidebarOffers.png"),
  sidebarRecycleBin: require("./sidebarRecycleBin.png"),
  sidebarViewhistory: require("./sidebarViewhistory.png"),
  sidebarSavedSearch: require("./savedSearch.png"),
  mostRecent: require("./most recent.png"),
  activeListing: require("./active listing.png"),
  eContract: require("./e-Contract.png"),
  boughtSold: require("./bought sold.png"),
  // Marque Logo Images
  honda: require("./honda-logo-2000-full-640.png"),
  landRover: require("./Land-Rover.png"),
  mazda: require("./mazda-logo.png"),
  mercedes: require("./Mercedes-Logo.png"),
  mini: require("./Mini-logo.png"),
  opel: require("./Opel-logo.png"),
  toyta: require("./p1ngegg.png"),
  alfaRomeo: require("./alfaRomeo.png"),
  bantley: require("./bantley.png"),
  peugeot: require("./peugeot.png"),
  Mitsubishi: require("./Mitsubishi.png"),
  Maserati: require("./Maserati.png"),
  Volvo: require("./Volvo.png"),
  Porsche: require("./Porsche.png"),
  volkswagen: require("./WolsWagon.png"),
  Lamborghini: require("./Lamborghini.png"),
  Audi: require("./Audi.png"),
  Bmw: require("./BMW.png"),
  Buick: require("./Buick.png"),
  Chevrolet: require("./Chevrolet.png"),
  chrysler: require("./chrysler.png"),
  dodge: require("./dodge.png"),
  ferrari: require("./ferrari.png"),
  fiat: require("./Fiat.png"),
  ford: require("./ford.png"),
  gmc: require("./GMC.png"),
  pontiac: require("./Pontiac-Logo.png"),
  saturn: require("./Saturn-Logo.png"),
  // new update in car--------------------
  ram: require("./RAM.png"),
  acura: require("./Acura.png"),
  astonmartin: require("./Aston Martin.png"),
  bentley: require("./Bentley.png"),
  cadillac: require("./Cadillac.png"),
  genesis: require("./Genesis.png"),
  hyundai: require("./Hyundai.png"),
  infiniti: require("./Infiniti.png"),
  jaguar: require("./jaguar.png"),
  jeep: require("./Jeep.png"),
  kia: require("./Kia.png"),
  lexus: require("./Lexus.png"),
  maserati: require("./Maserati.png"),
  mclaren: require("./McLaren.png"),
  mitsubishi: require("./Mitsubishi.png"),
  nissan: require("./Nissan.png"),
  //peugeot:require('./Peugeot.png'),
  porsche: require("./Porsche.png"),
  renault: require("./Renault.png"),
  rollsroyce: require("./Rolls-Royce.png"),
  subaru: require("./Subaru.png"),
  tesla: require("./tesla.png"),
  toyota: require("./Toyota.png"),
  // Shop By Vehicle
  suv: require("./SUV.png"),
  convertible: require("./CONVERTIBLE.png"),
  coupe: require("./COUPE.png"),
  crossover: require("./CROSSOVER.png"),
  hatchback: require("./HATCHBACK.png"),
  // Add Listing page icons
  fuelType: require("./FuelType.png"),
  engineCylinder: require("./EngineCylinder.png"),
  engineSize: require("./EngineSize.png"),
  transmission: require("./Transmission.png"),
  mileage: require("./MilageInRead.png"),
  horsePower: require("./HorsePower.png"),
  doorCount: require("./DoorCounts.png"),
  keys: require("./Keys.png"),
  title: require("./Title2.png"),
  owner: require("./Owner.png"),
  uploadImage: require("./UploadImage.png"),
  tireCondition: require("./TireCondition.png"),
  tcFair: require("./Tc-Fair.png"),
  tcFullyUsed: require("./Tc-FullyUsed.png"),
  tcGood: require("./Tc-Good.png"),
  tcNew: require("./Tc-New.png"),
  linkImage: require("./link.png"),
  // icons adv manager
  facebookIcon: require("./icons8-facebook-48.png"),
  craigslist: require("./Craigslist-Zeichen.png"),
  facebook: require("./facebook.png"),
  craigslist: require("./Craigslist-Zeichen.png"),
  twitter: require("./twitterinventory.png"),
  insta: require("./instagram.png"),
  google: require("./google.png"),
  unitedmotors: require("./dark-logo.png"),
  dacmp: require("./dark-logo.png"),

  //TwitterIcon: require('./Twitter.png'),
  // seller adlst section icons-------
  redTyre: require("./redTyre.png"),
  greenTyre: require("./greenTyre.png"),
  yellowTyre: require("./yellowTyre.png"),
  blueTyre: require("./blueTyre.png"),
  damage: require("./damage.png"),
  car: require("./car.png"),
  carFees: require("./carFees.png"),
  smokedIn: require("./smokedIn.png"),
  suitKeys: require("./keySuitcase.png"),
  search_orange_icon: require("./icons8-search-50 (1).png"),
  closeIcon: require("./close.png"),
  apple_logo: require("./apple-logo.png"),
  google_logo: require("./google.png"),
  fb_logo: require("./facebook.png"),
  ig_logo: require("./instagram.png"),
  linkedin_logo: require("./linkedin.png"),
  twitter_x_logo: require("./twitter.png"),
  ms_logo: require("./microsoft.png"),
  eye_icon: require("./eye.png"),
  hide_icon: require("./hide.png"),
  listView: require("./list-view.png"),
  cardView: require("./card-view.png"),
  listViewS: require("./list-view-s.png"),
  cardViewS: require("./card-view-s.png"),
  back_btn: require("./back.png"),
  next_btn: require("./next.png"),
  select: require("./select.png"),
  selected: require("./selected.png"),
  twitter: require("./twitterinventory.png"),
  avatar_img: require("./avatar_img.png"),
  upload_icon: require("./upload.png"),
  car_image: require("./car_image.jpg"),
  four_tyre_img: require("./four_tyres.png"),
  drive_and_run: require("./drive_and_run.png"),
  user_icon: require("./user_icon.png"),
  image_not_available: require("./image_not_available.png"),
  reset_button: require("./resetButton.png"),
  whiteReset: require("./whiteReset.png"),
  trim: require("./trim.png"),
  seat: require("./seat.png"),
  noImage: require("./No-Image.png"),
  dealer_email: require("./dealer_mail.png"),
  dealer_master: require("./Dealer_Master.png"),
  dealer_information: require("./Dealer_information.png"),
  dealer_taxes_and_fees: require("./Dealer Taxes and Fees.png"),
  dealer_retail: require("./Dealer Retail 1.png"),
  dealer_oos: require("./Dealer Out of State.png"),
  //    dealer_whole_sale : require("./deale")
  dealer_export: require("./Dealer Export.png"),
  dealer_finance: require("./FinanceBHp.png"),
  dealer_rent_lease: require("./Dealer Rent & Lease.png"),
  dealer_forms: require("./Forms Dealer.png"),
  dealer_user: require("./Dealer Users.png"),
  dealer_all_user: require("./Dealer All Users.png"),
  dealer_setup_user: require("./Dealer_Setup_User.png"),
  dealer_deleted_user: require("./Dealer Deleted User.png"),
  dealer_lien_holders: require("./Dealer Lienholder.png"),
  dealer_floor_company: require("./Dealer Floor Company.png"),
  dealer_gap_insurance: require("./Dealer Gap Insurance.png"),
  dealer_insurance_company: require("./Dealer Insurance Company.png"),
  dealer_inventory: require("./Dealer Inventory.png"),
  dealer_import_export: require("./Dealer Import & Export.png"),
  dealer_inventory_tax_maker: require("./Dealer Inventory Tax Maker.png"),
  dealer_warranty_company: require("./Dealer Warranty Company.png"),
  defaultImage: require("./defaultImg.png"),
  defaultImg: require("./Frame 1447.png"),
  connectedadvmanager: require("./connected.png"),
  connectadvmanager: require("./connect.png"),
  green_smoke_icon: require("./green_smoke_img.png"),
  yahooLogo: require("./yahoo.png"),
  carImageDetails: require("./carDetailsImage.png"),
  // Marque Logo Images
  honda: require("./honda-logo-2000-full-640.png"),
  landrover: require("./Land-Rover.png"),
  mazda: require("./mazda-logo.png"),
  mercedesbenz: require("./Mercedes-Logo.png"),
  mini: require("./Mini-logo.png"),
  opel: require("./Opel-logo.png"),
  toyta: require("./p1ngegg.png"),
  alfaromeo: require("./alfaRomeo.png"),
  bantley: require("./bantley.png"),
  // peugeot: require('./peugeot.png'),
  mitsubishi: require("./Mitsubishi.png"),
  maserati: require("./Maserati.png"),
  volvo: require("./Volvo.png"),
  porsche: require("./Porsche.png"),
  wolswagon: require("./WolsWagon.png"),
  lamborghini: require("./Lamborghini.png"),
  audi: require("./Audi.png"),
  bmw: require("./BMW.png"),
  buick: require("./Buick.png"),
  chevrolet: require("./Chevrolet.png"),
  chrysler: require("./chrysler.png"),
  dodge: require("./dodge.png"),
  ferrari: require("./ferrari.png"),
  fiat: require("./Fiat.png"),
  ford: require("./ford.png"),
  gmc: require("./GMC.png"),
  leads: require("./lead.png"),
  // images single car details----
  vectorImg: require("./Vector.png"),
  driveTrain: require("./DriveTrain.png"),
  bodyType: require("./bodyType.png"),
  incomplete: require("./incomplete.png"),
  passanger: require("./passager.png"),
  mpv: require("./mpv.png"),
  balls_loader: require("./ball_loaders.gif"),
  notification_bell: require("./notification.gif"),
  // threeSixty: require('./istockphoto.png'),
  selectCat: require("./Select category.png"),
  dropHere: require("./Frame 1447.png"),
  slider_icon1: require("./slider-car1.png"),
  slider_icon2: require("./slider-car2.png"),
  slider_icon3: require("./slider-car3.png"),
  slider_icon4: require("./slider-car4.png"),
  powerButton : require("./power-button.png"),
  car_bg3: require("./car-bg3.jpg"),
  web_screenshot_1: require("./Preview-1.png"),
  web_screenshot_2: require("./Preview-2.png"),
  docxPreview: require("./preview.png"),
  web3_car_home_image: require("./web3_car_home_image.png"),
  web3_about_image: require("./web3_about_us.png"),
  web3_gmap_image: require("./web3_image.png"),
  web3_card_car_image: require("./web3_card_car.png"),
  // web4 logo images slider----
  logo1: require("./logo1.webp"),
  logo2: require("./logo2.webp"),
  logo3: require("./logo3.webp"),
  logo4: require("./logo4.webp"),
  logo5: require("./logo5.webp"),
  car_website: require("./carWebsite.png"),
  web_screenshot_3: require("./Web_screenshot3.png"),
  web_screenshot_4: require("./web_screenshot_4.png"),
  inventory_not_selected: require("./Inventory_not_selected.png"),
  sidebarMyFavrites: require("./red_heart_icon.png"),
  three_sixty_icon: require("./360.png"),
  support_icon: require("./support.webp"),
  mailSetting: require("./mailSetting.png"),
  billingAdd: require("./billing.png"),
  logina:require("./Login-1.png"),
  loginb:require("./Login.png"),
  logouta:require("./logouta.png"),
  logoutb:require("./logoutb.png"),
  // web preview images or steps-----------
  godaddy: {
    login: require("./goda.png"),
    product: require("./godb.jpg"),
    manageDomains:  require("./godc.jpg"),
    updateDomain: require("./godd.jpg"),
    manageDNS:  require("./gode.jpg"),
    updateDNS: require("./godf.jpg"),
    save:  require("./godg.jpg"),
  },
  namecheap: {
    login: require("./namecha.jpg"),
    product: require("./namechb.jpg"),
    manageDomains:  require("./namechc.jpg"),
    updateDomain: require("./namechd.jpg"),
    manageDNS:  require("./nameche.jpg"),
    updateDNS: require("./namechf.jpg"),
    // save:  require("./godg.jpg"),
    
  },
  // -------------------------------------
  websiteIcon : require("./websiteIcon.png")
};
export default Image;
