import React, { forwardRef, useEffect, useState } from 'react'
import './Calculator.css';
import { withExtraArgument } from 'redux-thunk';

const MonthlyLoanCalculator = forwardRef((props, ref) => {
  const [vehiclePrice, setVehiclePrice] = useState("");
  const [tradeInValue, setTradeInValue] = useState(0);
  const [ttlPrice,setTtlPrice] = useState(0);
  const [interestRate, setInterestRate] = useState("6.85");
  const [downPayment, setDownPayment] = useState(0);
  const [loanTermInMonth, setLoanTermInMonth] = useState("48");
  const [activeButton, setActiveButton] = useState('48');
  const [monthlyPayment, setMonthlyPayment] = useState(0);
  const [loanAountData, setLoanAmountData] = useState(0);
  const [activeRate, setActiveRate] = useState(6.85); //for button bg color only----------
  const [showInput, setShowInput] = useState(false);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (vehiclePrice) {
      // Calculate 10% of vehiclePrice
      const calculatedDownPayment = parseFloat(vehiclePrice) * 0.10;
      setDownPayment(calculatedDownPayment); // Update downPayment with two decimal places
    } else {
      setDownPayment(""); // Clear downPayment if vehiclePrice is empty
    }
  }, [vehiclePrice]);

  useEffect(() => {
    if (vehiclePrice) {
      // Calculate 65% of vehiclePrice
      const calculatedttlPrice = parseFloat(vehiclePrice) * 0.65 + 150;
      const calculatedTtlPrice = Math.ceil(calculatedttlPrice);
      setTtlPrice(calculatedTtlPrice); // Update downPayment with two decimal places
    } else {
      setTtlPrice(""); // Clear downPayment if vehiclePrice is empty
    }
  }, [vehiclePrice]);

  useEffect(() => {
    calculateMonthlyPayment();
  }, [vehiclePrice, tradeInValue, interestRate, downPayment, loanTermInMonth,ttlPrice]);
  
  const calculateMonthlyPayment = () => {
    const P = parseFloat(vehiclePrice) + parseFloat(ttlPrice);
    const TIV = parseFloat(tradeInValue);
    const r = parseFloat(interestRate) / 100 / 12;
    const DP = parseFloat(downPayment);
    const n = parseInt(loanTermInMonth, 10);

    if (isNaN(P) || isNaN(TIV) || isNaN(r) || isNaN(DP) || isNaN(n)) {
      return;
    }

    const loanAmount = P - DP - TIV;
    setLoanAmountData(loanAmount);
    const M = loanAmount * (r * Math.pow((1 + r), n)) / (Math.pow((1 + r), n) - 1);
    //const roundedValue = M > 0 ? Math.round(M) : Math.ceil(M); 
    setMonthlyPayment(Math.ceil(M));
  };
  // bg color-----------------------------------
  const handleButtonClickb = (rate) => {
    setInterestRate(rate);
    setActiveRate(rate); // Set the active button based on the rate
  };

  // Function to get button styles based on whether it's active
  const getButtonStyles = (rate) => ({
    backgroundColor: activeRate === rate ? '#d1823a' : 'transparent', // Active button color
    color: activeRate === rate ? 'white' : 'white', // Active text color
  });
  // btn bg color---------------------------------------
  console.log("vehiclePrice:", vehiclePrice);
  console.log("tradeInValue:", tradeInValue);
  console.log("interestRate:", interestRate);
  console.log("downPayment:", downPayment);
  console.log("loanTermInMonth:", loanTermInMonth);

  // const handleButtonClick = (value) => {
  //   if (activeButton === value) {
  //     setActiveButton(null);
  //     setLoanTermInMonth('');
  //   } else {
  //     setActiveButton(value);
  //     setLoanTermInMonth(value);
  //   }
  // };
  const handleButtonClick = (value) => {
    if (activeButton === value) {
      setActiveButton(null);
      setLoanTermInMonth('');
    } else {
      setActiveButton(value);
      setLoanTermInMonth(value);
    }
  };
  
  const getButtonStyle = (value) => {
    return activeButton === value ? { backgroundColor: '#d1823a' } : {};
  };
  
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };
  
  const handleInputKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleButtonClick(inputValue);
      setShowInput(false); // Hide input field after pressing Enter
    }
  };
  
  const handleInputBlur = () => {
    // Hide input field if it loses focus
    setShowInput(false);
  };

  return (
    <section style={{ paddingTop: "20px", paddingBottom: "20px", backgroundColor: "#6b6363",border:"none" }} ref={ref}>
      <h3 className="text-center text-dark fw-bold text-white">
        Auto Loan Calculator
      </h3>
      <div className="col-12" style={{ marginRight: "5%", display: "flex", justifyContent: "space-evenly" }}>
        <div className="col-4">
          <div className="col-12">
            {/* <div className=""> */}
            <div className="view">
              <div className="details">
                <div className='mt-2' style={{ display: "flex", flexDirection: "row", color: "white", justifyContent: "space-between", alignItems: "center" }}><span><p style={{ marginBottom: "0px" }}>Vehicle Price</p></span><span><p style={{ marginBottom: "0px" }}></p></span></div>
                <div className="input-container-percentage">
                  <input type="text" className="form-control text-center" value={vehiclePrice} style={{ fontWeight: "bolder", letterSpacing: "2px", fontSize: "20px"}} onChange={(e) => setVehiclePrice(e.target.value)} />
                  <span className="percentage-symbol" style={{ fontWeight: "bolder", fontSize: "20px" }}>$</span>
                </div>
                <div className='mt-2' style={{ display: "flex", color: "white", flexDirection: "row", justifyContent: "space-between" }}><span><p style={{ marginBottom: "0px" }}>Terms(Month)</p></span><span><p style={{ marginBottom: "0px" }}></p></span></div>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: 'center' }}>
                  <span className='btn-car-details'>
                    <button type='button' onClick={() => handleButtonClick('36')} style={getButtonStyle('36')}>
                      36
                    </button>
                  </span>
                  <span className='btn-car-details'>
                    <button type='button' onClick={() => handleButtonClick('48')} style={getButtonStyle('48')}>
                      48
                    </button>
                  </span>
                  <span className='btn-car-details'>
                    <button type='button' onClick={() => handleButtonClick('60')} style={getButtonStyle('60')}>
                      60
                    </button>
                  </span>
                  <span className='btn-car-details'>
                    <button type='button' onClick={() => handleButtonClick('72')} style={getButtonStyle('72')}>
                      72
                    </button>
                  </span>
                  {/* <span className='btn-car-details'>
                    <button type='button' onClick={() => handleButtonClick('84')} style={getButtonStyle('84')}>
                      84
                    </button>
                  </span> */}
                  {/* <span className='btn-car-details'>
                    {showInput ? (
                      <input
                        type='text'
                        value={inputValue}
                        onChange={handleInputChange}
                        onKeyDown={handleInputKeyDown}
                        onBlur={handleInputBlur}
                        placeholder='Enter'
                        style={{ marginLeft: '10px', width: '65px',height:"40px",borderRadius:"5px" }}
                      />
                    ) : (
                      <button type='button' onClick={handleMoreButtonClick} style={getButtonStyle('more')}>
                        more
                      </button>
                    )}
                  </span> */}
                  <span className='btn-car-details'>
                    <input
                      type='text'
                      value={inputValue}
                      onChange={handleInputChange}
                      onKeyDown={handleInputKeyDown}
                      onBlur={handleInputBlur}
                      placeholder='More'
                      style={{
                        marginLeft: '10px',
                        width: '65px',
                        height: "40px",
                        paddingLeft:"13px",
                        borderRadius: "5px",
                        ...getButtonStyle('input')
                      }}
                    />
                  </span>
                </div>
                <div className='mt-2' style={{ display: "flex", color: "white", flexDirection: "row", justifyContent: "space-between" }}><span><p style={{ marginBottom: "0px" }}>Traded-in-value</p></span><span><p style={{ marginBottom: "0px" }}></p></span></div>
                <div className="input-container-percentage">
                  <input type="text" className="form-control text-center" placeholder='0' style={{ fontWeight: "bolder", letterSpacing: "2px", fontSize: "20px" }} onChange={(e) => setTradeInValue(e.target.value)} />
                  <span className="percentage-symbol" style={{ fontWeight: "bolder", fontSize: "20px" }}>$</span>
                </div>
                <div className='mt-2' style={{ display: "flex", color: "white", flexDirection: "row", justifyContent: "space-between" }}><span><p style={{ marginBottom: "0px" }}>Down-payment</p></span><span><p style={{ marginBottom: "0px" }}></p></span></div>
                <div className="input-container-percentage">
                  <input type="text" className="form-control text-center" placeholder='0' value={downPayment} style={{ fontWeight: "bolder", letterSpacing: "2px", fontSize: "20px" }} onChange={(e) => setDownPayment(e.target.value)} />
                  <span className="percentage-symbol" style={{ fontWeight: "bolder", fontSize: "20px" }}>$</span>
                </div>
                <div className='mt-2' style={{ display: "flex", color: "white", flexDirection: "row", justifyContent: "space-between" }}><span><p style={{ marginBottom: "0px" }}>TTL(Title,Taxes and Licences Plates) Est.</p></span><span><p style={{ marginBottom: "0px" }}></p></span></div>
                <div className="input-container-percentage">
                  <input type="text" className="form-control text-center" placeholder='0' value={ttlPrice} style={{ fontWeight: "bolder", letterSpacing: "2px", fontSize: "20px" }} onChange={(e) => setTtlPrice(e.target.value)}/>
                  <span className="percentage-symbol" style={{ fontWeight: "bolder", fontSize: "20px" }}>$</span>
                </div>
                <div className='mt-2' style={{ display: "flex", flexDirection: "row", color: "white", justifyContent: "space-between" }}><span><p style={{ marginBottom: "0px" }}>Approx. Credit Score</p></span><span><p style={{ marginBottom: "0px" }}></p></span></div>
                {/* <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                  <span className='txt-btn-car-details' onClick={() => setInterestRate(11)}><button type='button'>
                    <p style={{ fontWeight: "bold" }}>Poor</p>
                    <p>630 or less</p>
                  </button></span>
                  <span className='txt-btn-car-details' onClick={() => setInterestRate(6.85)}><button type='button'>
                    <p style={{ fontWeight: "bold" }}>Fair</p>
                    <p>640 or 699</p>
                  </button></span>
                  <span className='txt-btn-car-details' onClick={() => setInterestRate(5.85)}><button type='button'>
                    <p style={{ fontWeight: "bold" }}>Good</p>
                    <p>700 or 749</p>
                  </button></span>
                  <span className='txt-btn-car-details' onClick={() => setInterestRate(4)}><button type='button'>
                    <p style={{ fontWeight: "bold" }}>Excellent</p>
                    <p>750 or 850</p>
                  </button></span>
                </div> */}
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                  <span className='txt-btn-car-details-home'>
                    <button
                      type='button'
                      style={getButtonStyles(11)}
                      onClick={() => handleButtonClickb(11)}
                    >
                      <p style={{ fontWeight: "bold" }}>Poor</p>
                      <p>630 or less</p>
                    </button>
                  </span>
                  <span className='txt-btn-car-details-home'>
                    <button
                      type='button'
                      style={getButtonStyles(6.85)}
                      onClick={() => handleButtonClickb(6.85)}
                    >
                      <p style={{ fontWeight: "bold" }}>Fair</p>
                      <p>640 or 699</p>
                    </button>
                  </span>
                  <span className='txt-btn-car-details-home'>
                    <button
                      type='button'
                      style={getButtonStyles(5.85)}
                      onClick={() => handleButtonClickb(5.85)}
                    >
                      <p style={{ fontWeight: "bold" }}>Good</p>
                      <p>700 or 749</p>
                    </button>
                  </span>
                  <span className='txt-btn-car-details-home'>
                    <button
                      type='button'
                      style={getButtonStyles(4)}
                      onClick={() => handleButtonClickb(4)}
                    >
                      <p style={{ fontWeight: "bold" }}>Excellent</p>
                      <p>750 or 850</p>
                    </button>
                  </span>
                </div>
                <div className='mt-2' style={{ display: "flex", color: "white", flexDirection: "row", justifyContent: "space-between" }}><span><p style={{ marginBottom: "0px" }}>Est. APR</p></span><span><p style={{ marginBottom: "0px" }}></p></span></div>
                <div className="input-container-percentage">
                  <input type="text" value={interestRate} name='percentageData' className="form-control text-center" style={{ fontWeight: "bolder", letterSpacing: "2px", fontSize: "20px" }} placeholder="0" onChange={(e) => setInterestRate(e.target.value)} />
                  <span className="percentage-symbol" style={{ fontWeight: "bolder", fontSize: "20px" }}>%</span>
                </div>
                {/* <div className='mt-2' style={{ display: "flex", color: "white", flexDirection: "row", justifyContent: "space-between" }}><span><p style={{ marginBottom: "0px" }}>ZIP Code</p></span><span><p style={{ marginBottom: "0px" }}></p></span></div>
                <div>
                  <input type="text" className="form-control" placeholder="23234" />
                </div>
                <div className='mt-2'><p style={{ fontSize: "14px", color: "white",}}>This is only estimated monthly payment actual monthly payments will very. Does not represent a fanancing offer or guarantee of credit</p>
                </div> */}
              </div>
            </div>
          </div>
          {/* <div className="col-12 est-monthly-heading mt-2">
            <div><h5 style={{ marginBottom: "0px", marginTop: "9px", fontWeight: "900", color: "#006099" }}>$501</h5></div>
            <div><p>Est. Monthly Payment</p></div>
          </div> */}
          {/* </div> */}
          {/* <div><button type='button'>Calculate Payment</button></div> */}
        </div>
        <div className="col-4">
          <div className='est-monthly-heading-home animated-box' style={{height:"20%", marginTop:"32px"}}>
          <div><h4 style={{ marginBottom: "0px", marginTop: "9px", fontWeight: "900", color: "#006099" }}>${new Intl.NumberFormat().format(monthlyPayment)}</h4></div>
          <div><p style={{ marginBottom: "0px" }}>Est. Monthly Payment</p>
          </div>
          </div>
          <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",marginTop:"10%",marginLeft:"4%"}}>
            <div className='text-box-cal'>Apply For Loan</div>
            <div className='text-box-cal'><p>Total loan amount <span style={{ fontWeight: "bold" }}>${new Intl.NumberFormat().format(loanAountData)}</span></p></div>
          </div>
        </div>
      </div>
    </section>
  )
});

export default MonthlyLoanCalculator;