import React, { useEffect, useState } from "react";
import Image from "../../imgData";
import "../Styles/ParentDialogue.css";
import EmailLogin from "./EmailLogin";
import SocialBox from "../components/SocialBox";
import PassLogin from "./PassLogin";
import OtpLogin from "./OtpLogin";
import ForgetPass_Email from "./ForgetPass_Email";
import ForgetPass_OTP from "./ForgetPass_OTP";
import SetPass from "./SetPass";
import SetPass_ForgetPass from "./SetPass_ForgetPass";
import DeviceVerifyOTP from "./DeviceVerifyOTP";

const ParentDialogue = ({ setShowLoginDialogueBox }) => {
  const handleBoxView = () => {
    setShowLoginDialogueBox(false);
  };

  const [resUser, setResUser] = useState(null);
  const [otpVerified, setOtpverified] = useState(false);
  const [isLoginEmail, setIsEmailLogin] = useState(false);
  const [isPassWordForgot, setIsPassWordForgot] = useState(false);
  const [forgetPassOtpVerified, setForgetPassOtpVerified] = useState(false);
  const [deviceVerify, setDeviceVerify] = useState(true);
  const [countryCode, setCountryCode] = useState('');
  const [extraInformation, setExtraInformation] = useState({
    password : "",
    fingerprint: ""
  });
  console.log("Extra Information",extraInformation);
  console.log("Device verify", deviceVerify);
  console.log("isLoginEmail in ParentDialogue", isLoginEmail);
  console.log("otpVerified", otpVerified);
  console.log(
    "forgetPassOtpVerified in Parent Dialogue",
    forgetPassOtpVerified
  );
  console.log("resUser in parent Dialogue", resUser);

  // // useEffect to get data from the url
  // useEffect(() => {
  //   // Function to get a specific query parameter
  //   const getQueryParam = (param) => {
  //     const searchParams = new URLSearchParams(window.location.search);
  //     return searchParams.get(param);
  //   };

  //   // Extract email and countryCode
  //   const emailParam = getQueryParam('email');
  //   const countryCodeParam = getQueryParam('countryCode');

  //   // Update state
  //   if (emailParam) setResUser(emailParam);
  //   if (countryCodeParam) setCountryCode(countryCodeParam);
  //   if(emailParam && countryCodeParam) setOtpverified(true)
  // }, []);

  return (
    <div className="GrandParent">
      <div className="closeIcon" onClick={handleBoxView}>
        <img src={Image.closeIcon} alt="Close Icon" height={20} width={20} />
      </div>

      {/* isloginEmail is  */}
      {forgetPassOtpVerified ? (
        <div className="child">
          <SetPass_ForgetPass
            isLoginEmail={isLoginEmail}
            setResUser={setResUser}
            setForgetPassOtpVerified={setForgetPassOtpVerified}
            setIsPassWordForgot={setIsPassWordForgot}
            setIsEmailLogin={setIsEmailLogin}
          />
        </div>
      ) : isLoginEmail ? (
        <div className="child">
          {isPassWordForgot ? (
            <ForgetPass_OTP
              isLoginEmail={isLoginEmail}
              setForgetPassOtpVerified={setForgetPassOtpVerified}
            />
          ) : !deviceVerify ? (
            <DeviceVerifyOTP
              isLoginEmail={isLoginEmail}
              extraInformation={extraInformation}
              setShowLoginDialogueBox={setShowLoginDialogueBox}
            />
          ) : (
            // Pass Login is for logging the password during login
            <PassLogin
              isLoginEmail={isLoginEmail}
              setIsPassWordForgot={setIsPassWordForgot}
              setDeviceVerify={setDeviceVerify}
              setExtraInformation={setExtraInformation}
              setShowLoginDialogueBox={setShowLoginDialogueBox}
            />
          )}
        </div>
      ) : resUser === null ? ( // resUser is getting after user signing in
        <div className="child">
          <EmailLogin
            setResUser={setResUser}
            setIsEmailLogin={setIsEmailLogin}
          />
        </div>
      ) : !otpVerified ? (
        <div className="child">
          <OtpLogin resUser={resUser} setOtpverified={setOtpverified} />
        </div>
      ) : (
        <div className="child">
          {/* Set Pass is for saving the password during the signup */}
          <SetPass
            resUser={resUser}
            countryCode={countryCode}
            setShowLoginDialogueBox={setShowLoginDialogueBox}
          />
        </div>
      )}

      <div>
        <SocialBox setShowLoginDialogueBox ={setShowLoginDialogueBox}/>
      </div>
    </div>
  );
};

export default ParentDialogue;
