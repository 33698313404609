import React, { useEffect, useState } from "react";
import Image from "../../imgData";
import "../Styles/Dealer_login_feature.css";
import Socialchild from "../../UserAuthDialogue/components/Socialchild";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";
import SignInWithApple from "../../UserAuthDialogue/components/SignInwithApple";
import YahooSignIn from "../../UserAuthDialogue/components/SignInWithYahoo";
import SignInWithFacebook from "../../UserAuthDialogue/components/fblogin";

import "react-toastify/dist/ReactToastify.css";
import {
  initializeGoogleSignIn,
  handleGoogleSignIn,
} from "../../UserAuthDialogue/components/SignInWithGoogle";
import toast, { Toaster } from "react-hot-toast";

import { GoogleOAuthProvider } from "@react-oauth/google";
import GoogleConsoleLogin from "../../UserAuthDialogue/components/GoogleConsoleLogin";
import { saveUser } from "../../../Redux/slice/userSlice";
const Dealer_Login_feature = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [inputEmail, setInputEmail] = useState("");
  console.log(inputEmail);
  const [isUserExists, setIsUserExists] = useState(true);
  console.log("Does user exist ?", isUserExists);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleContinueResult = async () => {
    console.log("Handling API continue Result");
    setLoading(true);
    try {
      const unformattedPhoneNumber = inputEmail.replace(/\D/g, "");
      const formData = new FormData();
      if (/^\d{10}$/.test(unformattedPhoneNumber)) {
        console.log(unformattedPhoneNumber);
        console.log("Number detected");

        formData.append("email", unformattedPhoneNumber);
      } else {
        console.log("Number not detected");
        formData.append("email", inputEmail);
      }
      formData.append("user_type", "Dealer");
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/signin`,
        formData
      );

      if (res.status === 200) {
        console.log(res.data);

        navigate("/dealer/inputlogin", { state: res.data });
      }
      console.log(res);
    } catch (error) {
      console.log(error, "Error while logging the user");
      setLoading(false);
      if (error.response.status === 400) {
        toast.error(error.response.data);
      } else if (error.response.status === 500) {
        toast.error(error.response.data);
      } else if (error.response.status === 404) {
        toast.error(error.response.data);
      }
    }
  };

  const [countryCode, setCountryCode] = useState("");
  console.log(countryCode);
  useEffect(() => {
    const getUserCountryCode = async () => {
      try {
        const res = await axios.get("https://ipapi.co/json");
        console.log("User all info", res.data);
        setCountryCode(res.data.country_calling_code);
        // setIP(res.data.ip)
      } catch (error) {
        console.log("error while getting ip ", error);
      }
    };
    getUserCountryCode();
  }, [countryCode]);
  const handleCreateResult = async () => {
    try {
      setLoading(true);
      const unformattedPhoneNumber = inputEmail.replace(/\D/g, "");
      const formData = new FormData();
      if (/^\d{10}$/.test(unformattedPhoneNumber)) {
        console.log(
          "unformattedPhoneNumber number sent to backend",
          unformattedPhoneNumber
        );
        console.log("Number detected");

        formData.append("email", unformattedPhoneNumber);
      } else {
        console.log("Number not detected");
        formData.append("email", inputEmail);
      }
      formData.append("user_type", "Dealer");
      formData.append("country_code", countryCode);
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/send_link`,
        formData
      );

      if (res.status === 200) {
        console.log(res.data);
        setLoading(false);
        toast.success("OTP sent to your device");
        navigate("/dealer/signupOTP", { state: res.data });
      }
      console.log("resUser is for signup");
      console.log(res);
    } catch (error) {
      console.log(error, "Error while creating the user");
      setLoading(false);
      if (error.response.status === 400) {
        toast.error(error.response.data);
        setIsUserExists(true);
        setInputEmail("");
      } else if (error.response.status === 500) {
        toast.error(error.response.data);
      } else if (error.response.status === 404) {
        toast.error(error.response.data);
      }
    }
  };
  useEffect(() => {
    if (inputEmail.trim() === "") {
      setIsUserExists(true);
    }
  }, [inputEmail]);
  useEffect(() => {
    const handlekeypress = (e) => {
      if (e.key === "Enter") {
        if (isUserExists) {
          handleContinueResult();
        } else {
          handleCreateResult();
        }
      }
    };
    window.addEventListener("keypress", handlekeypress);
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("keypress", handlekeypress);
    };
  }, [isUserExists, inputEmail]);

  const handleInputChange = async (e) => {
    const { value } = e.target;

    // Check if the input is a phone number
    if (/^\d{10}$/.test(value)) {
      // Format the phone number to USA format (assuming 10 digits)
      const formattedPhoneNumber = `(${value.slice(0, 3)}) ${value.slice(
        3,
        6
      )}-${value.slice(6)}`;
      setInputEmail(formattedPhoneNumber);

      // Remove formatting for API submission
      const unformattedPhoneNumber = value.replace(/\D/g, "");
      if (unformattedPhoneNumber.length === 10) {
        await callAPI(unformattedPhoneNumber);
      }
    } else {
      setInputEmail(value);

      // Check if a dot is typed after "@"
      const indexOfAt = value.indexOf("@");
      const indexOfDot = value.indexOf(".", indexOfAt);
      if (indexOfDot !== -1) {
        // Call your API here
        await callAPI(value);
      }
    }
  };

  const callAPI = async (email) => {
    try {
      console.log("Calling the API");
      const formData = new FormData();
      formData.append("email", email);
      formData.append("user_type", "Dealer");
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/check_account`,
        formData
      );
      console.log(res.data);
      if (res.status == 200) {
        // navigate("/loginpassword", { state: res.data });
        console.log("Status 200");
        setIsUserExists(res.data);
      } else {
        console.log("Login Failed...");
      }
    } catch (error) {
      console.log("error in catch");
    }
  };

  //Tracking the scren size for the tablet and mobile
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const [isScreenSmaller, setIsScreenSmaller] = useState(false);

  console.log("Is this screen size smaller than 1024 => ", isScreenSmaller);
  useEffect(() => {
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    if (screenSize >= 1024) {
      setIsScreenSmaller(false);
    } else {
      setIsScreenSmaller(true);
    }
  }, [screenSize]);
  const handleAppleSign = async () => {
    try {
      await SignInWithApple(dispatch, navigate, "Dealer", isScreenSmaller);
    } catch (error) {
      toast.error(error.message);
    }
  };
  const handleYahooSignIn = async () => {
    try {
      await YahooSignIn(dispatch, navigate, "Dealer");
    } catch (error) {
      toast.error(error.message);
    }
  };
  // Google Sign-in

  const [googleInitialized, setGoogleInitialized] = useState(false);

  useEffect(() => {
    initializeGoogleSignIn()
      .then(() => {
        setGoogleInitialized(true);
        console.log("Google Sign-In initialized successfully");
      })
      .catch((error) => {
        console.error("Failed to initialize Google Sign-In:", error);
        toast.error("Failed to initialize Google Sign-In");
      });
  }, []);
  const handleGoogleSignInClick = async () => {
    if (!googleInitialized) {
      toast.error("Google Sign-In is not initialized");
      return;
    }

    try {
      await handleGoogleSignIn(dispatch, navigate, "Dealer");
      toast.success("Sign-in successful!");
    } catch (error) {
      console.error("Google Sign-In failed:", error);
      toast.error(error.message || "Google Sign-In failed");
    }
  };

  const handleFacebookSignIn = async () => {
    console.log("Clicked the FB login button");
    try {
      await SignInWithFacebook(dispatch, navigate, "Dealer", isScreenSmaller);
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    }
  };

  return (
    <div className="login_feature_wrapper">
      <div className="login_feature_container">
        <p
          className="welcome_message"
          style={{ fontWeight: "700", fontSize: "xx-large" }}
        >
          Dealer Log-in
        </p>
        {/* <p className="sign_in_message">Please enter Phone number of Email </p> */}
        {/* <label htmlFor="email_username" className="email_username_label">
          Email or User Name *
        </label> */}
        <div className="dealer_email_container">
          <input
            type="text"
            id="email_username"
            onChange={handleInputChange}
            value={inputEmail}
            placeholder="Enter Phone or Email"
          />
        </div>
        <p style={{ display: "flex", justifyContent: "end", color: "end" }}>
          <span>{error}</span>
        </p>
        {isUserExists ? (
          <button
            onClick={handleContinueResult}
            className={loading ? "blink_now" : ""}
            style={{ fontWeight: "600", fontSize: "x-large" }}
            disabled={inputEmail.trim() === "" ? true : false}
          >
            <span>Continue</span>
            {loading ? (
              <img
                src="https://dacbuckets3.s3.amazonaws.com/sport-car.png"
                alt="animated car icon"
                className="animated_car_icon"
              />
            ) : null}
          </button>
        ) : (
          <button
            onClick={handleCreateResult}
            className={loading ? "blink_now" : ""}
            style={{ fontWeight: "600", fontSize: "x-large" }}
            disabled={inputEmail.trim() === "" ? true : false}
          >
            <span>Create</span>
            {loading ? (
              <img
                src="https://dacbuckets3.s3.amazonaws.com/sport-car.png"
                alt="animated car icon"
                className="animated_car_icon"
              />
            ) : null}
          </button>
        )}

        <div
          className="dealer_social_container"
          style={{ fontWeight: "700", fontSize: "xx-large" }}
        >
          <div onClick={handleAppleSign}>
            <Socialchild imgSrc={Image.apple_logo} socialName="Apple" />
          </div>
          <div onClick={handleYahooSignIn}>
            <Socialchild imgSrc={Image.yahooLogo} socialName="Yahoo" />
          </div>
          {/* <div onClick={handleGoogleSignInClick}>
            <Socialchild imgSrc={Image.google_logo} socialName="Google" />
          </div> */}
          <div>
            <GoogleOAuthProvider clientId="854910170922-t0t33tjvq2dubabr5a54j9o89knqvarv.apps.googleusercontent.com">
              <GoogleConsoleLogin userType="Dealer" />
            </GoogleOAuthProvider>
          </div>
          <div onClick={handleFacebookSignIn}>
            <Socialchild imgSrc={Image.fb_logo} socialName="Facebook" />
          </div>
          {/* <span className="dealer_fb_container" style={{ color: "white" }}>
            <img src={Image.facebookIcon} alt="fb" height={35} />
            <SignInWithFB
              dispatch={dispatch}
              navigate={navigate}
              userType="Dealer"
            />
          </span> */}

          {/* <div>
            <Socialchild imgSrc={Image.ig_logo} socialName="Instagram" />
          </div> */}
          {/* <div>
            <Socialchild imgSrc={Image.twitter_x_logo} socialName="Twitter" />
          </div> */}
          {/* <div>
            <Socialchild imgSrc={Image.ms_logo} socialName="Microsoft" />
          </div> */}
        </div>
      </div>
      <Toaster
        position="top-right"
        reverseOrder={false}
        toastOptions={{
          // Define default options
          className: "",
          duration: 1000,
          style: {
            background: "#363636",
            color: "#fff",
            fontSize: "larger",
          },
        }}
      />
    </div>
  );
};

export default Dealer_Login_feature;
