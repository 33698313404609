import React from "react";
import Styles from "./DealerRetail.module.css";

const DealerRetailInputs = ({head,type,name,InputValue,handleChange}) => {
  const formatPhoneNumber = (value) => {
    // Normalize the value (remove non-digit characters)
    const cleaned = ('' + value).replace(/\D/g, '');
    
    // Format as US phone number (XXX) XXX-XXXX or XXX-XXX-XXXX
    const match = cleaned.match(/^(1?)(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return match[1] ? `+1 (${match[2]}) ${match[3]}-${match[4]}` : `(${match[2]}) ${match[3]}-${match[4]}`;
    }
    
    return value; // Return original value if it doesn't match
  };
  const handleInputChange = (e) => {
    handleChange(name,e.target.value)
  }
  const displayValue = name === 'landlineNumber' || name === 'phoneNumber'
  ? formatPhoneNumber(InputValue)
  : InputValue;
  const maxLength = (head === "Mobile Number" || head === "Land Line") ? 10 : undefined;
  return (
      <div className={Styles.input_wrapper}>
        <p className={Styles.input_header}>{head}</p>
        <div className={Styles.input_container}>
          <input type="text" value={displayValue} onChange={handleInputChange} style={{fontWeight : "600", fontSize : "x-large"}}  maxLength={maxLength}/>
          <span>{type}</span>
        </div>
      </div>

  );
};

export default DealerRetailInputs;
