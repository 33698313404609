import React, { useEffect, useState } from "react";
import Styles from "../DealerSetupUser/setup.module.css";
import { Button, Col, Row } from "react-bootstrap";
import  RollsDeatail from "./RollsDeatail.jsx";
const Permissions = ({ formData, setFormData }) => {
  const [selectedTeam, setSelectedTeam] = useState("admin");
  const [customRole, setCustomRole] = useState("");
  const [customRoles, setCustomRoles] = useState([]);
// ----------------------------------------------------
const [showRollsModal, setShowRollsModal] = useState(false);
// const [rollsData, setRollsData] = useState([]);
const handleLinkClickRolls = () => {
  setShowRollsModal(true);
  // setRollsData();
};
const handleModalCloseRolls = () => {
  setShowRollsModal(false);
};
// -----------------------------------------------------
  const handleChange = (e) => {
    setSelectedTeam(e.target.value);
  };

  const handleCustomRoleChange = (e) => {
    setCustomRole(e.target.value);
  };

  const handleAddCustomRole = () => {
    if (customRole && !customRoles.includes(customRole)) {
      setCustomRoles([...customRoles, customRole]);
      setCustomRole(""); // Clear input field after adding
    }
  };
  // --------------------------------------------------------------
  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      roles: selectedTeam,
    }));
  }, [selectedTeam]);
  // --------------------------------------------------------------
  console.log("selectedTeam", selectedTeam);
  console.log("customRole", customRole);
  console.log("customRoles", customRoles);
  return (
    <Row className={Styles.permissionWrapper}>
      <Col className={Styles.permissions}>
        <p>
          <span>Grant Access</span>
        </p>
        <Row className={Styles.selectTeams}>
          <Col sm={4}>
            <label htmlFor="admin">
              <input
                type="radio"
                name="access"
                id="admin"
                value="admin"
                className={Styles.dealerPermissionRadio}
                onChange={handleChange}
                checked={selectedTeam === "admin"}
                style={{ marginRight: "1rem" }}
              />
              <span>Admin</span>
            </label>
          </Col>
          <Col sm={4}>
            <label htmlFor="manager">
              <input
                type="radio"
                name="access"
                id="manager"
                value="manager"
                onChange={handleChange}
                className={Styles.dealerPermissionRadio}
                checked={selectedTeam === "manager"}
                style={{ marginRight: "1rem" }}
              />
              <span>Manager</span>
            </label>
          </Col>
          <Col sm={4}>
            <label htmlFor="sales">
              <input
                type="radio"
                name="access"
                id="sales"
                value="sales"
                onChange={handleChange}
                className={Styles.dealerPermissionRadio}
                checked={selectedTeam === "sales"}
                style={{ marginRight: "1rem" }}
              />
              <span>Sales</span>
            </label>
          </Col>

          {/* Render custom roles as radio buttons */}
          {customRoles.length > 0 &&
            customRoles.map((role, index) => (
              <Col sm={4}>
                <label htmlFor={role}>
                  <input
                    type="radio"
                    name="access"
                    id={role}
                    value={role}
                    onChange={handleChange}
                    className={Styles.dealerPermissionRadio}
                    checked={selectedTeam === role}
                    style={{ marginRight: "1rem" }}
                  />
                  <span>{role}</span>
                </label>
              </Col>
            ))
          }

        </Row>

        {/* Section to add new custom roles */}
        {/* {selectedTeam === "customRoles" && (
            <> */}
        <div className="d-flex flex-nowrap">
          <input
            type="text"
            value={customRole}
            onChange={handleCustomRoleChange}
            placeholder="Enter custom role"
            className="p-1 w-25"
            style={{ marginRight: "1rem", borderRadius: '5px', borderColor: '#e7a162', }}
          />
          <Button className="btn-orange p-2 border-0 me-2" onClick={handleAddCustomRole}>Add Custom Role</Button>
          <Button className="btn-orange p-2 border-0"  onClick={() => handleLinkClickRolls()}>Role Details</Button>
        </div>
      </Col>
      <RollsDeatail
        show={showRollsModal}
        handleClose={handleModalCloseRolls}
        // data={previewData}
        />
    </Row>
  );
};

export default Permissions;
