import React, { useEffect, useState } from "react";
import "./Styles/DealerDashBoard.css";
import DealerDashBoardModal from "../DealerDashBoardModal/DealerDashBoardModal";

import BarChart from "./DealerHorizontalBarChart1";
import DealerVerticalChart2 from "./DealerVerticalChart2";
import DealerDoughNutChart3 from "./DealerDoughNutChart3";
import NavigationCircles4 from "./NavigationCircles4";
import QuarterRevenueChart5 from "./QuarterRevenueChart5";
import DiscountSection from "./DiscountSection";
import ProgressGraphs from "./Components/ProgressGraphs";
import LastWeekSalesComparison from "./Components/LastWeekSalesComparison";
import NavigationCircle from "./Components/NavigationCircle";
import NavigationCircles42 from "./NavigationCircles42";
import WarningCenter from "./WarningCenter";
import DealerMarquee from "./DealerMarquee";
import { saveUser } from "../../../Redux/slice/userSlice";
import { useDispatch } from "react-redux";
import { Col, Container, Row } from "react-bootstrap";

const DealerDashBoard = () => {
  const [showNavigationModal, setShowNavigationModal] = useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    if (showNavigationModal) {
      dispatch(saveUser({ isSideBarOpen: false }));
    }
  }, [showNavigationModal]);
  return (
    <div className="dealerDashBoard_Wrapper">
      <div className="dealerDashBoard_Container">
        {showNavigationModal ? (
          <DealerDashBoardModal
            setShowNavigationModal={setShowNavigationModal}
          />
        ) : null}

        {/* <div className="chart_first_row">
          <BarChart />
          <DealerVerticalChart2 />
          <DealerDoughNutChart3 />
        </div> */}
        {/* <div className="chart_second_row">
          <div className="navigation_circles_wrapper">
            <NavigationCircles4 />
          </div>
          <div className="quartely_revenue_wrapper">
            <QuarterRevenueChart5 />
          </div>
        </div> */}
        {/* <div className="chart_third_row">
          <div className="chart_third_row_a">
            <div className="discount_wrapper">
              <DiscountSection />
            </div>
            <div className="progress_wrapper">
              <ProgressGraphs text="Reviews" progress="250" color="#3d74d7" />
              <ProgressGraphs text="Ratings" progress="180" color="#3d74d7" />
              <ProgressGraphs text="Signups" progress="80" color="lightgreen" />
            </div>
          </div>
          <div className="chart_third_row_b">
            <LastWeekSalesComparison
              numbers="70"
              category="Products"
              categoryProgressStatus={true}
              progressNumbers="7.2"
            />
            <LastWeekSalesComparison
              numbers="650"
              category="Orders"
              categoryProgressStatus={true}
              progressNumbers="7.5"
            />
            <LastWeekSalesComparison
              numbers="995"
              category="Revenue"
              categoryProgressStatus={false}
              progressNumbers="5.7"
            />
          </div>
        </div> */}
        {/* <div className="chart_fourth_row">
          <NavigationCircles42 />
        </div>
        <div className="chart_fourth_row">
          <NavigationCircles42 />
        </div> */}
        {/* <div>
          <DealerMarquee />
        </div> */}
        <Container className="p-4">
          <Row className="d-flex justify-content-evenly">

            <Col sm={5} className="dashLable">Sales This Month $ 21,500</Col>
            <Col sm={5} className="dashValue">Value 1</Col>

            <Col sm={5} className="dashLable">Sales Year to Date $200,451 </Col>
            <Col sm={5} className="dashValue">Value 2</Col>

            <Col sm={5} className="dashLable">Sales By Users</Col>
            <Col sm={5} className="dashValue">Value 3</Col>

            <Col sm={5} className="dashLable">Sales By Make </Col>
            <Col sm={5} className="dashValue">Value 4</Col>

            <Col sm={5} className="dashLable">Sales Summery By Type (Retail, Fiannce,)</Col>
            <Col sm={5} className="dashValue">Value 5</Col>

            <Col sm={5} className="dashLable">All Inventory</Col>
            <Col sm={5} className="dashValue">Value 6</Col>

            <Col sm={5} className="dashLable">All Sold This Month </Col>
            <Col sm={5} className="dashValue">Value 7</Col>

            <Col sm={5} className="dashLable">all Sold Year to date</Col>
            <Col sm={5} className="dashValue">Value 8</Col>

            <Col sm={5} className="dashLable">This month Total Collected Taxes </Col>
            <Col sm={5} className="dashValue">Value 9</Col>

            <Col sm={5} className="dashLable">Year to date Collected taxes</Col>
            <Col sm={5} className="dashValue">Value 10</Col>

            <Col sm={5} className="dashLable">Collected Fees </Col>
            <Col sm={5} className="dashValue">Value 11</Col>

            <Col sm={5} className="dashLable">Collected Fees Y to date </Col>
            <Col sm={5} className="dashValue">Value 12</Col>

            <Col sm={5} className="dashLable">All Documenateen fees (This month & Year to date)</Col>
            <Col sm={5} className="dashValue">Value 13</Col>

            <Col sm={5} className="dashLable">All Inspected </Col>
            <Col sm={5} className="dashValue">Value 14</Col>

            <Col sm={5} className="dashLable">All Need Inspection </Col>
            <Col sm={5} className="dashValue">Value 15</Col>

            <Col sm={5} className="dashLable">Inspection not classified</Col>
            <Col sm={5} className="dashValue">Value 16</Col>

            <Col sm={5} className="dashLable">All active Balance </Col>
            <Col sm={5} className="dashValue">Value 17</Col>

            <Col sm={5} className="dashLable">Loan Applications </Col>
            <Col sm={5} className="dashValue">Value 18</Col>

            <Col sm={5} className="dashLable">All leads </Col>
            <Col sm={5} className="dashValue">Value 19</Col>

            <Col sm={5} className="dashLable">Titles status ( Available out of total) received not received</Col>
            <Col sm={5} className="dashValue">Value 20</Col>

            <Col sm={5} className="dashLable">Total Advertised out of not advertised</Col>
            <Col sm={5} className="dashValue">Value 21</Col>

            <Col sm={5} className="dashLable">Advertised on google, facebook , website, and otehr platforms.</Col>
            <Col sm={5} className="dashValue">Value 22</Col>
          </Row>
        </Container>
        <div className="chart_fifth_row">
          <WarningCenter />
        </div>
      </div>
    </div>
  );
};

export default DealerDashBoard;
