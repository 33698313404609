import React, { useState } from 'react';
import './AmountInputWithCalculator.css';
import { evaluate } from 'mathjs'; // Import mathjs for evaluation

function AmountInputWithCalculator({ setShowModalCalculator, setInputValueAmount,setDisplayValues,displayValues,setFormData,statusShowCal }) {
  const [value, setValue] = useState('');
  console.log("displayValues****7Cal",displayValues);
  console.log("statusShowCal****7Cal",statusShowCal);
  const setDatCalculator = () => {
    if(statusShowCal===1){
      setInputValueAmount(value);
    } else if(statusShowCal===2){
      const formattedValue = Number(value).toLocaleString();
      setDisplayValues((prevDisplayValues) => ({
        ...prevDisplayValues,
        listing_price: formattedValue,
      }));
      setFormData((prevDisplayValues) => ({
        ...prevDisplayValues,
        listing_price: value,
      }));
    } else if(statusShowCal===3){
      const formattedValue = Number(value).toLocaleString();
      setDisplayValues((prevDisplayValues) => ({
        ...prevDisplayValues,
        purchase_price: formattedValue,
      }));
      setFormData((prevDisplayValues) => ({
        ...prevDisplayValues,
        purchase_price: value,
      }));
    }else if (statusShowCal===4){
      const formattedValue = Number(value).toLocaleString();
      setDisplayValues((prevDisplayValues) => ({
        ...prevDisplayValues,
        mileage_in: formattedValue,
      }));
      setFormData((prevDisplayValues) => ({
        ...prevDisplayValues,
        mileage_in: value,
      }));
    }
    setShowModalCalculator(false);
  };
  
  const handleButtonClick = (e) => {
    const { value: buttonValue } = e.target;
    setValue((prevValue) => prevValue + buttonValue);
  };

  const handleEqualClick = () => {
    try {
      // Evaluate the expression while respecting BODMAS/PEMDAS rules
      const result = evaluate(value);
      setValue(result.toString());
    } catch (error) {
      setValue('Error');
    }
  };

  const handleClearClick = () => {
    setValue('');
  };

  const handleDeleteClick = () => {
    setValue((prevValue) => prevValue.slice(0, -1));
  };

  return (
    <div className="container">
      <div className="calculator">
        <form action="">
          <div className="display">
            <input type="text" value={value} readOnly />
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', marginLeft: '17%' }}>
            <div>
              <input className="input_calculator equal" type="button" value="AC" onClick={handleClearClick} />
              <input className="input_calculator equal" type="button" value="C" onClick={handleDeleteClick} />
            </div>
            <div>
               <input className="input_calculator" type="button" value="(" onClick={handleButtonClick} />
               <input className="input_calculator" type="button" value=")" onClick={handleButtonClick} />
              <input className="input_calculator" type="button" value="." onClick={handleButtonClick} />
              <input className="input_calculator" type="button" value="/" onClick={handleButtonClick} />
            </div>
            <div>
              <input className="input_calculator" type="button" value="7" onClick={handleButtonClick} />
              <input className="input_calculator" type="button" value="8" onClick={handleButtonClick} />
              <input className="input_calculator" type="button" value="9" onClick={handleButtonClick} />
              <input className="input_calculator" type="button" value="*" onClick={handleButtonClick} />
            </div>
            <div>
              <input className="input_calculator" type="button" value="4" onClick={handleButtonClick} />
              <input className="input_calculator" type="button" value="5" onClick={handleButtonClick} />
              <input className="input_calculator" type="button" value="6" onClick={handleButtonClick} />
              <input className="input_calculator" type="button" value="+" onClick={handleButtonClick} />
            </div>
            <div>
              <input className="input_calculator" type="button" value="1" onClick={handleButtonClick} />
              <input className="input_calculator" type="button" value="2" onClick={handleButtonClick} />
              <input className="input_calculator" type="button" value="3" onClick={handleButtonClick} />
              <input className="input_calculator" type="button" value="-" onClick={handleButtonClick} />
            </div>
            <div>
              <input className="input_calculator" type="button" value="00" onClick={handleButtonClick} />
              <input className="input_calculator" type="button" value="0" onClick={handleButtonClick} />
              <input className="input_calculator equal" type="button" value="=" onClick={handleEqualClick} />
            </div>
            <div style={{ marginLeft: '17%' }}>
              <input className="input_calculator equal" type="button" value="Submit" onClick={setDatCalculator} />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AmountInputWithCalculator;
