import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const tabs = [
  "Vehicle List",
  "Sale Type",
  "Buyer",
  "Co Buyer",
  "Trade In",
  "Prices and Fees",
  "Forms",
  ,
  "Drafts",
];

const initialState = {
  user: {
    id: null, // Assuming user ID for session management
    // Other user-related data as needed
  },
  tabs, // Use the same array for the initial state
  activeTab: "Vehicle List",
  activeTabIndex: 0,
  vehicleData: [],
  selectedVehicle: null,
  selectedVehiclePopOut: null,
  buyer: {
    listingId: 0,
    firstName: "",
    middleName: "",
    lastName: "",
    businessName: "",
    idType: "",
    idNumber: "",
    idState: "",
    address: "",
    county: "",
    address2: "",
    phoneNumber: "",
    email: "",
    isBuyerEntity: false,
    isAddressValid: false,
  },
  sellCoBuyer: {
    firstName: "",
    middleName: "",
    lastName: "",
    businessName: "",
    idType: "",
    idNumber: "",
    idState: "",
    address: "",
    address2: "",
    county: "",
    phoneNumber: "",
    email: "",
    isCoBuyerEntity: false,
  },
  sellTradeIn: {
    vinNumber: "",
    condition: "",
    stockNumber: "",
    vehicleYear: "",
    make: "",
    model: "",
    fuelType: "",
    transmission: "",
    engineCylinder: "",
    engineSize: "",
    engineType: "",
    vehicleType: "",
    horsePower: "",
    mileageIn: "",
    tradeinValue: 0,
    actualValue: 0,
    listingValue: 0,
  },
  sellPriceFees: {
    priceFeeDate: "",
    saleType: "Private",
    odometerOnSale: 0,
    salePrice: 0,
    tradeinValue: 0,
    owedOnTrade: 0,
    cashDifference: 0,
    totalDue: 0,
    paymentMethod: "",
    payment: 0,
    balanceDue: 0,
    sellPriceFeeItems: [
      // {
      //   itemName: "",
      //   itemValue: 0,
      // },
      // {
      //   itemName: "",
      //   itemValue: 0,
      // },
    ],
  },

  sellPayment: {
    paymentMethod: "",
    ccHolderName: "",
    creditCard: "",
    cardExpiryDate: "",
    cardExpiryMonth: "",
    cardCvv: "",
    billingAddress: "",
  },
  isLoading: false,
  error: null,
};

export const saveSellData = createAsyncThunk();

const sellSlice = createSlice({
  name: "sell",
  initialState,
  reducers: {
    setSellData(state, action) {
      const { buyer } = action.payload;
      state.buyer = { ...state.buyer, ...buyer };
    },
    setCoBuyerSellData(state, action) {
      const { sellCoBuyer } = action.payload;
      state.sellCoBuyer = { ...state.sellCoBuyer, ...sellCoBuyer };
    },
    resetCoBuyerSellData(state) {
      state.sellCoBuyer = {
        stockNumber: "",
        firstName: "",
        middleName: "",
        lastName: "",
        idType: "",
        idNumber: "",
        idState: "",
        address: "",
        address2: "",
        businessName: "",
        county: "",
        isCoBuyerEntity: false,
        phoneNumber: "",
        email: "",
      };
    },
    resetBuyerSellData(state) {
      state.buyer = {
        firstName: "",
        middleName: "",
        lastName: "",
        idType: "",
        idNumber: "",
        idState: "",
        businessName: "",
        address: "",
        address2: "",
        county: "",
        phoneNumber: "",
        email: "",
        isBuyerEntity: false,
      };
    },
    resetBuyerName: (state) => {
      state.buyer = {
        ...state.buyer,
        firstName: "",
        middleName: "",
        lastName: "",
        isBuyerEntity: true,
      };
    },
    resetBuyerBusiness: (state) => {
      state.buyer = { ...state.buyer, businessName: "", isBuyerEntity: false };
    },
    resetCoBuyerName: (state) => {
      state.sellCoBuyer = {
        ...state.sellCoBuyer,
        firstName: "",
        middleName: "",
        lastName: "",
        isCoBuyerEntity: true,
      };
    },
    resetCoBuyerBusiness: (state) => {
      state.sellCoBuyer = {
        ...state.sellCoBuyer,
        businessName: "",
        isCoBuyerEntity: false,
      };
    },
    setVehicleData(state, action) {
      console.log('vehicleData updated');
      state.vehicleData = action.payload;
    },
    setSelectedVehicle(state, action) {
      state.selectedVehicle = action.payload;
    },

    setSelectedVehiclePopOut(state, action) {
      state.selectedVehiclePopOut = action.payload;
    },
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },

    setActiveTab(state, action) {
      console.log("Setting active tab:", action.payload);
      if (action.payload === "vehicle-list") {
        state.activeTab = "vehicle-list";
        state.activeTabIndex = 0;
      } else {
        state.activeTab = action.payload;
        state.activeTabIndex = tabs.indexOf(action.payload);
      }
    },

    setActiveTabIndex(state, action) {
      console.log("Setting active tab index:", action.payload);
      if (action.payload === 0) {
        state.activeTabIndex = 0;
        state.activeTab = "vehicle-list";
      } else {
        state.activeTabIndex = action.payload;
        state.activeTab = tabs[action.payload];
      }
    },

    incrementTabIndex(state) {
      if (state.activeTabIndex < tabs.length - 1) {
        state.activeTabIndex += 1;
        state.activeTab = tabs[state.activeTabIndex];
      }
    },

    decrementTabIndex(state) {
      if (state.activeTabIndex > 0) {
        state.activeTabIndex -= 1;
        state.activeTab = tabs[state.activeTabIndex];
      }
    },
    resetSellData: () => initialState,
    // setVehicleData(state, action) {
    //   state.vehicleData = action.payload;
    // },
    // Add more reducers as needed
  },

  extraReducers: (builder) => {
    builder
      .addCase(saveSellData.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(saveSellData.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(saveSellData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const {
  setSellData,
  resetCoBuyerSellData,
  resetBuyerSellData,
  setCoBuyerSellData,
  setVehicleData,
  setSelectedVehicle,
  setSelectedVehiclePopOut,
  setLoading,
  setError,
  setActiveTab,
  setActiveTabIndex,
  incrementTabIndex,
  decrementTabIndex,
  resetBuyerName,
  resetSellData,
  resetBuyerBusiness,
  resetCoBuyerName,
  resetCoBuyerBusiness,
} = sellSlice.actions;
export default sellSlice.reducer;
