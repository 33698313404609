import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Row, Table } from 'react-bootstrap'
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { MdDelete } from "react-icons/md";
import { BiSolidEditAlt } from "react-icons/bi";
import { Link, useOutletContext } from "react-router-dom";
import styles from './users.module.css'
import Image from "../../imgData";
import { FaBullseye } from 'react-icons/fa';
import { truncate } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { incrementCounter } from '../../../Redux/slice/activeSlice';
import DealerdataEdit from './DealerSetupUser/DealerdataEdit';

const DealerUsers = () => {
  const isExpanded = useOutletContext();
  const { id } = useSelector((state) => state.user);
  const counter = useSelector((state) => state.active.counter);
  const [userStatus, setUserStatus] = useState(false);
  const [userCount, setUserCount] = useState("");
  const [deleteCount, setDeleteCount] = useState(0);
  const dispatch = useDispatch();
  const [userData, setUserData] = useState([
    // {
    //   serialNumber: 1, firstName: "John", lastName: "Doe", jobTitle: "Software Engineer", userStatus: true, grantAccess: "Manager", createdDate: "2024-01-15"
    // },
    // {
    //   serialNumber: 2, firstName: "Jane", lastName: "Smith", jobTitle: "Project Manager", userStatus: false, grantAccess: "Sales", createdDate: "2023-12-22"
    // },
    // {
    //   serialNumber: 3, firstName: "Alice", lastName: "Johnson", jobTitle: "UX Designer", userStatus: false, grantAccess: "Manager", createdDate: "2024-02-05"
    // },
    // {
    //   serialNumber: 4, firstName: "Bob", lastName: "Williams", jobTitle: "Data Analyst", userStatus: true, grantAccess: "Admin", createdDate: "2024-03-10"
    // },
    // {
    //   serialNumber: 5, firstName: "Eve", lastName: "Brown", jobTitle: "Product Owner", userStatus: false, grantAccess: "Sales", createdDate: "2024-01-25"
    // }
  ]);
  //   fetrch user details -----------------------------------------------------
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/user/get_users/${id}`);

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const result = await response.json();
        setUserData(result);
        // setData(result);
      } catch (err) {
        console.log(err.message);
      } finally {
        console.log(false);
      }
    };

    fetchData();
  }, [deleteCount]); // Re-run when the id changes
  // -------------------------------------------------------------------------------
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/user/get_dealer_users/${id}`);

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const result = await response.json();
        setUserCount(result);
        console.log("result", result);
      } catch (err) {
        console.log(err.message);
      } finally {
        console.log(false);
      }
    };

    fetchData();
  }, [deleteCount]); // Re-run when the id changes
  // ----------------------------------------------------------------------------------
  console.log("userData", userData);
  console.log("deleteCount", deleteCount);
  console.log("userCount", userCount);
  // for toggle status -------------------------------------------------------------------
  const [message, setMessage] = useState('');
  const handleUpdate = async (userIdData) => {
    console.log("userIdData", userIdData);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/user/${userIdData}/${id}/toggle-status`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setDeleteCount(prevCount => prevCount + 1);
      setMessage(`Resource updated: ${JSON.stringify(data)}`);
    } catch (error) {
      setMessage(`Error: ${error.message}`);
    }
  };
  // for delete the user-------------------------------------------------------------------------
  const handleDelete = async (userIdData) => {
    console.log("userIdData", userIdData);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/user/delete/${id}/${userIdData}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response;
      setDeleteCount(prevCount => prevCount + 1);
      setMessage(`Resource updated: ${JSON.stringify(data)}`);
    } catch (error) {
      setMessage(`Error: ${error.message}`);
    }
  };
  // -------------------------------------------------------------------------------------

  const [selectedSort, setSelectedSort] = useState(null);

  const handleSelect = async (selectedStatus) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/user/get_users/${id}?sort=${selectedStatus}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setUserData(data);
      setSelectedSort(selectedStatus);
      console.log(data);
      // Handle the response data as needed
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  // -------------------------------------------------------------------------------------
  // ----------------------------------------------------
  const [showEditModal, setShowEditModal] = useState(false);
  const [editId, setEditId] = useState(0);
  const [userEditData, setUserEditData] = useState();
  const handleLinkClickEdit = async (data_id) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/user/get_userdetails/${id}/${data_id}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      if (response.ok) {
        setUserEditData(data);
        setShowEditModal(true);
        setEditId(data_id)
      }
      console.log(data);
      // Handle the response data as needed
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  // const handleLinkClickEdit = (data_id) => {

  //   setShowEditModal(true);
  //   setEditId(data_id)
  // };
  const handleModalCloseEdit = () => {
    setShowEditModal(false);
  };
  console.log("userEditData", userEditData);
  // -----------------------------------------------------
  const [userHistory, setUserHistory] = useState(null)

  const toggleUserStatus = (index) => {
    setUserData(prevData => {
      const newData = [...prevData];
      newData[index].status = newData[index].status ? 0 : 1;
      return newData;
    });
  };

  const handleHistory = (index) => {
    setUserHistory(userData[index]);
  }

  console.log("userHistory", userHistory);

  return (
    <Container fluid className={`p-5 ${styles.wrapper} ${isExpanded ? styles.content_expanded : styles.content_collapsed
      }  `}>
      <Row className={`m-2 d-flex justify-content-end`}>
        <Col sm={3} className={``}><Link to='/dealer/users/setup' className={`${styles.linkOrange} border-0`}>Create User</Link></Col>
        <Col sm={3} className={``}><Link to='/dealer/users/deleted' className={`${styles.linkOrange} border-0`}>Deleted User</Link></Col>
      </Row>

      <Row className={`d-flex justify-content-evenly my-4`}>
        <Col sm={3} className={`p-3 border rounded text-center shadow d-flex justify-content-between`}>
          <div className={``}>
            <h1>{userCount?.all_users}</h1>
            <span className='text-warning fw-bolder'> All Users </span>
          </div>
          <div className={`${styles.profile} border-0`}>
            <img src={Image.user_icon} className={`p-2 rounded`} alt='All Users' width="65px" height='65px' />
          </div>
        </Col>
        <Col sm={3} className={`p-3 border rounded text-center shadow d-flex justify-content-between`}>
          <div className={``}>
            <h1>{userCount?.active_users}</h1>
            <span className='text-success fw-bolder'>Actives Users</span>
          </div>
          <div className={`${styles.profile} `}>
            <img src={Image.user_icon} className={`p-2 rounded`} alt='Actives Users' width="65px" height='65px' />
          </div>
        </Col>
        <Col sm={3} className={`p-3 border rounded text-center shadow d-flex justify-content-between`}>
          <div className={``}>
            <h1>{userCount?.deleted_users}</h1>
            <span className='text-danger fw-bolder' >Deleted users</span>
          </div>
          <div className={`${styles.profile} `}>
            <img src={Image.user_icon} className={`p-2 rounded`} alt='Deleted Users' width="65px" height='65px' />
          </div>
        </Col>
      </Row>

      <Row className={`border-0 d-flex justify-content-between pb-5 mb-5`}>
        <Col sm={3}><span>Show: </span>
          <DropdownButton id="dropdown-basic-button" title="10" className={`${styles.dropOrange} border-0`} style={{ display: 'inline-block' }}>
            <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
            <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
            <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
          </DropdownButton>
        </Col>
        <Col sm={3} className={`border-0 d-flex justify-content-end align-items-center `}><span>Sort By: </span>
          <DropdownButton id="dropdown-basic-button" title="Status" className={`${styles.dropOrange} my-0 mx-1 border-0`} style={{ display: 'inline-block' }}>
            <Dropdown.Item onClick={() => handleSelect("Active")}>Active</Dropdown.Item>
            <Dropdown.Item onClick={() => handleSelect("Deactive")}>Inactive</Dropdown.Item>
            <Dropdown.Item className={`dropdown-item ${selectedSort === "Desc" ? 'selected-item' : ''}`} onClick={() => handleSelect("Desc")}>Newest</Dropdown.Item>
            <Dropdown.Item className={`dropdown-item ${selectedSort === "Asc" ? 'selected-item' : ''}`} onClick={() => handleSelect("Asc")} >Oldest</Dropdown.Item>
          </DropdownButton>
        </Col>
      </Row>

      <Row className='mt-5'>
        <Col sm={12}>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Sr. No.</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Job Title</th>
                <th>User Status</th>
                <th>Grant Access</th>
                <th>Change User Status</th>
                <th>Login History</th>
                <th>Created Date </th>
                <th>Edit</th>
                <th>Delete</th>
                {/* <th></th> */}
              </tr>
            </thead>
            <tbody>
              {userData.map((user, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  {/* <td>{user.serialNumber}</td> */}
                  <td>{user.firstName}</td>
                  <td>{user.lastName}</td>
                  <td>{user.jobTitle}</td>

                  <td><span className={`${user.status === 1 ? `${styles.active}` : `${styles.inactive}`}`} >{user.status ? 'Active' : 'Inactive'}</span>
                  </td>

                  <td>{user.grantAccess}</td>

                  <td className={`d-flex justify-content-center align-items-center`}><span onClick={() => handleUpdate(user.id)} className={`${user.status ? `${styles.inactiveBtn}` : `${styles.activeBtn}`}`}>{user.status ? 'Inactive' : 'Active'}</span>
                  </td>

                  <td className={`text-center`}><Button className={`${styles.btnOrange} p-1 px-3 border-0 `} onClick={() => handleHistory(index)}>View</Button></td>

                  <td>{user?.createdAt}</td>
                  <td className={`text-center`}>
                    {/* <BiSolidEditAlt className={`${styles.editIcon}`} onClick={() => handleLinkClickEdit(user.id)} /> */}
                    <Button className="publish-btn border-0 py-2" onClick={() => handleLinkClickEdit(user.id)} style={{ color: "white", width: "70px", }}>
                      Edit
                    </Button>
                  </td>
                  <td className={`text-center`}>
                    {/* <MdDelete className={`${styles.deleteIcon}`} onClick={() => handleDelete(user.id)} /> */}
                    <Button className="publish-btn border-0 py-2" onClick={() => handleDelete(user.id)} style={{ color: "white", width: "70px", }}>
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}

              {/* <tr>
                <td>1</td>
                <td>Mark</td>
                <td>Otto</td>
                <td>@mdo</td>

                <td><span className={`${userStatus ? `${styles.active}` : `${styles.inactive}`}`}>{userStatus ? 'Active' : 'Inactive'}</span>
                </td>

                <td>Otto</td>

                <td className={`d-flex justify-content-center align-items-center`}><span onClick={() => setUserStatus(!userStatus)} className={`${userStatus ? `${styles.inactiveBtn}` : `${styles.activeBtn}`}`}>{userStatus ? 'Inactive' : 'Active'}</span>
                </td>

                <td><Button className={`${styles.btnOrange} p-1 px-3 border-0 `}>View</Button></td>
                <td>Otto</td>
                <td><BiSolidEditAlt className={`${styles.editIcon}`} /></td>
                <td><MdDelete className={`${styles.deleteIcon}`} /></td>
              </tr> */}

            </tbody>
          </Table>
        </Col>
        {/* <Col sm={12} className={`d-flex justify-content-between my-5`} >
          <div className="row-count">pagination</div>
          <div className="pagination">pagination</div>
        </Col> */}
        <DealerdataEdit
          show={showEditModal}
          handleClose={handleModalCloseEdit}
          userId={editId}
          userEditData={userEditData}
          setDeleteCount={setDeleteCount}
        />
      </Row>

      {userHistory &&
        (<Row className='mt-3 d-flex justify-content-center'>
          <Col sm={8}><h1>Login History</h1></Col>
          <Col sm={8} className={``} >
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Date </th>
                  <th>Login IP</th>
                  <th>User Status</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{userHistory?.userLoginHistories[0]?.created_at}</td>
                  <td>{userHistory?.userLoginHistories[0]?.ip_address}</td>
                  <td><span className={`${userHistory?.userLoginHistories[0]?.status === "Success" ? `${styles.active}` : `${styles.inactive}`}`}>{userHistory?.userLoginHistories[0]?.status}</span>
                  </td>
                </tr>

                {/* <tr>
                  <td>08/09/24</td>
                  <td>122.234.344.456 -Noida, UP</td>
                  <td><span className={`${userStatus ? `${styles.active}` : `${styles.inactive}`}`}>{userStatus ? 'Success' : 'Failed'}</span>
                  </td>
                </tr>

                <tr>
                  <td>08/09/24</td>
                  <td>122.234.344.456 -Noida, UP</td>
                  <td><span className={`${userStatus ? `${styles.active}` : `${styles.inactive}`}`}>{userStatus ? 'Success' : 'Failed'}</span>
                  </td>
                </tr>

                <tr>
                  <td>08/09/24</td>
                  <td>122.234.344.456 -Noida, UP</td>
                  <td><span className={`${userStatus ? `${styles.active}` : `${styles.inactive}`}`}>{userStatus ? 'Success' : 'Failed'}</span>
                  </td>
                </tr> */}
              </tbody>
            </Table>
          </Col>
        </Row>)
      }


    </Container>
  )
}

export default DealerUsers