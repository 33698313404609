import React, { useEffect, useState } from "react";
import Styles from "./DNSsteps.module.css";
import { MdOutlineAddLink } from "react-icons/md";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Image from "../../imgData";
const DNSsteps = ({ show, handleClose, DomainName }) => {
  const [currentImage, setCurrentImage] = useState("");
  let accountType; // Declare the variable outside

  if (DomainName === "GoDaddy.com, LLC") {
    accountType = "GoDaddy";
  } else if (DomainName === "NameCheap, Inc.") {
    accountType = "Namecheap";
  } else {
    accountType = "Unknown"; // Optional: handle other cases
  }

  // Define instructions for each account type
  const getDefaultImage = (type) => {
    if (type === "GoDaddy") {
      return Image.godaddy.login; // Replace with the appropriate first step image
    } else if (type === "Namecheap") {
      return Image.namecheap.login; // Replace with the appropriate first step image
    }
    return ""; // Fallback if no account type matches
  };
  // for hyper link-----------
  const handleClickLink = () => {
    let url;

    if (accountType === 'GoDaddy') {
      url = 'https://sso.godaddy.com/?realm=idp&app=cart&path=%2Fcheckoutapi%2Fv1%2Fredirects%2Flogin';
    } else if (accountType === 'Namecheap') {
      url = 'https://www.namecheap.com/myaccount/login/';
    }

    if (url) {
      window.location.href = url;
    }
  };
  // ------------------------
  const instructions = {
    GoDaddy: [
      "1. Log in to your GoDaddy account.",
      "2. Click On My Product.",
      "3. Click Manage Domains.",
      "4. Click on the domain you want to update.",
      "5. Click Manage DNS.",
      "6. Update the DNS records.",
      "7. Click Save.",
    ],
    Namecheap: [
      "1. Log in to your Namecheap account.",
      "2. Click On Domain List.",
      "3. Click On Manage.",
      "4. Choose Custom DNS In Dropdown.",
      "5. Enter New DNS.",
      "6. Click On Save.",
    ],
    // Add more companies as needed
  };
  const handleStepClick = (step) => {
    let images;
    // Determine which image set to use
    if (accountType === "GoDaddy") {
      images = Image.godaddy;
    } else if (accountType === "Namecheap") {
      images = Image.namecheap;
    }

    // Set the current image based on the step
    switch (step) {
      case 1:
        setCurrentImage(images.login);
        break;
      case 2:
        setCurrentImage(images.product);
        break;
      case 3:
        setCurrentImage(images.manageDomains);
        break;
      case 4:
        setCurrentImage(images.updateDomain);
        break;
      case 5:
        setCurrentImage(images.manageDNS);
        break;
      case 6:
        setCurrentImage(images.updateDNS);
        break;
      case 7:
        setCurrentImage(images.save);
        break;
      default:
        setCurrentImage(getDefaultImage(accountType));
    }
  };
  // Effect to set the default image on accountType change
  useEffect(() => {
    setCurrentImage(getDefaultImage(accountType));
  }, [accountType]);
  return (
    <Modal
      show={show}
      size="xl"
      onHide={handleClose}
      dialogClassName="modal-90w"
    >
      <Modal.Header closeButton>
        <div>
          <Modal.Title>DNS Steps</Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className={Styles.wrapper}>
          <div className={Styles.container}>
            <p className={Styles.header}>
              Here are some steps to update DNS in "{accountType}"
            </p>
            <div className={Styles.stepsHero}>
              {/* <p className={Styles.sideBarItems}>
        <span onClick={() => handleStepClick(1)}>1. Log in to your GoDaddy account.</span>
        <span onClick={() => handleStepClick(2)}>2. Click On My Product.</span>
        <span onClick={() => handleStepClick(3)}>3. Click Manage Domains.</span>
        <span onClick={() => handleStepClick(4)}>4. Click on the domain you want to update.</span>
        <span onClick={() => handleStepClick(5)}>5. Click Manage DNS.</span>
        <span onClick={() => handleStepClick(6)}>6. Update the DNS records.</span>
        <span onClick={() => handleStepClick(7)}>7. Click Save.</span>
      </p> */}
              <p className={Styles.sideBarItems}>
                {instructions[accountType]?.map((instruction, index) => (
                  <span key={index} onClick={() => handleStepClick(index + 1)}>
                    {instruction}
                  </span>
                ))}
              </p>
              {currentImage && (
                <img src={currentImage} alt="Instructional step" />
              )}
            </div>
            <div className={Styles.stepsLinkWrapper}>
              <div className={Styles.stepsLinkContainer}>
                <p>
                  <span>Domain Name Server</span>
                </p>
                <p>
                  <span>NS1.Dealersautocenter.com</span>
                </p>
                <p>
                  <span>NS2.Dealersautocenter.com</span>
                </p>
              </div>
              <div className={Styles.stepsLinkNavigaion}>
                <p onClick={handleClickLink} style={{ cursor: "pointer" }} className={Styles.link_icon}>
                  <span>
                    <MdOutlineAddLink />
                  </span>
                </p>

                <p>
                  <span>
                    {/* <Link to="/dealer/website/dns" style={{ color: "black" }}>
                  Back
                </Link>{" "} */}
                    {/* | Next */}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

export default DNSsteps;
