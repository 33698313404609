import React, { useEffect, useState } from "react";
import "../Styles/OtpLogin.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  saveUser,
  setShowUserCompleteProfileDialogueBox,
  updateInitialDataFromAPI,
} from "../../../Redux/slice/userSlice";

import { sideBarArray } from "../../dashboard/SideBarData";
import { UserCity } from "./UserCity";
import toast, { Toaster } from "react-hot-toast";

const DeviceVerifyOTP = ({
  isLoginEmail,
  setShowLoginDialogueBox,
  extraInformation,
}) => {
  const dispatch = useDispatch();
  console.log("Extra Information in Device Verify OTP", extraInformation);
  console.log("isLoginEmail in Device Verify OTP", isLoginEmail);
  const navigate = useNavigate();
  const [InputOtp, setInputOtp] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [ip, setIP] = useState("");

  const [disableResendOtp, setdisableResendOtp] = useState(false);
  console.log(errorMessage);
  console.log(InputOtp);

  // ---------------------------------------------------------------//
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  console.log(
    "longitude in  PassLogin=> ",
    longitude,
    "latitude in PassLogin=>",
    latitude
  );

  const [cityStats, setCityStats] = useState("");
  console.log("User current city", cityStats);

  useEffect(() => {
    async function fetchCity() {
      const userCity = await UserCity();
      console.log(userCity);
      if (userCity) {
        setCityStats(userCity);
      }
    }
    fetchCity();
  }, []);

  // useEffect for IP Address of user while
  const getUserIPaddress = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");
    console.log("User IP Address", res);
    setIP(res.data.ip);
  };
  useEffect(() => {
    getUserIPaddress();
  }, []);
  const handleContinueResult = async (value) => {
    try {
      console.log(
        "Handling API continue Result while sending the otp to verify device"
      );
      const formData = new FormData();
      console.log("formData", formData);
      formData.append("email", isLoginEmail.email);
      formData.append("password", extraInformation.password);
      formData.append("fingerprint", extraInformation.fingerprint);
      formData.append("otp", value);
      formData.append("ip", ip);
      formData.append("user_type", "User");

      console.log("OTP while verifying the device", value);
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/verify_otp`,
        formData
      );

      if (res.status !== 200) {
        console.log("User OTP Failure");
      }
      dispatch(
        saveUser({
          email: res.data.email,
          id: res.data.id,
          phoneNumber: res.data.phoneNumber,
          firstName: res.data.firstName,
          lastName: res.data.lastName,
          office_number: res.data.office_number,
          lattitude: res.data.lattitude,
          longitude: res.data.longitude,
          zip_code: res.data.zip_code,
          address: res.data.address,
          profile_picture: res.data.profile_picture,
          website: res.data.website,
          roles: res.data.roles,
        })
      );
      navigate("/myprofile/dashboard");
      fetchSideBarData(res.data.id);
      setShowLoginDialogueBox(false);
      toast.success("Device verification successful");
      console.log("response when verifying the device", res);
      // dispatch(saveUser({ email: res.data.email, id: res.data.id }));
      // navigate("/myprofile/dashboard");
    } catch (error) {
      console.log("error in  catch part while using otp");
      if (error.response.status === 400) {
        toast.error(error.response.data);
      } else if (error.response.status === 500) {
        toast.error(error.response.data);
      }
    }
  };

  // Update side bar user activity after login
  const fetchSideBarData = async (user_id) => {
    const fd = new FormData();
    fd.append("user_id", user_id);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/tab/get`,
        fd
      );
      console.log(res);
      if (res.status === 200) {
        const transformedData = sideBarArray.map((item) => {
          const apiItem = res.data.find((apiItem) => apiItem.tabId === item.id);
          return {
            ...item,
            count: apiItem ? apiItem.tabCount : item.count,
          };
        });

        // Dispatch action to update the initial state with transformed data
        dispatch(updateInitialDataFromAPI(transformedData));
        localStorage.setItem(
          "user_sidebar_activity",
          JSON.stringify(transformedData)
        );
      }
    } catch (error) {
      console.log(
        "Server side error during getting user activity in my profile",
        error
      );
    }
  };

  const handleInputChange = async (e) => {
    const { value } = e.target;
    if (/^\d{0,6}$/.test(value)) {
      setInputOtp(value);

      if (value.length === 6) {
        await handleContinueResult(value);
      }
    }
  };

  const [time, setTime] = useState(300); // 300 seconds for 5 minutes
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    let timer;

    if (time > 0 && isDisabled) {
      timer = setInterval(() => {
        setTime((prevTime) => prevTime - 1);
      }, 1000);
    } else if (time <= 0) {
      setIsDisabled(false);
      clearInterval(timer);
    }

    return () => clearInterval(timer); // Cleanup on component unmount
  }, [time, isDisabled]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(secs).padStart(
      2,
      "0"
    )}`;
  };
  const handleResendOtp = async () => {
    if (!isDisabled) {
      setIsDisabled(true);
      setTime(300); // Reset to 5 minutes
    }
    console.log("Please resend OTP");
    const formData = new FormData();
    formData.append("email", isLoginEmail.email);
    formData.append("password", extraInformation.password);
    formData.append("fingerprint", extraInformation.fingerprint);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/verify_password`,
        formData
      );

      if (res.status === 200) {
        console.log(res.data);
        console.log("I am got new OTP");
        toast.success("New OTP successfully generated");
        setTimeout(() => {
          setdisableResendOtp(true);
        }, 5000);
      }
    } catch (error) {
      if (error.response.status === 400) {
        toast.error(error.response.data);
      } else if (error.response.status === 500) {
        toast.error(error.response.data);
      }
    }
  };

  return (
    <div className="GrandOTPContainer">
      <div className="OtpContainer">
        <h2 className="heading">Verify your Identity</h2>
        <div className="input_otp_container">
          {/* <p className="detailing_1">
            <span>Enter the OTP</span>
            <span>required</span>
          </p> */}

          <div className="input_and_icon">
            <input
              type="text"
              onChange={handleInputChange}
              value={InputOtp}
              placeholder="6 Digit OTP"
              className="user_input_login"
            />
          </div>
          {errorMessage ? (
            <p className="error_message">{errorMessage}</p>
          ) : null}

          <p className="forget_password">
            <span
              style={{
                color: "green",
                borderBottom: "0",
                fontSize: "large",
              }}
            >
              {isDisabled ? formatTime(time) : ""}
            </span>
            <button
              className="resend_button"
              onClick={handleResendOtp}
              style={{
                cursor: "pointer",
                cursor: isDisabled ? "not-allowed" : "",
                marginLeft: "0.5em",
              }}
              disabled={isDisabled}
            >
              Resend OTP
            </button>
          </p>
        </div>

        <div className="sign_in_line">
          <p>Or Signin with</p>
        </div>
      </div>
      <Toaster
        position="top-right"
        reverseOrder={false}
        toastOptions={{
          // Define default options
          className: "",
          duration: 1000,
          style: {
            background: "#363636",
            color: "#fff",
            fontSize: "larger",
          },
        }}
      />
    </div>
  );
};

export default DeviceVerifyOTP;
