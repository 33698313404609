import React, { useState } from "react";
import Styles from "./setup.module.css";
import BasicInfo from "../components/BasicInfo";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import Permissions from "../components/Permissions";
import LeadNotification from "../components/LeadNotification";
import SecuritySettings from "../components/SecuritySettings";
import HistoryTable from "../components/HistoryTable";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, Col, Container, Row } from "react-bootstrap";
import {
  Tabs,
  Tab,
} from "react-bootstrap";
import axios from "axios";
import { useSelector } from "react-redux";
const DealerSetupUser = () => {
  const { id } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [imageData, setImageData] = useState(null);
  const [formData, setFormData] = useState({
    dealerId: id,
    id: 0,
    firstName: "",
    lastName: "",
    email: "",
    jobTitle: "",
    phoneNumber: "",
    landlineNumber: "",
    password: "",
    confirmpassword: "",
    roles: "dealer",
    sms_notify: false,
    email_notify: false,
  });
  const isExpanded = useOutletContext();

  // for tab----------------------------------------------
  const [activeTab, setActiveTab] = useState("tab1");
  const handleNextButtonClick = () => {
    const tabKeys = [
      "tab1",
      "tab2",
    ];
    const currentIndex = tabKeys.indexOf(activeTab);

    if (currentIndex < tabKeys.length - 1) {
      const nextTab = tabKeys[currentIndex + 1];
      setActiveTab(nextTab);
    }
  };

  const handleBackButtonClick = () => {
    const tabKeys = ["tab1", "tab2"];
    const currentIndex = tabKeys.indexOf(activeTab);

    if (currentIndex > 0) {
      const prevTab = tabKeys[currentIndex - 1];
      setActiveTab(prevTab);
    }
  };
  // ---------------------------------------------------
  console.log("formData57", formData);
  // for images -----------------------------------
  const handleImageUpload = (binaryData) => {
    setImageData(binaryData); // Update state with the binary image data
    console.log('Uploaded Image Data:', binaryData);
  };
  // -----------------------------------------------
  const handleUpdateuserInfo = async () => {
    if (!formData.email || !formData.phoneNumber) {
      toast.error("Email & PhoneNumber Required.");
      return;
    }
    setLoading(true);
    const fd = new FormData();
    fd.append("profile_picture", imageData);
    fd.append("data", JSON.stringify(formData));
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/add_user`,
        fd
      );
      if (res.status === 200) {
        navigate("/dealer/users");
      }
    } catch (error) {
      console.log("Error 500 while updating the user information");
    } finally {
      setLoading(false); // Set loading to false when the request finishes
    }
  };
  return (
    <div className={`px-0 mx-auto ${Styles.wrapper} ${isExpanded ? Styles.expanded : Styles.collapsed}`}>
      <Container fluid className={`p-3 ${Styles.container}`}>
        <Row className={`d-flex justify-content-end py-2`}>
          {/* <Button className={`btn-orange border-0 px-5`}>Apply</Button> */}
          <Col sm={2} className={`d-flex justify-content-end`}>
            <Link to='/dealer/users' className={`${Styles.linkOrange} border-0`}>All Users</Link>
          </Col >
          <Col sm={3} className={`d-flex justify-content-center`}>
            <Link to='/dealer/users/deleted' className={`${Styles.linkOrange} border-0 me-3`}>Deleted User</Link>
          </Col >
          <Col sm={2} className={`d-flex justify-content-start`}><Button className={`btn-orange border-0 px-5`} onClick={handleUpdateuserInfo} disabled={loading}>{loading ? <div className={Styles.spinner}></div> : 'Apply'}</Button></Col>
          {/* <span style={{ fontSize: "xx-large", fontWeight: "600", color: "white", backgroundColor: "#e7a162", padding: "0.5rem 3rem" }}>Apply</span> */}
        </Row>


        {/* <SecuritySettings /> */}
        {/* <HistoryTable /> */}
        {/* <Tabs 
        activeKey={activeTab}
        onSelect={(k) => setActiveTab(k)}
        id="my-tabs"
    >*/}
        {/* <Tab eventKey="tab1" title="Users"> */}
        <BasicInfo formData={formData} setFormData={setFormData} onImageUpload={handleImageUpload} />
        {/* </Tab> */}
        {/* <Tab eventKey="tab2" title="Information"> */}
        <Permissions formData={formData} setFormData={setFormData} />
        <LeadNotification formData={formData} setFormData={setFormData} />
        {/* </Tab> */}
        {/* </Tabs> */}
      </Container>
      <ToastContainer />
    </div>
  );
};

export default DealerSetupUser;
