import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Row,
  Table,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import "./DealerbalanceInventory.css";
import Buyerdata from "./Buyerdata";
import { useDispatch, useSelector } from "react-redux";
import AddlistingData from "./AddlistingData";
import { IoMdInformationCircleOutline } from "react-icons/io";
import AddlistingDataSold from "./AddlistingDataSold";
import { incrementCounter } from "../../../../Redux/slice/activeSlice";
import { BiReset } from "react-icons/bi";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
const apiUrl = process.env.REACT_APP_API_URL;
const DealerBalanceInventory = () => {
  const { id } = useSelector((state) => state.user);
  const [selectedRows, setSelectedRows] = useState([]);
  const [sortedData, setSortedData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  //   const [tableData, setTableData] = useState([]);
  const dispatch = useDispatch();
  const counter = useSelector((state) => state.active.counter);
  const [showModal, setShowModal] = useState(false);
  const [showModalAddlisting, setShowModalAddlisting] = useState(false);
  const [dealerData, setDealerData] = useState([]);
  const [dealerId, setDealerId] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [cobuyerData, setCobuyerdata] = useState([]);
  const [addlistingData, setAddlistingData] = useState([]);
  // Function to handle closing the modal
  const handleLinkClick = (itemData) => {
    setShowModal(true);
    setCobuyerdata(itemData);
  };
  const handleLinkClickAddlisting = (itemDataAddlisting) => {
    setShowModalAddlisting(true);
    setAddlistingData(itemDataAddlisting);
  };
  console.log("cobuyerData", cobuyerData);
  const handleModalClose = () => {
    setShowModal(false);
  };
  const handleModalCloseAddlisting = () => {
    setShowModalAddlisting(false);
  };
  console.log("addlistingData", addlistingData);
  const [initialFilters, setInitialFilters] = useState([]);
  // get the data of sell section and co-buyer---------------------------------------
  const fetchData = async () => {
    try {
      const response = await fetch(`${apiUrl}/sell/get/active_balance/${id}`);
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await response.json();
      setTableData(data);
      setInitialFilters(data.map((item) => item.addListing2));
      // setSortedData(Array.isArray(data) ? data : []);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [counter]);
  // for fetch data----------------------------

  const handleIncrement = () => {
    dispatch(incrementCounter());
    setSelectedFilter({
      make: "Make",
      models: "Model",
      year: "Year",
      bodyClass: "Body Type",
    });
  };
  // --------------------------------------
  // --------------------------------------------------------------------------------------
  console.log("tabledata", tableData);

  // table data for all users dealers --------------------------------------------------------
  // ---------------------------------------------------------------------------------
  console.log("tableData", tableData);

  const itemsPerPage = 20; // Change this value according to your preference
  const totalItems = tableData?.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  // Calculate current page items
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = tableData.slice(indexOfFirstItem, indexOfLastItem);

  // Add empty rows if necessary
  const emptyRowsCount = Math.max(itemsPerPage - currentItems.length, 0);
  const emptyRows = Array.from({ length: emptyRowsCount }).map((_, index) => (
    <tr key={`empty-${index}`}>
      {[...Array(12)].map((_, i) => (
        <td key={i}>&nbsp;</td>
      ))}
    </tr>
  ));

  const renderTableRow = (item, index) => {
    console.log("item?????", item);

    return (
      <tr
        key={index}
        className={selectedRows.includes(index) ? "selected-rows" : ""}
      // onDoubleClick={() => handleLinkClick(item.id)}
      >
        {item ? (
          <>
            <td>{index + 1}</td>
            <td>{item.accountNumber}</td>
            <td>{item.addListing2?.stock_no}</td>
            <td
              className="ac-col fw-bold"
              onClick={() => handleLinkClickAddlisting(item)}
              style={{ color: "blue" }}
            >
              <u>
                {item.addListing2?.modelYear} {item.addListing2?.modelMake}{" "}
                {item.addListing2?.makeModel}
              </u>
            </td>
            {/* <td>{item.make}</td>
            <td >{item.modal}</td> */}
            <td className="fw-bold">
              {new Intl.NumberFormat().format(item.mileageIn)}
            </td>
            <td onClick={() => handleLinkClick(item)} style={{ color: "blue" }}>
              <u>
                {item.firstName} {item.lastName}
              </u>
            </td>
            {/* <td>{item.idType}</td>
            <td >{item.idNumber}</td>
            <td >{item.address}</td>
            <td >{item.county}</td> */}
            <td>{item.phoneNumber}</td>
            {/* <td >{item.email}</td> */}
            <td>
              {new Intl.NumberFormat().format(item.sellPriceFees.listingPrice)}
            </td>
            <td>{item.sellPriceFees.priceFeeDate}</td>
            <td>{item.sellPriceFees.priceFeesPaymentMethod}</td>
            <td>
              {new Intl.NumberFormat().format(
                item.sellPriceFees.paymentPriceFees
              )}
            </td>
            <td>
              {new Intl.NumberFormat().format(item.sellPriceFees.balanceDue)}
            </td>
            {/* <td>
              <Button className={`border-0 decline-btn`}>Pending</Button>
            </td> */}
          </>
        ) : (
          Array.from({ length: 12 }).map((_, i) => <td key={i}>&nbsp;</td>)
        )}
      </tr>
    );
  };

  // for filter sold data---------------------------------------------------------------*********************-----------------------------

  const [selectedFilter, setSelectedFilter] = useState({
    make: "Make",
    models: "Model",
    year: "Year",
    bodyClass: "Body Type",
  });

  // ----------for search bar filter -----------------------------------------------------------
  const [inputValue, setInputValue] = useState("");
  const [selectedSort, setSelectedSort] = useState(null);

  const handleSelect = async (selectedStatus, type = "") => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/listing/get_sold_listing/${id}?searchTerm=${selectedStatus}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();

      // Update the selected filter based on the type
      if (type) {
        setSelectedFilter((prev) => ({
          ...prev,
          [type]: selectedStatus, // Use computed property name here
        }));
      }

      setTableData(data);
      setSelectedSort(selectedStatus);
      console.log(data);
      // Handle the response data as needed
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSelect(inputValue); // Call the function with the input value
      setInputValue(""); // Clear the input field if desired
    }
  };
  const handleButtonClick = () => {
    handleSelect(inputValue); // Call the function with the input value
    setInputValue(""); // Clear the input field if desired
  };

  const handleSortByColumn = async (sort, column) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/listing/get_sold_listing/${id}?searchTerm=${sort}&extraParam=${column}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setTableData(data);
      console.log(data);
      // Handle the response data as needed
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  // -------------------------------------------------------------------------------------------
  console.log("initialFilters32455***********", initialFilters);

  return (
    <>
      <Container
        fluid
        className=""
        style={{ minWidth: "82vw", marginTop: "-1.5rem" }}
      >
        <Row>
          {/* input field start */}
          <Col sm={11} className="input-group mt-3 mb-4" id="act-search">
            <Button
              className="btn border-0 dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              style={{ backgroundColor: "#e8a262" }}
            >
              All
            </Button>
            <ul className="dropdown-menu">
              <li onClick={() => handleSelect("Price10k")}>
                <Link className="dropdown-item" to="#">
                  Price &lt; 10k
                </Link>
              </li>
              <li onClick={() => handleSelect("Mileage50k")}>
                <Link className="dropdown-item" to="#">
                  Miles &lt; 50k
                </Link>
              </li>
              <li onClick={() => handleSelect("Mileage100k")}>
                <Link className="dropdown-item" to="#">
                  Miles &lt; 100k
                </Link>
              </li>
            </ul>

            <input
              type="text"
              className="form-control"
              placeholder="Search Inventory"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)} // Update state on input change
              onKeyPress={handleKeyPress} // Call handleKeyPress on key press
            />
            <Button
              className="btn border-0"
              type="button"
              id="button-addon1"
              style={{ backgroundColor: "#e8a262" }}
              onClick={handleButtonClick}
            >
              <i className="fas fa-search"></i>
            </Button>

            <Button
              className="btn border-0 dropdown-toggle btn-eq-wd"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              id="dropdownYear" // Added ID for accessibility
              style={{
                backgroundColor: "#e8a262",
                margin: "0 0.3rem",
              }}
            >
              {selectedFilter.year}
            </Button>
            <ul
              className="dropdown-menu"
              aria-labelledby="dropdownYear"
              style={{ maxHeight: "300px", overflowY: "scroll" }}
            >
              <li onClick={handleIncrement}>
                <Link className={`dropdown-item ${selectedFilter.year === ''
                  ? "selected-item"
                  : ""
                  }`} to="#">
                  Select None
                </Link>
              </li>
              {initialFilters &&
                [...new Set(initialFilters.map((car) => car.modelYear))]
                  .sort((a, b) => a - b)
                  .map((modelYear, index) => (
                    <li key={index} onClick={() => handleSelect(modelYear, 'year')}>
                      <Link className={`dropdown-item ${selectedFilter.year === modelYear
                        ? "selected-item"
                        : ""
                        }`} to="#">
                        {modelYear}
                      </Link>
                    </li>
                  ))}
            </ul>

            <Button
              className="btn border-0 dropdown-toggle btn-eq-wd"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              id="dropdownMake" // Added ID for accessibility
              style={{ backgroundColor: "#e8a262" }}
            >
              {selectedFilter.make}
            </Button>
            <ul
              className="dropdown-menu"
              aria-labelledby="dropdownMake"
              style={{ maxHeight: "300px", overflowY: "scroll" }}
            >
              <li onClick={handleIncrement}>
                <Link className={`dropdown-item ${selectedFilter.make === ''
                  ? "selected-item"
                  : ""
                  }`} to="#">
                  Select None
                </Link>
              </li>
              {initialFilters &&
                [...new Set(initialFilters.map((car) => car.modelMake))]
                  .sort()
                  .map((modelMake, index) => (
                    <li key={index} onClick={() => handleSelect(modelMake, 'make')}>
                      <Link className={`dropdown-item ${selectedFilter.make === modelMake
                        ? "selected-item"
                        : ""
                        }`} to="#">
                        {modelMake}
                      </Link>
                    </li>
                  ))}
            </ul>

            <Button
              className="btn border-0 dropdown-toggle btn-eq-wd"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              id="dropdownModel" // Added ID for accessibility
              style={{
                backgroundColor: "#e8a262",
                margin: "0 0.3rem",
              }}
            >
              {selectedFilter.models}
            </Button>
            <ul
              className="dropdown-menu"
              aria-labelledby="dropdownModel"
              style={{ maxHeight: "300px", overflowY: "scroll" }}
            >
              <li onClick={handleIncrement}>
                <Link className={`dropdown-item ${selectedFilter.models === ''
                  ? "selected-item"
                  : ""
                  }`} to="#">
                  Select None
                </Link>
              </li>
              {initialFilters &&
                [...new Set(initialFilters.map((car) => car.makeModel))]
                  .sort()
                  .map((makeModel, index) => (
                    <li key={index} onClick={() => handleSelect(makeModel, 'models')}>
                      <Link className={`dropdown-item ${selectedFilter.models === makeModel
                        ? "selected-item"
                        : ""
                        }`} to="#">
                        {makeModel}
                      </Link>
                    </li>
                  ))}
            </ul>

            <Button
              className="btn border-0 dropdown-toggle btn-eq-wd"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              id="dropdownModel" // Added ID for accessibility
              style={{
                backgroundColor: "#e8a262",
                margin: "0 0.3rem",
              }}
            >
              {selectedFilter.bodyClass}
            </Button>
            <ul
              className="dropdown-menu"
              aria-labelledby="dropdownModel"
              style={{ maxHeight: "300px", overflowY: "scroll" }}
            >
              <li onClick={handleIncrement}>
                <Link className={`dropdown-item ${selectedFilter.bodyClass === ''
                  ? "selected-item"
                  : ""
                  }`} to="#">
                  Select None
                </Link>
              </li>
              {initialFilters &&
                [...new Set(initialFilters.map((car) => car.bodyClass))]
                  .sort()
                  .map((bodyClass, index) => (
                    <li key={index} onClick={() => handleSelect(bodyClass, 'bodyClass')}>
                      <Link className={`dropdown-item ${selectedFilter.bodyClass === bodyClass
                        ? "selected-item"
                        : ""
                        }`} to="#">
                        {bodyClass}
                      </Link>
                    </li>
                  ))}
            </ul>

            <Button
              className="btn border-0 dropdown-toggle btn-eq-wd"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              id="dropdownModel" // Added ID for accessibility
              style={{
                backgroundColor: "#e8a262",
                margin: "0 0.3rem",
              }}
            >
              Sort BY
            </Button>
            <ul className="dropdown-menu">
              <li onClick={() => handleSelect("Desc")}>
                <Link className={`dropdown-item ${selectedSort === "Desc" ? 'selected-item' : ''}`} to="#">
                  Newest
                </Link>
              </li>
              <li onClick={() => handleSelect("Asc")}>
                <Link className={`dropdown-item ${selectedSort === "Asc" ? 'selected-item' : ''}`} to="#">
                  Oldest
                </Link>
              </li>
            </ul>

            <Button
              className="btn border-0 btn-eq-wd"
              type="button"
              id="button-addon1"
              style={{ backgroundColor: "#e8a262" }}
              onClick={handleIncrement}
            >
              <BiReset className="icons-r-flt" />
            </Button>
          </Col>
          {/* input field end */}
        </Row>

        <Row
          className="mx-0 rounded  bg-body border-2"
          style={{
            position: "relative",
            top: "",
            zIndex: "5 ",
            // minWidth: "1000px", overflowX: "scroll"
          }}
        >
          <div className="table-wrapper rounded px-0 table-scrollbar">
            <Table
              striped
              bordered
              hover
              //   ref={tableRef}
              className="shadow mb-0"
              id="table"
            >
              <thead>
                <tr>
                  <th>No#</th>
                  <th className="ac-col">Ac N.</th>

                  <th className="ac-col">Stock N.
                    <span className="filter-icons">
                      <OverlayTrigger
                        key="bottom-start"
                        placement="bottom-start"
                        overlay={<Tooltip id={`tooltip-bottom-start`} className="table-tooltip" >Descending</Tooltip>}
                      >
                        <span onClick={() => handleSortByColumn('Desc', "Stock")}><TiArrowSortedUp /></span>
                      </OverlayTrigger>

                      <OverlayTrigger
                        key="bottom-start"
                        placement="bottom-start"
                        overlay={<Tooltip id={`tooltip-bottom-start`} className="table-tooltip" >Ascending</Tooltip>}
                      >
                        <span onClick={() => handleSortByColumn('Asc', "Stock")}><TiArrowSortedDown /></span>
                      </OverlayTrigger>
                    </span>
                  </th>

                  <th>
                    Vehicle Info
                    <span className="tooltip-container-active-inventory">
                      <IoMdInformationCircleOutline className="info-icon-active-inventory" />
                      <div className="tooltip-text-active-inventory">
                        Year Make Modal
                      </div>
                    </span>

                    <span className="filter-icons">
                      <OverlayTrigger
                        key="bottom-start"
                        placement="bottom-start"
                        overlay={<Tooltip id={`tooltip-bottom-start`} className="table-tooltip" >Descending</Tooltip>}
                      >
                        <span onClick={() => handleSortByColumn('Desc', "Vehicle")}><TiArrowSortedUp /></span>
                      </OverlayTrigger>

                      <OverlayTrigger
                        key="bottom-start"
                        placement="bottom-start"
                        overlay={<Tooltip id={`tooltip-bottom-start`} className="table-tooltip" >Ascending</Tooltip>}
                      >
                        <span onClick={() => handleSortByColumn('Asc', "Vehicle")}><TiArrowSortedDown /></span>
                      </OverlayTrigger>
                    </span>
                  </th>
                  {/* <th>make</th>
                <th>Modal</th> */}
                  <th>Mileage
                    <span className="filter-icons">
                      <OverlayTrigger
                        key="bottom-start"
                        placement="bottom-start"
                        overlay={<Tooltip id={`tooltip-bottom-start`} className="table-tooltip" >Descending</Tooltip>}
                      >
                        <span onClick={() => handleSortByColumn('Desc', "Mileage")}><TiArrowSortedUp /></span>
                      </OverlayTrigger>

                      <OverlayTrigger
                        key="bottom-start"
                        placement="bottom-start"
                        overlay={<Tooltip id={`tooltip-bottom-start`} className="table-tooltip" >Ascending</Tooltip>}
                      >
                        <span onClick={() => handleSortByColumn('Asc', "Mileage")}><TiArrowSortedDown /></span>
                      </OverlayTrigger>
                    </span>
                  </th>

                  <th>Buyer</th>
                  {/* <th>ID-Type</th>
                <th>ID-Number</th>
                <th>Address</th>
                <th>County</th> */}
                  <th>Phone</th>
                  {/* <th>Email</th> */}
                  <th>Sell Price</th>
                  <th>Sell Date</th>
                  <th>Payment Method</th>
                  <th>Payment</th>
                  <th>Payment Due</th>
                </tr>
              </thead>
              <tbody>
                {currentItems.map(renderTableRow)}
                {emptyRows}
              </tbody>
              <tfoot>
                <tr style={{ backgroundColor: "orange" }}>
                  <td colSpan={4}>Active Dealers: </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td colSpan={4}>Pending Request</td>
                  <td></td>
                </tr>
              </tfoot>
            </Table>
          </div>
        </Row>
        {/* <DealerData
        show={showModal}
        handleClose={handleModalClose}
        dealerData={dealerData}
        dealerId={dealerId}
        addCost="addcost"
      /> */}
      </Container>
      <Buyerdata
        show={showModal}
        handleClose={handleModalClose}
        cobuyerData={cobuyerData}
      />
      <AddlistingDataSold
        show={showModalAddlisting}
        handleClose={handleModalCloseAddlisting}
        addlistingData={addlistingData}
        addlistionIdShow={2}
      />
    </>
  );
};

export default DealerBalanceInventory;
