import "../Styles/Dealer_Input_Login_feature.css";
import React, { useEffect, useState } from "react";
import "../Styles/Dealer_Input_Login_feature.css";
import axios from "axios";

import { useLocation, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

const Dealer_ForgetPass_OTP_feature = () => {
  const location = useLocation();
  const navigate = useNavigate();
  console.log(
    "location.state in Dealer_ForgetPass_OTP_Feature and then get OTP",
    location.state
  );
  const [InputOtp, setInputOtp] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [disableResendOtp, setdisableResendOtp] = useState(false);
  console.log(errorMessage);
  console.log(InputOtp);

  const handleContinueResult = async (value) => {
    try {
      console.log("Handling API continue Result");
      const formData = new FormData();
      console.log("Data to be sent ");
      console.log("formData", formData);
      formData.append("email", location.state);
      formData.append("otp", value);
      formData.append("user_type", "Dealer");
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/verifyforgetotp`,
        formData
      );
      console.log(res);
      if (res.status !== 200) {
        console.log("User OTP Failure");
        setErrorMessage("User OTP Failure");
      }
      navigate("/dealer/forgetPassSetPass", { state: location.state });
    } catch (error) {
      console.log("error in  catch part while using otp");
      setErrorMessage(error.response.data);
    }
  };

  const handleInputChange = async (e) => {
    const { value } = e.target;
    if (/^\d{0,7}$/.test(value)) {
      setInputOtp(value);

      if (value.length === 6) {
        await handleContinueResult(value);
      }
    }
  };
  const [time, setTime] = useState(() => {
    const savedTime = localStorage.getItem("otpTimer");
    return savedTime ? parseInt(savedTime, 10) : 300;
  });

  const [isDisabled, setIsDisabled] = useState(() => {
    return localStorage.getItem("otpButtonDisabled") === "true";
  });
  useEffect(() => {
    let timer;

    if (time > 0 && isDisabled) {
      timer = setInterval(() => {
        setTime((prevTime) => {
          const newTime = prevTime - 1;
          localStorage.setItem("otpTimer", newTime.toString());
          return newTime;
        });
      }, 1000);
    } else if (time <= 0) {
      setIsDisabled(false);
      localStorage.removeItem("otpTimer");
      localStorage.setItem("otpButtonDisabled", "false");
      clearInterval(timer);
    }

    return () => clearInterval(timer); // Cleanup on component unmount
  }, [time, isDisabled]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(secs).padStart(
      2,
      "0"
    )}`;
  };
  const handleResendOtp = async () => {
    console.log("Please resend OTP");
    if (!isDisabled) {
      setIsDisabled(true);
      setTime(300); // Reset to 5 minutes
      localStorage.setItem("otpTimer", "300");
      localStorage.setItem("otpButtonDisabled", "true");
    }
    const formData = new FormData();
    formData.append("email", location.state);
    formData.append("user_type", "Dealer");
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/forgetotp`,
        formData
      );

      if (res.status === 200) {
        console.log(res.data);
        console.log("I am got new OTP");
        toast.success("OTP sent again.");
      }
    } catch (error) {
      console.log("Error in catch part", error);
      if (error.response.status === 400) {
        toast.error(error.response.data);
      } else if (error.response.status === 500) {
        toast.error(error.response.data);
      }
    }
  };
  useEffect(() => {
    localStorage.setItem("otpButtonDisabled", isDisabled.toString());
  }, [isDisabled]);

  useEffect(() => {
    // Reset timer and enable button when route changes
    return () => {
      setTime(300);
      setIsDisabled(false);
      localStorage.removeItem("otpTimer");
      localStorage.setItem("otpButtonDisabled", "false");
    };
  }, [location.pathname]);
  return (
    <div className="login_feature_wrapper">
      <div className="login_feature_container">
        <p className="welcome_message">Dealer Log-in</p>
        {/* <p className="sign_in_message">Please enter OTP to reset Password</p> */}

        <div className="input_and_icon">
          <input
            type="text"
            onChange={handleInputChange}
            value={InputOtp}
            placeholder="6 Digit OTP"
          />
        </div>
        {errorMessage ? <p className="error_message">{errorMessage}</p> : null}

        <p className="forget_password">
          <span
            style={{
              color: "white",
              borderBottom: "0",
              fontSize: "large",

              paddingBottom: "0",
            }}
          >
            {" "}
            {isDisabled ? formatTime(time) : ""}
          </span>
          <button
            className={`dealer_forget_pass_btn ${
              isDisabled ? "greyColor" : "whiteColor"
            }`}
            onClick={handleResendOtp}
            style={{
              cursor: "pointer",
              cursor: isDisabled ? "not-allowed" : "pointer",
              marginLeft: "0.5em",
              color: isDisabled ? "#B2BEB5" : "white",
              backgroundColor: "#515558",
              fontSize: "smaller",

              paddingBottom: "0px",
              width: "20%",
            }}
            disabled={isDisabled}
          >
            Resend OTP
          </button>
        </p>
      </div>
      <Toaster
        position="top-right"
        reverseOrder={false}
        toastOptions={{
          // Define default options
          className: "",
          duration: 3000,
          style: {
            background: "#363636",
            color: "#fff",
            fontSize: "larger",
          },
        }}
      />
    </div>
  );
};

export default Dealer_ForgetPass_OTP_feature;
