
import React, { useEffect, useState } from "react";
import "./dasbord.css";
import SideBar from "./SideBar";
import { Link, useNavigate } from "react-router-dom";
// import image from './image/Logo.png';
import Image from "../imgData";
import axios from "axios";

import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setData } from "../../Redux/slice/data";
import {
  logoutUser,
  resetAllSidebarCounts,
  setShowUserCompleteProfileDialogueBox,
} from "../../Redux/slice/userSlice";
import CompleteUserDetailModal from "../UserAuthDialogue/Pages/CompleteUserDetailModal";
import { resetSellData } from "../../Redux/slice/sellSlice";
import { Dropdown } from "primereact/dropdown";
import "primereact/resources/themes/saga-blue/theme.css"; // or other theme
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import InventoryDropdown from "./dropdown/InventoryDropdown";
import Addcost from "./dropdown/Addcost";
import { FaMicrophone } from "react-icons/fa";
import { IoMenu } from "react-icons/io5";
// import notificationbell from ',/imgData/notification.gif'
const apiUrl = process.env.REACT_APP_API_URL;

const MyProfile = () => {
  //const { id } = useSelector((state) => state.user);
  // Session Time out for 15 minutes-------------------------------------
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user);
  const { profile_picture, firstName, lastName } = userData;
  console.log("Id of user in my profile from redux", userData.id);
  const [showDialogueBox, setShowDialogueBox] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const timeoutInMinutes = 15; // Default timeout value
  const userStoreData = useSelector((state) => state.user);
  const [showUserActionsMobile, setShowNavMenuMobile] = useState(false);
  useEffect(() => {
    let timer;

    const resetTimer = () => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        //setIsActive(false); // Timeout occurred, log out or show session expired message
        console.log("session Expire......line15");
        navigate("/login");
      }, timeoutInMinutes * 60 * 1000); // Convert minutes to milliseconds
    };

    const clearTimer = () => {
      clearTimeout(timer);
    };

    resetTimer();

    window.addEventListener("mousemove", resetTimer); // Reset timer on mouse movement
    window.addEventListener("keydown", resetTimer); // Reset timer on key press
    window.addEventListener("scroll", resetTimer); // Reset timer on scroll
    window.addEventListener("resize", resetTimer); // Reset timer on window resize
    window.addEventListener("touchstart", resetTimer); // Reset timer on touch start
    window.addEventListener("touchend", resetTimer); // Reset timer on touch end

    return () => {
      window.removeEventListener("mousemove", resetTimer);
      window.removeEventListener("keydown", resetTimer);
      window.removeEventListener("scroll", resetTimer);
      window.removeEventListener("resize", resetTimer);
      window.removeEventListener("touchstart", resetTimer);
      window.removeEventListener("touchend", resetTimer);
      clearTimer();
    };
  }, []);
  console.log("User is available here");
  // Session Time out for 15 minutes End-------------------------------------
  //  Side bar text show on navbar------------------------------
  const [navbarText, setNavbarText] = useState("");
  const handleNavbarTextChange = (text) => {
    setNavbarText(text);
  };
  //  Side bar text show on navbar end------------------------------

  const [usersData, setUsersData] = useState("");
  const location = useLocation();

  // Function to fetch usersData based on email
  useEffect(() => {
    const fetchData = async () => {
      try {
        const email = location.state?.formData?.email; // Safely access email property
        if (email) {
          const response = await axios.post(
            `${apiUrl}/user/userProfile/email=${email}`
          );
          setUsersData(response.data);
          localStorage.setItem("usersData", JSON.stringify(response.data)); // Save usersData to local storage
        }
      } catch (error) {
        console.error("API Error:", error);
      }
    };

    // Fetch data only if usersData is not available or location state changes
    if (!usersData) {
      fetchData();
    }
  }, [location.state]);

  // Retrieve usersData from local storage when the component mounts
  useEffect(() => {
    const savedUsersData = JSON.parse(localStorage.getItem("usersData"));
    if (savedUsersData) {
      setUsersData(savedUsersData);
    }
  }, []);

  const newX = usersData; // Example: this could be any new value for x
  //console.log("line245",usersData);
  dispatch(setData(newX));

  useEffect(() => {
    if (firstName && lastName) {
      setShowDialogueBox(false);
      console.log(
        "All user details are present like first name , lastname and profile picture"
      );
    } else {
      console.log("Not present =>  first name , lastname and profile picture");
      setShowDialogueBox(true);
    }
  }, [firstName, lastName, profile_picture]);

  useEffect(() => {
    if (showDialogueBox) {
      document.body.classList.add("disable-scroll");
      // Disable other user interactions
      const disableUserEvents = (e) => e.stopImmediatePropagation();
      // window.addEventListener("click", disableUserEvents, true);
      window.addEventListener("keydown", disableUserEvents, true);

      return () => {
        window.removeEventListener("click", disableUserEvents, true);
        window.removeEventListener("keydown", disableUserEvents, true);
      };
    } else {
      document.body.classList.remove("disable-scroll");
    }
  }, [showDialogueBox]);

  const { sideBarOrder } = useSelector((state) => state.user);
  const { id } = useSelector((state) => state.user);
  const apiData =
    sideBarOrder &&
    sideBarOrder?.map((item) => ({
      tabId: item.id,
      tabCount: item.count,
      userId: id,
    }));
  const handleLogout = async () => {
    console.log("User is logged out");
    dispatch(logoutUser());
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/tab/save`,
        apiData
      );
      if (res.status === 200) {
        dispatch(resetAllSidebarCounts());
        localStorage.removeItem("user_sidebar_activity");
        dispatch(resetSellData());
      }
      console.log(
        "response from the API while sending user activity to the backend related to sidebar",
        res
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleLinkClick = () => {
    setShowModal(true);
  };

  // Function to handle closing the modal
  const handleModalClose = () => {
    setShowModal(false);
  };
  const handeMobileDesign = () => {
    setShowNavMenuMobile(!showUserActionsMobile);
  };

  const [isOpen, setIsOpen] = useState(false);
  const [isPermanentlyExpanded, setIsPermanentlyExpanded] = useState(false);
  console.log("isOpen", isOpen);
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
    setIsPermanentlyExpanded(!isPermanentlyExpanded);
  };

  return (
    <>
      <div className="container-scroller">
        {/* {showDialogueBox ? (
          <div className="complete_user_details_modal">
            <CompleteUserDetailModal />
          </div>
        ) : null} */}
        <nav
          className="navbar navbar-expand-lg"
          style={{ paddingTop: "0px" }}
        >
          <div className="container-fluid">
            <div style={{display:"flex",flexDirection:"row",gap:"10px",paddingTop:"28px"}}>
              <div><button
                onClick={toggleSidebar}
                className={`menu-button-user`}
              >
                <IoMenu />
              </button></div>
             <div><Link className="navbar-brand" to="/">
                <div style={{ textAlign: 'center', paddingTop:"1px" }}>
                  <h4 style={{ color: '#E8A262', fontWeight: 900 }}>
                    DEALERS <span style={{ color: 'black' }}>AUTO</span> CENTER
                  </h4>
                </div>
              </Link></div>
            </div>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              style={{ backgroundColor: "white" }}
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <div className="wrapper_search-dash-nav">
                {/* input field */}
                <span id="sdbrtxt">{navbarText}</span>
                <div><p className="input_search_field-dash-nav">
                  <input type="text" placeholder="Search by Vehicle" />
                  <span className="microphone_container-dash-nav">
                    <FaMicrophone />
                  </span>
                </p></div>
              </div>
              {/* </div> */}

              <div className="d-flex mssd navdata" style={{ marginLeft: "0px" }}>
                <div
                  className="col-lg-12 col-md-12"
                >
                  <div className="row" id="row-sec-nav">
                    {/* <div
                      className="col-2 cdb"
                      id="data-nav"
                      style={{ borderBottomLeftRadius: "10px", paddingTop: "40px" }}
                    >
                       <Link
                        to="/"
                        className="text-dark text-decoration-none"
                        id="data-nav-link"
                      >
                        <h6 style={{ marginTop: "-8px" }} >Home</h6>
                      </Link>
                    </div>  */}
                    <div className="col-3 cdb addinventory" id="data-nav" >
                      <Link
                        to="/myprofile/addlist"
                        className="text-dark text-decoration-none"
                        id="data-nav-link"
                      >
                        <h5 style={{ marginTop: "-8px", color: "rgb(232, 162, 98)" }} >Sale My Car</h5>
                      </Link>
                    </div>
                    {/* <div className="col-3 cdb" id="data-nav">
                      <Link
                        to="/myprofile/sell"
                        className="text-dark text-decoration-none"
                        id="data-nav-link"
                      >
                        <h5 style={{ marginTop: "-8px", color: "rgb(232, 162, 98)" }} >Make A Sale</h5>
                      </Link>
                    </div> */}
                    <div className="col-2 cdb notification" id="data-nav">
                      <Link
                        to="#"
                        className="text-dark text-decoration-none"
                        id="data-nav-link"
                      >
                        <h5 style={{ marginTop: "-8px", color: "rgb(232, 162, 98)" }} >Notification | 0</h5>
                      </Link>
                    </div>
                    <div
                      className="col-2 cdb navimg"
                      //  id="data-nav"
                      // onClick={handleLogout}
                      style={{ paddingRight: "0px" }}
                      
                    >
                      <div className="user_action_mobile" style={{ width: "78px" }}>
                        <div style={{ display: "flex", flexDirection: "column" }}><img
                          src={
                            userStoreData.profile_picture
                              ? `https://5starsmotors.com/images/${userData.id}/${userStoreData.profile_picture}`
                              : Image.avatar_img
                          }
                          alt="avatar image"
                          height={35}
                          width={35}
                          onClick={handeMobileDesign}
                          style={{ borderRadius: "50%" }}
                        />
                          <p style={{ color: "black" }}>
                            {userStoreData.firstName
                              ? `${userStoreData.firstName}`
                              : "User"}
                          </p></div>
                        {showUserActionsMobile ? (
                          <div className="user_action_mobile_items-dash">
                            <p>
                              <Link to="/myprofile/customerdetails">Profile</Link>
                            </p>
                            <p onClick={handleLogout} style={{ cursor: "pointer" }} id="dash-logout">
                              Logout
                            </p>
                          </div>
                        ) : null}
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
      <SideBar onButtonClick={handleNavbarTextChange} isOpen={isOpen}
        toggleSidebar={toggleSidebar}
        isPermanentlyExpanded={isPermanentlyExpanded} setIsOpen={setIsOpen} setIsPermanentlyExpanded={setIsPermanentlyExpanded} />
    </>
  );
};

export default MyProfile;
