// YahooSignIn.js
import { auth, YahooOAuthProvider } from "./firebaseConfig";
import { signInWithPopup } from "firebase/auth";
import axios from "axios";
import { saveUser } from "../../../Redux/slice/userSlice"; // Adjust the path as needed

const getIPAddress = async () => {
  try {
    const res = await axios.get("https://api.ipify.org/?format=json");
    return res.data.ip;
  } catch (error) {
    console.error("Error fetching IP information:", error);
    return "";
  }
};

const YahooSignIn = async (dispatch, navigate,userType) => {
  try {
    // Fetch IP address
    const ip = await getIPAddress();

    // Proceed with Yahoo sign-in
    const result = await signInWithPopup(auth, YahooOAuthProvider);
    const user = result.user;

    console.log("User:", user);
    console.log("User Name:", user.displayName);
    console.log("User Email:", user.email);
    console.log("User Phone Number:", user.phoneNumber);
    console.log("User IP:", ip);

    // Create FormData instance
    const formData = new FormData();
    formData.append('email', user.email || '');
    formData.append('name', user.displayName ? user.displayName.split(' ')[0] : '');
    formData.append('ip', ip);
    formData.append('phoneNumber', user.phoneNumber || '');
    formData.append('user_type', userType);

    // Send the form data to your API
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/user/save_user`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );

    if (res.status === 200) {
      dispatch(
        saveUser({
          email: res.data.email,
          id: res.data.id,
          firstName: res.data.firstName,
          lastName: res.data.lastName,
          roles : res.data.roles,
          loginAccess: res.data.loginAccess,
          dealerApproval: res.data.dealerApproval,
          dealerData:res.data.dealerData,
          loginAccess: res.data.loginAccess,
          dealerApproval: res.data.dealerApproval,
          dealerData:res.data.dealerData,
        })
      );
      if (res.data.roles === "Dealer") {
         navigate("/dealer/dashboard");
      }
    }
  } catch (error) {
    console.error("Sign in with Yahoo failed:", error);
    if (error.response && error.response.status === 400) {
      // throw new Error(error.response.data + " as " + userType === "Dealer" ? "User" : "Dealer");
      const oppositeUserType = userType === "Dealer" ? "User" :  userType;
      
      console.log(`${error.response.data} as ${oppositeUserType}`)
      throw new Error(`${error.response.data} as ${oppositeUserType}`);
    }
    // else {
    //   throw new Error("An unexpected error occurred. Please try again later.");
    // }
  }
};

export default YahooSignIn;